import { useEffect, useRef, useState } from "react";
import Input from "../../../library/input";
import Selections from "../../../library/selections";
import { AttentionBoxWrapper, ListWrapper, SearchContainer } from "./styled";
import EmptyView from "../../EmptyView";
import { OPTIONS } from "../options";
import AttentionBox from "../../../library/attention-box";

interface TranscriptSelectProps {
  onSelect: (value: any) => void;
  variant: "editor" | "list";
  selection?: string;
  generateLoading?: boolean;
  videoDownloadUrl?: string;
  descriptionHeight?: number;
}

const Index = ({
  onSelect,
  selection,
  variant,
  generateLoading,
  videoDownloadUrl,
  descriptionHeight,
}: TranscriptSelectProps) => {
  const [attentionBoxHeight, setAttentionBoxHeight] = useState<number>(0);
  const attentionBoxRef = useRef<HTMLDivElement>(null);
  const [searchQuery, setSearchQuery] = useState<string>();

  const VIDEO_SELECTION = {
    id: "video-subtitles",
    title: "Video med undertekst",
    description: "Undertekst brent inn i video",
    value: "video-subtitles",
    icon: generateLoading ? "spinner" : "transcription",
    paid: false,
  };

  useEffect(() => {
    if (selection && selection === "transcript") {
      onSelect(OPTIONS[0].id);
    }
  }, [selection, onSelect]);

  const clickSelect = (value: any) => {
    onSelect(value.id);
  };

  const handleSearch = (value: any) => {
    const term = value.target.value.toUpperCase();
    setSearchQuery(term);
  };

  const options = () => {
    let ALL_OPTIONS: any[] = [];

    if (variant === "list") {
      ALL_OPTIONS = OPTIONS;
    } else {
      ALL_OPTIONS = [VIDEO_SELECTION, ...OPTIONS];
    }

    if (searchQuery) {
      return ALL_OPTIONS.filter((x) => x.title.includes(searchQuery));
    }
    return ALL_OPTIONS;
  };

  useEffect(() => {
    if (videoDownloadUrl && attentionBoxRef.current) {
      const height = attentionBoxRef.current.clientHeight; // Get the height
      setAttentionBoxHeight(height); // Store the height in state
    }
  }, [videoDownloadUrl]);

  return (
    <div>
      <SearchContainer>
        {videoDownloadUrl && (
          <AttentionBoxWrapper ref={attentionBoxRef}>
            <AttentionBox
              type="information"
              icon="download"
              title="Video klar til nedlasting"
              text={`Din video er ferdig generert og kan nå lastes ned ved å trykke på "Last ned"-knappen igjen.`}
            />
          </AttentionBoxWrapper>
        )}
        <Input
          onChange={handleSearch}
          placeholder="Søk etter type"
          variant="search"
        />
      </SearchContainer>
      <ListWrapper
        descriptionHeight={descriptionHeight}
        attentionBoxHeight={attentionBoxHeight}
      >
        {options().length > 0 ? (
          <Selections
            defaultSelected={selection}
            options={options()}
            onSelect={clickSelect}
            type="format"
          />
        ) : (
          <EmptyView
            title="Ingen filtyper"
            description="Fant ingen filtyper som matchet søket ditt."
            size="small"
          />
        )}
      </ListWrapper>
    </div>
  );
};

export default Index;

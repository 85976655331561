import React from "react";
import { Title } from "../../library/title";
import {
  ModalTitle,
  PopupModal,
  ExitIconButton,
  ModalBody,
  ContentContainer,
  ModalText,
  ModalButtonsContainer,
} from "./styled";
import Button from "../button";
import { useTranslation } from "react-i18next";

interface IModalProps {
  onClose: () => void;
  open: boolean;
  title: string;
  text?: string;
  onSubmit: any;
  submitText?: string;
  submitAriaLabel?: string;
}

const Confirm: React.FC<IModalProps> = ({
  open,
  onClose,
  title,
  text,
  onSubmit,
  submitText,
  submitAriaLabel,
}) => {
  const { t } = useTranslation();
  return (
    <PopupModal size={"lg"} show={open} onHide={onClose}>
      <ModalTitle>
        <Title>{title}</Title>
        <ExitIconButton onClick={onClose}>
          <i className="fa-solid fa-xmark"></i>
        </ExitIconButton>
      </ModalTitle>
      <ModalBody>
        <ContentContainer>
          <ModalText>{text}</ModalText>
        </ContentContainer>
      </ModalBody>
      <ModalButtonsContainer>
        <Button
          onClick={onClose}
          color="grey"
          variant="regular"
          spacing="right"
          ariaLabel={
            t("dashboard.universal.modals.buttons.cancel.ariaLabel") as string
          }
        >
          {t("dashboard.universal.modals.buttons.cancel.title")}
        </Button>
        <Button
          ariaLabel={submitAriaLabel}
          onClick={onSubmit}
          color="blue"
          variant="regular"
        >
          {submitText}
        </Button>
      </ModalButtonsContainer>
    </PopupModal>
  );
};

export default Confirm;

import styled from "@emotion/styled";

export const Section = styled.section``;

export const Container = styled.div`
  max-width: 87.5rem;
  margin: 0 auto;
  padding: 4.6875rem 1.25rem;
  @media (max-width: 767px) {
    padding: 0rem 1.25rem;
  }
`;

export const MessageContainer = styled.div`
  text-align: center;
  padding: 12.5rem 0rem;
`;

export const AnimationWrapper = styled.div`
  max-width: 31.25rem;
  margin: 0 auto;
  margin-bottom: 1.5625rem;
`;

export const TextWrapper = styled.div`
  max-width: 35.9375rem;
  margin: 0 auto;
  margin-top: 0.625rem;
`;

export const ButtonsWrapper = styled.div`
  margin-top: 1.5625rem;
`;

import styled from "@emotion/styled";

export const SelectionWrapper = styled.div``;

export const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  button {
    margin-left: 5px;
    margin-right: 5px;
  }
`;

export const Field = styled.div`
  margin-bottom: 15px;
  input {
    margin-top: 5px;
  }
`;

export const DetailsRow = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
`;

export const DetailCol = styled.div`
  &:first-of-type {
    width: 30%;
  }
  &:last-of-type {
    width: 70%;
  }
`;

export const Thumbnail = styled.img`
  width: 100%;
  max-width: 200px;
`;

export const SmallText = styled.small`
  font-family: Poppins, sans-serif;
  color: grey;
  font-size: 12px;
  padding-left: 20px;
  display: block;
  margin-top: 10px;
`;

import { Bold } from "../../styled";
import { ListItem, ListWrapper, TabsWrapper } from "./styled";

export const Index = () => {
  return (
    <TabsWrapper>
      <ListWrapper>
        <ListItem>
          Velg <Bold>Lyd eller videofil</Bold> fra <Bold>Velg type</Bold>
          -steget.
        </ListItem>
        <ListItem>
          Velg deretter ønsket språk du ønsker lyd eller videofilen din tekstet
          til. Randi gjør oversettelse til over 70 språk.
        </ListItem>
        <ListItem>
          Trykk på <Bold>Velg fil</Bold>-teksten eller dra filen din inn i den
          rutete boksen og trykk på <Bold>Lag transkript</Bold>-knappen nederst.
        </ListItem>
      </ListWrapper>
    </TabsWrapper>
  );
};

export default Index;

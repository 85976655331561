import { firebaseApp } from "../firebase";
import { getAuth } from "firebase/auth";

const useDeleteBlogpost = () => {
  const auth = getAuth(firebaseApp);

  const deleteBlogpost = async (id: string) => {
    try {
      const token = await auth.currentUser?.getIdToken(true);
      const request = await fetch(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/admin/blogposts/delete/${id}/${token}`,
        { method: "DELETE" }
      );
      const response = await request.json();
      if (response.error === null) {
        return { error: null };
      } else {
        throw response.error;
      }
    } catch (e) {
      throw e;
    }
  };
  return { deleteBlogpost };
};

export default useDeleteBlogpost;

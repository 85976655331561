import styled from "@emotion/styled";

export const TextareaWrapper = styled.div``;

export const StyledTextarea = styled.textarea`
  width: 100%;
  padding: 0.7813rem 1.25rem;
  border-radius: 0.875rem;
  border: 1px solid #f1f6ff;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  background-color: #f4f4f4;
  &:focus {
    outline: 0.125rem solid #2f8de4 !important;
  }
`;

export const ErrorWrapper = styled.div`
  padding-left: 15px;
`;

import styled from "@emotion/styled";
import ReactQuill from "react-quill";

export const StyledTextEditorContainer = styled.div`
  position: relative;
`;

export const StyledTextEditor = styled(ReactQuill)`
  min-height: 12.5rem;
`;

export const ErrorWrapper = styled.div`
  padding-left: 15px;
`;

import moment from "moment";
import { TableData, TableRow as FullTableRow, Value, Badge } from "./styled";
import { useSelector } from "react-redux";
import Button from "../../../../../../../../library/button";
import useDeleteMember from "../../../../../../../../hooks/useDeleteMember";
import {
  sendErrorNotification,
  sendSuccessNotification,
} from "../../../../../../../../library/notification";
import useMembersList from "../../../../../../../../hooks/useMembersList";
import useWorkspaceDetails from "../../../../../../../../hooks/useWorkspaceDetails";
import { useTranslation } from "react-i18next";
import useDeleteInvite from "../../../../../../../../hooks/useDeleteInvite";
import { IState } from "../../../../../../../../types";
import Confirmation from "../../../../../../../../library/modal/confirmation";
import { useState } from "react";

interface ListItemProps {
  data: any;
}

const ListItem: React.FC<ListItemProps> = ({ data }) => {
  const { t } = useTranslation();

  const { deleteMember } = useDeleteMember();
  const { deleteInvite } = useDeleteInvite();
  const { fetchMembersList } = useMembersList();
  const { fetchWorkspaceDetails } = useWorkspaceDetails();
  const userId = useSelector((state: IState) => state.user?.details?.uid);
  const [showDeleteInvitationModal, setShowDeleteInvitationModal] =
    useState<boolean>(false);
  const [showDeleteMemberModal, setShowDeleteMemberModal] =
    useState<boolean>(false);

  const openDeleteInvitationModal = () => {
    setShowDeleteInvitationModal(true);
  };

  const closeDeleteInvitationModal = () => {
    setShowDeleteInvitationModal(false);
  };

  const openDeleteMemberModal = () => {
    setShowDeleteMemberModal(true);
  };

  const closeDeleteMemberModal = () => {
    setShowDeleteMemberModal(false);
  };

  const clickDeleteMember = async () => {
    try {
      const deletion = await deleteMember(data.id);
      if (deletion.error === null) {
        closeDeleteMemberModal();
        sendSuccessNotification(
          t("dashboard.universal.notifications.workspace.delete-user.success")
        );
        fetchMembersList();
        fetchWorkspaceDetails();
      } else {
        closeDeleteMemberModal();
        sendErrorNotification(
          t("dashboard.universal.notifications.workspace.delete-user.failed")
        );
      }
    } catch (e) {
      closeDeleteMemberModal();
      sendErrorNotification(
        t("dashboard.universal.notifications.workspace.delete-user.failed")
      );
    }
  };

  const clickDeleteInvitation = async () => {
    try {
      const deletion = await deleteInvite(data.id);
      if (deletion.error === null) {
        closeDeleteInvitationModal();
        sendSuccessNotification(
          t(
            "dashboard.universal.notifications.workspace.delete-invitation.success"
          )
        );
        fetchMembersList();
        fetchWorkspaceDetails();
      } else {
        closeDeleteInvitationModal();
        sendErrorNotification(
          t(
            "dashboard.universal.notifications.workspace.delete-invitation.failed"
          )
        );
      }
    } catch (e) {
      closeDeleteInvitationModal();
      sendErrorNotification(
        t(
          "dashboard.universal.notifications.workspace.delete-invitation.failed"
        )
      );
    }
  };

  const renderName = () => {
    if (data.recipient) {
      return "-";
    } else {
      return `${data.firstName} ${data.lastName}`;
    }
  };

  const checkMember = () => {
    if (data?.recipient) {
      return false;
    } else {
      return true;
    }
  };

  const clickDelete = () => {
    if (data?.recipient) {
      openDeleteInvitationModal();
    } else {
      openDeleteMemberModal();
    }
  };

  return (
    <FullTableRow>
      <TableData>
        <Value className="bold">{renderName()}</Value>
      </TableData>
      <TableData>
        <Value>{data.email}</Value>
      </TableData>
      <TableData>
        <Value>{data.role ? data.role : "-"}</Value>
      </TableData>
      <TableData>
        <Badge className={checkMember() ? "active" : "pending"}>
          {checkMember()
            ? t(
                "dashboard.pages.workspace.bottom-view.table.data.statuses.active"
              )
            : t(
                "dashboard.pages.workspace.bottom-view.table.data.statuses.pending"
              )}
        </Badge>
      </TableData>
      <TableData>
        <Value>{moment.unix(data.timestamp).format("HH:mm DD/MM/YYYY")}</Value>
      </TableData>
      <TableData>
        <Button
          color="grey"
          onClick={clickDelete}
          disabled={data.uid === userId}
          size="sm"
          variant="regular"
          ariaLabel={
            t(
              "dashboard.pages.workspace.bottom-view.table.data.buttons.delete.ariaLabel"
            ) as string
          }
        >
          {t(
            "dashboard.pages.workspace.bottom-view.table.data.buttons.delete.title"
          )}
        </Button>
      </TableData>
      <Confirmation
        open={showDeleteMemberModal}
        onClose={closeDeleteMemberModal}
        title={t("dashboard.pages.workspace.delete-user-modal.title")}
        description={t("dashboard.pages.workspace.delete-user-modal.text")!}
        variant="delete"
        submitText={
          t("dashboard.pages.workspace.delete-user-modal.buttons.delete")!
        }
        onSubmit={clickDeleteMember}
      />
      <Confirmation
        open={showDeleteInvitationModal}
        onClose={closeDeleteInvitationModal}
        title={t("dashboard.pages.workspace.delete-invite-modal.title")}
        description={t("dashboard.pages.workspace.delete-invite-modal.text")!}
        variant="delete"
        submitText={
          t("dashboard.pages.workspace.delete-invite-modal.buttons.delete")!
        }
        onSubmit={clickDeleteInvitation}
      />
    </FullTableRow>
  );
};

export default ListItem;

import styled from "@emotion/styled";

export const SearchContainer = styled.div`
  margin-top: 1.875rem;
  input {
    width: 100%;
    height: 3.125rem;
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  background-color: #f2f2f2;
  padding: 0.7813rem 1.25rem;
  border-radius: 0.875rem;
  border: none;
  font-family: "Poppins", sans-serif;
`;

export const ContentContainer = styled.div`
  margin-top: 1.5625rem;
`;

export const InteractionContainer = styled.div`
  border-color: #ebedf2;
  border-radius: 1rem;
  border-width: 0.125rem;
  border-style: dashed;
  padding: 1.875rem 0.625rem;
  text-align: center;
  position: relative;
  margin-top: 1.5625rem;
  &.error {
    border-color: #ed0c60;
  }
`;

export const OuterLoadingWrapper = styled.div``;

export const LoadingDroppedContainer = styled.div`
  height: 3.75rem;
  width: 3.75rem;
  margin: 0 auto;
  display: block;
  font-family: NexaBold;
`;

export const LoadingText = styled.span`
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-align: center;
  font-size: 0.875rem;
  margin-top: 0.9375rem;
  display: block;
  color: grey;
`;

export const DroppedContainer = styled.div`
  postion: relative;
`;

export const RemoveButton = styled.button`
  position: absolute;
  right: 0.625rem;
  top: 0.625rem;
  border: none;
  background: none;
  color: #f83d76;
  font-size: 1.125rem;
`;

export const FileIcon = styled.h2`
  color: #2f8de4;
`;

export const FileName = styled.h4`
  font-family: "Poppins", sans-serif;
  color: #3b3b3b;
  font-size: 1rem;
  font-weight: 600;
`;

export const FileSize = styled.span`
  font-size: 0.875rem;
  display: block;
  font-family: "Poppins", sans-serif;
  color: grey;
  margin-top: -0.3125rem;
`;

export const NotDroppedContainer = styled.div``;

export const UploadFileIcon = styled.h3`
  font-size: 1.75rem;
  color: grey;
`;

export const UploadTextWrapper = styled.span`
  font-family: "Poppins", sans-serif;
  font-weight: 500;
`;

export const UploadButtonLink = styled.button`
  border: none;
  background: transparent;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #2f8de4 !important;
  font-size: 0.875rem;
  padding: 0rem;
`;

export const FileSupportedText = styled.span`
  display: block;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: grey;
  font-size: 0.75rem;
`;

export const DetailsContainer = styled.div`
  margin-top: 1.5625rem;
  input {
    width: 100%;
  }
`;

export const ModalButtonsContainer = styled.div`
  margin-top: 1.5625rem;
  text-align: center;
`;

export const ToggleButtonWrapper = styled.div`
  text-align: center;
  margin-bottom: 25px;
`;

export const ToggleGroupContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5625rem;
  padding: 0.4375rem 0.3125rem;
  border: 0.0625rem solid #f4f4f4;
  border-radius: 0.875rem;
`;

export const ToggleButton = styled.button`
  border: none;
  margin-left: 0.1563rem;
  margin-right: 0.1563rem;
  padding: 0.625rem 1.5625rem;
  border-radius: 0.75rem;
  background: none;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: grey;
  font-size: 0.875rem;
  &.active {
    color: grey;
    background-color: #f4f4f4;
  }
`;

export const Field = styled.div`
  margin-bottom: 0.9375rem;
  input {
    margin-top: 0.3125rem;
  }
`;

export const FileUploadButton = styled.input`
  display: none;
`;

export const ParticipantsWrapper = styled.div``;

export const LoaderContainer = styled.div`
  position: relative;
  padding-top: 1.5625rem;
  padding-bottom: 1.5625rem;
`;

export const InnerLoaderContainer = styled.div`
  text-align: center;
  margin: 0 auto;
  display: block;
`;

export const UploadText = styled.span`
  display: block;
  font-family: "Poppins", sans-serif;
  color: grey;
  font-size: 0.875rem;
  font-weight: 400;
`;

export const TableWrapper = styled.div`
  margin-top: 1.25rem;
  &.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  @media (max-width: 767px) {
    overflow-x: scroll;
  }
`;

export const MobileNotificationWrapper = styled.div``;

export const MobileNotificationText = styled.span``;

export const UploadForm = styled.form`
  transition: 0.5s;
`;

export const ErrorWrapper = styled.div`
  padding-left: 15px;
`;

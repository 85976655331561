import { Title } from "../../library/title";
import { ButtonsWrapper, TitleWrapper, TopBarWrapper } from "./styled";

interface DashboardTopBarProps {
  title: string;
  buttons?: React.ReactNode;
}

const DashboardTopBar: React.FC<DashboardTopBarProps> = ({
  title,
  buttons,
}) => {
  return (
    <TopBarWrapper>
      <TitleWrapper>
        <Title>{title}</Title>
      </TitleWrapper>
      <ButtonsWrapper>{buttons}</ButtonsWrapper>
    </TopBarWrapper>
  );
};

export default DashboardTopBar;

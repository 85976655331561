import styled from "@emotion/styled";

export const TableRow = styled.tr`
  border-bottom: 0.0625rem solid rgb(235, 235, 235);

  &:last-of-type {
    border: none !important;
  }
`;

export const TableData = styled.td`
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  &:first-of-type {
    max-width: 2.5rem !important;
  }
  &:last-of-type {
    max-width: 8.75rem;
  }
  @media (max-width: 767px) {
    &:nth-of-type(2) {
      min-width: 9.375rem;
    }
    &:last-of-type {
      max-width: unset;
    }
  }
`;

export const Value = styled.span`
  font-family: "Poppins", sans-serif;
  color: grey;
  font-size: 0.875rem;
  a {
    font-family: "Poppins", sans-serif;
    color: grey;
    text-decoration: none;
  }
  &.bold {
    font-weight: 500;
  }
`;

export const ProgressWrapper = styled.span`
  margin-right: 0.625rem;
`;

export const Status = styled.span`
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  background: none;
  &.completed {
    color: rgb(80, 205, 137);
  }
  &.progress {
    color: rgb(255, 199, 0);
  }
  &.failed {
    color: rgb(241, 65, 108);
  }
`;

export const Badge = styled.span`
  background-color: yellow;
  font-size: 0.75rem;
  padding: 0.4688rem 0.625rem;
  border-radius: 0.25rem;
  display: inline;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  background-color: rgb(241, 250, 255);
  color: rgb(0, 158, 247);
`;

export const ButtonsWrapper = styled.div`
  min-width: 15.625rem;
  display: inline-flex !important;
  button {
    &:last-of-type {
      margin-right: 0.9375rem;
    }
  }
`;

export const ButtonWrapper = styled.div`
  button {
    height: 2.5rem !important;
  }
`;

import { Tabs as AllTabs } from "react-bootstrap";
import { SingleTab, TabsWrapper } from "./styled";
import React from "react";

interface ITabsProps {
  selections: any[];
  activeTab: string;
  setActiveTab: (key: string) => void;
}

const Tabs: React.FC<ITabsProps> = ({
  activeTab,
  setActiveTab,
  selections,
}) => {
  return (
    <TabsWrapper>
      <AllTabs
        activeKey={activeTab}
        onSelect={(key) => setActiveTab(key as string)}
      >
        {selections.map((selection) => (
          <SingleTab
            aria-label={selection.ariaLabel}
            eventKey={selection.key}
            title={selection.title}
          >
            {React.cloneElement(selection.display, {
              isActive: activeTab === selection.key,
            })}
          </SingleTab>
        ))}
      </AllTabs>
    </TabsWrapper>
  );
};

export default Tabs;

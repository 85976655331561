import { useCallback, useState } from "react";

const useInvitation = () => {
  const [valid, setValid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const validateInvitation = useCallback(
    async (id: string) => {
      try {
        const request = await fetch(
          `${process.env.REACT_APP_BACKEND_ENDPOINT}/validate-invitation?invitation=${id}`,
          { method: "GET" }
        );
        const response = await request.json();
        if (response.error === null && !valid) {
          setValid(true);
          setLoading(false);
          return { error: null, email: response.result };
        } else {
          throw response.error;
        }
      } catch (e) {
        setLoading(false);
        throw e;
      }
    },
    [valid]
  );

  return { validateInvitation, loading, valid };
};

export default useInvitation;

import { useForm } from "react-hook-form";
import { ContactFormSchema, schema } from "./schema";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  ButtonWrapper,
  Field,
  Form,
  MultiWrapper,
  NotificationWrapper,
} from "./styled";
import { Label } from "../../../../library/label";
import Input from "../../../../library/input";
import { useTranslation } from "react-i18next";
import Textarea from "../../../../library/textarea";
import Button from "../../../../library/button";
import useContactForm from "../../../../hooks/useContactForm";
import AttentionBox from "../../../../library/attention-box";
import { useState } from "react";

const FormView = () => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<ContactFormSchema>({
    resolver: zodResolver(schema),
    defaultValues: {
      email: "",
      firstName: "",
      lastName: "",
      subject: "",
      message: "",
    },
  });
  const { submitForm } = useContactForm();

  const [notification, setNotification] = useState<string | null>(null);

  const renderNotification = () => {
    switch (notification) {
      case "send-error":
        return (
          <AttentionBox
            icon="error"
            title={t("contact-us.notifications.send-error.title")}
            text={t("contact-us.notifications.send-error.text")}
            type="fail"
          />
        );
      case "success":
        return (
          <AttentionBox
            icon="error"
            title={t("contact-us.notifications.success.title")}
            text={t("contact-us.notifications.success.text")}
            type="information"
          />
        );
      default:
        break;
    }
  };

  const clickSubmit = async (data: ContactFormSchema) => {
    try {
      const message = await submitForm(data);
      if (message?.error === null) {
        setNotification("success");
        reset();
      } else {
        throw message?.error;
      }
    } catch (e) {
      setNotification("send-error");
      reset();
    }
  };

  return (
    <>
      <NotificationWrapper>{renderNotification()}</NotificationWrapper>
      <Form onSubmit={handleSubmit(clickSubmit)}>
        <MultiWrapper>
          <Field>
            <Label htmlFor="firstNameCInput">
              {t("contact-us.form.first-name.label")}
            </Label>
            <Input
              placeholder={
                t("contact-us.form.first-name.input.placeholder") as string
              }
              ariaLabel={
                t("contact-us.form.first-name.input.ariaLabel") as string
              }
              id="firstNameCInput"
              name="firstName"
              register={register}
              error={errors["firstName"]}
              variant="regular"
            />
          </Field>
          <Field>
            <Label htmlFor="lastNameCInput">
              {t("contact-us.form.last-name.label")}
            </Label>
            <Input
              placeholder={
                t("contact-us.form.last-name.input.placeholder") as string
              }
              ariaLabel={
                t("contact-us.form.last-name.input.ariaLabel") as string
              }
              id="lastNameCInput"
              name="lastName"
              register={register}
              error={errors["lastName"]}
              variant="regular"
            />
          </Field>
        </MultiWrapper>
        <Field>
          <Label htmlFor="emailCInput">
            {t("contact-us.form.email.label")}
          </Label>
          <Input
            placeholder={t("contact-us.form.email.input.placeholder") as string}
            ariaLabel={t("contact-us.form.email.input.ariaLabel") as string}
            name="email"
            type="email"
            id="emailCInput"
            register={register}
            error={errors["email"]}
            variant="regular"
          />
        </Field>
        <Field>
          <Label htmlFor="subjectCInput">
            {t("contact-us.form.subject.label")}
          </Label>
          <Input
            placeholder={
              t("contact-us.form.subject.input.placeholder") as string
            }
            ariaLabel={t("contact-us.form.subject.input.ariaLabel") as string}
            name="subject"
            id="subjectCInput"
            register={register}
            error={errors["subject"]}
            variant="regular"
          />
        </Field>
        <Field>
          <Label htmlFor="messageCInput">
            {t("contact-us.form.message.label")}
          </Label>
          <Textarea
            name="message"
            register={register}
            error={errors["message"]}
            id="messageCInput"
            ariaLabel={t("contact-us.form.message.input.ariaLabel") as string}
            placeholder={
              t("contact-us.form.message.input.placeholder") as string
            }
          />
        </Field>
        <ButtonWrapper>
          <Button
            type="submit"
            color="blue"
            variant="regular"
            ariaLabel="Send melding"
          >
            {t("contact-us.form.submit-button")}
          </Button>
        </ButtonWrapper>
      </Form>
    </>
  );
};

export default FormView;

import styled from "@emotion/styled";

export const PageContainer = styled.div`
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  width: 100%;
  @media (max-width: 975px) {
    margin-top: 1.8625rem;
    padding-top: 1.5625rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export const InnerPageContainer = styled.div`
  margin: 1.875rem !important;
  padding: 1.875rem;
  background-color: white;
  border-color: #eff2f5;
  border-radius: 1.25rem;
  box-shadow: 0rem 0rem 0.625rem #e2e2e2;
  overflow: hidden;

  &.default {
    min-height: calc(100vh - 3.125rem - 5.4375rem - 2.5rem);
  }

  &.stacked {
    &:first-of-type {
      margin-bottom: 1.5625rem !important;
    }
  }
  @media (max-width: 767px) {
    margin: 0rem !important;
    padding: 0rem 0rem 0rem 0rem !important;
    box-shadow: none;
    background-color: transparent;
    border-radius: 0;
  }
`;

export const NotificationWrapper = styled.div`
  margin: 1.875rem !important;
`;

import styled from "@emotion/styled";

export const FullWrapper = styled.div`
  position: relative;
`;

export const ColorWrapper = styled.div`
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 0.875rem;
  margin-top: 0.3125rem;
`;

export const InnerColorWrapper = styled.div`
  display: flex;
  align-items: center;
  button {
    position: absolute;
    right: 5px;
  }
`;

export const NotVisibleIndicator = styled.div`
  text-align: center;
  font-size: 14px;
  color: grey;
  padding: 4.5px 5px;
`;

export const ColorIndicator = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 0.5rem;
  background-color: #ffffff;
`;

export const ColorText = styled.span`
  margin-left: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
`;

export const SelectorWrapper = styled.div`
  position: absolute;
  background-color: #ffffff;
  max-width: 250px;
  max-height: 300px;
  z-index: 999;
`;

export const MobileSelectorWrapper = styled.div`
  position: fixed;
  background-color: #ffffff;
  padding: 20px;
  min-width: 100vw;
  bottom: 30px;
  left: 0px;
  right: 0px;
  text-align: center;
  z-index: 100;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 20px;
  z-index: 999;
  .chrome-picker {
    box-shadow: none !important;
    background-color: #fff !important;
    min-width: 100%;
    & > div:first-of-type {
      padding-bottom: 120px !important;
      & > div {
        height: 120px !important;
      }
    }
    & > div:last-of-type {
      padding-top: 8px !important;
      padding-bottom: 2px !important;
      padding-left: 0px !important;
      & > .flexbox-fix:first-of-type {
        display: flex !important;
        align-items: center;
        justify-content: center;
        & > div:first-of-type {
          display: none;
        }
        & > div:last-of-type {
          padding-top: 5px;
          padding-bottom: 15px;
          padding-right: 30px;
        }
      }
      & > .flexbox-fix:last-of-type {
        display: none !important;
      }
    }
  }
  @media (max-height: 570px) {
    .chrome-picker {
      & > div:first-of-type {
        padding-bottom: 70px !important;
        & > div {
          height: 70px !important;
        }
      }
    }
  }
`;

export const FinishButton = styled.button`
  position: absolute;
  bottom: 33px;
  right: 20px;
  border: none;
  background-color: #fff;
  color: #2f8de4;
  font-size: 18px;
  padding: 0px !important;
  margin-right: 0px;
  font-size: 20px !important;
  padding: 0px 5px !important;
  border-radius: 8px;
  font-size: 22px !important;
`;

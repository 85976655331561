import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser, UserDetails, Workspace } from "../../types/user";

const initialState: IUser = {
  details: {
    firstName: "",
    lastName: "",
    phone: "",
    uid: "",
    role: "",
    thumbnail: "",
    consents: [],
    onboarded: false,
  },
  workspaces: [],
  loadingWorkspaces: true,
  loadingUserDetails: true,
  selectedWorkspace: {
    id: "",
    name: "",
    subscription: "",
    role: "",
    status: "",
  },
  fetchDetails: {
    status: false,
    loading: false,
    error: null,
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserCredentials: (state, action: PayloadAction<UserDetails>) => {
      state.details = action.payload;
    },
    setLoadingUserDetails: (state, action: PayloadAction<boolean>) => {
      state.loadingUserDetails = action.payload;
    },
    setUserWorkspaces: (state, action: PayloadAction<Workspace[]>) => {
      state.workspaces = action.payload;
    },
    setLoadingWorkspaces: (state, action: PayloadAction<boolean>) => {
      state.loadingWorkspaces = action.payload;
    },
    setActiveWorkspace: (state, action: PayloadAction<Workspace>) => {
      state.selectedWorkspace = action.payload;
    },
    updateWorkspaceSubscription: (state, action: PayloadAction<any>) => {
      const { id, status } = action.payload;
      state.selectedWorkspace = {
        ...state.selectedWorkspace,
        status,
      };
      state.workspaces = state.workspaces.map((workspace) =>
        workspace.id === id ? { ...workspace, status } : workspace
      );
    },
    clearUserState: (state) => {
      state.details = {
        firstName: "",
        lastName: "",
        phone: "",
        uid: "",
        role: "",
        thumbnail: "",
        consents: [],
        onboarded: false,
      };
      state.workspaces = [];
      state.loadingWorkspaces = true;
      state.loadingUserDetails = true;
      state.selectedWorkspace = {
        id: "",
        name: "",
        subscription: "",
        role: "",
      };
      state.fetchDetails = {
        status: false,
        loading: false,
        error: null,
      };
    },
    setUserOnboarded: (state) => {
      state.details.onboarded = true;
    },
  },
});

export const {
  setUserCredentials,
  setLoadingUserDetails,
  setUserWorkspaces,
  setLoadingWorkspaces,
  setActiveWorkspace,
  updateWorkspaceSubscription,
  clearUserState,
  setUserOnboarded,
} = userSlice.actions;

export default userSlice.reducer;

import styled from "@emotion/styled";
import { Col, Row } from "react-bootstrap";

export const PageContainer = styled.div`
  margin-top: 3.125rem;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  width: 100%;
`;

export const InnerPageContainer = styled.div`
  margin: 1.875rem !important;
  padding: 1.875rem;
  background-color: white;
  border-color: #eff2f5;
  border-radius: 1.25rem;
  box-shadow: 0rem 0rem 0.625rem #e2e2e2;
  overflow: hidden;
`;

export const UpperRow = styled(Row)`
  width: 100%;
  margin: 0 auto;
`;

export const TitleCol = styled(Col)`
  padding-left: 0rem;
  max-width: 9.375rem;
`;

export const ButtonCol = styled(Col)`
  text-align: right;
  padding-right: 0rem;
`;

export const ContentContainer = styled.div`
  margin-top: 1.5625rem;
`;

export const UpperDescriptionContainer = styled.div`
  margin-top: 1.875rem;
  span {
    font-size: 0.875rem;
  }
`;

export const VideoWrapper = styled.div`
  margin-top: 1.5625rem;
  overflow: hidden;
  border-radius: 1.25rem;
`;

import { Helmet } from "react-helmet";

const Meta = () => {
  return (
    <Helmet>
      <title>Randi - Tilbakestill passord</title>
      <meta name="thumbnail" content="https://randi.ai/showcase.jpg" />
      <meta name="robots" content="index, follow" />
      <meta
        name="description"
        content="Dersom du ikke får logget inn på kontoen din eller har glemt passordet ditt kan du tilbakestille det her."
      />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:url" content="https://randi.ai/" />
      <meta name="twitter:title" content="Randi - Tilbakestill passord" />
      <meta
        name="twitter:description"
        content="Dersom du ikke får logget inn på kontoen din eller har glemt passordet ditt kan du tilbakestille det her."
      />
      <meta name="twitter:image" content="https://randi.ai/showcase.jpg" />

      <meta property="og:url" content="https://randi.ai/" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content="Randi - Tilbakestill passord" />
      <meta
        property="og:description"
        content="Dersom du ikke får logget inn på kontoen din eller har glemt passordet ditt kan du tilbakestille det her."
      />
      <meta property="og:image" content="https://randi.ai/showcase.jpg" />
      <link rel="canonical" href="https://randi.ai/" />
    </Helmet>
  );
};

export default Meta;

import { useCallback } from "react";
import { ContactFormSchema } from "../pages/contact/content/form-view/schema";

const useContactForm = () => {
  const submitForm = useCallback(async (details: ContactFormSchema) => {
    try {
      if (
        details.firstName &&
        details.lastName &&
        details.subject &&
        details.message
      ) {
        const request = await fetch(
          `${process.env.REACT_APP_BACKEND_ENDPOINT}/general/contact`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(details),
          }
        );
        const response = await request.json();
        if (response.error === null) {
          return { error: null };
        } else {
          throw response.error;
        }
      }
    } catch (e) {
      throw e;
    }
  }, []);
  return { submitForm };
};

export default useContactForm;

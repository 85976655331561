import { Text } from "../../../library/text";
import { Title } from "../../../library/title";
import {
  Article,
  Bold,
  Container,
  InfoBox,
  ListItem,
  OrderedList,
  Paragraph,
  Section,
} from "./styled";

const PrivacyPolicyContent = () => {
  return (
    <Section>
      <Container>
        <Article>
          <Paragraph>
            <Title>Brukervilkår (lisensavtale)</Title>
            <Text color="dark">
              Randi Labs AS (“oss”, “vi” eller “vår”) driver nettstedet
              www.randi.ai (“tjenesten”). Randi er et online
              videoredigeringsverktøy som tilbyr en rekke forskjellige
              undertjenester som tale til tekst, tekst til tale og en
              video-editor som gjør det mulig for brukere å enkelt bearbeide og
              jobbe med automatisk generert teksting og manuelle undertekster
              til videoene sine.
            </Text>
          </Paragraph>
          <Paragraph>
            <Title>Aksept av avtale</Title>
            <Text color="dark">
              Når du aksepterer denne avtalen godtar du vilkårene i avtalen og
              det blir inngått en juridisk bindende avtale mellom deg (kunden)
              og Randi (Randi Labs AS). Denne avtalen regulerer din bruksrett av
              tjenesten, og ved å merke av "Jeg godtar brukervilkårene" under
              registrering, aksepterer du denne avtalen.
            </Text>
          </Paragraph>
          <Paragraph>
            <Title>Krav om bruk</Title>
            <Text color="dark">
              Brukerne forplikter seg til å overholde all gjeldende norsk
              lovgivning ved bruk av denne digitale tjenesten. Dette innebærer
              at alt innhold som lastes opp, deles, eller på annen måte
              distribueres gjennom tjenesten, må være i full overensstemmelse
              med norske lover. Nedenfor finner du en oversikt over våre krav
              for bruk:
            </Text>
            <OrderedList>
              <ListItem>
                Du må være myndig (over 18 år) for å benytte deg av denne
                tjenesten.
              </ListItem>
              <ListItem>
                Du må være et menneske, da vi ikke tillater automatisert
                software, macro eller utvidelser på/av/for vår tjeneste.
              </ListItem>
              <ListItem>
                Du må oppgi dine ekte personopplysninger som navn, e-postadresse
                og lignende informasjon ved registrering.
              </ListItem>
              <ListItem>
                Din konto kan kun benyttes av èn person, og skal alltid kun
                tilhøre èn person. Ved mistanke om deling av kontoer vil
                brukeren bli stengt ned umiddelbart og du vil ikke ha krav på
                refundering.
              </ListItem>
              <ListItem>
                Det er ikke lov til å bruke tjenesten til å drive
                konsulentvirksomhet ved f.eks å selge transkriberingsjobber som
                teksting eller annet lignende arbeid generert av Randi
                (tjenesten) til en tredjepart, hverken som privatperson eller
                virksomhet, til en privatperson eller virksomhet, uten skriftlig
                samtykke fra oss (Randi). Brudd på dette vil resultere i
                suspensjon av konto.
              </ListItem>
              <ListItem>
                Du er selv ansvarlig for å vedlikeholde sikkerheten rundt din
                egen konto.
              </ListItem>
              <ListItem>
                Du er selv ansvarlig for alt innhold som blir publisert og
                aktiviteter som skjer i sammenheng med bruk av tjenesten.
              </ListItem>
              <ListItem>
                Det er strengt forbudt å laste opp, dele, eller distribuere
                materiale som er ulovlig. Dette inkluderer, men er ikke
                begrenset til, innhold som fremmer, illustrerer eller oppfordrer
                til vold, utpressing, hatkriminalitet, eller annen kriminell
                adferd.
              </ListItem>
              <ListItem>
                Det er et absolutt forbud mot å laste opp, dele, eller på noen
                måte distribuere barnepornografi eller materiale som
                seksualiserer mindreårige. Brudd på dette vil medføre
                umiddelbare tiltak, inkludert, men ikke begrenset til,
                suspensjon av konto, rapportering til relevante myndigheter og
                rettslig forfølgelse.
              </ListItem>
              <ListItem>
                Du skal ikke bruke denne tjenesten til kriminell aktivitet eller
                noe som bryter med våre retningslinjer eller Norges lover.
              </ListItem>
            </OrderedList>
          </Paragraph>
          <Paragraph>
            <Title>Dataeierskap</Title>
            <Text color="dark">
              Som kunde hos Randi vil du alltid eie dine egne data og alt som
              blir lastet opp, bearbeidet og lastet ned gjennom tjenesten vil
              forbli brukeren sin private eiendom. Randi Labs AS vil ikke under
              noen omstendigheter få eierskap til materiale som blir produsert,
              og Randi har ingen rettigheter til å bruke dette materialet til
              noe uten spesielt skriftlig samtykke fra brukeren.
              <br />
              <br />
              Randi benytter seg av det offentlige OpenAI Whisper API-et for å
              automatisk gjøre lyd og video om til tekst. Dette betyr at
              lydopptakene dine blir sendt til OpenAI sine servere for å bli
              transkribert. OpenAI vil likevel ikke få eierskap til materialet
              som går inn til tjenesten, og heller ikke det som kommer ut av
              tjenesten. De presiserer på{" "}
              <a
                target="_blank"
                href="https://openai.com/enterprise-privacy/"
                rel="noreferrer"
              >
                sine nettsider
              </a>{" "}
              at all data som lastes opp gjennom API-et forblir brukeren sin
              private eiendom og at de ikke vil få eierskap til dette
              materialet.
              <br />
              <br />
              <InfoBox>
                <Bold>Who owns inputs and outputs?</Bold>
                <br />
                <br />
                <Bold>
                  "As between you and OpenAI: you retain all rights to the
                  inputs you provide to our services and you own any output you
                  rightfully receive from our services to the extent permitted
                  by law. We only receive rights in input and output necessary
                  to provide you with our services, comply with applicable law,
                  and enforce our policies."
                </Bold>
                <br />
                -OpenAI sin personvernserklæring per 16. Juni 2024.
              </InfoBox>
              <br />
              Randi Labs AS vil heller aldri selge eller dele dine data med noen
              tredjeparter utenom det som er nødvendig for å levere tjenesten,
              som vil si at vi kun deler video og lydinnhold med OpenAI gjennom
              vår tale til tekst funksjonalitet, Google for vår tekst til tale
              funksjonalitet og Stripe for betalingsbehandling.
              <br />
              <br />
              OpenAI har implementert retningslinjer for å beskytte brukernes
              personlige informasjon, og du kan få en full oversikt over deres
              sertifiseringer og hvordan OpenAI forholder seg til personvern og
              beskyttelse av data på deres{" "}
              <a
                target="_blank"
                href="https://trust.openai.com/"
                rel="noreferrer"
              >
                sikkerhetsportal.
              </a>
            </Text>
          </Paragraph>
          <Paragraph>
            <Title>Personvern og datamodeller</Title>
            <Text color="dark">
              Ved å akseptere disse vilkårene, erkjenner og samtykker du til at
              tjenesten benytter OpenAI's Whisper teknologi for å konvertere
              tale til tekst. Under denne prosessen sendes lydopptakene fra
              Randi til OpenAI sine servere, der Whisper-algoritmen er
              implementert. OpenAI gjør det tydelig på sin{" "}
              <a
                target="_blank"
                href="https://openai.com/enterprise-privacy/"
                rel="noreferrer"
              >
                side for personvern
              </a>{" "}
              at de ikke bruker data som sendes gjennom API-et deres til å trene
              modellene sine, og at de heller ikke deler dataene med noen
              tredjeparter.
              <br />
              <br />
              <InfoBox>
                <Bold>
                  <u>
                    "At OpenAI, protecting user data is fundamental to our
                    mission. We do not train our models on inputs and outputs
                    through our API."
                  </u>
                </Bold>
                <br />- OpenAI sin dokumentasjonsside per 16. Juni 2024.
              </InfoBox>
              <br />
              OpenAI vil lagre innholdet som lastes opp gjennom API-et deres i
              opp til 30 dager med AES-256 kryptering, før det blir slettet. Når
              dataen overføres vil det også bli kryptert med TLS 1.2 eller
              høyere for å sikre at dataen er trygg under overføringen. For mer
              informasjon angående OpenAI sitt API kan du lese mer{" "}
              <a
                target="_blank"
                href="https://platform.openai.com/docs/introduction"
                rel="noreferrer"
              >
                her
              </a>
              .
              <br />
              <br />
              <InfoBox>
                <Bold>
                  <u>
                    "We do not train on your business data (data from ChatGPT
                    Team, ChatGPT Enterprise, or our API Platform)"
                  </u>
                </Bold>
                <br />- OpenAI sin dokumentasjonsside per 16. Juni 2024.
              </InfoBox>
              <br />
              OpenAI har implementert retningslinjer for å beskytte brukernes
              personlige informasjon, og er revidert for SOC-2 kompabilitet for
              deres API som betyr at de oppfyller høre standarder for sikkerhet,
              tilgjengelighet, prosessintegritet, konfidensialitet og
              personvern. OpenAI er i tillegg sertifisert for blant annet CCPA,
              CSA STAR og GDPR. Du kan få mer informasjon om hvordan OpenAI
              forholder seg til personvern og beskyttlese av data på deres{" "}
              <a
                target="_blank"
                href="https://trust.openai.com/"
                rel="noreferrer"
              >
                sikkerhetsportal
              </a>
              .
            </Text>
          </Paragraph>
          <Paragraph>
            <Title>Tilgang til tjenesten</Title>
            <Text color="dark">
              Ved å akseptere denne avtalen får du tilgang til tjenesten, og du
              vil fra start av abonnement få en ikke-eksklusiv bruksrett til å
              benytte deg av Randi for egen virksomhet. Ved et aktivt abonnement
              skal Randi gi deg full tilgang til tjenesten, og samtidig bistå
              med teknisk support dersom det er behov for dette. Du er å anse
              som en lisenseier kun ved et aktivt abonnement, og du vil ha
              tilgang til tjenesten alle dager i året uten noen begrensninger.{" "}
              <br />
              <br />
              Det kan forekomme at Randi vil ha nedetid, selvom det er ytterst
              sjeldent. Dersom du kan dokumentere at din tilgang har blitt
              merkbart redusert som følge av vår nedetid vil det åpne seg
              muligheter for å diskutere en kompensasjon i form av lavere
              fremtidig lisenspris over en gitt tidsperiode for å gjøre opp for
              dette.
            </Text>
          </Paragraph>
          <Paragraph>
            <Title>Opphavsrett</Title>
            <Text color="dark">
              Randi Labs AS eier Randi og har opphavsrett til denne tjenesten.
              Denne opphavsretten er beskyttet i lov om opphavsrett til
              åndsverk. Eiendomsretten til tjenesten tilhører Randi Labs AS,
              uavhengig av lisensiering av tjenesten til kunden i henhold til
              avtalen, som også gjelder både for originalversjon og eventuelle
              kopier. Ved bruk av denne tjenesten er du forpliktet til å
              behandle tjenesten som et hvert annet opphavsrettslig beskyttet
              materiale, og du skal ikke kopiere materiale fra Randi uten å
              oppgi kilder eller få et skriftlig samtykke fra Randi Labs AS.
              <br />
              <br />
              Som bruker har du heller ikke, under noen omstendigheter, lov til
              å selge eller overføre noe fra tjenesten over til en annen
              tredjepart eller virksomhet. Det er heller ikke under noen
              omstendigheter tillatt å dekompilere, reverse-engineere, bygge
              videre på eller dele opp noe av Randi-tjenesten, da dette er å
              anse som privat eiendom. All kontaktinformasjon som blir lagret
              hos Randi er å anse som våres eiendom, hvor data utenom er å anse
              som din (kunden) sin eiendom.
            </Text>
          </Paragraph>
          <Paragraph>
            <Title>Lisens</Title>
            <Text color="dark">
              For tilgang til Randi skal det betales en månedlig lisensavgift
              til Randi Labs AS, som betales med betalingskort gjennom Stripe.
              Etter betaling vil du umiddelbart få tilgang til hele plattformen,
              og du vil fra datoen du starter abonnementet bli belastet èn gang
              hver måned for antall brukertilganger du har kjøpt. Etter kjøp har
              du ikke krav på refusjon, og dersom du ønsker å kansellere
              abonnementet ditt må dette gjøres før neste månedlige trekk.
              <br />
              <br />
              Ved kansellering vil abonnementet ditt automatisk bli stoppet, så
              lenge du ikke har noe utestående hos oss. I denne avtalen regnes
              èn bruker som en samarbeidspartner eller en person i et annet
              foretak med eksklusiv tilgang, og denne kan justeres både i
              registreringsprosessen og endres i etterkant.
            </Text>
          </Paragraph>
          <Paragraph>
            <Title>Varighet og oppsigelse</Title>
            <Text color="dark">
              Lisensen til Randi er gyldig èn måned om gangen og betales
              forskuddsvis. Den fornyes automatisk dersom du har et aktivt
              abonnement og ikke har valgt å terminere det. Du har ikke krav på
              refusjon etter kjøpet er gjennomført, men står fritt til å si opp
              abonnementet ditt når som helst. Når du registrerer ny bruker hos
              Randi og benytter deg av 30 dagers gratis prøvetid, vil du bli
              fakturert automatisk etter denne perioden dersom du ikke
              kansellerer manuelt i forkant.
            </Text>
          </Paragraph>
          <Paragraph>
            <Title>Ansvarsbegrensning</Title>
            <Text color="dark">
              Randi Labs AS fraskriver seg alt ansvar for ethvert tap, skade
              eller uhell som oppstår av kundens bruk av tjenesten, herunder,
              men ikke begrenset til feil i oversettelse, tekstoppbygning,
              formulering, transkribering, eller annen feil som kommer fra en
              tredjepart. Dette gjelder også alt som er utenfor Randi sin
              kontroll, som for eksempel manglende teknisk kunnskap,
              begrensninger på teknisk utstyr o.l.
              <br />
              <br />
              Randi, vår tale-til-tekst tjeneste som bruker OpenAI, gir ingen
              garantier for nøyaktigheten av transkripsjonene. Feil og
              unøyaktigheter kan forekomme som følge av ulike faktorer,
              inkludert kvaliteten på lydkilden og kompleksiteten i det
              språklige innholdet. Det er brukerens ansvar å kvalitetssikre og
              verifisere transkripsjonene for nøyaktighet og fullstendighet. Vi
              informerer også om fenomenet "hallusinering", hvor tjenesten kan
              generere ord eller setninger som ikke er blitt sagt, men som kan
              vises i transkripsjonen basert på modellens tolkning av
              konteksten.
            </Text>
          </Paragraph>
          <Paragraph>
            <Title>Mislighold</Title>
            <Text color="dark">
              Dersom kunden motsetter seg eller av en annen grunn ikke betaler
              den månedlige lisensavgiften, har Randi rett til å hindre brukeren
              tilgang til tjenesten med umiddelbar virkning frem det utestående
              blir gjort opp, hvor i enkelte tilfeller renter og omkostninger
              vil foreligge. Ved ekstraordinære situasjoner som etter
              alminnelige regler må regnes som force majeure, anses ikke avtalen
              misligholdt så lenge force majeure situasjonen fortsatt er
              tilstede.
              <br />
              <br />
              Skulle situasjonen vedvare, eller hvis det er antydninger til at
              det vil pågå i mer enn 30 dager kan hver av partene si opp avtalen
              med 30 dagers forvarsel.
            </Text>
          </Paragraph>
          <Paragraph>
            <Title>Overdragelse</Title>
            <Text color="dark">
              Kunden kan ikke under noen omstendigheter overføre avtalen til en
              annen fysisk eller juridisk person, ei heller en tredjepart uten
              skriftlig samtykke fra Randi Labs AS.
            </Text>
          </Paragraph>
          <Paragraph>
            <Title>Generelt</Title>
            <Text color="dark">
              Denne avtalen erstatter alle eventuelle tidligere avtaler og
              forpliktelser, både muntlige og skriftlige, vedrørende tilgang til
              tjenesten både i regi av brukertilganger og pris. Denne avtalen
              reguleres av norsk lov, og tillegg eller endringer i avtalen er
              kun gyldig dersom ny, skriftlig avtale med henvisning til avtalen
              foreligger. Denne avtalen er bindende og skal ved eventuelle
              konflikter avgjøres etter norsk rett og vedtas ved Oslo Tingrett.
            </Text>
          </Paragraph>
        </Article>
      </Container>
    </Section>
  );
};

export default PrivacyPolicyContent;

import { useState } from "react";
import DashboardTopBar from "../../../../../components/DashboardTopBar";
import InviteModalContent from "./invite-modal";
import Modal from "../../../../../library/modal";
import Button from "../../../../../library/button";
import AccessView from "./access-view";
import { useTranslation } from "react-i18next";

const BottomView = () => {
  const { t } = useTranslation();
  const [inviteModalOpen, setInviteModalOpen] = useState<boolean>(false);

  const openInviteModal = () => {
    setInviteModalOpen(true);
  };

  const closeInvitationModal = () => {
    setInviteModalOpen(false);
  };

  return (
    <>
      <DashboardTopBar
        title={t("dashboard.pages.workspace.bottom-view.title")}
        buttons={
          <Button
            onClick={() => openInviteModal()}
            color="blue"
            size="md"
            variant="regular"
            ariaLabel={
              t(
                "dashboard.pages.workspace.top-view.buttons-column.buttons.invite-user.ariaLabel"
              ) as string
            }
          >
            {t(
              "dashboard.pages.workspace.top-view.buttons-column.buttons.invite-user.title"
            )}
          </Button>
        }
      />
      <AccessView />
      <Modal
        open={inviteModalOpen}
        onClose={closeInvitationModal}
        title={t("dashboard.pages.workspace.invite-modal.title")}
      >
        <InviteModalContent onClose={closeInvitationModal} />
      </Modal>
    </>
  );
};

export default BottomView;

import Footer from "../../components/PublicFooter";
import Navigation from "../../components/PublicNavigation";
import Content from "./content";
import Meta from "./meta";

const Index = () => {
  return (
    <>
      <Meta />
      <Navigation background={true} />
      <Content />
      <Footer />
    </>
  );
};

export default Index;

import { StyledText } from "./styled";

interface ITextProps {
  color: "dark" | "light" | "fail";
  size?: "regular" | "small" | "extrasmall";
  children: React.ReactNode;
}

export const Text: React.FC<ITextProps> = ({
  color = "dark",
  size = "regular",
  children,
}) => {
  return (
    <StyledText className={`color-${color} size-${size}`}>
      {children}
    </StyledText>
  );
};

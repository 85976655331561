import Footer from "../../components/PublicFooter";
import Content from "./content";
import Navigation from "../../components/PublicNavigation";
import useBlogpost from "../../hooks/useBlogpost";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import Meta from "./meta";

const Index = () => {
  const { id } = useParams();
  const { fetchBlogpost, loading, blogpost } = useBlogpost();

  useEffect(() => {
    if (id) fetchBlogpost(id);
  }, [id, fetchBlogpost]);

  return (
    <>
      <Meta content={blogpost} />
      <Navigation background={true} />
      <Content loading={loading} content={blogpost} />
      <Footer />
    </>
  );
};

export default Index;

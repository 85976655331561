import {
  DetailsRow,
  ThumbnailCol,
  ThumbnailWrapper,
  Thumbnail,
  InformationCol,
  Text,
  ProgressTitle,
  ProgressBarWrapper,
  ButtonsCol,
  TitleBadge,
} from "./styled";
import moment from "moment";
import { Title } from "../../../../../library/title";
import Button from "../../../../../library/button";
import thumbnail from "../../../../../assets/img/thumbnail.svg";
import ProgressBar from "../../../../../library/progress-bar";
import useWorkspaceDetails from "../../../../../hooks/useWorkspaceDetails";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { IState } from "../../../../../types";
import { Link } from "react-router-dom";

const UpperArea = () => {
  const { t } = useTranslation();

  const details = useSelector((state: IState) => state?.workspace?.details);
  const { loading } = useWorkspaceDetails();

  const renderPercentageBar = () => {
    if (details?.licenses?.used && details?.licenses?.total) {
      return (details.licenses.used / details.licenses.total) * 100;
    } else {
      return 0;
    }
  };

  const renderSubscription = () => {
    switch (details?.subscription) {
      case "small":
        return "Gratisversjon";
      case "go-monthly":
        return "Go, månedlig";
      case "go-annually":
        return "Go, årlig";
      default:
        return "N/A";
    }
  };

  return (
    <DetailsRow>
      <ThumbnailCol>
        <ThumbnailWrapper>
          <Thumbnail src={thumbnail} />
        </ThumbnailWrapper>
      </ThumbnailCol>
      <InformationCol>
        <Title>
          {loading ? <Skeleton /> : details?.name}
          <TitleBadge>{renderSubscription()}</TitleBadge>
        </Title>
        <Text>
          {loading ? (
            <Skeleton />
          ) : (
            `${t(
              "dashboard.pages.workspace.top-view.details-column.member-since"
            )} ${moment.unix(details?.dateCreated).format("DD/MM/YYYY")}`
          )}
        </Text>
        <ProgressTitle>
          {loading ? (
            <Skeleton />
          ) : (
            `${t(
              "dashboard.pages.workspace.top-view.details-column.licenses-used"
            )} (${details?.licenses?.used}/${details?.licenses?.total})`
          )}
        </ProgressTitle>
        <ProgressBarWrapper>
          <ProgressBar
            size="md"
            percentage={renderPercentageBar()}
            color="progress2"
          />
        </ProgressBarWrapper>
      </InformationCol>
      <ButtonsCol>
        {details?.customerId && (
          <Link
            target="_blank"
            to="https://billing.stripe.com/p/login/eVag055TNgu1bss6oo"
          >
            <Button
              color="grey"
              variant="regular"
              size="md"
              spacing="right"
              onClick={() => null}
              ariaLabel={
                t(
                  "dashboard.pages.workspace.top-view.buttons-column.buttons.change-subscription.ariaLabel"
                ) as string
              }
            >
              {t(
                "dashboard.pages.workspace.top-view.buttons-column.buttons.change-subscription.title"
              )}
            </Button>
          </Link>
        )}
      </ButtonsCol>
    </DetailsRow>
  );
};

export default UpperArea;

import styled from "@emotion/styled";

export const TabsWrapper = styled.div`
  margin-top: 20px;
`;

export const ListWrapper = styled.ul``;

export const ListItem = styled.li`
  font-family: Poppins, sans-serif;
  color: grey;
  line-height: 1.5rem;
  margin-bottom: 0.75rem;
  font-size: 14px;
  &:last-of-type {
    margin-bottom: 0px;
  }
`;

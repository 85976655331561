import { StyledLabel } from "./styled";

interface ILabelProps {
  htmlFor?: string;
  children: React.ReactNode;
}

export const Label: React.FC<ILabelProps> = ({
  htmlFor,
  children,
  ...props
}) => {
  return <StyledLabel htmlFor={htmlFor}>{children}</StyledLabel>;
};

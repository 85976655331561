import styled from "@emotion/styled";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

export const NavWrapper = styled(Nav)`
  min-height: calc(100vh - 75px);
  padding-top: 50px;
`;

export const NavLink = styled(Link)`
  display: block;
  text-align: center;
  line-height: 45px;
  color: #3b3b3b;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  font-weight: 500;
  &.active {
    color: #2f8de4;
    &:hover {
      color: #2f8de4;
    }
  }

  &:hover {
    color: #3b3b3b;
  }
`;

export const NavigationWrapper = styled.div`
  padding: 25px 0px;
`;

export const NavText = styled.span`
  line-height: 3.1rem;
`;

export const ButtonsWrapper = styled.div`
  position: fixed;
  left: 0px;
  right: 0px;

  padding: 20px;
  width: 100%;
  bottom: 0px;
  gap: 10px;
  a {
    display: block;
    button {
      display: block;
      width: 100%;
      &:last-of-type {
        margin-top: 15px;
      }
    }
  }
`;

import React, { useEffect, useState } from "react";
import {
  ButtonContainer,
  ContentContainer,
  ExitIconButton,
  NewModalBody,
  NewModalTitle,
} from "./styled";
import Modal from "react-modal";
import { Title } from "../title";
import { renderSize } from "./config";
import Button from "../button";
import { Text } from "../text";

interface IConfirmProps {
  onClose: () => void;
  open: boolean;
  title: string;
  size?: number;
  variant: "default" | "delete";
  description: string;
  submitText: string;
  onSubmit: () => void;
}

const ConfirmComponent: React.FC<IConfirmProps> = ({
  open,
  onClose,
  title,
  description,
  submitText,
  onSubmit,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Modal
      style={renderSize(440, windowWidth, false)}
      isOpen={open}
      onRequestClose={onClose}
    >
      <NewModalTitle>
        <Title>{title}</Title>
        <ExitIconButton onClick={onClose}>
          <i className="fa-solid fa-xmark"></i>
        </ExitIconButton>
      </NewModalTitle>
      <NewModalBody>
        <ContentContainer className="confirmation">
          <Text color="dark">{description}</Text>
        </ContentContainer>
        <ButtonContainer>
          <Button onClick={onClose} variant="regular" color="grey">
            Avbryt
          </Button>
          <Button
            onClick={onSubmit}
            variant="regular"
            spacing="left"
            color="red"
          >
            {submitText}
          </Button>
        </ButtonContainer>
      </NewModalBody>
    </Modal>
  );
};

export default ConfirmComponent;

import styled from "@emotion/styled";

export const SliderWrapper = styled.div`
  background-color: #fff;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  z-index: 1000;
  &.hide {
    display: none;
  }
`;

export const WaveformWrapper = styled.div`
  width: 100%;
  padding: 20px 20px;
  @media (max-width: 900px) {
    padding: 15px 20px;
  }
`;

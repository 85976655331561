import styled from "@emotion/styled";

export const Section = styled.section``;

export const Container = styled.div`
  max-width: 87.5rem;
  margin: 0 auto;
  padding: 4.6875rem 0.9375rem;
  @media (max-width: 767px) {
    padding: 0rem 1.25rem;
  }
`;

export const Wrapper = styled.div`
  max-width: 62.5rem;
  margin: 0 auto;
  margin-top: 7.8125rem;
  margin-bottom: 7.8125rem;
  @media (max-width: 767px) {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
  }
`;

export const Thumbnail = styled.img`
  width: 100%;
  border-radius: 1.25rem;
`;

export const Published = styled.span`
  display: block;
  font-family: "Poppins", sans-serif;
  color: #2f8de4;
  font-size: 0.875rem;
  margin-top: 1.875rem;
  font-weight: 500;
`;

export const Content = styled.div`
  margin-top: 0.625rem;
  font-family: "Poppins", sans-serif;
  color: grey;
  font-size: 1rem;
  line-height: 1.5625rem;
  iframe.ql-video {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9; /* Ensures 16:9 aspect ratio */
  }

  b,
  strong {
    font-family: "Poppins", sans-serif;
  }
  a {
    color: #2f8de4;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Poppins", sans-serif;
    padding-top: 1.25rem;
    font-weight: 500 !important;
    color: #3b3b3b;
    & > strong {
      font-weight: 600;
      font-size: 1.625rem;
    }
  }
  table {
    width: 100% !important;

    tr {
      height: 3.4375rem;
      td {
        strong {
          font-weight: 500;
          font-family: "Poppins", sans-serif;
          color: #3b3b3b;
        }
      }
    }
  }
`;

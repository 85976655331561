import { OnboardingCol, OnboardingRow } from "../../styled";
import { AnimationWrapper } from "./styled";
import Lottie from "lottie-react";
import onboardingAnimation from "../../../../assets/animations/onboarding-animation.json";
import { Title } from "../../../../library/title";
import { Text } from "../../../../library/text";

const Index = () => {
  return (
    <OnboardingRow className="introduction">
      <OnboardingCol md className="animation">
        <AnimationWrapper>
          <Lottie loop animationData={onboardingAnimation} />
        </AnimationWrapper>
      </OnboardingCol>
      <OnboardingCol md className="my-auto">
        <Title size="sm">La oss vise deg hvordan ting funker</Title>
        <Text size="small" color="dark">
          Vi har laget en kort presentasjon for å gi deg et bedre overblikk over
          hvordan Randi fungerer. På denne måten kan du føle deg tryggere på
          hvordan du bruker tjenesten og hva slags funksjonalitet som finnes.
          <br />
          <br /> Du kan også kontakte oss gjennom kontaktskjemaet på nettsiden
          vår dersom du måtte stå fast eller ha noen spørsmål.
        </Text>
      </OnboardingCol>
    </OnboardingRow>
  );
};

export default Index;

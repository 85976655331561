import styled from "@emotion/styled";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import Button from "../../../library/button";

export const NavWrapper = styled(Nav)`
  @media (max-width: 900px) {
    padding-top: 10px;
  }
`;

export const NavLink = styled(Link)`
  color: #3b3b3b;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-decoration: none;
  line-height: 30px;
  border-bottom: 2px solid transparent;
  &.active {
    color: #2f8de4;
    border-bottom: 2px solid #2f8de4;
    &:hover {
      color: #2f8de4;
    }
  }
  &.aligned-left {
    margin-right: 1.25rem;
  }
  &.aligned-right {
    margin-left: 1.25rem;
    &.flag {
      margin-left: 0.625rem;
    }
  }
  &:hover {
    color: #3b3b3b;
    border-bottom: 2px solid #2f8de4;
  }
`;

export const HelpButton = styled(Button)`
  font-size: 16px !important;
  background-color: transparent;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #2f8de4;
  i {
    margin-left: 5px;
  }
`;

export const UserThumbnail = styled.img`
  width: 32.5px;
  height: 32.5px;
  margin-right: 5px;
  object-fit: cover;
  border-radius: 0.5rem;
`;

export const Name = styled.span`
  margin-right: 0.625rem;
  font-size: 16px;
  & > span {
    display: block;
  }
  &.mobile {
    font-size: 1rem;
  }
`;

import { useEffect, useState } from "react";
import { getIcon } from "../../../../../../utils/get-icon";
import {
  ActiveSubtitle,
  ControllerWrapper,
  IconWrapper,
  PlayerWrapper,
  SubtitleWrapper,
  Video,
  VideoWrapper,
} from "./styled";

interface VideoPlayerMobileProps {
  togglePlayPause: () => void;
  isPlaying: boolean;
  videoRef: any;
  videoUrl: string;
  onTimeUpdate: any;
  activeSubtitle: () => string;
  configuration: any;
  playerWidth: number;
}

const VideoPlayerMobile: React.FC<VideoPlayerMobileProps> = ({
  togglePlayPause,
  isPlaying,
  videoRef,
  videoUrl,
  onTimeUpdate,
  activeSubtitle,
  configuration,
  playerWidth,
}) => {
  const [dynamicFontSize, setDynamicFontSize] = useState(
    configuration?.fontSize
  );
  const [showIcon, setShowIcon] = useState(false);

  const handleToggle = () => {
    togglePlayPause();
    setShowIcon(true);

    setTimeout(() => {
      setShowIcon(false);
    }, 1000);
  };

  useEffect(() => {
    const updateFontSize = () => {
      if (videoRef.current) {
        const videoHeight = videoRef.current.clientHeight;
        const userDefinedFontSize = configuration?.fontSize || 16;

        const scaleFactor = videoHeight / 500;
        const calculatedFontSize = userDefinedFontSize * scaleFactor;

        setDynamicFontSize(calculatedFontSize);
      }
    };

    const handleMetadataLoaded = () => {
      updateFontSize();
    };

    if (videoRef.current) {
      videoRef.current.addEventListener("loadedmetadata", handleMetadataLoaded);
    }

    window.addEventListener("resize", updateFontSize);

    // Update font size immediately when fontSize changes
    updateFontSize();

    return () => {
      if (videoRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        videoRef.current.removeEventListener(
          "loadedmetadata",
          handleMetadataLoaded
        );
      }
      window.removeEventListener("resize", updateFontSize);
    };
  }, [videoRef, configuration?.fontSize]);

  return (
    <PlayerWrapper>
      <VideoWrapper>
        <Video
          ref={videoRef}
          src={videoUrl}
          onTimeUpdate={onTimeUpdate}
          preload="metadata"
          playsInline
          controls={false}
        >
          Your browser does not support the video tag.
        </Video>
        <SubtitleWrapper
          className={`vertical-${configuration?.alignment?.vertical}`}
          style={{
            textAlign: configuration?.alignment?.horizontal || "center",
          }}
          playerWidth={playerWidth}
        >
          <ActiveSubtitle
            fontSize={dynamicFontSize}
            color={configuration?.color}
            backgroundColor={configuration?.backgroundColor}
            fontFamily={configuration?.fontFamily}
            decoration={configuration?.decoration}
            fontStyle={configuration?.fontStyle}
          >
            {activeSubtitle()}
          </ActiveSubtitle>
        </SubtitleWrapper>
      </VideoWrapper>
      <ControllerWrapper onClick={handleToggle}>
        <IconWrapper visible={showIcon}>
          {isPlaying ? getIcon("play") : getIcon("pause")}
        </IconWrapper>
      </ControllerWrapper>
    </PlayerWrapper>
  );
};

export default VideoPlayerMobile;

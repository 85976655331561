import styled from "@emotion/styled";

export const NewModalTitle = styled.div`
  text-align: center;
  padding-top: 0.625rem;
`;

export const ExitIconButton = styled.button`
  position: absolute;
  right: 1.4rem;
  top: 1.25rem;
  border: none;
  color: grey;
  background-color: transparent;
  font-size: 1.25rem;
  opacity: 0.38;
  transition: 0.5s;
  &:hover {
    opacity: 1;
  }
`;

export const NewModalBody = styled.div``;

export const ContentContainer = styled.div`
  padding-top: 0.625rem;
  margin-left: 0.1563rem;
  margin-right: 0.1563rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  .input-field {
    margin-bottom: 1.25rem;
  }
  &.confirmation {
    text-align: center;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`;

import Button from "../../../library/button";
import {
  HeaderBottom,
  HeaderVideo,
  HeaderWrapper,
  InnerContainer,
  Logo,
  LogoContainer,
  LogoWrapper,
  NavigationBar,
  OuterVideoWrapper,
  TextWrapper,
  TitleWrapper,
  VideoWrapper,
} from "./styled";
import { Link } from "react-router-dom";
import headerVideo from "../../../assets/videos/showcase.mp4";
import { Text } from "../../../library/text";
import { useTranslation } from "react-i18next";
import { Title } from "../../../library/title";
import headerBottom from "../../../assets/img/header-bottom.svg";
import navigationBar from "../../../assets/img/navigation-bar.svg";
import splaeshLogo from "../../../assets/img/splaesh-logo.svg";
import garasjetidLogo from "../../../assets/img/garasjetid-logo.svg";
import streamhubLogo from "../../../assets/img/streamhub-logo.svg";
import nattuglaLogo from "../../../assets/img/nattugla-logo.svg";
import pipelifeLogo from "../../../assets/img/pipelife-logo.svg";
import p4Logo from "../../../assets/img/p4-logo.svg";

const Header = () => {
  const { t } = useTranslation();

  return (
    <HeaderWrapper>
      <InnerContainer>
        <TitleWrapper>
          <Title size="xl">{t("frontpage.sections.header.title")}</Title>
        </TitleWrapper>
        <TextWrapper>
          <Text color="dark">{t("frontpage.sections.header.description")}</Text>
        </TextWrapper>
        <Link to="/register">
          <Button
            color="blue"
            onClick={() => null}
            variant="regular"
            ariaLabel="Registrer ny bruker"
          >
            {t("frontpage.sections.header.get-started-button")}
          </Button>
        </Link>
        <OuterVideoWrapper>
          <VideoWrapper>
            <NavigationBar
              alt={t("frontpage.sections.header.video.navigation-bar.alt")!}
              src={navigationBar}
            />
            <HeaderVideo
              className="video-player"
              width="100%"
              height="100%"
              controls={false}
              muted={true}
              autoPlay={true}
              loop={true}
              playsInline={true}
            >
              <source src={headerVideo} type="video/mp4" />
            </HeaderVideo>
          </VideoWrapper>
          <LogoContainer>
            <Title size="sm">
              {t("frontpage.sections.header.customers-title")}
            </Title>
            <LogoWrapper>
              <Link target="_blank" to="https://splaesh.com/">
                <Logo src={splaeshLogo} />
              </Link>
              <Link target="_blank" to="https://garasjetid.no/">
                <Logo src={garasjetidLogo} />
              </Link>
              <Link target="_blank" to="https://streamhub.no/">
                <Logo src={streamhubLogo} />
              </Link>
              <Link target="_blank" to="https://nattugla.no/">
                <Logo src={nattuglaLogo} />
              </Link>
              <Link target="_blank" to="https://pipelife.no/">
                <Logo src={pipelifeLogo} />
              </Link>
              <Link target="_blank" to="https://p4.no/">
                <Logo className="shrink" src={p4Logo} />
              </Link>
            </LogoWrapper>
          </LogoContainer>
        </OuterVideoWrapper>
      </InnerContainer>
      <HeaderBottom
        src={headerBottom}
        alt={t("frontpage.sections.header.bottom-separator.alt")!}
      />
    </HeaderWrapper>
  );
};

export default Header;

import SingleSoundtrack from "./list-item";
import {
  TableContainer,
  Table as FullTable,
  TableRow,
  TableHeader,
  Title,
} from "./styled";
import { useTranslation } from "react-i18next";
import { SoundtrackRecordItem } from "../../../../types";
import EmptyView from "../../../../components/EmptyView";

interface ITableProps {
  data: SoundtrackRecordItem[];
}

const Table: React.FC<ITableProps> = ({ data }) => {
  const { t } = useTranslation();

  const TITLES = [
    t("dashboard.pages.soundtracks.table.titles.select"),
    t("dashboard.pages.soundtracks.table.titles.title"),
    t("dashboard.pages.soundtracks.table.titles.date"),
    "Status",
    "Format",
    t("dashboard.pages.soundtracks.table.titles.action"),
  ];

  if (data?.length === 0) {
    return (
      <EmptyView
        title={t("dashboard.pages.soundtracks.empty-view.title")}
        description={t("dashboard.pages.soundtracks.empty-view.text")}
      />
    );
  }

  return (
    <TableContainer>
      <FullTable>
        <TableRow className="header">
          {TITLES.map((title, index, arr) => (
            <TableHeader className={index === arr?.length - 1 ? "select" : ""}>
              <Title>{title}</Title>
            </TableHeader>
          ))}
        </TableRow>
        {data?.map((data, index) => (
          <SingleSoundtrack data={data} arr={data} index={index} />
        ))}
      </FullTable>
    </TableContainer>
  );
};

export default Table;

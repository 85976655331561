export const NORWEGIAN_NO_OPTIONS = [
  {
    id: "ingrid-no-nb",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.norwegian-nb.ingrid-voice.title",
    value: "ingrid-no-nb",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fnorwegian-nb%2Fingrid_voice.ogg?alt=media&token=6efa9e5f-8120-40c2-8739-04282ea793e2",
    gender: "female",
  },
  {
    id: "marte-no-nb",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.norwegian-nb.marte-voice.title",
    value: "marte-no-nb",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fnorwegian-nb%2Fmarte_voice.ogg?alt=media&token=33091ddb-cdbe-4c64-a4ab-fef5a87cc69a",
    gender: "female",
  },
  {
    id: "even-no-nb",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.norwegian-nb.even-voice.title",
    value: "even-no-nb",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fnorwegian-nb%2Feven_voice.ogg?alt=media&token=faaa092a-3119-484e-b4f6-45f5c49721c8",
    gender: "male",
  },
];

export const SWEDISH_SV_OPTIONS = [
  {
    id: "emilia-sv",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.swedish-sv.emilia-voice.title",
    value: "emilia-sv",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fswedish-sv%2Femilia_voice.ogg?alt=media&token=15a81f4a-e80f-443b-9b0e-2825b7245ee2",
    gender: "female",
  },
  {
    id: "linnea-sv",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.swedish-sv.linnea-voice.title",
    value: "linnea-sv",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fswedish-sv%2Flinnea_voice.ogg?alt=media&token=d6d5e1d9-7720-4b78-9d65-d339ed0accb2",
    gender: "female",
  },
  {
    id: "gustav-sv",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.swedish-sv.gustav-voice.title",
    value: "gustav-sv",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fswedish-sv%2Fgustav_voice.ogg?alt=media&token=ebfe48b0-315a-4fec-982a-7ecfe29edee4",
    gender: "male",
  },
];

export const DANISH_DK_OPTIONS = [
  {
    id: "freja-dk",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.danish-dk.freja-voice.title",
    value: "freja-dk",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fdanish-dk%2Ffreja_voice.ogg?alt=media&token=fe3390df-3396-4737-bf1f-699328667385",
    gender: "female",
  },
  {
    id: "magnus-dk",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.danish-dk.magnus-voice.title",
    value: "magnus-dk",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fdanish-dk%2Fmagnus_voice.ogg?alt=media&token=314ed0a5-b502-49fb-a9ab-6f50d3692a7f",
    gender: "male",
  },
  {
    id: "signe-dk",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.danish-dk.signe-voice.title",
    value: "signe-dk",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fdanish-dk%2Fsigne_voice.ogg?alt=media&token=4dd8bf84-85c3-454e-87ab-8f89e26a4b0e",
    gender: "female",
  },
];

export const ENGLISH_UK_OPTIONS = [
  {
    id: "james-en",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.english-uk.james-voice.title",
    value: "james-en",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fenglish-en-gb%2Fjames_voice.ogg?alt=media&token=fdca5ee2-73ab-4247-99f2-5bb87ca4762b",
    gender: "male",
  },
  {
    id: "william-en",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.english-uk.william-voice.title",
    value: "william-en",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fenglish-en-gb%2Fwilliam_voice.ogg?alt=media&token=0a5b2e0c-0813-435b-922f-6cbe21f67a8c",
    gender: "male",
  },
  {
    id: "sophia-en",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.english-uk.sophia-voice.title",
    value: "sophia-en",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fenglish-en-gb%2Fsophia_voice.ogg?alt=media&token=9b82951a-347d-40c2-bbdf-543570639d8e",
    gender: "female",
  },
  {
    id: "lily-en",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.english-uk.lily-voice.title",
    value: "lily-en",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fenglish-en-gb%2Flily_voice.ogg?alt=media&token=438dd777-bc65-4af0-ae58-8728a72907cb",
    gender: "female",
  },
];

export const ENGLISH_US_OPTIONS = [
  {
    id: "sophia-en-us",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.english-us.sophia-voice.title",
    value: "sophia-en-us",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fenglish-en-us%2Fsophia_voice.ogg?alt=media&token=5b91a7ad-2b1e-4e40-9d2b-bf4743732179",
    gender: "female",
  },
  {
    id: "liam-en-us",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.english-us.liam-voice.title",
    value: "liam-en-us",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fenglish-en-us%2Fliam_voice.ogg?alt=media&token=05ae9186-f59f-4a97-96c4-0611eb39860f",
    gender: "male",
  },
  {
    id: "jackson-en-us",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.english-us.jackson-voice.title",
    value: "jackson-en-us",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fenglish-en-us%2Fjackson_voice.ogg?alt=media&token=307d3c68-78c2-4de1-aafe-7b6c1b2c532d",
    gender: "male",
  },
  {
    id: "aiden-en-us",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.english-us.aiden-voice.title",
    value: "aiden-en-us",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fenglish-en-us%2Faiden_voice.ogg?alt=media&token=0a16e1c4-bec3-47fb-a3a3-6a6603afcd01",
    gender: "male",
  },
  {
    id: "harper-en-us",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.english-us.harper-voice.title",
    value: "harper-en-us",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fenglish-en-us%2Fharper_voice.ogg?alt=media&token=d64332d6-67fc-484b-a4d5-8805e0dbdb1b",
    gender: "female",
  },
  {
    id: "amelia-en-us",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.english-us.amelia-voice.title",
    value: "amelia-en-us",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fenglish-en-us%2Famelia_voice.ogg?alt=media&token=647129a5-413f-4a4f-aad8-1b58076a437d",
    gender: "female",
  },
];

export const GERMAN_DE_OPTIONS = [
  {
    id: "felix-de",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.german-de.felix-voice.title",
    value: "felix-de",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fgerman-de%2Ffelix_voice.ogg?alt=media&token=003eaadb-af9c-4fc1-afdd-b208a7698bc0",
    gender: "male",
  },
  {
    id: "johanna-de",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.german-de.johanna-voice.title",
    value: "johanna-de",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fgerman-de%2Fjohanna_voice.ogg?alt=media&token=1233c858-94a0-4421-8f2b-14e789064ce0",
    gender: "female",
  },
  {
    id: "lena-de",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.german-de.lena-voice.title",
    value: "lena-de",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fgerman-de%2Flena_voice.ogg?alt=media&token=fce3431a-cb2d-4889-963a-abc316a9b420",
    gender: "female",
  },
  {
    id: "leon-de",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.german-de.leon-voice.title",
    value: "leon-de",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fgerman-de%2Fleon_voice.ogg?alt=media&token=7b2841b7-bb30-4322-a76e-14b5875a0e46",
    gender: "male",
  },
];

export const DUTCH_NL_OPTIONS = [
  {
    id: "daan-nl",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.norwegian.ingrid-voice.title",
    value: "daan-nl",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fdutch-nl%2Fdaan_voice.mp3?alt=media&token=6f64352f-342b-4477-9ba6-ce2703aea561",
    gender: "male",
  },
  {
    id: "eva-nl",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.norwegian.marte-voice.title",
    value: "eva-nl",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fdutch-nl%2Feva_voice.mp3?alt=media&token=8a9cf6e1-4e87-47b6-835e-ea5d1170e0bb",
    gender: "female",
  },
  {
    id: "lotte-nl",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.norwegian.even-voice.title",
    value: "lotte-nl",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fdutch-nl%2Flotte_voice.mp3?alt=media&token=c8d9dfa3-e889-462e-9f4f-973455736246",
    gender: "female",
  },
];

export const FRENCH_FR_OPTIONS = [
  {
    id: "antoine-fr",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.french-fr.antoine-voice.title",
    value: "antoine-fr",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Ffrench-fr%2Fantoine_voice.ogg?alt=media&token=272f37c3-fc2a-48a2-804a-8910e834dd3e",
    gender: "male",
  },
  {
    id: "camille-fr",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.french-fr.camille-voice.title",
    value: "camille-fr",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Ffrench-fr%2Fcamille_voice.ogg?alt=media&token=afceb2ae-89f0-4a51-a5b0-3cc2c112b443",
    gender: "female",
  },
  {
    id: "manon-fr",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.french-fr.manon-voice.title",
    value: "manon-fr",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Ffrench-fr%2Fmanon_voice.ogg?alt=media&token=422afffb-4736-42e8-b9e2-7c4700338029",
    gender: "female",
  },
  {
    id: "pierre-fr",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.french-fr.pierre-voice.title",
    value: "pierre-fr",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Ffrench-fr%2Fpierre_voice.ogg?alt=media&token=f1cc6a10-92b1-4243-9b1e-f47eceeca1a5",
    gender: "male",
  },
];

export const HEBREW_HE_OPTIONS = [
  {
    id: "yosef-he",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.hebrew-he.yosef-voice.title",
    value: "yosef-he",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fhebrew-he%2Fyosef_voice.ogg?alt=media&token=74eb3d30-4d18-43bb-8033-be1235b782a7",
    gender: "male",
  },
  {
    id: "sarah-he",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.hebrew-he.sarah-voice.title",
    value: "sarah-he",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fhebrew-he%2Fsarah_voice.ogg?alt=media&token=0514bf24-2854-4fcc-ab51-b719093997c9",
    gender: "female",
  },
];

export const POLISH_PL_OPTIONS = [
  {
    id: "wojciech-pl",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.polish-pl.wojciech-voice.title",
    value: "wojciech-pl",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fpolish-pl%2Fwojciech_voice.ogg?alt=media&token=be7dd3d6-9ab3-438d-a01e-6e9606747d17",
    gender: "male",
  },
  {
    id: "katarzyna-pl",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.polish-pl.katarzyna-voice.title",
    value: "katarzyna-pl",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fpolish-pl%2Fkatarzyna_voice.ogg?alt=media&token=42e28821-2ffb-4a1a-8ea3-d6a887a43bd4",
    gender: "female",
  },
  {
    id: "marta-pl",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.polish-pl.marta-voice.title",
    value: "marta-pl",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fpolish-pl%2Fmarta_voice.ogg?alt=media&token=f173f84e-251c-4ec2-936f-86fac800833b",
    gender: "female",
  },
];

export const RUSSIAN_RU_OPTIONS = [
  {
    id: "sergei-ru",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.russian-ru.sergei-voice.title",
    value: "sergei-ru",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Frussian-ru%2Fsergei_voice.ogg?alt=media&token=8d52d825-89a7-45b2-9b08-b2d32ac54cc2",
    gender: "male",
  },
  {
    id: "olga-ru",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.russian-ru.olga-voice.title",
    value: "olga-ru",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Frussian-ru%2Folga_voice.ogg?alt=media&token=a82d0b3f-30f9-497d-afa7-4eea5b9e531f",
    gender: "female",
  },
  {
    id: "anastasia-ru",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.russian-ru.anastasia-voice.title",
    value: "anastasia-ru",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Frussian-ru%2Fanastasia_voice.ogg?alt=media&token=d7cd2b37-81d4-4620-8f8c-c07a314005df",
    gender: "female",
  },
];

export const HINDI_HI_OPTIONS = [
  {
    id: "rohan-hi",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.hindi-hi.rohan-voice.title",
    value: "rohan-hi",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fhindi-hi%2Frohan_voice.ogg?alt=media&token=5917118a-f3ec-4b94-9c1a-7a8ef5d8f04b",
    gender: "male",
  },
  {
    id: "ananya-hi",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.hindi-hi.ananya-voice.title",
    value: "ananya-hi",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fhindi-hi%2Fananya_voice.ogg?alt=media&token=b81e969d-b016-4440-891f-6ff18eb270cd",
    gender: "female",
  },
];

export const JAPANESE_JP_OPTIONS = [
  {
    id: "hiroshi-ja",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.japanese-jp.hiroshi-voice.title",
    value: "hiroshi-ja",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fjapanese-ja%2Fhiroshi_voice.ogg?alt=media&token=fd86a6bf-48f6-4afd-90a6-e129de04b5d5",
    gender: "male",
  },
  {
    id: "yuki-ja",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.japanese-jp.yuki-voice.title",
    value: "yuki-ja",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fjapanese-ja%2Fyuki_voice.ogg?alt=media&token=2cf557ec-ef82-408b-ac22-0e62dd9d7ad5",
    gender: "female",
  },
];

export const ITALIAN_IT_OPTIONS = [
  {
    id: "marco-it",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.italian-it.marco-voice.title",
    value: "marco-it",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fitalian-it%2Fmarco_voice.ogg?alt=media&token=f4ef6b69-702a-484a-a2fe-a78aa60f6adc",
    gender: "male",
  },
  {
    id: "giuseppe-it",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.italian-it.giuseppe-voice.title",
    value: "giuseppe-it",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fitalian-it%2Fgiuseppe_voice.ogg?alt=media&token=5ec36f4d-cb0a-4ec8-a4c5-b30f9ceaf0ae",
    gender: "male",
  },
  {
    id: "giulia-it",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.italian-it.giulia-voice.title",
    value: "giulia-it",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fitalian-it%2Fgiulia_voice.ogg?alt=media&token=e4a65aa5-c42b-4e80-a5b1-955c22b497ed",
    gender: "female",
  },
  {
    id: "giovanni-it",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.italian-it.giovanni-voice.title",
    value: "giovanni-it",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fitalian-it%2Fgiovanni_voice.ogg?alt=media&token=676d9f45-a3db-4484-9bea-81bad7482f4c",
    gender: "male",
  },
  {
    id: "francesca-it",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.italian-it.francesca-voice.title",
    value: "francesca-it",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fitalian-it%2Ffrancesca_voice.ogg?alt=media&token=02fc7cbc-1875-4806-835b-2c73be00c4e5",
    gender: "female",
  },
  {
    id: "chiara-it",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.italian-it.chiara-voice.title",
    value: "chiara-it",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fitalian-it%2Fchiara_voice.ogg?alt=media&token=f91749cd-fd2e-4933-bab3-02dfc375024e",
    gender: "female",
  },
];

export const PORTUGUESE_PT_OPTIONS = [
  {
    id: "joao-pt",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.portuguese-pt.joao-voice.title",
    value: "joao-pt",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fportuguese-pt%2Fjoao_voice.ogg?alt=media&token=5c9c8ecd-3bad-473f-a0eb-cb2fc1277616",
    gender: "male",
  },
  {
    id: "carolina-pt",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.portuguese-pt.carolina-voice.title",
    value: "carolina-pt",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fportuguese-pt%2Fcarolina_voice.ogg?alt=media&token=4c264c3c-ffe0-44e6-8e9c-916ed008a69e",
    gender: "female",
  },
  {
    id: "sofia-pt",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.portuguese-pt.sofia-voice.title",
    value: "sofia-pt",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fportuguese-pt%2Fsofia_voice.ogg?alt=media&token=d69884d2-30fc-4440-bdb4-7a9d1f36ff72",
    gender: "female",
  },
];

export const SPANISH_ES_OPTIONS = [
  {
    id: "javier-es",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.spanish-es.javier-voice.title",
    value: "javier-es",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fspanish-es%2Fjavier_voice.ogg?alt=media&token=63887a6e-7dc9-4bc3-8ad7-4d6ec6331e9e",
    gender: "male",
  },
  {
    id: "carmen-es",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.spanish-es.carmen-voice.title",
    value: "carmen-es",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fspanish-es%2Fcarmen_voice.ogg?alt=media&token=7064bb2b-211d-4ee6-83b4-1b772145056c",
    gender: "female",
  },
];

export const KOREAN_KO_OPTIONS = [
  {
    id: "minho-ko",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.korean-ko.minho-voice.title",
    value: "minho-ko",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fkorean-ko%2Fminho_voice.ogg?alt=media&token=c63a03b6-8254-4106-8427-1b5cc7164e68",
    gender: "male",
  },
  {
    id: "jiyeon-ko",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.korean-ko.jiyeon-voice.title",
    value: "jiyeon-ko",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fkorean-ko%2Fjiyeon_voice.ogg?alt=media&token=8e3ece8e-e546-47fb-966e-5be4ab6f1006",
    gender: "female",
  },
  {
    id: "joonwoo-ko",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.korean-ko.joonwoo-voice.title",
    value: "joonwoo-ko",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fkorean-ko%2Fjoonwoo_voice.ogg?alt=media&token=45ae18d6-8352-40b1-8335-ee5ee3e06e0c",
    gender: "male",
  },
  {
    id: "sooyoung-ko",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.korean-ko.sooyoung-voice.title",
    value: "sooyoung-ko",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fkorean-ko%2Fsooyoung_voice.ogg?alt=media&token=07e0b709-c0ba-48ff-af23-fd44f5417c60",
    gender: "female",
  },
];

export const ARABIC_AR_XA_OPTIONS = [
  {
    id: "mohammad-ar-xa",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.arabic-ar-xa.mohammad-voice.title",
    value: "mohammad-ar-xa",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Farabic-ar-xa%2Fmohammad_voice.ogg?alt=media&token=5396db88-65d5-482c-9217-ee960ef9568d",
    gender: "male",
  },
  {
    id: "aisha-ar-xa",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.arabic-ar-xa.aisha-voice.title",
    value: "aisha-ar-xa",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Farabic-ar-xa%2Faisha_voice.ogg?alt=media&token=79993972-809c-4cfb-bf18-bed9896d2ef2",
    gender: "female",
  },
  {
    id: "omar-ar-xa",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.arabic-ar-xa.omar-voice.title",
    value: "omar-ar-xa",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Farabic-ar-xa%2Fomar_voice.ogg?alt=media&token=bda68eaf-298e-4529-ae5d-64b0bd34640a",
    gender: "male",
  },
  {
    id: "fatima-ar-xa",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.arabic-ar-xa.fatima-voice.title",
    value: "fatima-ar-xa",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Farabic-ar-xa%2Ffatima_voice.ogg?alt=media&token=2310df8e-abf9-4633-bb1c-d85e7dd21f20",
    gender: "female",
  },
];

export const ENGLISH_AU_OPTIONS = [
  {
    id: "jack-en-au",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.english-au.jack-voice.title",
    value: "jack-en-au",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fenglish-en-au%2Fjack_voice.ogg?alt=media&token=7c18f0d4-0e21-4c0a-8908-7df8c4d947f7",
    gender: "male",
  },
  {
    id: "ava-en-au",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.english-au.ava-voice.title",
    value: "ava-en-au",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fenglish-en-au%2Fava_voice.ogg?alt=media&token=15f21975-3050-4eca-9588-f0d71a0e1fef",
    gender: "female",
  },
  {
    id: "ethan-en-au",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.english-au.ethan-voice.title",
    value: "ethan-en-au",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fenglish-en-au%2Fethan_voice.ogg?alt=media&token=e538a214-6e01-48db-a7eb-6302eae2e18a",
    gender: "male",
  },
  {
    id: "emily-en-au",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.english-au.emily-voice.title",
    value: "emily-en-au",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fenglish-en-au%2Femily_voice.ogg?alt=media&token=3a6a9ee5-adc1-4f34-8781-fa91bfe97745",
    gender: "female",
  },
];

export const FILIPINO_FIL_OPTIONS = [
  {
    id: "andres-fil",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.filipino-fil.andres-voice.title",
    value: "andres-fil",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Ffilipino-fil%2Fandres_voice.ogg?alt=media&token=c2747ec9-3f7d-4d2c-9e67-28a253e85149",
    gender: "male",
  },
  {
    id: "angela-fil",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.filipino-fil.angela-voice.title",
    value: "angela-fil",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Ffilipino-fil%2Fangela_voice.ogg?alt=media&token=1a24824a-972c-4a10-9025-f5dc32beaceb",
    gender: "female",
  },
];

export const VIETNAMESE_VI_OPTIONS = [
  {
    id: "minh-vi",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.vietnamese-vi.minh-voice.title",
    value: "minh-vi",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fvietnamese-vi%2Fminh_voice.ogg?alt=media&token=23c7466d-3972-4ce6-9d20-15adb5a413b8",
    gender: "male",
  },
  {
    id: "mai-vi",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.vietnamese-vi.mai-voice.title",
    value: "mai-vi",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fvietnamese-vi%2Fmai_voice.ogg?alt=media&token=14bd950e-2ad6-4ccf-a75f-7043256a19c1",
    gender: "female",
  },
  {
    id: "duy-vi",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.vietnamese-vi.duy-voice.title",
    value: "duy-vi",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fvietnamese-vi%2Fduy_voice.ogg?alt=media&token=c2520d73-3c8e-43a1-927b-d11814880a76",
    gender: "male",
  },
  {
    id: "thao-vi",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.vietnamese-vi.thao-voice.title",
    value: "thao-vi",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fvietnamese-vi%2Fthao_voice.ogg?alt=media&token=89b993bd-6f69-4b87-9542-b7643e7d8585",
    gender: "female",
  },
];

export const PORTUGUESE_BR_OPTIONS = [
  {
    id: "pedro-pt-br",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.portuguese-br.pedro-voice.title",
    value: "pedro-pt-br",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fportuguese-pt-br%2Fpedro_voice.ogg?alt=media&token=456c6c65-82d4-4b7b-944f-f4f037ccb86d",
    gender: "male",
  },
  {
    id: "gabriel-pt-br",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.portuguese-br.gabriel-voice.title",
    value: "gabriel-pt-br",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fportuguese-pt-br%2Fgabriel_voice.ogg?alt=media&token=5d4e3165-01fc-47d8-872f-db4e428e420b",
    gender: "male",
  },
  {
    id: "juliana-pt-br",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.portuguese-br.juliana-voice.title",
    value: "juliana-pt-br",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fportuguese-pt-br%2Fjuliana_voice.ogg?alt=media&token=2a54a2f8-7dc2-48f6-b978-05af9e52f5fc",
    gender: "female",
  },
  {
    id: "maria-pt-br",
    title:
      "dashboard.pages.soundtracks.add-modal.content.selection-step.voice-selection.portuguese-br.maria-voice.title",
    value: "maria-pt-br",
    voice:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/text-to-speech%2Fvoice-preview%2Fportuguese-pt-br%2Fmaria_voice.ogg?alt=media&token=426a3b8c-02c9-4852-a2fc-e504faea25f0",
    gender: "female",
  },
];

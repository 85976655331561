import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface BlogPost {
  published: any[];
  draft: any[];
}

interface BlogpostsState {
  list: BlogPost;
  deletionArray: string[];
  loadingList: boolean;
  searchQuery: string | null;
}

const initialState: BlogpostsState = {
  list: {
    published: [],
    draft: [],
  },
  deletionArray: [],
  loadingList: true,
  searchQuery: "",
};

const blogpostsSlice = createSlice({
  name: "blogposts",
  initialState,
  reducers: {
    setBlogposts(state, action: PayloadAction<BlogPost>) {
      state.list = action.payload;
    },
    clearBlogposts(state) {
      state.list = { published: [], draft: [] };
    },
    setBlogpostsLoading(state, action: PayloadAction<boolean>) {
      state.loadingList = action.payload;
    },
    setSearchQuery(state, action: PayloadAction<string | null>) {
      state.searchQuery = action.payload;
    },
    appendDeletionArray(state, action: PayloadAction<string>) {
      state.deletionArray.push(action.payload);
    },
    subtractDeletionArray(state, action: PayloadAction<string>) {
      state.deletionArray = state.deletionArray.filter(
        (id) => id !== action.payload
      );
    },
    clearDeletionArray(state) {
      state.deletionArray = [];
    },
  },
});

export const {
  setBlogposts,
  clearBlogposts,
  setBlogpostsLoading,
  setSearchQuery,
  appendDeletionArray,
  subtractDeletionArray,
  clearDeletionArray,
} = blogpostsSlice.actions;

export default blogpostsSlice.reducer;

import styled from "@emotion/styled";
import ProgressBar from "react-bootstrap/ProgressBar";

export const ProgressBarOuterWrapper = styled.div`
  width: 100%;
  .full-progress-bar {
    .progress-bar {
      background-color: #2f8de4;
    }
  }
  &.size-md {
    .full-progress-bar {
      height: 0.875rem;
      font-size: 0.625rem;
    }
  }
  &.size-sm {
    .full-progress-bar {
      height: 0.5rem;
    }
  }
  &.size-xs {
    .full-progress-bar {
      height: 0.3rem;
    }
  }
  &.color-completed {
    .full-progress-bar {
      .progress-bar {
        background-color: rgb(80, 205, 137);
      }
    }
  }
  &.color-failed {
    .full-progress-bar {
      .progress-bar {
        background-color: rgb(241, 65, 108);
      }
    }
  }
  &.color-progress {
    .full-progress-bar {
      .progress-bar {
        background-color: rgb(255, 199, 0);
      }
    }
  }
  &.color-progress2 {
    .full-progress-bar {
      .progress-bar {
        background-color: #2f8de4 !important;
      }
    }
  }
`;

export const ProgressBarWrapper = styled(ProgressBar)``;

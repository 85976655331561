import { useCallback, useState } from "react";
import { firebaseApp } from "../firebase";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { RegisterSchema } from "../pages/register/schema";

const useRegister = () => {
  const auth = getAuth(firebaseApp);
  const [loading, setLoading] = useState<boolean>(false);

  const performRegister = useCallback(
    async (details: RegisterSchema) => {
      try {
        setLoading(true);
        const request = await fetch(
          `${process.env.REACT_APP_BACKEND_ENDPOINT}/register`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(details),
          }
        );
        const response = await request.json();
        if (response.error === null) {
          setLoading(false);
          await signInWithEmailAndPassword(
            auth,
            details.email,
            details.password
          );
          return { error: null, url: response.url };
        } else {
          throw response.error;
        }
      } catch (e) {
        setLoading(false);
        throw e;
      }
    },
    [auth]
  );

  return { performRegister, loading };
};

export default useRegister;

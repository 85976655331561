import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export const BoxWrapper = styled.div`
  max-width: 31.875rem;
  min-height: 320px;
  background-color: #fff;
  border-radius: 1.25rem;
  padding: 1.5625rem;
  box-shadow: 0rem 0rem 0.9375rem #e3e3e3;
  margin: 0.625rem;
  @media (max-width: 767px) {
    max-width: 21.25rem;
  }
`;

export const BoxContent = styled.div``;

export const TopBoxRow = styled.div`
  display: inline-flex;
  width: 100%;
`;

export const BoxThumbnailCol = styled.div`
  display: flex;
  max-width: 3.75rem;
  justify-content: center;
  align-items: center;
  @media (max-width: 767px) {
    max-width: 2.8125rem;
  }
`;

export const Thumbnail = styled.img`
  border-radius: 100%;
  width: 100%;
  object-fit: contain;
`;

export const BoxNameCol = styled.div`
  display: block;
  padding-left: 0.7813rem;
`;

export const BoxLinksCol = styled.div`
  text-align: right;

  flex-grow: 2;
  display: flex;
  text-align: right;
  justify-content: flex-end;
  align-items: center;
  button {
    margin-left: 0.625rem;
  }
`;

export const BoxName = styled.span`
  display: block;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  margin-top: 0.625rem;
  color: #3b3b3b;
  @media (max-width: 767px) {
    margin-top: 0.3125rem;
  }
`;

export const BoxDescription = styled.span`
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  display: block;
  color: grey;
`;

export const BoxContentWrapper = styled.div`
  margin-top: 0.625rem;
`;

export const BoxTestimonial = styled.span`
  color: #808080;
  font-family: "Poppins", sans-serif;
  line-height: 1.7;
  font-size: 0.875rem;
`;

export const LinkWrapper = styled.div`
  margin-top: 0.625rem;
`;

export const LinkIcon = styled.span`
  color: #3b3b3b;
`;

export const LinkUrl = styled(Link)`
  color: #3b3b3b;
  font-family: "Poppins", sans-serif;
  line-height: 1.7;
  font-size: 0.875rem;
  margin-left: 0.3125rem;
`;

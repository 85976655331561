import { getIcon } from "../../utils/get-icon";
import ProgressBar from "../progress-bar";
import {
  FullWrapper,
  Step,
  StepBarWrapper,
  StepCircle,
  StepCircleIcon,
  StepTitle,
} from "./styled";

interface StepBarProps {
  titles: string[];
  currentStep: 1 | 2 | 3 | 4;
}

const StepBar: React.FC<StepBarProps> = ({ titles, currentStep }) => {
  const renderPercentage = () => {
    if (titles.length < 2) return 0;
    return ((currentStep - 1) / (titles.length - 1)) * 100;
  };

  return (
    <FullWrapper>
      <ProgressBar
        className="progress-bar"
        percentage={renderPercentage()}
        size="xs"
        color="progress2"
      />
      <StepBarWrapper>
        {titles?.map((title, index) => (
          <>
            <Step
              className={`step-${currentStep} ${
                currentStep >= index + 1 ? "active" : ""
              }`}
            >
              <StepCircle
                className={`step-${currentStep} ${
                  currentStep >= index + 1 ? "active" : ""
                }`}
              >
                <StepCircleIcon
                  className={`step-${currentStep} ${
                    currentStep >= index + 1 ? "active" : ""
                  }`}
                >
                  {getIcon("selected")}
                </StepCircleIcon>
              </StepCircle>
              <StepTitle
                className={`step-${currentStep} ${
                  currentStep >= index + 1 ? "active" : ""
                }`}
              >
                {title}
              </StepTitle>
            </Step>
          </>
        ))}
      </StepBarWrapper>
    </FullWrapper>
  );
};

export default StepBar;

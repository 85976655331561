import styled from "@emotion/styled";

export const SelectionWrapper = styled.div``;

export const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  button {
    margin-left: 5px;
    margin-right: 5px;
  }
`;

export const ToggleWrapper = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
`;

export const Field = styled.div`
  margin-bottom: 0.9375rem;
  input {
    margin-top: 0.3125rem;
    width: 100%;
  }
`;

export const SelectionsWrapper = styled.div`
  padding: 10px 0px;
`;

import styled from "@emotion/styled";

export const ToggleButtonWrapper = styled.div`
  text-align: center;
  padding: 0px 10px;
`;

export const ToggleGroupContainer = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4375rem 0.3125rem;
  border: 0.0625rem solid #f4f4f4;
  border-radius: 0.875rem;
  overflow: hidden;
`;

export const ToggleButton = styled.button<{ size: any }>`
  border: none;
  margin-left: 0.1563rem;
  margin-right: 0.1563rem;
  padding: 0.625rem 1.5625rem;
  border-radius: 0.75rem;
  background: none;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: grey;
  font-size: 0.875rem;
  &.active {
    color: #2f8de4;
    background-color: #f4f4f4;
  }
  &:disabled {
    color: grey;
    cursor: not-allowed;
    &:hover {
      color: grey !important;
    }
  }
  ${({ size }) =>
    size === 2 &&
    `
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  `}
`;

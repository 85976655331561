import styled from "@emotion/styled";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export const Container = styled.div`
  max-width: 87.5rem;
  margin: 0 auto;
  padding: 6.25rem 1.25rem;
  @media (max-width: 767px) {
    padding-top: 3.125rem;
  }
`;

export const OptionsWrapper = styled.div`
  margin: 0 auto;
  display: block;
  max-width: 60rem;
  margin-top: 50px;
`;

export const OptionsRow = styled(Row)``;

export const OptionCol = styled(Col)`
  @media (max-width: 767px) {
    &:first-of-type {
      padding-bottom: 50px;
    }
  }
`;

export const OptionWrapper = styled.div`
  padding: 25px;
  border-radius: 1rem;
  border: 0.125rem dashed #e1e1e1;
  &.version-go {
    border: 0.125rem dashed #2f8de4;
    background-color: #e5f0f9;
  }
`;

export const PriceWrapper = styled.div`
  padding-top: 10px;
  h2 {
    color: #2f8de4 !important;
  }
`;

export const ListWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 25px;
`;

export const DetailRow = styled(Row)`
  margin: 10px 0px;
`;

export const DetailCol = styled(Col)`
  padding-left: 0px;
  &.icon {
    max-width: 50px;
    text-align: right;
  }
`;

export const DetailTitle = styled.span`
  display: block;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #2f8de4;
`;

export const DetailIcon = styled.span`
  font-size: 24px;
  color: grey;
  &.check {
    color: rgb(23, 198, 83);
  }
`;

export const ButtonsWrapper = styled.div`
  button {
    width: 100%;
  }
  a {
    display: block;
    margin: 0 auto;
    text-align: center;
    margin-top: 10px;
  }
`;

export const ContactLink = styled(Link)`
  font-family: Poppins, sans-serif;
  color: grey;
  font-size: 16px;
  padding-top: 5px;
  &:hover {
    color: #2f8de4 !important;
  }
`;

export const ToggleWrapper = styled.div``;

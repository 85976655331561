import { useCallback } from "react";
import { firebaseApp } from "../firebase";
import { getAuth } from "firebase/auth";

const useDeleteBlogposts = () => {
  const auth = getAuth(firebaseApp);

  const deleteBlogposts = useCallback(
    async (deletions: string[]) => {
      try {
        const token = await auth.currentUser?.getIdToken(true);
        const request = await fetch(
          `${process.env.REACT_APP_BACKEND_ENDPOINT}/admin/blogposts/delete-multiple/${token}/${deletions}`,
          {
            method: "DELETE",
          }
        );
        const response = await request.json();
        if (response.error === null) {
          return { error: null };
        } else {
          throw response.error;
        }
      } catch (e) {
        throw e;
      }
    },
    [auth.currentUser]
  );

  return { deleteBlogposts };
};

export default useDeleteBlogposts;

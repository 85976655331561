import {
  Content,
  InnerContainer,
  Logo,
  LogoContainer,
  RegisterContainer,
  Section,
  Separator,
} from "./styled";
import SelectSubscription from "./steps/select-subscription";
import UserDetails from "./steps/user-details";
import logo from "../../assets/img/logo-2.svg";
import { useCallback, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import useInvitation from "../../hooks/useInvitation";
import EmptyView from "../../components/EmptyView";
import TableLoader from "../../library/table-loader";
import { schema } from "./schema";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { RegisterSchema } from "./schema";
import { zodResolver } from "@hookform/resolvers/zod";
import ProgressBar from "./progressBar";
import Meta from "./meta";

const Register = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<RegisterSchema>({
    resolver: zodResolver(schema),
    defaultValues: {
      subscription: "small",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      passwordRetype: "",
      acceptedTerms: false,
    },
  });
  const {
    validateInvitation,
    loading: validateLoading,
    valid: validInvitation,
  } = useInvitation();
  const [searchParams] = useSearchParams();
  const [step, setStep] = useState<1 | 2 | 3 | 4>(1);

  const selectedSubscription = watch("subscription");
  const acceptedTerms = watch("acceptedTerms");
  let invitationId = searchParams.get("invitation") ?? null;

  const validateInvitationId = useCallback(
    async (id: string) => {
      try {
        const valid = await validateInvitation(id);
        if (valid.error === null && step !== 2) {
          setValue("invitationId", id);
          setValue("email", valid.email);
          setStep(2);
        } else {
          setStep(4);
          throw valid.error;
        }
      } catch (e) {
        return null;
      }
    },
    [validateInvitation, step, setValue]
  );

  useEffect(() => {
    if (window.rewardful) {
      window.rewardful("ready", function () {
        if (window.Rewardful && window.Rewardful.referral) {
          setValue("referralId", window.Rewardful.referral);
        }
      });
    }
  }, [setValue]);

  useEffect(() => {
    if (invitationId) {
      validateInvitationId(invitationId);
    }
  }, [searchParams, validateInvitationId, invitationId]);

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <SelectSubscription
            setValue={setValue}
            selectedSubscription={selectedSubscription}
            setStep={setStep}
          />
        );
      case 2:
        return (
          <UserDetails
            register={register}
            errors={errors}
            setValue={setValue}
            handleSubmit={handleSubmit}
            setStep={setStep}
            acceptedTerms={acceptedTerms}
          />
        );
      default:
        return null;
    }
  };

  const renderView = () => {
    if (invitationId) {
      if (validateLoading) {
        return <TableLoader />;
      } else {
        if (validInvitation) {
          return renderStep();
        } else {
          return (
            <EmptyView
              title={t("register.views.empty-view.title")}
              description={t("register.views.empty-view.text")}
            />
          );
        }
      }
    } else {
      return renderStep();
    }
  };

  return (
    <>
      <Meta />
      <Section>
        <InnerContainer>
          <Content>
            <LogoContainer>
              <Link to="/">
                <Logo alt="Randi logo" src={logo} />
              </Link>
            </LogoContainer>
            <RegisterContainer>
              <ProgressBar step={step} />
              <Separator />
              {renderView()}
            </RegisterContainer>
          </Content>
        </InnerContainer>
      </Section>
    </>
  );
};

export default Register;

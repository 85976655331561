import { useTranslation } from "react-i18next";
import { getIcon } from "../../../../../../../utils/get-icon";
import {
  Wrapper,
  FileUploadButton,
  UploadFileIcon,
  UploadTextWrapper,
  UploadButtonLink,
  FileSupportedText,
} from "./styled";
import { useRef } from "react";

interface NotDroppedViewProps {
  handleFileDrop: any;
}

const NotDroppedView = ({ handleFileDrop }: NotDroppedViewProps) => {
  const { t } = useTranslation();
  const fileInput = useRef<HTMLInputElement>(null);

  const clickUpload = () => {
    fileInput?.current!.click();
  };

  return (
    <Wrapper>
      <FileUploadButton
        type="file"
        onChange={(f) => handleFileDrop(f, "click")}
        ref={fileInput}
      />
      <UploadFileIcon>{getIcon("upload")}</UploadFileIcon>
      <UploadTextWrapper>
        {t(
          "dashboard.pages.transcripts.add-modal.content.source-step.upload-container.subtitle-first"
        )}{" "}
        <UploadButtonLink
          aria-label={
            t(
              "dashboard.pages.transcripts.add-modal.content.source-step.upload-container.choose-file.ariaLabel"
            ) as string
          }
          onClick={clickUpload}
          type="button"
        >
          {t(
            "dashboard.pages.transcripts.add-modal.content.source-step.upload-container.choose-file.title"
          )}
        </UploadButtonLink>{" "}
        {t(
          "dashboard.pages.transcripts.add-modal.content.source-step.upload-container.subtitle-last"
        )}
      </UploadTextWrapper>
      <FileSupportedText>
        {`.mp3 | .mp4 | .mpeg | .mpga | .m4a | .wav | .webm | .ogg | .heic | .mov | .avi`}
      </FileSupportedText>
    </Wrapper>
  );
};

export default NotDroppedView;

import { z } from "zod";

export const schema = z
  .object({
    password: z.string().min(1, "Du må skrive inn et passord."),
    passwordRetype: z.string().min(1, "Du må skrive inn et passord."),
  })
  .superRefine((data, ctx) => {
    if (data.password !== data.passwordRetype) {
      ctx.addIssue({
        path: ["passwordRetype"],
        message: "Passordene må være like.",
        code: "custom",
      });
    }
  });

export type ChangePasswordSchema = z.infer<typeof schema>;

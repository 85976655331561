import { useCallback } from "react";
import { firebaseApp } from "../firebase";
import { getAuth } from "firebase/auth";
import { sendErrorNotification } from "../library/notification";
import {
  setActiveComparison,
  setLoadingComparison,
} from "../redux/slices/transcript";
import { useAppDispatch, useAppSelector } from "../redux/hooks";

const useRevision = () => {
  const dispatch = useAppDispatch();

  const auth = getAuth(firebaseApp);
  const selectedWorkspace = useAppSelector(
    (state) => state.user.selectedWorkspace
  );
  const transcriptId = useAppSelector((state) => state.transcript?.data?.id);
  const comparison = useAppSelector((state) => state.transcript?.comparison);

  const getRevision = useCallback(
    async (revisionId: string | null) => {
      try {
        dispatch(setLoadingComparison(true));
        const token = await auth.currentUser?.getIdToken(true);
        const request = await fetch(
          `${process.env.REACT_APP_BACKEND_ENDPOINT}/workspace/transcript/revision?workspaceId=${selectedWorkspace.id}&token=${token}&transcriptId=${transcriptId}&revisionId=${revisionId}`,
          {
            method: "GET",
          }
        );
        const response = await request.json();
        if (response.error === null) {
          dispatch(setLoadingComparison(false));
          dispatch(setActiveComparison(response.data));
        } else {
          throw response.error;
        }
      } catch (e) {
        dispatch(setLoadingComparison(false));
        sendErrorNotification("Kunne ikke hente revisjon.");
      }
    },
    [auth.currentUser, dispatch, selectedWorkspace.id, transcriptId]
  );

  return {
    getRevision,
    comparison,
  };
};

export default useRevision;

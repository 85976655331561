import styled from "@emotion/styled";

export const SearchContainer = styled.div`
  margin-top: 30px;
  input {
    width: 100%;
    height: 50px;
  }
`;

export const ContentContainer = styled.div`
  margin-top: 25px;
`;

export const TableWrapper = styled.div`
  margin-top: 20px;
  &.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`;

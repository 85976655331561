import { RegistrationForm, Field, InputRow } from "./styled";
import { useTranslation } from "react-i18next";
import { RegisterSchema } from "../../schema";
import { ButtonsContainer } from "../../styled";
import Button from "../../../../library/button";
import useRegister from "../../../../hooks/useRegister";
import { getIcon } from "../../../../utils/get-icon";
import { sendErrorNotification } from "../../../../library/notification";
import { REGULAR_FIELDS } from "./fields";
import { FieldErrors, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { Dispatch, SetStateAction } from "react";

interface IUserDetailsProps {
  register: UseFormRegister<RegisterSchema>;
  errors: FieldErrors;
  setValue: UseFormSetValue<RegisterSchema>;
  handleSubmit: any;
  setStep: Dispatch<SetStateAction<1 | 2 | 3 | 4>>;
  acceptedTerms: any;
}

const UserDetails: React.FC<IUserDetailsProps> = ({
  register,
  errors,
  setValue,
  handleSubmit,
  setStep,
  acceptedTerms,
}) => {
  const { t } = useTranslation();
  const { performRegister, loading: registerLoading } = useRegister();

  const finalSubmit = async (data: RegisterSchema) => {
    try {
      const checkout = await performRegister(data);
      if (checkout?.error === null) {
        window.location.href = checkout?.url;
      }
    } catch (err) {
      sendErrorNotification(t("register.notifications.register-error"));
    }
  };

  const clickGoBack = () => {
    setStep(1);
  };

  const renderLoader = () => {
    return getIcon("loader");
  };

  const renderNextButtonText = () => {
    if (registerLoading) {
      return (
        <>
          {renderLoader()}{" "}
          {t("register.bottom-row.proceed-button.loading.title")}
        </>
      );
    }
    return t("register.bottom-row.proceed-button.default.title");
  };

  const renderNextButtonAriaLabel = () => {
    if (registerLoading) {
      return t("register.bottom-row.proceed-button.loading.ariaLabel");
    }
    return t("register.bottom-row.proceed-button.default.ariaLabel");
  };

  return (
    <RegistrationForm onSubmit={handleSubmit(finalSubmit)}>
      <Field>
        <InputRow>
          {REGULAR_FIELDS.map((field) =>
            field.renderField(register, errors, setValue, {
              acceptedTerms: acceptedTerms,
            })
          )}
        </InputRow>
      </Field>
      <ButtonsContainer>
        <Button
          onClick={clickGoBack}
          ariaLabel={
            t("register.bottom-row.go-back-button.ariaLabel") as string
          }
          variant="regular"
          color="grey"
          type="button"
        >
          Gå tilbake
        </Button>
        <Button
          type="submit"
          spacing="left"
          ariaLabel={renderNextButtonAriaLabel()}
          variant="regular"
          color="blue"
        >
          {renderNextButtonText()}
        </Button>
      </ButtonsContainer>
    </RegistrationForm>
  );
};

export default UserDetails;

import styled from "@emotion/styled";

export const Wrapper = styled.div`
  margin-top: 25px;
  transition: 0.5s;
  &.uploading {
    overflow: hidden;
    height: 0.1px;
  }
`;

export const Field = styled.div`
  margin-bottom: 0.9375rem;
  input {
    margin-top: 0.3125rem;
    width: 100%;
  }
`;

export const BottomWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SmallText = styled.small`
  font-family: Poppins, sans-serif;
  color: grey;
  font-size: 12px;
  padding-left: 20px;
  display: block;
  margin-top: 10px;
`;

import { Link } from "react-router-dom";
import { Text } from "../../../../../library/text";
import { Title } from "../../../../../library/title";
import { ErrorWrapper, Icon } from "./styled";
import { getIcon } from "../../../../../utils/get-icon";

const Index = () => {
  return (
    <ErrorWrapper>
      <Icon className="error">{getIcon("error")}</Icon>
      <Title>En feil oppstod</Title>
      <Text color="dark">
        Kunne ikke laste inn video og transkript. Trykk{" "}
        <Link to="/dashboard/transcripts">her</Link> for å gå tilbake.
      </Text>
    </ErrorWrapper>
  );
};

export default Index;

import styled from "@emotion/styled";

export const AnimationWrapper = styled.div`
  text-align: center;
  padding: 20px 40px;
  max-width: 320px;
  text-align: center;
  margin: 0 auto;
  background-color: #f4f4f4;
  border-radius: 1.25rem;
  @media (max-width: 767px) {
    max-width: 100%;
    padding: 0px 80px;
  }
`;

import styled from "@emotion/styled";

export const FullWrapper = styled.div`
  position: relative;
  padding-bottom: 40px;
  padding-top: 10px;
  .full-progress-bar {
    top: 12.5px;
    margin-left: 30px;
    margin-right: 30px;
  }
`;

export const StepBarWrapper = styled.div`
  position: absolute;
  width: 100%;
  margin-top: -17.5px;
  display: flex;
  justify-content: space-between;
`;

export const Step = styled.div`
  text-align: center;
`;

export const StepCircle = styled.div`
  height: 30px;
  width: 30px;
  margin: 0 auto;
  border-radius: 100%;
  position: relative;
  background-color: #f4f4f4;
  z-index: 2;
  &.active {
    animation: pulse 1s forwards 0.2s;
  }

  @keyframes pulse {
    0% {
      background-color: #f4f4f4;
      transform: scale(1);
      color: grey !important;
      opacity: 1;
    }
    50% {
      transform: scale(1.1);
      background-color: #2f8de4;
      opacity: 0.95;
    }
    100% {
      transform: scale(1);
      background-color: #2f8de4;
      opacity: 1;
    }
  }
`;

export const StepCircleIcon = styled.span`
  color: grey;
  display: absolute;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &.active {
    color: #fff;
  }
`;

export const StepTitle = styled.span`
  display: block;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  margin-top: 5px;
  color: grey;

  @media (max-width: 768px) {
    font-size: 0.75rem;
  }
`;

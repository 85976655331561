import Tabs from "../../../../library/tabs";
import { Text } from "../../../../library/text";
import { Title } from "../../../../library/title";
import {
  OnboardingCol,
  OnboardingRow,
  Video,
  VideoWrapper,
} from "../../styled";
import { TabsWrapper } from "./styled";
import EditCaptions from "./tabs/edit-captions";
import AddCaption from "./tabs/add-caption";
import DeleteCaptions from "./tabs/delete-caption";
import { useState } from "react";

const Index = () => {
  const [activeTab, setActiveTab] = useState<string>("edit-time-and-text");
  const SELECTIONS = [
    {
      id: 1,
      key: "edit-time-and-text",
      title: "Endre teksting",
      display: <EditCaptions />,
    },
    {
      id: 1,
      key: "add-new-text",
      title: "Legg til teksting",
      display: <AddCaption />,
    },
    {
      id: 1,
      key: "delete-text",
      title: "Slett teksting",
      display: <DeleteCaptions />,
    },
  ];

  return (
    <OnboardingRow>
      <OnboardingCol md className="video">
        <VideoWrapper>
          <Video
            controls={false}
            muted={true}
            autoPlay={true}
            loop={true}
            playsInline={true}
            src="https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/public%2Fonboarding_step_three.mp4?alt=media&token=371f6bb8-a866-46d9-92bc-957232501953"
          />
        </VideoWrapper>
      </OnboardingCol>
      <OnboardingCol md className="details my-auto">
        <Title size="sm">Rediger videoen din i editoren</Title>
        <Text size="small" color="dark">
          Etter du har transkribert lyd -eller videofilen din kan du trykke på
          tittelen i listen over alle transkriptene dine. Da vil du bli tatt til
          Randi sin video editor, og slik tar du den i bruk:
        </Text>
        <TabsWrapper>
          <Tabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            selections={SELECTIONS}
          />
        </TabsWrapper>
      </OnboardingCol>
    </OnboardingRow>
  );
};

export default Index;

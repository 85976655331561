import styled from "@emotion/styled";

export const SearchContainer = styled.div`
  margin-top: 1.875rem;
  input {
    width: 100%;
    height: 3.125rem;
  }
`;

export const ContentContainer = styled.div`
  margin-top: 1.5625rem;
`;

export const TableWrapper = styled.div`
  margin-top: 1.25rem;
  &.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  @media (max-width: 767px) {
    overflow-x: scroll;
  }
`;

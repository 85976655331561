import { useCallback, useState } from "react";
import { firebaseApp } from "../firebase";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { sendSuccessNotification } from "../library/notification";

const auth = getAuth(firebaseApp);

const useResetPassword = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const resetPassword = useCallback(async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email);
      setLoading(false);
      sendSuccessNotification(
        "Du vil motta en lenke for tilbakestilling hvis e-posten eksisterer hos oss."
      );
    } catch (e) {
      setLoading(false);
      sendSuccessNotification(
        "Du vil motta en lenke for tilbakestilling hvis e-posten eksisterer hos oss."
      );
    }
  }, []);

  return { resetPassword, loading };
};

export default useResetPassword;

import { Dispatch, SetStateAction } from "react";
import DesktopTopBar from "./desktop";
import MobileTopBar from "./mobile";
import { useMediaQuery } from "react-responsive";
import { MOBILE_VIEW } from "../../../../../variables";
import { useNavigate } from "react-router-dom";
import useTranscript from "../../../../../hooks/useTranscript";
import { VideoAppearance, VideoCaption } from "../../../../../types";

interface TopBarProps {
  onGenerateVideo: () => void;
  generateLoading: boolean;
  progress: number;
  appearance: VideoAppearance;
  captions: VideoCaption[];
  method: any;
  videoDownloadUrl?: string;
  setVideoDownloadUrl?: Dispatch<SetStateAction<string | undefined>>;
}

const TopBar = ({
  onGenerateVideo,
  generateLoading,
  progress,
  appearance,
  captions,
  method,
  videoDownloadUrl,
  setVideoDownloadUrl,
}: TopBarProps) => {
  const navigate = useNavigate();
  const { transcript } = useTranscript();
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_VIEW}px)` });

  const clickGoBack = () => {
    navigate("/dashboard/transcripts");
  };

  return isMobile ? (
    <MobileTopBar
      appearance={appearance}
      captions={captions}
      transcriptId={transcript?.id}
      onGenerateVideo={onGenerateVideo}
      generateLoading={generateLoading}
      progress={progress}
      videoDownloadUrl={videoDownloadUrl}
      setVideoDownloadUrl={setVideoDownloadUrl}
      method={method}
      onGoBack={clickGoBack}
    />
  ) : (
    <DesktopTopBar
      lastUpdated={transcript?.lastUpdated}
      generateLoading={generateLoading}
      title={transcript?.filename}
      progress={progress}
      onGenerateVideo={onGenerateVideo}
      transcriptId={transcript?.id}
      captions={captions}
      appearance={appearance}
      videoDownloadUrl={videoDownloadUrl}
      setVideoDownloadUrl={setVideoDownloadUrl}
    />
  );
};

export default TopBar;

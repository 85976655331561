import { useMediaQuery } from "react-responsive";
import MobileView from "./mobile";
import { MOBILE_VIEW } from "../../../../../variables";
import { useCallback, useEffect } from "react";
import useTranscript from "../../../../../hooks/useTranscript";

interface SelectionsProps {
  currentTime: number;
  videoRef: any;
  setValue: any;
  appearance: any;
  fonts: any;
  register: any;
  playerHeight?: number;
  scrollingContainerRef: any;
  activeCaptionRef: any;
  captions: any[];
  onSave: () => void;
}

const Selections = ({
  currentTime,
  videoRef,
  setValue,
  appearance,
  fonts,
  register,
  playerHeight = 0,
  scrollingContainerRef,
  activeCaptionRef,
  captions,
  onSave,
}: SelectionsProps) => {
  const { transcript } = useTranscript();
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_VIEW}px)` });

  const updateGoogleFontsLink = useCallback(
    (selectedFont: any) => {
      const linkElement = document.getElementById(
        "editor-preview-fonts-link"
      ) as HTMLLinkElement;
      if (linkElement) {
        linkElement.href = `https://fonts.googleapis.com/css2?family=${selectedFont?.family?.replace(
          " ",
          "+"
        )}&display=swap`;
      } else {
        const newLink = document.createElement("link");
        newLink.id = "editor-preview-fonts-link";
        newLink.rel = "stylesheet";
        newLink.href = `https://fonts.googleapis.com/css2?family=${selectedFont?.family?.replace(
          " ",
          "+"
        )}&display=swap`;
        document.head.appendChild(newLink);
      }
      setValue("appearance.fontFamily", selectedFont.family);
    },
    [setValue]
  );

  useEffect(() => {
    if (transcript?.appearance?.fontFamily) {
      setValue("appearance", transcript?.appearance);
      updateGoogleFontsLink({
        family: transcript?.appearance?.fontFamily,
      });
    }
  }, [setValue, transcript?.appearance, updateGoogleFontsLink]);

  const handleTextChange = (key: string, value: string) => {
    setValue(
      "captions",
      captions.map((caption: any) => {
        if (caption.key === key) {
          return { ...caption, text: value };
        }
        return caption;
      })
    );
  };

  return isMobile ? (
    <MobileView
      currentTime={currentTime}
      videoRef={videoRef}
      handleTextChange={handleTextChange}
      captions={captions}
      setValue={setValue}
      appearance={appearance}
      fonts={fonts}
      register={register}
      updateGoogleFontsLink={updateGoogleFontsLink}
      playerHeight={playerHeight}
      scrollingContainerRef={scrollingContainerRef}
      activeCaptionRef={activeCaptionRef}
      onSave={onSave}
    />
  ) : null;
};

export default Selections;

import { getIcon } from "../../utils/get-icon";
import { Text } from "../text";
import {
  ClearButton,
  ErrorWrapper,
  SearchIcon,
  StyledInput,
  StyledInputContainer,
} from "./styled";

interface IInputProps {
  variant: string;
  register?: any;
  value?: string;
  onChange?: any;
  type?: string;
  name?: string;
  placeholder?: string;
  ariaLabel?: string;
  min?: number | string | undefined;
  max?: number | string | undefined;
  disabled?: boolean;
  onBlur?: any;
  className?: string;
  error?: any;
  id?: string;
  onFocus?: any;
}

const Input: React.FC<IInputProps> = ({
  value,
  placeholder,
  onChange,
  type,
  name,
  variant,
  min,
  max,
  disabled,
  ariaLabel,
  onBlur,
  register,
  className = "",
  error,
  id,
  onFocus,
  ...props
}) => {
  const onClear = () => {
    onChange({ target: { value: "" } });
  };

  if (variant === "search") {
    return (
      <StyledInputContainer>
        <SearchIcon className="fa-solid fa-magnifying-glass" />
        <StyledInput
          placeholder={placeholder}
          className={`variant-${variant} ${className}`}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          aria-label={ariaLabel}
          id={id}
          {...props}
        />
        {value && (
          <ClearButton onClick={onClear}>{getIcon("remove")}</ClearButton>
        )}
      </StyledInputContainer>
    );
  }
  if (variant === "numeric") {
    return (
      <StyledInputContainer>
        <StyledInput
          {...register(name)}
          placeholder={placeholder}
          className={`variant-${variant}`}
          value={value}
          onChange={onChange}
          type="number"
          min={min}
          max={max}
          disabled={disabled}
          aria-label={ariaLabel}
          onFocus={onFocus}
          id={id}
          {...props}
        />
      </StyledInputContainer>
    );
  }
  return (
    <StyledInputContainer>
      <StyledInput
        {...register(name)}
        placeholder={placeholder}
        type={type}
        value={value}
        name={name}
        disabled={disabled}
        onBlur={onBlur}
        className={`${error?.message ? "error" : ""}`}
        aria-label={ariaLabel}
        onFocus={onFocus}
        id={id}
        {...props}
      />
      {error?.message && (
        <ErrorWrapper>
          <Text color="fail" size="extrasmall">
            {error?.message}
          </Text>
        </ErrorWrapper>
      )}
    </StyledInputContainer>
  );
};

export default Input;

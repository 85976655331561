import React, { useEffect, useState } from "react";
import {
  ContentContainer,
  ExitIconButton,
  NewModalBody,
  NewModalTitle,
} from "./styled";
import Modal from "react-modal";
import { Title } from "../title";
import { renderSize } from "./config";

interface IModalProps {
  onClose: any;
  open: any;
  title: string;
  size?: number;
  isMobileEditor?: boolean;
  children: React.ReactNode;
}

const ModalComponent: React.FC<IModalProps> = ({
  open,
  onClose,
  title,
  size = 575,
  isMobileEditor = false,
  children,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Modal
      style={renderSize(size, windowWidth, isMobileEditor)}
      isOpen={open}
      onRequestClose={onClose}
    >
      <NewModalTitle>
        <Title>{title}</Title>
        <ExitIconButton onClick={onClose}>
          <i className="fa-solid fa-xmark"></i>
        </ExitIconButton>
      </NewModalTitle>
      <NewModalBody>
        <ContentContainer>{children}</ContentContainer>
      </NewModalBody>
    </Modal>
  );
};

export default ModalComponent;

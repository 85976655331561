import { combineReducers } from "@reduxjs/toolkit";
import user from "../slices/user";
import transcripts from "./transcripts";
import transcript from "./transcript";
import workspace from "./workspace";
import blogposts from "./blogposts";
import blogpost from "./blogpost";
import soundtracks from "./soundtracks";

const appReducer = combineReducers({
  user,
  transcripts,
  transcript,
  workspace,
  blogpost,
  blogposts,
  soundtracks,
});

const rootReducer = (state: any, action: any) => {
  return appReducer(state, action);
};

export default rootReducer;

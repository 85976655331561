import { useTranslation } from "react-i18next";
import { getIcon } from "../../../utils/get-icon";
import WaveSurfer from "wavesurfer.js";
import {
  SelectionCol,
  SelectionDescription,
  SelectionRow,
  SelectionTitle,
  SelectionsWrapper,
} from "../styled";
import { AudioWrapper, PlayButton, WaveformWrapper } from "./styled";
import { useEffect, useRef, useState } from "react";

type AudioSelectionProps = {
  option: any;
  index: number;
};

const AudioSelection = ({ option }: AudioSelectionProps) => {
  const { t } = useTranslation();

  const waveformRef = useRef<HTMLDivElement>(null);
  const wavesurferRef = useRef<WaveSurfer | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  useEffect(() => {
    if (!waveformRef.current) return;

    const wavesurfer = WaveSurfer.create({
      container: waveformRef.current,
      waveColor: "#74bcff",
      progressColor: "#2f8de4",
      cursorWidth: 1,
      barWidth: 2,
      height: 40,
    });

    if (!option?.voice || option?.voice === "") return;

    wavesurfer.load(option?.voice);
    wavesurferRef.current = wavesurfer;

    const handlePlay = () => {
      setIsPlaying(true);
    };

    const handlePause = () => {
      setIsPlaying(false);
    };

    wavesurfer.on("play", handlePlay);
    wavesurfer.on("pause", handlePause);

    return () => {
      wavesurfer.un("play", handlePlay);
      wavesurfer.un("pause", handlePause);
      wavesurfer.destroy();
    };
  }, [option?.voice]);

  const handleWaveformClick = () => {
    if (wavesurferRef.current) {
      if (isPlaying) {
        wavesurferRef.current.pause();
      } else {
        wavesurferRef.current.play();
      }
    }
  };

  return (
    <SelectionsWrapper>
      <SelectionRow>
        <SelectionCol className="details-col audio my-auto">
          <SelectionTitle className="audio">{t(option.title)}</SelectionTitle>
          {option?.description && (
            <SelectionDescription>{t(option.description)}</SelectionDescription>
          )}
        </SelectionCol>
        <SelectionCol className="audio-col my-auto">
          <AudioWrapper>
            <WaveformWrapper ref={waveformRef} />
            <PlayButton type="button" onClick={() => handleWaveformClick()}>
              {wavesurferRef.current?.isPlaying()
                ? getIcon("pause")
                : getIcon("play")}
            </PlayButton>
          </AudioWrapper>
        </SelectionCol>
      </SelectionRow>
    </SelectionsWrapper>
  );
};

export default AudioSelection;

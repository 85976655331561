import { useCallback } from "react";
import { useSelector } from "react-redux";
import { firebaseApp } from "../firebase";
import { getAuth } from "firebase/auth";
import { IState } from "../types";

const useDeleteSoundtracks = () => {
  const auth = getAuth(firebaseApp);
  const selectedWorkspace = useSelector(
    (state: IState) => state.user.selectedWorkspace
  );
  const deleteSoundtracks = useCallback(
    async (deletions: string[]) => {
      try {
        const token = await auth.currentUser?.getIdToken(true);
        const request = await fetch(
          `${process.env.REACT_APP_BACKEND_ENDPOINT}/workspace/soundtracks/delete-multiple/${selectedWorkspace.id}/${token}/${deletions}`,
          {
            method: "DELETE",
          }
        );
        const response = await request.json();
        if (response.error === null) {
          return { error: null };
        } else {
          throw response.error;
        }
      } catch (e) {
        throw e;
      }
    },
    [auth.currentUser, selectedWorkspace?.id]
  );

  return { deleteSoundtracks };
};

export default useDeleteSoundtracks;

import styled from "@emotion/styled";

export const PageContainer = styled.div`
  margin-top: 0rem;
  padding-left: 0rem;
  padding-right: 0rem;
  width: 100%;
  position: relative;
  @media (max-width: 975px) {
    padding-left: 0rem;
    padding-right: 0rem;
  }
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #fff;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 975px) {
    top: 0px;
    z-index: 200;
    height: 100vh;
  }
`;

import styled from "@emotion/styled";

export const ErrorWrapper = styled.div`
  text-align: center;
  max-width: 350px;
  a {
    color: #2f8de4;
  }
`;

export const Icon = styled.span`
  font-size: 2.625rem;
  display: block;
  &.success {
    color: #5cd394;
  }
  &.error {
    color: #e84969;
  }
`;

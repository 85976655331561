export const OPTIONS = [
  {
    id: "file",
    title:
      "dashboard.pages.transcripts.add-modal.content.selection-step.file.title",
    description:
      "dashboard.pages.transcripts.add-modal.content.selection-step.file.description",
    value: "file",
    icon: "movie",
  },
  {
    id: "recording",
    title:
      "dashboard.pages.transcripts.add-modal.content.selection-step.recording.title",
    description:
      "dashboard.pages.transcripts.add-modal.content.selection-step.recording.description",
    value: "recording",
    icon: "record",
  },
];

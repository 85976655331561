import { useTranslation } from "react-i18next";
import { Text } from "../../../library/text";
import { Title } from "../../../library/title";
import {
  Container,
  DescriptionWrapper,
  Section,
  TextContentWrapper,
  VideoWrapper,
  Wrapper,
} from "./styled";

const Index = () => {
  const { t } = useTranslation();
  return (
    <Section>
      <Container>
        <Wrapper>
          <TextContentWrapper>
            <Title>{t("frontpage.sections.video-showcase.title")}</Title>
            <DescriptionWrapper>
              <Text color="dark">
                {t("frontpage.sections.video-showcase.text")}
              </Text>
            </DescriptionWrapper>
          </TextContentWrapper>
          <VideoWrapper>
            <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
              <iframe
                aria-label={
                  t("frontpage.sections.video-showcase.ariaLabel") as string
                }
                src="https://player.vimeo.com/video/875296816?badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479"
                allow="autoplay; fullscreen; picture-in-picture"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                title="video-randi-generere-transkript-UNDERTEKST-BRENT-INN"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </VideoWrapper>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default Index;

import { StyledSelect } from "./styled";

interface ISelectProps {
  items: any[];
  onChange: any;
  selected?: string;
  ariaLabel?: string;
  id?: string;
}

export const Select: React.FC<ISelectProps> = ({
  items,
  onChange,
  selected,
  ariaLabel,
  id,
  ...props
}) => {
  return (
    <StyledSelect
      className="kjb-select"
      aria-label={ariaLabel}
      onChange={onChange}
      id={id}
      {...props}
    >
      {items &&
        items.map((item) => (
          <option
            disabled={item.disabled ? true : false}
            selected={selected === item.value ? true : false}
            value={item.value}
            {...item}
          >
            {item.title}
          </option>
        ))}
    </StyledSelect>
  );
};

import styled from "@emotion/styled";

export const PlayerWrapper = styled.div`
  width: 100%;
  height: auto;
  padding-bottom: 80px;
  z-index: 1;
  position: fixed;
  width: 100%;
  top: 0px;
  max-height: 55vh;
`;

export const ControllerWrapper = styled.div`
  top: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconWrapper = styled.div<{ visible: boolean }>`
  color: white;
  font-size: 48px;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.3s ease;
  pointer-events: none; /* Prevent the icon from intercepting clicks */
`;

export const VideoWrapper = styled.div`
  position: relative;
  display: block;
  background-color: black;
  text-align: center;
`;

export const Video = styled.video`
  display: block;
  object-fit: contain;
  background-color: #000;
  height: 100%;
  border-radius: 0rem;
  max-height: 55vh;
  width: 100%;
  margin: 0 auto;
`;

export const SubtitleWrapper = styled.div<{ playerWidth: number }>`
  position: absolute;
  text-align: center;
  padding: 0px 20px;
  width: 100%;
  max-width: ${({ playerWidth }) => playerWidth}px;
  left: 50%;
  transform: translateX(-50%);
  overflow-x: hidden;

  &.vertical-top {
    top: 5%;
  }
  &.vertical-center {
    top: 50%;
    transform: translateY(-50%);
    transform: translateX(-50%);
  }
  &.vertical-bottom {
    bottom: 5%;
  }
`;

export const ActiveSubtitle = styled.span<{
  fontSize: number;
  color: string;
  backgroundColor: string;
  fontFamily: string;
  decoration: string;
  fontStyle: string;
}>`
  position: relative;
  color: white;
  display: inline;
  line-height: 1.1;
  font-size: ${({ fontSize }) => fontSize}px;
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  font-family: ${({ fontFamily }) => fontFamily};
  text-decoration: ${({ decoration }) => decoration};
  font-style: ${({ fontStyle }) =>
    fontStyle === "italic" ? "italic" : "normal"};
  font-weight: ${({ fontStyle }) => (fontStyle === "bold" ? "bold" : "none")};
`;

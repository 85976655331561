import styled from "@emotion/styled";

export const Wrapper = styled.div``;

export const LoadingContainer = styled.div`
  height: 3.75rem;
  width: 3.75rem;
  margin: 0 auto;
  display: block;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
`;

export const LoadingText = styled.span`
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-align: center;
  font-size: 0.875rem;
  margin-top: 0.9375rem;
  display: block;
  color: grey;
`;

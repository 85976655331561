import { Link } from "react-router-dom";
import Button from "../../../library/button";
import { Text } from "../../../library/text";
import { Title } from "../../../library/title";
import { getIcon } from "../../../utils/get-icon";
import ToggleButtons from "../../../library/toggle-buttons";
import {
  ButtonsWrapper,
  ContactLink,
  Container,
  OptionWrapper,
  DetailCol,
  DetailRow,
  OptionsWrapper,
  OptionCol,
  OptionsRow,
  ListWrapper,
  DetailIcon,
  PriceWrapper,
  ToggleWrapper,
} from "./styled";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Content = () => {
  const { t } = useTranslation();
  const [selectedSubscription, setSelectedSubscription] =
    useState<string>("monthly");

  const handleTabSelection = (selection: string) => {
    setSelectedSubscription(selection);
  };

  return (
    <Container>
      <ToggleWrapper>
        <ToggleButtons
          selected={selectedSubscription}
          options={[
            {
              title: t(
                "register.views.select-subscription.payment-duration.monthly-selection.title"
              ),
              value: "monthly",
            },
            {
              title: t(
                "register.views.select-subscription.payment-duration.annually-selection.title"
              ),
              value: "annually",
            },
          ]}
          onSelect={handleTabSelection}
        />
      </ToggleWrapper>
      <OptionsWrapper>
        <OptionsRow>
          <OptionCol md={6}>
            <OptionWrapper>
              <Title>Randi Small</Title>
              <PriceWrapper>
                <Title size="sm">Gratis</Title>
              </PriceWrapper>
              <ListWrapper>
                <DetailRow>
                  <DetailCol className="my-auto">
                    <Text color="dark">Tilgang til video-editor</Text>
                  </DetailCol>
                  <DetailCol className="icon my-auto">
                    <DetailIcon className="check">
                      {getIcon("available")}
                    </DetailIcon>
                  </DetailCol>
                </DetailRow>
                <DetailRow>
                  <DetailCol className="my-auto">
                    <Text color="dark">
                      Laste ned transkripter (srt, vtt, html og txt)
                    </Text>
                  </DetailCol>
                  <DetailCol className="icon my-auto">
                    <DetailIcon className="check">
                      {getIcon("available")}
                    </DetailIcon>
                  </DetailCol>
                </DetailRow>
                <DetailRow>
                  <DetailCol className="my-auto">
                    <Text color="dark">
                      Laste ned tekst til tale (ogg og mp4)
                    </Text>
                  </DetailCol>
                  <DetailCol className="icon my-auto">
                    <DetailIcon className="check">
                      {getIcon("available")}
                    </DetailIcon>
                  </DetailCol>
                </DetailRow>
                <DetailRow>
                  <DetailCol className="my-auto">
                    <Text color="dark">
                      Laste ned transkripter (.pdf, .csv og .docx)
                    </Text>
                  </DetailCol>
                  <DetailCol className="icon my-auto">
                    <DetailIcon>{getIcon("unavailable")}</DetailIcon>
                  </DetailCol>
                </DetailRow>
                <DetailRow>
                  <DetailCol className="my-auto">
                    <Text color="dark">Ubegrenset antall transkripter</Text>
                  </DetailCol>
                  <DetailCol className="icon my-auto">
                    <DetailIcon>{getIcon("unavailable")}</DetailIcon>
                  </DetailCol>
                </DetailRow>
                <DetailRow>
                  <DetailCol className="my-auto">
                    <Text color="dark">Ubegrenset antall tekst til tale</Text>
                  </DetailCol>
                  <DetailCol className="icon my-auto">
                    <DetailIcon>{getIcon("unavailable")}</DetailIcon>
                  </DetailCol>
                </DetailRow>
                <DetailRow>
                  <DetailCol className="my-auto">
                    <Text color="dark">
                      Ingen vannmerke på nedlastede videoer
                    </Text>
                  </DetailCol>
                  <DetailCol className="icon my-auto">
                    <DetailIcon>{getIcon("unavailable")}</DetailIcon>
                  </DetailCol>
                </DetailRow>
              </ListWrapper>

              <ButtonsWrapper>
                <Link to="/register">
                  <Button variant="regular" color="grey">
                    Kom i gang nå
                  </Button>
                </Link>
                <ContactLink to="mailto:andreas@randi.ai">
                  Kontakt oss for mer informasjon
                </ContactLink>
              </ButtonsWrapper>
            </OptionWrapper>
          </OptionCol>
          <OptionCol md={6}>
            <OptionWrapper className="version-go">
              <Title>Randi Go</Title>
              <PriceWrapper>
                <Title size="sm">
                  {selectedSubscription === "monthly"
                    ? "349kr per mnd"
                    : "3 999kr per år"}
                </Title>
              </PriceWrapper>
              <ListWrapper>
                <DetailRow>
                  <DetailCol className="my-auto">
                    <Text color="dark">Tilgang til video-editor</Text>
                  </DetailCol>
                  <DetailCol className="icon my-auto">
                    <DetailIcon className="check">
                      {getIcon("available")}
                    </DetailIcon>
                  </DetailCol>
                </DetailRow>
                <DetailRow>
                  <DetailCol className="my-auto">
                    <Text color="dark">
                      Laste ned transkripter (srt, vtt, html og txt)
                    </Text>
                  </DetailCol>
                  <DetailCol className="icon my-auto">
                    <DetailIcon className="check">
                      {getIcon("available")}
                    </DetailIcon>
                  </DetailCol>
                </DetailRow>
                <DetailRow>
                  <DetailCol className="my-auto">
                    <Text color="dark">
                      Laste ned tekst til tale (ogg og mp4)
                    </Text>
                  </DetailCol>
                  <DetailCol className="icon my-auto">
                    <DetailIcon className="check">
                      {getIcon("available")}
                    </DetailIcon>
                  </DetailCol>
                </DetailRow>
                <DetailRow>
                  <DetailCol className="my-auto">
                    <Text color="dark">
                      Laste ned transkripter (.pdf, .csv og .docx)
                    </Text>
                  </DetailCol>
                  <DetailCol className="icon my-auto">
                    <DetailIcon className="check">
                      {getIcon("available")}
                    </DetailIcon>
                  </DetailCol>
                </DetailRow>
                <DetailRow>
                  <DetailCol className="my-auto">
                    <Text color="dark">Ubegrenset antall transkripter</Text>
                  </DetailCol>
                  <DetailCol className="icon my-auto">
                    <DetailIcon className="check">
                      {getIcon("available")}
                    </DetailIcon>
                  </DetailCol>
                </DetailRow>
                <DetailRow>
                  <DetailCol className="my-auto">
                    <Text color="dark">Ubegrenset antall tekst til tale</Text>
                  </DetailCol>
                  <DetailCol className="icon my-auto">
                    <DetailIcon className="check">
                      {getIcon("available")}
                    </DetailIcon>
                  </DetailCol>
                </DetailRow>
                <DetailRow>
                  <DetailCol className="my-auto">
                    <Text color="dark">
                      Ingen vannmerke på nedlastede videoer
                    </Text>
                  </DetailCol>
                  <DetailCol className="icon my-auto">
                    <DetailIcon className="check">
                      {getIcon("available")}
                    </DetailIcon>
                  </DetailCol>
                </DetailRow>
              </ListWrapper>
              <ButtonsWrapper>
                <Link to="/register">
                  <Button variant="regular" color="blue">
                    Kom i gang nå
                  </Button>
                </Link>
                <ContactLink to="mailto:andreas@randi.ai">
                  Kontakt oss for mer informasjon
                </ContactLink>
              </ButtonsWrapper>
            </OptionWrapper>
          </OptionCol>
        </OptionsRow>
      </OptionsWrapper>
    </Container>
  );
};

export default Content;

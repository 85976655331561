import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SoundtrackRecordItem, ISoundtrack } from "../../types";

const initialState: ISoundtrack = {
  records: {
    private: [],
    public: [],
  },
  loadingList: true,
  deletionArray: [],
  searchQuery: "",
  loadingCreation: false,
};

const soundtracksSlice = createSlice({
  name: "soundtracks",
  initialState,
  reducers: {
    setSoundtracksList(state, action: PayloadAction<any>) {
      state.records = action.payload;
    },
    clearSoundtracksList(state) {
      state.records = { private: [], public: [] };
    },
    setSoundtracksLoading(state, action: PayloadAction<boolean>) {
      state.loadingList = action.payload;
    },
    setSoundtrackCreationLoading(state, action: PayloadAction<boolean>) {
      state.loadingCreation = action.payload;
    },
    setSearchQuery(state, action: PayloadAction<string>) {
      state.searchQuery = action.payload !== "" ? action.payload : "";
    },
    clearSearchQuery(state) {
      state.searchQuery = "";
    },
    appendDeletionArray(state, action: PayloadAction<any>) {
      state.deletionArray.push(action.payload);
    },
    subtractDeletionArray(state, action: PayloadAction<any>) {
      state.deletionArray = state.deletionArray.filter(
        (id) => id !== action.payload
      );
    },
    clearDeletionArray(state) {
      state.deletionArray = [];
    },
    setSoundtrackStatus(state, action: PayloadAction<any>) {
      const { id, status, downloadUrl, progress } = action.payload;
      const updateStatus = (soundtrack: SoundtrackRecordItem) => {
        if (soundtrack.id === id) {
          return { ...soundtrack, status, downloadUrl, progress };
        }
        return soundtrack;
      };

      state.records.public = state.records.public.map(updateStatus);
      state.records.private = state.records.private.map(updateStatus);
    },
  },
});

export const {
  setSoundtracksList,
  clearSoundtracksList,
  setSoundtracksLoading,
  setSoundtrackCreationLoading,
  setSearchQuery,
  clearSearchQuery,
  appendDeletionArray,
  subtractDeletionArray,
  clearDeletionArray,
  setSoundtrackStatus,
} = soundtracksSlice.actions;

export default soundtracksSlice.reducer;

import styled from "@emotion/styled";

export const SignupFormContainer = styled.form``;

export const Field = styled.div`
  display: block;
  margin-bottom: 10px;
  input {
    width: 100%;
    margin-top: 0.3125rem;
  }
`;

export const ButtonsWrapper = styled.div`
  margin-top: 20px;
  @media (max-width: 767px) {
    button {
      width: 100%;
    }
  }
`;

export const InfoBoxWrapper = styled.div`
  margin-bottom: 20px;
`;

import styled from "@emotion/styled";

export const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  button {
    margin-left: 7.5px;
    margin-right: 7.5px;
  }
`;

export const DescriptionWrapper = styled.div`
  text-align: center;
`;

export const ToggleContainer = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 15px;
`;

export const ButtonContainer = styled.div``;

import moment from "moment";
import {
  TableData,
  TableRow as FullTableRow,
  Value,
  ButtonsWrapper,
} from "./styled";
import Checkbox from "../../../../../library/checkbox";
import Button from "../../../../../library/button";
import {
  sendErrorNotification,
  sendSuccessNotification,
} from "../../../../../library/notification";
import useDeleteBlogpost from "../../../../../hooks/useDeleteBlogpost";
import useFetchBlogpostsList from "../../../../../hooks/useFetchAdminBlogposts";
import { Link } from "react-router-dom";
import {
  appendDeletionArray,
  subtractDeletionArray,
} from "../../../../../redux/slices/blogposts";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import Confirmation from "../../../../../library/modal/confirmation";
import { useState } from "react";

interface ITableRowProps {
  data: any;
  arr: any;
  index: number;
}

const TableRow: React.FC<ITableRowProps> = ({ data }) => {
  const dispatch = useAppDispatch();

  const { deleteBlogpost } = useDeleteBlogpost();
  const { fetchBlogpostsList } = useFetchBlogpostsList();

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const deletions = useAppSelector<string[]>(
    (state) => state.transcripts.deletionArray
  );

  const handleSingleSelection = (e: any, id: string) => {
    if (e.target.checked) {
      if (!deletions.includes(id)) {
        dispatch(appendDeletionArray(id));
      }
    } else {
      if (deletions.includes(id)) {
        dispatch(subtractDeletionArray(id));
      }
    }
  };

  const clickDeleteBlogpost = async () => {
    try {
      const deletion = await deleteBlogpost(data.id);
      if (deletion.error === null) {
        sendSuccessNotification("Innlegget ble slettet.");
        closeDeleteModal();
        fetchBlogpostsList();
      }
    } catch (e) {
      sendErrorNotification("Kunne ikke slette innlegg.");
    }
  };

  return (
    <FullTableRow>
      <TableData className="select">
        <Checkbox
          onChange={(e: any) => handleSingleSelection(e, data.id)}
          checked={deletions.includes(data.id) ? true : false}
          name="product-select"
        />
      </TableData>
      <TableData>
        <Value className="bold title">
          {data.title ? (
            <Link to={`/dashboard/admin/blogposts/${data.id}`}>
              {data.title}
            </Link>
          ) : (
            "Ikke tilgjengelig"
          )}
        </Value>
      </TableData>
      <TableData className="date-display">
        <Value>{moment.unix(data.timestamp).format("HH:mm DD/MM/YYYY")}</Value>
      </TableData>
      <TableData className="buttons">
        <ButtonsWrapper>
          <Button
            spacing="left"
            size="md"
            color="grey"
            variant="icon"
            icon="trash"
            onClick={openDeleteModal}
          />
        </ButtonsWrapper>
      </TableData>
      <Confirmation
        open={showDeleteModal}
        onClose={closeDeleteModal}
        title={"Slette innlegg?"}
        description={
          "Dersom du sletter dette innlegget vil du ikke ha mulighet til å gjenopprette det."
        }
        variant="delete"
        submitText="Ja, slett det"
        onSubmit={clickDeleteBlogpost}
      />
    </FullTableRow>
  );
};

export default TableRow;

export const SUBSCRIPTION_TYPES = [
  {
    id: "small",
    title: "register.views.select-subscription.options.small-option.title",
    description:
      "register.views.select-subscription.options.small-option.description",
    price: "register.views.select-subscription.options.small-option.price",
    duration:
      "register.views.select-subscription.options.small-option.duration",
    ariaLabel:
      "register.views.select-subscription.options.small-option.ariaLabel",
  },
  {
    id: "go-monthly",
    title: "register.views.select-subscription.options.go-option.title",
    description:
      "register.views.select-subscription.options.go-option.description",
    price: "register.views.select-subscription.options.go-option.monthly.price",
    duration:
      "register.views.select-subscription.options.go-option.monthly.duration",
    ariaLabel:
      "register.views.select-subscription.options.go-option.monthly.ariaLabel",
  },
  {
    id: "go-annually",
    title: "register.views.select-subscription.options.go-option.title",
    description:
      "register.views.select-subscription.options.go-option.description",
    price:
      "register.views.select-subscription.options.go-option.annually.price",
    duration:
      "register.views.select-subscription.options.go-option.annually.duration",
    ariaLabel:
      "register.views.select-subscription.options.go-option.annually.ariaLabel",
  },
];

import styled from "@emotion/styled";

export const CardWrapper = styled.div``;

export const ThumbnailWrapper = styled.div`
  margin: 0 auto;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  button {
    display: block;
    margin: 0 auto;
    margin-top: 20px;
  }
`;

export const Thumbnail = styled.img`
  width: 125px;
  height: 125px;
  object-fit: cover;
  margin: 0 auto;
  display: block;
  border-radius: 1rem;
  border: 2px dashed #2f8de4;
`;

export const DetailsWrapper = styled.div`
  margin-top: 35px;
  label {
    padding-left: 0px;
  }
`;

export const Field = styled.div`
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export const FileUploadButton = styled.input`
  display: none;
`;

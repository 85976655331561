import {
  StyledDropdown,
  DropdownToggle,
  DropdownMenuWrapper,
  DropdownItem,
  DropdownTitle,
} from "./styled";

interface IDropdownMenuProps {
  toggle: any;
  open?: any;
  setOpen?: any;
  title?: string;
  ariaLabel?: string;
  direction?: "up" | "down";
  selections: any;
  variant?: "regular" | "button";
}

const DropdownMenu: React.FC<IDropdownMenuProps> = ({
  toggle,
  open,
  setOpen,
  title,
  selections,
  direction = "down",
  ariaLabel,
  variant = "regular",
}) => {
  return (
    <StyledDropdown
      open={open}
      setOpen={setOpen}
      autoClose={true}
      collapseOnSelect
      drop={direction}
    >
      <DropdownToggle
        className={`${variant === "regular" ? "regular" : "button"}`}
        aria-label={ariaLabel}
      >
        {toggle}
      </DropdownToggle>
      <DropdownMenuWrapper>
        {title && <DropdownTitle>{title}</DropdownTitle>}
        {selections &&
          selections.map((selection: any) => (
            <DropdownItem onClick={selection.onClick}>
              {selection.display}
            </DropdownItem>
          ))}
      </DropdownMenuWrapper>
    </StyledDropdown>
  );
};

export default DropdownMenu;

import { Helmet } from "react-helmet";

interface IMetaProps {
  content: any;
}

const Meta: React.FC<IMetaProps> = ({ content }) => {
  return (
    <Helmet>
      <title>Randi.ai - {content.title ?? ""}</title>
      <meta name="thumbnail" content="https://randi.ai/showcase.jpg" />
      <meta name="robots" content="index, follow" />
      <meta name="description" content={content.excerpt ?? ""} />
      <meta name="author" content="Randi.ai" />
      <meta name="keywords" content={content.excerpt ?? ""} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:url" content="https://randi.ai/" />
      <meta
        name="twitter:title"
        content={`Randi.ai - ${content.title ?? ""}`}
      />
      <meta name="twitter:description" content={content.excerpt} />
      <meta name="twitter:image" content="https://randi.ai/showcase.jpg" />

      <meta property="og:url" content="https://randi.ai/" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={`Randi.ai - ${content.title ?? ""}`} />
      <meta property="og:description" content={content.excerpt ?? ""} />
      <meta property="og:image" content="https://randi.ai/showcase.jpg" />
      <link rel="canonical" href="https://randi.ai/" />
    </Helmet>
  );
};

export default Meta;

import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./translation/en.json";
import no from "./translation/no.json";
import sv from "./translation/sv.json";
import dk from "./translation/dk.json";

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "no",
    fallbackLng: "en",
    resources: {
      en: en,
      no: no,
      sv: sv,
      dk: dk,
    },
  });

import { z } from "zod";

export const webinarSignupSchema = z.object({
  firstName: z.string().min(1, "Du må oppgi et fornavn."),
  lastName: z.string().min(1, "Du må oppgi et etternavn."),
  email: z.string().email("Du må oppgi en e-post."),
  mobile: z.string().min(1, "Du må oppgi et telefonnummer."),
  acceptedTerms: z.boolean().refine((val) => val === true, {
    message: "Du må godta vilkårene.",
  }),
});

export type WebinarSignupSchema = z.infer<typeof webinarSignupSchema>;

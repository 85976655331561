import { useEffect } from "react";
import { useSelector } from "react-redux";
import useWorkspaceDetails from "../../../hooks/useWorkspaceDetails";
import TopView from "./views/top-view";
import { IState } from "../../../types";
import DashboardPage from "../../../components/DashboardPage";
import BottomView from "./views/bottom-view";
import AttentionBox from "../../../library/attention-box";
import { useTranslation } from "react-i18next";
import { metadata } from "./meta";

const Index = () => {
  const { t } = useTranslation();
  const { fetchWorkspaceDetails } = useWorkspaceDetails();
  const selectedWorkspace = useSelector(
    (state: IState) => state.user.selectedWorkspace
  );
  const user = useSelector((state: IState) => state.user.details);

  useEffect(() => {
    if (selectedWorkspace?.id && user?.uid) {
      fetchWorkspaceDetails();
    }
  }, [fetchWorkspaceDetails, selectedWorkspace?.id, user?.uid]);

  return (
    <DashboardPage
      meta={metadata}
      layout="stacked"
      notification={
        <AttentionBox
          type="information"
          icon="information"
          title={t("dashboard.pages.workspace.attention-box.title")}
          text={t("dashboard.pages.workspace.attention-box.text")}
        />
      }
    >
      <TopView />
      <BottomView />
    </DashboardPage>
  );
};

export default Index;

import { ListItem, ListWrapper, TabsWrapper } from "../styled";

const Index = () => {
  return (
    <TabsWrapper>
      <ListWrapper>
        <ListItem>
          For å endre en undertekst kan du trykke på teksten fra listen på
          venstre side hvor du kan gjøre endringene du ønsker i sanntid som vil
          bli synlig i videospilleren på høyre side.
        </ListItem>
        <ListItem>
          Du kan også endre tidskodene ved å trykke på den oppe i venstre hjørne
          (start og slutt) og skrive inn ønsket tidskode.
        </ListItem>
      </ListWrapper>
    </TabsWrapper>
  );
};

export default Index;

export const OPTIONS = [
  {
    id: "text",
    title: "TXT filformat",
    description: "Vanlig tekstformat (.txt)",
    value: "text",
    icon: "transcription",
    paid: false,
  },
  {
    id: "srt",
    title: "SRT filformat",
    description: "SubRip (.srt)",
    value: "srt",
    icon: "transcription",
    paid: false,
  },
  {
    id: "vtt",
    title: "VTT filformat",
    description: "Video Text Tracks (.vtt)",
    value: "vtt",
    icon: "transcription",
    paid: false,
  },
  {
    id: "html",
    title: "HTML filformat",
    description: "Hyper Text Markup Language (.html)",
    value: "html",
    icon: "transcription",
    paid: false,
  },
  {
    id: "csv",
    title: "CSV filformat",
    description: "Microsoft Excel (.csv)",
    value: "csv",
    icon: "transcription",
    paid: true,
  },
  {
    id: "docx",
    title: "DOCX filformat",
    description: "Microsoft Word (.docx)",
    value: "docx",
    icon: "transcription",
    paid: true,
  },
  {
    id: "pdf",
    title: "PDF filformat",
    description: "Portable Document Format (.pdf)",
    value: "pdf",
    icon: "transcription",
    paid: true,
  },
];

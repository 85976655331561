import { useTranslation } from "react-i18next";
import { getIcon } from "../../../utils/get-icon";
import {
  SelectButton,
  SelectIcon,
  SelectionCol,
  SelectionDescription,
  SelectionRow,
  SelectionTitle,
  SelectionsWrapper,
} from "../styled";
import { Badge } from "./styled";

type RegularSelectionProps = {
  option: any;
};

const FormatSelection = ({ option }: RegularSelectionProps) => {
  const { t } = useTranslation();

  return (
    <SelectionsWrapper>
      <SelectionRow>
        <SelectionCol className="button-col my-auto">
          {option?.icon ? (
            <SelectIcon>{getIcon(option?.icon)}</SelectIcon>
          ) : (
            <SelectButton>{getIcon("unselected-box")}</SelectButton>
          )}
        </SelectionCol>
        <SelectionCol
          className={`details-col my-auto ${
            option?.description ? "" : "small"
          }`}
        >
          <SelectionTitle>{t(option?.title)}</SelectionTitle>
          {option?.description && (
            <SelectionDescription>
              {t(option?.description)}
            </SelectionDescription>
          )}
        </SelectionCol>
        {option?.paid && (
          <SelectionCol className="badge my-auto">
            <Badge>Randi Go</Badge>
          </SelectionCol>
        )}
      </SelectionRow>
    </SelectionsWrapper>
  );
};

export default FormatSelection;

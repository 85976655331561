import styled from "@emotion/styled";

export const Wrapper = styled.div`
  postion: relative;
`;

export const FileUploadButton = styled.input`
  display: none;
`;

export const UploadFileIcon = styled.h3`
  font-size: 1.75rem;
  color: grey;
`;

export const UploadTextWrapper = styled.span`
  font-family: "Poppins", sans-serif;
  font-weight: 500;
`;

export const UploadButtonLink = styled.button`
  border: none;
  background: transparent;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #2f8de4 !important;
  font-size: 1rem;
  padding: 0rem;
`;

export const FileSupportedText = styled.span`
  display: block;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: grey;
  font-size: 0.75rem;
`;

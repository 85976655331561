import styled from "@emotion/styled";
import { Accordion } from "react-bootstrap";

export const AccordionItem = styled(Accordion.Item)`
  border: none;
  padding-left: 0rem !important;
  margin: 0rem !important;
  background: transparent;
  border-bottom: 0.0625rem solid #3b3b3b;
  &:last-of-type {
    border-bottom: none;
  }
  &:not(.collapsed) {
    border-color: #dee2e6 !important;
  }
`;

export const AccordionHeader = styled(Accordion.Header)`
  background: transparent;
  outline: none !important;
  button {
    padding: 1.25rem 0rem;
    background: transparent !important;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    outline: none !important;
    color: grey;
    &:not(.collapsed) {
      color: #2f8de4 !important;
    }
  }
`;

export const AccordionBody = styled(Accordion.Body)`
  padding-left: 0rem !important;
`;

import { z } from "zod";

export const schema = z.object({
  id: z.string().min(1, "Innlegget må ha en ID."),
  thumbnail: z.any(),
  status: z.string(),
  title: z.string().min(1, "Du må oppgi en tittel."),
  prefix: z.string().min(1, "Du må oppgi en URL-struktur."),
  excerpt: z
    .string()
    .min(1, "Du må oppgi et utdrag som er lengre enn 50 bokstaver."),
  content: z.string().min(1, "Du må oppgi et innhold."),
});

export type EditBlogpostSchema = z.infer<typeof schema>;

import styled from "@emotion/styled";

export const TopWrapper = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  @media (max-width: 975px) {
    margin-left: 0rem;
    margin-right: 0rem;
  }
`;

export const UpperTopWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ReturnWrapper = styled.div`
  display: flex;
  align-items: center;
  h2 {
    margin-left: 15px;
    margin-top: 7.5px;
  }
`;

export const GoBackIcon = styled.span`
  color: grey !important;
  opacity: 0.5;
`;

export const Logo = styled.img`
  max-width: 35px;
  margin-top: 0rem;
  margin-left: 7.5px;
  @media (max-width: 900px) {
    max-width: 40px;
  }
`;

export const ButtonsWrapper = styled.div``;

export const LastSavedLabel = styled.span`
  font-family: Poppins, sans-serif;
  display: block;
  color: grey;
  margin-top: -5px;
  margin-left: 15px;
  font-size: 12px;
  i {
    margin-right: 5px;
  }
`;

/*
export const ButtonsWrapper = styled.div`
  display: inline-flex !important;
  button {
    margin-left: 0.9375rem;
  }
`;
*/

export const RevisionWrapper = styled.div`
  margin-left: 15px;
`;

export const TitleWrapper = styled.div`
  display: block;
`;

import { useTranslation } from "react-i18next";
import Dropdown from "../../../library/dropdown-menu";
import { HelpButton, Name, NavLink, NavWrapper, UserThumbnail } from "./styled";
import thumbnail from "../../../assets/img/thumbnail.svg";
import { getIcon } from "../../../utils/get-icon";

interface DesktopViewProps {
  checkActive: (path: string) => boolean;
  selectedWorkspace: any;
  details: any;
  loading: boolean;
  renderLanguage: () => JSX.Element;
  navigationItems: any[];
  languageItems: any[];
  openOnboardingModal: () => void;
}

const DesktopView = ({
  checkActive,
  selectedWorkspace,
  details,
  loading,
  renderLanguage,
  navigationItems,
  languageItems,
  openOnboardingModal,
}: DesktopViewProps) => {
  const { t } = useTranslation();

  const renderThumbnail = () => {
    if (details?.thumbnail) {
      return details.thumbnail;
    }
    return thumbnail;
  };

  return (
    <>
      <NavWrapper className="me-auto">
        <NavLink
          aria-label={
            t("dashboard.top-navigation.links.transcripts.ariaLabel") as string
          }
          className={`aligned-left ${
            checkActive("/dashboard/transcripts") ? "active" : ""
          }`}
          to="/dashboard/transcripts"
        >
          {t("dashboard.top-navigation.links.transcripts.title")}
        </NavLink>
        <NavLink
          aria-label={
            t("dashboard.top-navigation.links.soundtracks.ariaLabel") as string
          }
          className={`aligned-left ${
            checkActive("/dashboard/soundtracks") ? "active" : ""
          }`}
          to="/dashboard/soundtracks"
        >
          {t("dashboard.top-navigation.links.soundtracks.title")}
        </NavLink>
        {selectedWorkspace?.role === "administrator" && (
          <NavLink
            aria-label={
              t("dashboard.top-navigation.links.workspace.ariaLabel") as string
            }
            className={`aligned-left ${
              checkActive("/dashboard/workspace") ? "active" : ""
            }`}
            to="/dashboard/workspace"
          >
            {t("dashboard.top-navigation.links.workspace.title")}
          </NavLink>
        )}
        {details?.role === "administrator" && (
          <NavLink
            aria-label={`Link blogginnlegg`}
            className={`aligned-left ${
              checkActive("/dashboard/admin/blogposts") ? "active" : ""
            }`}
            to="/dashboard/admin/blogposts"
          >
            Blogginnlegg
          </NavLink>
        )}
      </NavWrapper>
      <HelpButton
        onClick={openOnboardingModal}
        color="transparent"
        variant="regular"
      >
        {t("components.public-navigation.tutorial-button.title")}
        {getIcon("wave")}
      </HelpButton>
      <Dropdown
        selections={languageItems}
        title={t("components.public-navigation.language-dropdown.title")!}
        toggle={renderLanguage()}
        ariaLabel={
          t(
            "components.public-navigation.language-dropdown.toggle.ariaLabel"
          ) as string
        }
      />
      <Dropdown
        selections={navigationItems}
        title={t("dashboard.top-navigation.user-dropdown.title")!}
        toggle={
          <>
            <Name>
              {loading ? null : `${details?.firstName} ${details?.lastName}`}
            </Name>
            <UserThumbnail
              alt={
                t(
                  "dashboard.top-navigation.user-dropdown.thumbnail.alt"
                ) as string
              }
              src={renderThumbnail()}
            />
          </>
        }
        ariaLabel={
          t("dashboard.top-navigation.user-dropdown.ariaLabel") as string
        }
      />
    </>
  );
};

export default DesktopView;

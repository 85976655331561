import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface License {
  used: number;
  total: number;
}

interface WorkspaceDetails {
  name: string;
  dateCreated: number;
  subscription: string;
  customerId: string;
  licenses: License;
}

interface WorkspaceState {
  details: WorkspaceDetails;
  loadingDetails: boolean;
  members: Array<any>; // Adjust the type as needed
  loadingMembers: boolean;
}

const initialState: WorkspaceState = {
  details: {
    name: "",
    dateCreated: 0,
    subscription: "",
    customerId: "",
    licenses: {
      used: 0,
      total: 0,
    },
  },
  loadingDetails: true,
  members: [],
  loadingMembers: true,
};

const workspaceSlice = createSlice({
  name: "workspace",
  initialState,
  reducers: {
    setDetails(state, action: PayloadAction<WorkspaceDetails>) {
      state.details = action.payload;
    },
    setLoadingDetails(state, action: PayloadAction<boolean>) {
      state.loadingDetails = action.payload;
    },
    setMembersList(state, action: PayloadAction<any[]>) {
      state.members = action.payload;
    },
    setLoadingMembersList(state, action: PayloadAction<boolean>) {
      state.loadingMembers = action.payload;
    },
  },
});

export const {
  setDetails,
  setLoadingDetails,
  setMembersList,
  setLoadingMembersList,
} = workspaceSlice.actions;

export default workspaceSlice.reducer;

import { ProgressBarOuterWrapper, ProgressBarWrapper } from "./styled";

interface IProgressBarProps {
  percentage: number;
  label?: string;
  color?: "progress" | "progress2" | "completed" | "failed";
  size: "xs" | "sm" | "md" | "lg";
  className?: string;
}

const ProgressBar: React.FC<IProgressBarProps> = ({
  percentage,
  label,
  color = "progress",
  size = "md",
  className,
}) => {
  return (
    <ProgressBarOuterWrapper
      className={`${className} size-${size} color-${color}`}
    >
      <ProgressBarWrapper
        className="full-progress-bar"
        now={percentage}
        label={label}
      />
    </ProgressBarOuterWrapper>
  );
};

export default ProgressBar;

import { Dispatch, SetStateAction, useState } from "react";
import Button from "../../../../../library/button";
import Selections from "../../../../../library/selections";
import {
  ButtonsWrapper,
  SelectionWrapper,
  SelectionsWrapper,
  ToggleWrapper,
} from "./styled";
import { useTranslation } from "react-i18next";
import ToggleButtons from "../../../../../library/toggle-buttons";
import {
  ARABIC_AR_XA_OPTIONS,
  DANISH_DK_OPTIONS,
  DUTCH_NL_OPTIONS,
  ENGLISH_AU_OPTIONS,
  ENGLISH_UK_OPTIONS,
  ENGLISH_US_OPTIONS,
  FILIPINO_FIL_OPTIONS,
  FRENCH_FR_OPTIONS,
  GERMAN_DE_OPTIONS,
  HEBREW_HE_OPTIONS,
  HINDI_HI_OPTIONS,
  ITALIAN_IT_OPTIONS,
  JAPANESE_JP_OPTIONS,
  KOREAN_KO_OPTIONS,
  NORWEGIAN_NO_OPTIONS,
  POLISH_PL_OPTIONS,
  PORTUGUESE_BR_OPTIONS,
  PORTUGUESE_PT_OPTIONS,
  RUSSIAN_RU_OPTIONS,
  SPANISH_ES_OPTIONS,
  SWEDISH_SV_OPTIONS,
  VIETNAMESE_VI_OPTIONS,
} from "./options";
import { FieldErrors, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { UploadSoundtrackSchema } from "../schema";

interface SelectVoiceViewProps {
  setStep: Dispatch<SetStateAction<2 | 1 | 3>>;
  setValue: UseFormSetValue<UploadSoundtrackSchema>;
  selectedVoice: string;
  selectedLanguage: string;
  register: UseFormRegister<UploadSoundtrackSchema>;
  errors: FieldErrors;
}

const SelectVoiceView: React.FC<SelectVoiceViewProps> = ({
  setStep,
  setValue,
  selectedVoice,
  selectedLanguage,
}) => {
  const { t } = useTranslation();
  const [selectedGender, setSelectedGender] = useState<"female" | "male">(
    "female"
  );
  const handleSelection = (voice: any) => {
    setValue("voice", voice?.value);
  };

  const handleTabSelection = (selection: "male" | "female") => {
    setSelectedGender(selection);
  };

  const clickBack = () => {
    setStep(1);
  };

  const clickNext = () => {
    setStep(3);
  };

  const renderOptions = () => {
    switch (selectedLanguage) {
      case "norwegian":
        return NORWEGIAN_NO_OPTIONS.filter(
          (option) => option.gender === selectedGender
        );
      case "swedish":
        return SWEDISH_SV_OPTIONS.filter(
          (option) => option.gender === selectedGender
        );
      case "danish":
        return DANISH_DK_OPTIONS.filter(
          (option) => option.gender === selectedGender
        );
      case "english-uk":
        return ENGLISH_UK_OPTIONS.filter(
          (option) => option.gender === selectedGender
        );
      case "english-us":
        return ENGLISH_US_OPTIONS.filter(
          (option) => option.gender === selectedGender
        );
      case "german":
        return GERMAN_DE_OPTIONS.filter(
          (option) => option.gender === selectedGender
        );
      case "dutch":
        return DUTCH_NL_OPTIONS.filter(
          (option) => option.gender === selectedGender
        );
      case "french":
        return FRENCH_FR_OPTIONS.filter(
          (option) => option.gender === selectedGender
        );
      case "hebrew":
        return HEBREW_HE_OPTIONS.filter(
          (option) => option.gender === selectedGender
        );
      case "hindi":
        return HINDI_HI_OPTIONS.filter(
          (option) => option.gender === selectedGender
        );
      case "italian":
        return ITALIAN_IT_OPTIONS.filter(
          (option) => option.gender === selectedGender
        );
      case "japanese":
        return JAPANESE_JP_OPTIONS.filter(
          (option) => option.gender === selectedGender
        );
      case "polish":
        return POLISH_PL_OPTIONS.filter(
          (option) => option.gender === selectedGender
        );
      case "portuguese-pt":
        return PORTUGUESE_PT_OPTIONS.filter(
          (option) => option.gender === selectedGender
        );
      case "portuguese-br":
        return PORTUGUESE_BR_OPTIONS.filter(
          (option) => option.gender === selectedGender
        );
      case "russian":
        return RUSSIAN_RU_OPTIONS.filter(
          (option) => option.gender === selectedGender
        );
      case "spanish":
        return SPANISH_ES_OPTIONS.filter(
          (option) => option.gender === selectedGender
        );
      case "korean":
        return KOREAN_KO_OPTIONS.filter(
          (option) => option.gender === selectedGender
        );
      case "arabic":
        return ARABIC_AR_XA_OPTIONS.filter(
          (option) => option.gender === selectedGender
        );
      case "english-au":
        return ENGLISH_AU_OPTIONS.filter(
          (option) => option.gender === selectedGender
        );
      case "filipino":
        return FILIPINO_FIL_OPTIONS.filter(
          (option) => option.gender === selectedGender
        );
      case "vietnamese":
        return VIETNAMESE_VI_OPTIONS.filter(
          (option) => option.gender === selectedGender
        );
      default:
        return [];
    }
  };

  return (
    <SelectionWrapper>
      <ToggleWrapper>
        <ToggleButtons
          selected={selectedGender}
          options={[
            {
              title: t(
                "dashboard.pages.soundtracks.add-modal.content.selection-step.toggle-buttons.female-toggle.title"
              ),
              value: "female",
            },
            {
              title: t(
                "dashboard.pages.soundtracks.add-modal.content.selection-step.toggle-buttons.male-toggle.title"
              ),
              value: "male",
            },
          ]}
          onSelect={handleTabSelection}
        />
      </ToggleWrapper>
      <SelectionsWrapper>
        <Selections
          defaultSelected={selectedVoice}
          options={renderOptions()}
          onSelect={handleSelection}
          type="audio"
        />
      </SelectionsWrapper>
      <ButtonsWrapper>
        <Button
          onClick={clickBack}
          variant="regular"
          color="grey"
          ariaLabel={
            t(
              "dashboard.pages.soundtracks.add-modal.content.selection-step.buttons.back.ariaLabel"
            ) as string
          }
        >
          {t(
            "dashboard.pages.soundtracks.add-modal.content.selection-step.buttons.back.title"
          )}
        </Button>
        <Button
          type="submit"
          variant="regular"
          color="blue"
          onClick={clickNext}
          ariaLabel={
            t(
              "dashboard.pages.soundtracks.add-modal.content.selection-step.buttons.next.ariaLabel"
            ) as string
          }
        >
          {t(
            "dashboard.pages.soundtracks.add-modal.content.selection-step.buttons.next.title"
          )}
        </Button>
      </ButtonsWrapper>
    </SelectionWrapper>
  );
};

export default SelectVoiceView;

import { getAuth } from "firebase/auth";
import { useCallback } from "react";
import { firebaseApp } from "../firebase";
import { useSelector } from "react-redux";
import { IState } from "../types";

const useInvite = () => {
  const selectedWorkspace = useSelector(
    (state: IState) => state.user.selectedWorkspace
  );
  const auth = getAuth(firebaseApp);
  const sendInvitation = useCallback(
    async (email: string) => {
      try {
        const token = await auth.currentUser?.getIdToken(true);
        const request = await fetch(
          `${process.env.REACT_APP_BACKEND_ENDPOINT}/workspace/invite`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              email: email,
              workspaceId: selectedWorkspace.id,
              token: token,
            }),
          }
        );
        const response = await request.json();
        if (response.error === null) {
          return { error: null };
        } else {
          throw response.error;
        }
      } catch (e) {
        throw e;
      }
    },
    [auth.currentUser, selectedWorkspace.id]
  );
  return { sendInvitation };
};

export default useInvite;

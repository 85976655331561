import styled from "@emotion/styled";

export const StyledText = styled.span`
  font-family: Poppins, sans-serif;
  color: grey;
  line-height: 1.7rem;
  &.size-extrasmall {
    font-size: 12px;
  }
  &.size-small {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
  &.size-regular {
    font-size: 1rem;
  }
  &.color-light {
    color: #fff;
  }
  &.color-fail {
    color: #ed0c60;
  }
`;

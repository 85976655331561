import { Helmet } from "react-helmet";

const Meta = () => {
  return (
    <Helmet>
      <title>Randi.ai - Cookies</title>
      <meta name="thumbnail" content="https://randi.ai/showcase.jpg" />
      <meta name="robots" content="index, follow" />
      <meta
        name="description"
        content="Les om hvordan Randi benytter seg av cookies og hvordan vi bruker dataen vi samler inn om deg for å gi deg den best mulige brukeropplevelsen."
      />
      <meta name="author" content="Randi.ai" />
      <meta name="keywords" content="" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:url" content="https://randi.ai/" />
      <meta name="twitter:title" content="Randi.ai - Cookies" />
      <meta
        name="twitter:description"
        content="Les om hvordan Randi benytter seg av cookies og hvordan vi bruker dataen vi samler inn om deg for å gi deg den best mulige brukeropplevelsen."
      />
      <meta name="twitter:image" content="https://randi.ai/showcase.jpg" />

      <meta property="og:url" content="https://randi.ai/" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content="Randi.ai - Cookies" />
      <meta
        property="og:description"
        content="Les om hvordan Randi benytter seg av cookies og hvordan vi bruker dataen vi samler inn om deg for å gi deg den best mulige brukeropplevelsen."
      />
      <meta property="og:image" content="https://randi.ai/showcase.jpg" />
      <link rel="canonical" href="https://randi.ai/" />
    </Helmet>
  );
};

export default Meta;

import styled from "@emotion/styled";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";

export const NavigationWrapper = styled.div`
  top: 0px;
  padding: 10px 50px;
  position: fixed;
  width: 100%;
  transition: 0.5s;
  z-index: 9999;
  background-color: #fff;
  @media (max-width: 975px) {
    padding: 0.3125rem 20px;
  }
`;

export const InnerNavigationWrapper = styled.div`
  max-width: 87.5rem;
  margin: 0 auto;
`;

export const NavbarWrapper = styled(Navbar)`
  background-color: transparent !important;
`;

export const NavbarBrand = styled(Navbar.Brand)``;

export const NavbarLogo = styled.img`
  max-width: 45px;
  margin-top: 0rem;
  @media (max-width: 900px) {
    max-width: 40px;
  }
`;

export const NavbarToggle = styled(Navbar.Toggle)`
  border: none;
  padding: 0rem;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
`;

export const Toggle = styled.span`
  height: 1.5625rem;
  display: block;
  color: #3b3b3b;
  width: 1.25rem;
  > i {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.75rem;
  }
`;

export const NavbarCollapse = styled(Navbar.Collapse)``;

export const NavWrapper = styled(Nav)`
  @media (max-width: 900px) {
    padding-top: 10px;
  }
`;

export const NavLink = styled(Link)`
  color: #3b3b3b;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-decoration: none;
  line-height: 30px;
  border-bottom: 2px solid transparent;
  &.active {
    color: #2f8de4;
    border-bottom: 2px solid #2f8de4;
    &:hover {
      color: #2f8de4;
    }
  }
  &.aligned-left {
    margin-right: 1.25rem;
  }
  &.aligned-right {
    margin-left: 1.25rem;
    &.flag {
      margin-left: 0.625rem;
    }
  }
  &:hover {
    color: #3b3b3b;
    border-bottom: 2px solid #2f8de4;
  }

  @media (max-width: 900px) {
    border-bottom: none !important;
  }
`;

export const NavDropdownWrapper = styled(NavDropdown)``;

export const NavDropdownItem = styled(NavDropdown.Item)``;

import { Title } from "../../../../library/title";
import { Link } from "react-router-dom";
import Button from "../../../../library/button";
import {
  ButtonCol,
  ContentContainer,
  InnerPageContainer,
  PageContainer,
  TitleCol,
  UpperDescriptionContainer,
  UpperRow,
  VideoWrapper,
} from "./styled";
import { Text } from "../../../../library/text";

const Content = () => {
  return (
    <PageContainer>
      <InnerPageContainer>
        <UpperRow>
          <TitleCol className="my-auto">
            <Title>Hjelpesenter</Title>
          </TitleCol>
          <ButtonCol className="my-auto">
            {false && (
              <Button variant="regular" color="blue" icon="bug">
                Rapporter feil
              </Button>
            )}
          </ButtonCol>
        </UpperRow>
        <ContentContainer>
          <UpperDescriptionContainer>
            <Text color="dark">
              Nedenfor kan du få et innblikk i hvordan man kan bruke Randi til å
              raskt og enkelt generere transkripter for lyd og- videofilene dine
              helt automatisk.{" "}
              {/*Lenger ned kan du lese om tips til hvordan du kan
              effektivisere prosessene dine rundt transkribering og lese svar på
  ofte stilte spørsmål.*/}{" "}
              Dersom du ikke får svar på det du lurer på kan du sende oss en
              e-post fra kontaktsjemaet som du finner{" "}
              <Link to="/contact">her</Link>.
            </Text>
          </UpperDescriptionContainer>
          <VideoWrapper>
            <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
              <iframe
                src="https://player.vimeo.com/video/853408912?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                allow="autoplay; fullscreen; picture-in-picture"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                title="video-randi-generere-transkript-UNDERTEKST-BRENT-INN"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </VideoWrapper>
        </ContentContainer>
      </InnerPageContainer>
    </PageContainer>
  );
};

export default Content;

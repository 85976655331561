import { z } from "zod";

export const uploadSoundtrackSchema = z.object({
  title: z.string().min(1, "Du må oppgi en tittel."),
  text: z.string(),
  format: z.string(),
  voice: z.string(),
  language: z.string(),
  public: z.boolean(),
});

export type UploadSoundtrackSchema = z.infer<typeof uploadSoundtrackSchema>;

import { useCallback } from "react";
import { firebaseApp } from "../firebase";
import {
  clearTranscriptsList,
  setTranscriptsList,
  setTranscriptsListLoading,
} from "../redux/slices/transcripts";
import { getAuth } from "firebase/auth";
import { useAppDispatch, useAppSelector } from "../redux/hooks";

const useListTranscripts = () => {
  const auth = getAuth(firebaseApp);
  const dispatch = useAppDispatch();

  const loading = useAppSelector((state) => state.transcripts.loadingList);
  const selectedWorkspace = useAppSelector(
    (state) => state.user.selectedWorkspace
  );

  const fetchTranscriptsList = useCallback(async () => {
    try {
      dispatch(setTranscriptsListLoading(true));
      const token = await auth.currentUser?.getIdToken(true);
      const request = await fetch(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/workspace/transcripts?workspaceId=${selectedWorkspace.id}&token=${token}`
      );
      const response = await request.json();
      if (response.error === null) {
        dispatch(setTranscriptsList(response.status));
        dispatch(setTranscriptsListLoading(false));
      }
    } catch (e) {
      dispatch(clearTranscriptsList());
      dispatch(setTranscriptsListLoading(false));
      throw e;
    }
  }, [dispatch, auth, selectedWorkspace?.id]);

  return { fetchTranscriptsList, loading };
};

export default useListTranscripts;

import {
  ButtonsWrapper,
  DetailCol,
  DetailsRow,
  Field,
  SelectionWrapper,
  Thumbnail,
} from "./styled";
import thumbnail from "../../../../../assets/img/voice-illustration.png";
import { Title } from "../../../../../library/title";
import { Text } from "../../../../../library/text";
import { useTranslation } from "react-i18next";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { Label } from "../../../../../library/label";
import { Select } from "../../../../../library/select";
import Button from "../../../../../library/button";
import { EXTENSIONS } from "./utils/extensions";
import { languages } from "./utils/languages";
import { UseFormSetValue } from "react-hook-form";
import { UploadSoundtrackSchema } from "../schema";

interface FormatViewProps {
  setStep: Dispatch<SetStateAction<2 | 1 | 3>>;
  setValue: UseFormSetValue<UploadSoundtrackSchema>;
  selectedFormat: string;
  selectedLanguage: string;
  closeModal: () => void;
}

const FormatView = ({
  setStep,
  setValue,
  selectedFormat,
  selectedLanguage,
  closeModal,
}: FormatViewProps) => {
  const { t } = useTranslation();

  const handleSelection = (key: any, value: string) => {
    setValue(key, value);
  };

  const clickBack = () => {
    closeModal();
  };

  const clickNext = () => {
    setStep(2);
  };

  return (
    <SelectionWrapper>
      <DetailsRow>
        <DetailCol xs={3} className="my-auto">
          <Thumbnail src={thumbnail} />
        </DetailCol>
        <DetailCol>
          <Title size="xs">
            {t(
              "dashboard.pages.soundtracks.add-modal.content.format-step.title"
            )}
          </Title>
          <Text size="small" color="dark">
            {t(
              "dashboard.pages.soundtracks.add-modal.content.format-step.description"
            )}
          </Text>
        </DetailCol>
      </DetailsRow>
      <Field>
        <Label htmlFor="languageSelect">
          {t(
            "dashboard.pages.soundtracks.add-modal.content.format-step.input.language.label"
          )}
        </Label>
        <Select
          items={languages}
          onChange={(v: ChangeEvent<HTMLSelectElement>) =>
            handleSelection("language", v.target.value)
          }
          selected={selectedLanguage}
          id="languageSelect"
          ariaLabel={
            t(
              "dashboard.pages.soundtracks.add-modal.content.format-step.input.language.input-field.ariaLabel"
            ) as string
          }
        />
      </Field>
      <Field>
        <Label htmlFor="formatSelect">
          {t(
            "dashboard.pages.soundtracks.add-modal.content.format-step.input.format.label"
          )}
        </Label>
        <Select
          items={EXTENSIONS}
          onChange={(v: ChangeEvent<HTMLSelectElement>) =>
            handleSelection("format", v.target.value)
          }
          selected={selectedFormat}
          id="formatSelect"
          ariaLabel={
            t(
              "dashboard.pages.soundtracks.add-modal.content.format-step.input.format.input-field.ariaLabel"
            ) as string
          }
        />
      </Field>
      <ButtonsWrapper>
        <Button
          ariaLabel={
            t(
              "dashboard.pages.soundtracks.add-modal.content.format-step.buttons.back.ariaLabel"
            ) as string
          }
          onClick={clickBack}
          variant="regular"
          color="grey"
        >
          {t(
            "dashboard.pages.soundtracks.add-modal.content.format-step.buttons.back.title"
          )}
        </Button>
        <Button
          type="submit"
          variant="regular"
          color="blue"
          onClick={clickNext}
          ariaLabel={
            t(
              "dashboard.pages.soundtracks.add-modal.content.format-step.buttons.next.ariaLabel"
            ) as string
          }
        >
          {t(
            "dashboard.pages.soundtracks.add-modal.content.format-step.buttons.next.title"
          )}
        </Button>
      </ButtonsWrapper>
    </SelectionWrapper>
  );
};

export default FormatView;

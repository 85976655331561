import { z } from "zod";

export const schema = z.object({
  firstName: z.string().min(1, "Du må oppgi et fornavn."),
  lastName: z.string().min(1, "Du må oppgi et etternavn."),
  email: z.string().email("Du må oppgi en e-post."),
  subject: z.string().min(1, "Du må oppgi et emne."),
  message: z.string().min(1, "Du kan ikke la meldingsfeltet stå tomt."),
});

export type ContactFormSchema = z.infer<typeof schema>;

import styled from "@emotion/styled";

export const TableRow = styled.tr`
  width: 100%;
  border-bottom: 0.0625rem solid rgb(235, 235, 235);
  position: relative;
  &:last-of-type {
    border: none !important;
  }
`;

export const TableData = styled.td`
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  @media (max-width: 767px) {
    min-width: 9.375rem;
  }
`;

export const Value = styled.span`
  font-family: "Poppins", sans-serif;
  color: grey;
  font-size: 0.875rem;
  &.bold {
    font-weight: 500;
  }
`;

export const Badge = styled.span`
  background-color: yellow;
  font-size: 0.75rem;
  padding: 0.5rem 0.7813rem;
  border-radius: 0.75rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  &.active {
    color: rgb(80, 205, 137);
    background-color: rgb(232, 255, 243);
  }
  &.pending {
    background-color: #fef8e0;
    color: #f7c844;
  }
`;

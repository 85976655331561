import DashboardLayout from "../DashboardLayout";
import {
  InnerPageContainer,
  NotificationWrapper,
  PageContainer,
} from "./styled";
import Meta from "./meta";
import { PageMetadata } from "../../types/meta";

interface DashboardPageProps {
  showNavigation?: boolean;
  layout: "default" | "stacked";
  meta?: PageMetadata;
  notification?: React.ReactNode;
  children: React.ReactNode;
}

const DashboardPage = ({
  showNavigation = true,
  layout = "default",
  notification = null,
  meta,
  children,
}: DashboardPageProps) => {
  return (
    <>
      <Meta meta={meta} />
      <DashboardLayout showNavigation={showNavigation}>
        <PageContainer>
          {notification && (
            <NotificationWrapper>{notification}</NotificationWrapper>
          )}
          {layout === "default" ? (
            <InnerPageContainer className="default">
              {children}
            </InnerPageContainer>
          ) : (
            <>
              {Array.isArray(children) ? (
                children.map((child, index) => (
                  <InnerPageContainer className="stacked" key={index}>
                    {child}
                  </InnerPageContainer>
                ))
              ) : (
                <InnerPageContainer className="default">
                  {children}
                </InnerPageContainer>
              )}
            </>
          )}
        </PageContainer>
      </DashboardLayout>
    </>
  );
};

export default DashboardPage;

import { useCallback, useState } from "react";
import { getAuth } from "firebase/auth";
import { firebaseApp } from "../firebase";
import { UserDetailsSchema } from "../pages/dashboard/account/content/settings-view/user-details/schema";

const useUpdateUser = () => {
  const auth = getAuth(firebaseApp);
  const [loading, setLoading] = useState(false);

  const saveUserDetails = useCallback(
    async (data: UserDetailsSchema) => {
      try {
        setLoading(true);
        const token = await auth.currentUser?.getIdToken(true);
        const request = await fetch(
          `${process.env.REACT_APP_BACKEND_ENDPOINT}/user/update/details`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              token: token,
              firstName: data?.firstName,
              lastName: data?.lastName,
              phone: data?.phone,
            }),
          }
        );
        const response = await request.json();
        if (response.error === null) {
          return { error: null };
        } else {
          throw response.error;
        }
      } catch (e) {
        throw e;
      }
    },
    [auth]
  );

  return { loading, saveUserDetails };
};

export default useUpdateUser;

import {
  InnerCol,
  InnerPageContainer,
  PageContainer,
  SplitCol,
  SplitView,
} from "./styled";
import UserCard from "./user-card";
import SettingsView from "./settings-view";
import { useSelector } from "react-redux";
import { IState } from "../../../../types";

const Content = () => {
  const profile = useSelector((state: IState) => state.user?.details);

  return (
    <PageContainer>
      <InnerPageContainer>
        <SplitView>
          <SplitCol className="profile-view">
            <InnerCol>
              <UserCard profile={profile} />
            </InnerCol>
          </SplitCol>
          <SplitCol>
            <InnerCol>
              <SettingsView />
            </InnerCol>
          </SplitCol>
        </SplitView>
      </InnerPageContainer>
    </PageContainer>
  );
};

export default Content;

import styled from "@emotion/styled";
import { Dropdown } from "react-bootstrap";

export const StyledDropdown = styled(Dropdown)`
  button {
    background-color: transparent !important;
    border: none !important;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    color: grey;
  }
`;

export const DropdownToggle = styled(Dropdown.Toggle)`
  color: #3b3b3b !important;
  box-shadow: none !important;
  button,
  .btn,
  .btn-primary,
  .dropdown-toggle {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    &::active,
    &:focus,
    &::focus {
      outline: none !important;
      border: none !important;
      box-shadow: none !important;
    }
  }
  .language-wrapper {
    img {
      height: 2.0313rem;
      margin-right: 0.3125rem;
      border-radius: 0.9375rem;
    }
  }
  &::after {
    color: grey;
  }
  &:hover {
    &::after {
      color: grey;
    }
  }
  &.button {
    &::after {
      display: none;
    }
  }
`;

export const DropdownMenuWrapper = styled(Dropdown.Menu)`
  min-width: 13.75rem;
  border: 1px solid #2f8de4 important;
  margin-top: 0.3125rem;
  padding: 0.5375rem;
`;

export const DropdownTitle = styled(Dropdown.Header)`
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #2f8de4 !important;
  font-weight: 600;
`;

export const DropdownItem = styled(Dropdown.Item)`
  padding: 0.3125rem 1.25rem;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  color: grey;
  .single-selection {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: grey;
    font-size: 0.875rem;
    img {
      height: 1.7188rem;
      margin-right: 0.4688rem;
      border-radius: 0.7813rem;
    }
    &:hover {
      background: transparent !important;
      color: #2f8de4 !important;
    }
  }
  &:hover {
    background: transparent !important;
    color: #2f8de4 !important;
  }
`;

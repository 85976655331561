import styled from "@emotion/styled";

export const FullCheckboxWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const CheckboxWrapper = styled.div`
  margin-top: 5px;
`;

export const TextWrapper = styled.div`
  display: flex;
`;

export const CheckboxContainer = styled.div`
  position: relative;
  background-color: #edeff1;
  color: grey;
  border-radius: 0.25rem;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  &.checked {
    background-color: #3a96eb !important;
    color: white !important;
  }
`;

export const CheckboxToggle = styled.input`
  position: relative;
  opacity: 0;
  z-index: 5;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
`;

export const ToggleIcon = styled.i`
  position: absolute;
  z-index: 4;
  left: 0.1875rem;
  top: -1.875rem;
  font-size: 0.6875rem;
`;

export const ErrorWrapper = styled.div`
  padding-left: 25px;
`;

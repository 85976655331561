import { useForm } from "react-hook-form";
import Button from "../../../../../../library/button";
import Input from "../../../../../../library/input";
import { ButtonsWrapper, Field, Form, TabContainer } from "./styled";
import { Label } from "../../../../../../library/label";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { IState } from "../../../../../../types";
import { zodResolver } from "@hookform/resolvers/zod";
import { UserDetailsSchema, schema } from "./schema";
import useUpdateUser from "../../../../../../hooks/useUpdateUser";
import {
  sendErrorNotification,
  sendSuccessNotification,
} from "../../../../../../library/notification";
import useUser from "../../../../../../hooks/useUser";
import { getAuth } from "firebase/auth";
import { firebaseApp } from "../../../../../../firebase";
import { useTranslation } from "react-i18next";

const UserDetails = () => {
  const auth = getAuth(firebaseApp);
  const { saveUserDetails } = useUpdateUser();
  const { fetchUserDetails } = useUser();
  const { t } = useTranslation();
  const profile = useSelector((state: IState) => state.user?.details);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<UserDetailsSchema>({ resolver: zodResolver(schema) });

  useEffect(() => {
    if (profile && Object.keys(profile)?.length > 0) {
      setValue("firstName", profile?.firstName);
      setValue("lastName", profile?.lastName);
      setValue("phone", profile?.phone);
    }
  }, [profile, setValue]);

  const saveSettings = async (data: UserDetailsSchema) => {
    try {
      const update = await saveUserDetails(data);
      if (update?.error === null) {
        fetchUserDetails(auth.currentUser!.uid);
        sendSuccessNotification("Detaljer ble oppdatert.");
      } else {
        throw update.error;
      }
    } catch (e) {
      sendErrorNotification("Kunne ikke oppdatere detaljer.");
    }
  };

  return (
    <TabContainer>
      <Form onSubmit={handleSubmit(saveSettings)}>
        <Field>
          <Label htmlFor="firstNameInput">
            {
              t(
                "dashboard.pages.account.settings-view.tabs.user-details.view.form.first-name-label"
              ) as string
            }
          </Label>
          <Input
            placeholder={
              t(
                "dashboard.pages.account.settings-view.tabs.user-details.view.form.first-name-input.placeholder"
              ) as string
            }
            ariaLabel={
              t(
                "dashboard.pages.account.settings-view.tabs.user-details.view.form.first-name-input.ariaLabel"
              ) as string
            }
            id="firstNameInput"
            register={register}
            name="firstName"
            error={errors["firstName"]}
            variant="regular"
          />
        </Field>
        <Field>
          <Label htmlFor="lastNameInput">
            {
              t(
                "dashboard.pages.account.settings-view.tabs.user-details.view.form.last-name-label"
              ) as string
            }
          </Label>
          <Input
            placeholder="etternavn"
            register={register}
            name="lastName"
            id="lastNameInput"
            error={errors["lastName"]}
            ariaLabel={
              t(
                "dashboard.pages.account.settings-view.tabs.user-details.view.form.last-name-input.ariaLabel"
              ) as string
            }
            variant="regular"
          />
        </Field>
        <Field>
          <Label htmlFor="phoneInput">
            {
              t(
                "dashboard.pages.account.settings-view.tabs.user-details.view.form.phone-label"
              ) as string
            }
          </Label>
          <Input
            placeholder="Telefon"
            register={register}
            name="phone"
            id="phoneInput"
            error={errors["phone"]}
            ariaLabel={
              t(
                "dashboard.pages.account.settings-view.tabs.user-details.view.form.phone-input.ariaLabel"
              ) as string
            }
            variant="regular"
          />
        </Field>
        <ButtonsWrapper>
          <Button
            ariaLabel={
              t(
                "dashboard.pages.account.settings-view.tabs.user-details.view.form.submit-button.ariaLabel"
              ) as string
            }
            type="submit"
            color="blue"
            variant="regular"
          >
            {
              t(
                "dashboard.pages.account.settings-view.tabs.user-details.view.form.submit-button.title"
              ) as string
            }
          </Button>
        </ButtonsWrapper>
      </Form>
    </TabContainer>
  );
};

export default UserDetails;

import { useCallback, useState } from "react";

const useBlogpost = () => {
  const [blogpost, setBlogpost] = useState({});
  const [loading, setLoading] = useState<boolean>(false);

  const fetchBlogpost = useCallback(async (prefix: string) => {
    try {
      const request = await fetch(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/general/blogpost?prefix=${prefix}`,
        {
          method: "GET",
        }
      );
      const response = await request.json();
      if (response.error === null) {
        setLoading(false);
        setBlogpost(response.result);
      } else {
        throw response.error;
      }
    } catch (e) {
      setLoading(false);
      throw e;
    }
  }, []);

  return { loading, fetchBlogpost, blogpost };
};

export default useBlogpost;

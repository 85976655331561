import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export const Section = styled.section`
  background-color: #fbfbff;
  height: 100vh;
  @media (max-width: 767px) {
    background-color: #fff;
  }
`;

export const InnerContainer = styled.div`
  padding: 1.5625rem;
`;

export const LogoContainer = styled.div``;

export const Logo = styled.img`
  max-width: 10.9375rem;
  margin-bottom: 2.5rem;
`;

export const Content = styled.div`
  text-align: center;
  display: block;
  margin: 0 auto;
  width: 100%;
  padding-top: 6.25rem;
  max-width: 28.125rem;
  @media (max-width: 767px) {
    padding-top: 0.625rem;
  }
`;

export const LoginContainer = styled.div`
  background-color: #fff;
  padding: 1.875rem 2.5rem;
  border-radius: 1.5625rem;
  overflow: hidden;
  display: block;
  position: relative;
  box-shadow: 0rem 0rem 0.625rem #e2e2e2;
  @media (max-width: 767px) {
    box-shadow: none;
    padding: 0.625rem 0.625rem;
  }
`;

export const LoginTitle = styled.h2`
  font-family: NexaBold !important;
  padding-top: 0.625rem;
  font-size: 1.625rem;
`;

export const LoginDescription = styled.span`
  font-family: Poppins;
  color: grey;
  max-width: 30rem;
  display: block;
  font-size: 0.875rem;
  max-width: 18.75rem;
`;

export const LoginForm = styled.form`
  margin-top: 1.5625rem;
  text-align: left;
  input {
    width: 100%;
  }
  button {
    display: block;
    width: 100%;
    margin-bottom: 0.7813rem;
    &.login-button {
      margin-top: 1.5625rem;
    }
  }
`;

export const Field = styled.div`
  margin-bottom: 0.9375rem;
  input {
    margin-top: 0.3125rem;
  }
`;

export const SignUpText = styled.span`
  font-family: "Poppins";
  font-size: 0.875rem;
  display: block;
  margin-top: 1.5625rem;
  margin-bottom: 0.625rem;
  color: grey;
`;

export const FormLink = styled(Link)`
  font-family: "Poppins";
  color: #2f8de4;
  font-weight: 500;
`;

export const BottomWrapper = styled.div`
  border-top: 0.0625rem solid #f1f6ff;
  padding-top: 1.5625rem;
  margin-top: 1.5625rem;
  a {
    text-decoration: none;
  }
`;

export const ResetPasswordText = styled.span`
  font-family: "Poppins";
  font-size: 0.875rem;
  text-align: center;
  margin: 0 auto;
  display: block;
  margin-top: 1.25rem;
  color: grey;
  a {
    color: #2f8de4 !important;
  }
`;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITranscripts, TranscriptRecordItem } from "../../types";

const initialState: ITranscripts = {
  records: {
    private: [],
    public: [],
  },
  loadingList: true,
  deletionArray: [],
  searchQuery: "",
  loadingUpload: false,
};

const transcriptsSlice = createSlice({
  name: "transcripts",
  initialState,
  reducers: {
    setTranscriptsList: (
      state,
      action: PayloadAction<{
        private: TranscriptRecordItem[];
        public: TranscriptRecordItem[];
      }>
    ) => {
      state.records = action.payload;
    },
    clearTranscriptsList: (state) => {
      state.records = { private: [], public: [] };
    },
    setTranscriptsListLoading: (state, action: PayloadAction<boolean>) => {
      state.loadingList = action.payload;
    },
    setTranscriptUploading: (state, action: PayloadAction<boolean>) => {
      state.loadingUpload = action.payload;
    },
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload !== "" ? action.payload : "";
    },
    clearSearchQuery: (state) => {
      state.searchQuery = "";
    },
    appendDeletionArray: (state, action: PayloadAction<string>) => {
      state.deletionArray.push(action.payload);
    },
    subtractDeletionArray: (state, action: PayloadAction<string>) => {
      state.deletionArray = state.deletionArray.filter(
        (id) => id !== action.payload
      );
    },
    clearDeletionArray: (state) => {
      state.deletionArray = [];
    },
    setTranscriptStatus: (
      state,
      action: PayloadAction<{
        id: string;
        status: string;
        content?: string;
        progress?: number;
      }>
    ) => {
      const { id, status, content, progress } = action.payload;
      const updateTranscript = (transcripts: TranscriptRecordItem[]) =>
        transcripts.map((transcript) =>
          transcript.id === id
            ? { ...transcript, status, content, progress }
            : (transcript as any)
        );

      state.records.public = updateTranscript(state.records.public);
      state.records.private = updateTranscript(state.records.private);
    },
  },
});

export const {
  setTranscriptsList,
  clearTranscriptsList,
  setTranscriptsListLoading,
  setTranscriptUploading,
  setSearchQuery,
  clearSearchQuery,
  appendDeletionArray,
  subtractDeletionArray,
  clearDeletionArray,
  setTranscriptStatus,
} = transcriptsSlice.actions;

export default transcriptsSlice.reducer;

import { getAuth } from "firebase/auth";
import { firebaseApp } from "../firebase";
import { useCallback, useState } from "react";
import { sendErrorNotification } from "../library/notification";
import { useSelector } from "react-redux";
import { IState } from "../types";

const useGenerateVideo = () => {
  const auth = getAuth(firebaseApp);
  const [loading, setLoading] = useState<boolean>(false);
  const selectedWorkspace = useSelector(
    (state: IState) => state.user.selectedWorkspace
  );

  const downloadVideo = useCallback(
    async (transcriptId: string, captions: any, appearance: any) => {
      try {
        const token = await auth.currentUser?.getIdToken(true);
        if (token) {
          const request = await fetch(
            `${process.env.REACT_APP_BACKEND_ENDPOINT}/video/download`,
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                transcriptId: transcriptId,
                workspaceId: selectedWorkspace?.id,
                token: token,
                captions: captions,
                appearance: appearance,
              }),
            }
          );
          const response = await request.json();
          if (response.error === null) {
            setLoading(false);
            return {
              error: null,
              data: {
                status: response?.status,
                downloadUrl: response?.downloadUrl,
              },
            };
          } else {
            throw response.error;
          }
        }
      } catch (e) {
        setLoading(false);
        sendErrorNotification("Kunne ikke generere video.");
      }
    },
    [auth.currentUser, selectedWorkspace?.id]
  );

  return { loading, downloadVideo };
};

export default useGenerateVideo;

import styled from "@emotion/styled";

export const Section = styled.section`
  background-color: #fbfbff;
  height: 100vh;
  @media (max-width: 767px) {
    background-color: #fff;
  }
`;

export const InnerContainer = styled.div`
  padding: 1.5625rem;
`;

export const LogoContainer = styled.div`
  text-align: center;
  padding-bottom: 1.875rem;
`;

export const Logo = styled.img`
  max-width: 10.9375rem;
`;

export const Slogan = styled.span`
  display: block;
  margin-bottom: 0.9375rem;
  font-family: Poppins;
  font-weight: 600;
  font-size: 1.375rem;
  color: #3b3b3b;
  margin-top: 0.625rem;
  margin-bottom: 1.25rem;
`;

export const Content = styled.div`
  display: block;
  margin: 0 auto;
  max-width: 700px;
  padding-top: 6.25rem;
  @media (max-width: 767px) {
    padding-top: 0.625rem;
  }
`;

export const RegisterContainer = styled.div`
  background-color: #fff;
  padding: 1.875rem 2.5rem;
  border-radius: 1.5625rem;
  overflow: hidden;
  display: block;
  position: relative;
  box-shadow: 0rem 0rem 0.625rem #e2e2e2;
  input {
    width: 100%;
  }
  @media (max-width: 767px) {
    box-shadow: none;
    padding: 0.625rem 0.625rem;
  }
`;

export const ProgressWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const Separator = styled.div`
  position: relative;
  border-bottom: 0.0625rem solid #e1e1e1;
  width: 100%;
  margin-top: -0.0625rem;
  z-index: 1;
`;

export const StepWrapper = styled.div`
  flex: 33%;
  text-align: center;
  position: relative;
  padding-bottom: 0.625rem;
  z-index: 2;
  &.active {
    border-bottom: 0.125rem solid #2f8de4;
  }
`;

export const StepIcon = styled.span`
  display: block;
  font-size: 1.5rem;
  color: grey;
  &.active {
    color: #2f8de4;
  }
`;

export const StepTitle = styled.span`
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  color: grey;
  font-weight: 500;
  &.active {
    color: #2f8de4;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;

export const ButtonsContainer = styled.div`
  padding-top: 1.5625rem;
  text-align: right;
  @media (max-width: 767px) {
    padding-top: 0rem;
    text-align: center;
    display: flex;
    flex: 50%;
    button {
      width: 100%;
    }
  }
`;

import {
  HeaderBottom,
  HeaderWrapper,
  InnerContainer,
  TextWrapper,
  TitleWrapper,
} from "./styled";
import { Text } from "../../../library/text";
import { useTranslation } from "react-i18next";
import { Title } from "../../../library/title";
import headerBottom from "../../../assets/img/header-bottom.svg";

const Header = () => {
  const { t } = useTranslation();

  return (
    <HeaderWrapper>
      <InnerContainer>
        <TitleWrapper>
          <Title size="xl">Priser for bruk</Title>
        </TitleWrapper>
        <TextWrapper>
          <Text color="dark">
            Hos Randi kan du registrere deg en konto helt gratis uten å måtte
            legge inn kortinformasjon. Som gratisbruker er du begrenset til 2
            transkripter og 2 tekst til tale per måned. Nedenfor kan du se hva
            som er inkludert i Randi Go (betalt abonnement) for kun 349kr
            måneden.
          </Text>
        </TextWrapper>
      </InnerContainer>
      <HeaderBottom
        src={headerBottom}
        alt={t("frontpage.sections.header.bottom-separator.alt")!}
      />
    </HeaderWrapper>
  );
};

export default Header;

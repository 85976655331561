import { StyledTitle } from "./styled";

interface ITitleProps {
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  children: React.ReactNode;
}

export const Title: React.FC<ITitleProps> = ({ children, size = "lg" }) => {
  return <StyledTitle className={`size-${size}`}>{children}</StyledTitle>;
};

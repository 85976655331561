import styled from "@emotion/styled";

export const LoaderContainer = styled.div`
  position: relative;
  padding-top: 3.125rem;
  padding-bottom: 4.6875rem;
`;

export const InnerLoaderContainer = styled.div`
  text-align: center;
  margin: 0 auto;
  display: block;
`;

export const LoadingText = styled.span`
  display: block;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: grey;
  text-align: center;
`;

import styled from "@emotion/styled";

export const EmptyViewContainer = styled.div`
  margin-bottom: 3.125rem;
  margin-top: 3.125rem;
  text-align: center;
  &.size-sm {
    margin-top: 1.625rem;
  }
`;

export const AnimationContainer = styled.div`
  max-width: 14.0625rem;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 0.625rem;
  &.size-sm {
    max-width: 10rem;
  }
`;

export const EmptyTitle = styled.span`
  font-family: NexaBold;
  text-align: center;
  margin: 0 auto;
  display: block;
  font-size: 1.25rem;
  margin-top: 1.5625rem;
`;

export const TextWrapper = styled.div`
  max-width: 32.5rem;
  display: block;
  text-align: center;
  margin: 0 auto;
  margin-top: 0.625rem;
`;

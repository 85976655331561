import { useMediaQuery } from "react-responsive";
import { Caption as CaptionType } from "../../../../../../types";
import DesktopCaption from "./desktop";
import MobileCaption from "./mobile";
import { MOBILE_VIEW } from "../../../../../../variables";

interface CaptionProps {
  reference: any;
  captions: any;
  caption: CaptionType;
  handleAdjust: (key: string, method: "change" | "delete") => void;
  onTextChange: (key: string, value: string) => void;
  currentTime: number;
  clickSeek: (time: number) => void;
  setValue: any;
  index: number;
}

const Caption = ({
  reference,
  captions,
  caption,
  handleAdjust,
  onTextChange,
  currentTime,
  clickSeek,
  setValue,
}: CaptionProps) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_VIEW}px)` });

  const MENU_SELECTIONS = [
    {
      display: "Slett tekst",
      onClick: () => handleAdjust(caption.key, "delete"),
    },
  ];

  return isMobile ? (
    <MobileCaption
      captionRef={reference}
      captions={captions}
      caption={caption}
      handleAdjust={handleAdjust}
      onTextChange={onTextChange}
      selections={MENU_SELECTIONS}
      currentTime={currentTime}
      clickSeek={clickSeek}
      setValue={setValue}
    />
  ) : (
    <DesktopCaption
      captionRef={reference}
      captions={captions}
      caption={caption}
      onTextChange={onTextChange}
      handleAdjust={handleAdjust}
      currentTime={currentTime}
      clickSeek={clickSeek}
      setValue={setValue}
      selections={MENU_SELECTIONS}
    />
  );
};

export default Caption;

import { useCallback, useState } from "react";

const useValidate = () => {
  const [status, setStatus] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const validatePayment = useCallback(async (id: string) => {
    try {
      setLoading(true);
      const request = await fetch(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/validate-session?sessionId=${id}`
      );
      const response = await request.json();
      if (response.error === null) {
        setLoading(false);
        setStatus("success");
      } else {
        setLoading(false);
        setStatus("fail");
        throw response.error;
      }
    } catch (e) {
      setLoading(false);
      throw e;
    }
  }, []);

  return { validatePayment, loading, status };
};

export default useValidate;

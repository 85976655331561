import { useTranslation } from "react-i18next";
import Button from "../../../library/button";
import Input from "../../../library/input";
import { FormWrapper, SignupForm } from "./styled";
import { useState } from "react";
import { Text } from "../../../library/text";
import { NewsletterSignupSchema } from "./schema";
import { schema } from "./schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

const NewsletterForm = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<NewsletterSignupSchema>({
    resolver: zodResolver(schema),
    defaultValues: {
      email: "",
    },
  });

  const performSubscribe = async (data: NewsletterSignupSchema) => {
    try {
      setLoading(true);
      const request = await fetch(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/general/newsletter/subscribe`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email: data.email }),
        }
      );
      const response = await request.json();
      if (response?.error === null) {
        setLoading(false);
        setMessage(
          "Takk for at du abonnerte på nyhetsbrevet vårt. Du vil snart motta e-poster fra oss med informasjon rundt tjenesten."
        );
      } else {
        throw response?.error;
      }
    } catch (e) {
      setLoading(false);
      setMessage("En uventet feil oppstod.");
    }
  };

  return (
    <SignupForm onSubmit={handleSubmit(performSubscribe)}>
      {message ? (
        <Text color="dark" size="small">
          {message}
        </Text>
      ) : (
        <FormWrapper>
          <Input
            placeholder={
              t(
                "components.footer.subscribe-newsletter-block.form.email-input.placeholder"
              ) as string
            }
            variant="regular"
            register={register}
            name="email"
            error={errors["email"]}
            ariaLabel={
              t(
                "components.footer.subscribe-newsletter-block.form.email-input.ariaLabel"
              ) as string
            }
          />
          <Button
            variant="icon"
            color="blue"
            icon={loading ? "loader" : "mail"}
            size="lg"
            ariaLabel={
              t(
                "components.footer.subscribe-newsletter-block.form.subscribe-button.ariaLabel"
              ) as string
            }
            type="submit"
          />
        </FormWrapper>
      )}
    </SignupForm>
  );
};

export default NewsletterForm;

import SingleTranscript from "./table-row";
import {
  TableContainer,
  Table as FullTable,
  TableRow,
  TableHeader,
  Title,
} from "./styled";
import { useTranslation } from "react-i18next";

interface ITableProps {
  data: any[];
}

const Table: React.FC<ITableProps> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <FullTable>
        <TableRow className="header">
          <TableHeader>
            <Title>
              {t("dashboard.pages.transcripts.table.titles.select")}
            </Title>
          </TableHeader>
          <TableHeader>
            <Title>{t("dashboard.pages.transcripts.table.titles.title")}</Title>
          </TableHeader>
          <TableHeader>
            <Title>{t("dashboard.pages.transcripts.table.titles.date")}</Title>
          </TableHeader>
          <TableHeader className="select">
            <Title>
              {t("dashboard.pages.transcripts.table.titles.action")}
            </Title>
          </TableHeader>
        </TableRow>
        {data.map((data, index) => (
          <SingleTranscript data={data} arr={data} index={index} />
        ))}
      </FullTable>
    </TableContainer>
  );
};

export default Table;

import styled from "@emotion/styled";

export const HeaderWrapper = styled.header`
  width: 100%;
  max-height: 100%;
  background-color: #f7fafc;
  background-size: 100% 100%;
  z-index: 2;
  position: relative;
`;

export const InnerContainer = styled.div`
  padding: 10.0625rem 1.5625rem 4.25rem 1.5625rem;
  text-align: center;
  @media (max-width: 767px) {
    padding: 9.375rem 1.5625rem 3.125rem 1.5625rem;
  }
`;

export const TitleWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  display: block;
`;

export const TextWrapper = styled.div`
  max-width: 44rem;
  text-align: center;
  margin: 0 auto;
  margin-top: 0.9375rem;
  margin-bottom: 2.1875rem;
`;

export const HeaderVideo = styled.video`
  display: block;
  margin: 0 auto;
  z-index: 4;
  position: relative;
  margin-top: -15px;
`;

export const HeaderBottom = styled.img`
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  z-index: 3;
  max-height: 50px;
`;

export const OuterVideoWrapper = styled.div`
  padding: 0 25px 25px 25px;
  max-width: 52.25rem;
  margin: 0 auto;
  margin-top: 75px;
  margin-bottom: 50px;
`;

export const VideoWrapper = styled.div`
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0rem 0rem 0.9375rem #c2c2c2;
  position: relative;
  z-index: 20px;
`;

export const NavigationBar = styled.img`
  width: 100%;
  position: relative;
  z-index: 5;
`;

export const LogoContainer = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
`;

export const LogoWrapper = styled.div`
  margin-top: 40px;
  padding: 0px 20px;
  display: grid;
  gap: 20px;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  text-align: center;

  @media (max-width: 767px) {
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    gap: 25px;
  }
`;

export const Logo = styled.img`
  max-width: 110px;
  max-height: 60px;
  margin: 0 auto;
  opacity: 0.5;
  transition: 0.5s;
  &.shrink {
    padding: 5px;
  }
  &:hover {
    opacity: 1;
  }
  @media (max-width: 767px) {
    max-width: 90px;
  }
`;

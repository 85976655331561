import styled from "@emotion/styled";

export const Section = styled.section`
  background-color: #f7fafc;
`;

export const Container = styled.div`
  max-width: 87.5rem;
  margin: 0 auto;
  padding: 0.3125rem 1.25rem;
  @media (max-width: 767px) {
    padding: 0.3125rem 1.25rem;
  }
`;

export const Wrapper = styled.div`
  margin-top: 4.6875rem;
  margin-bottom: 4.6875rem;
  @media (max-width: 767px) {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
  }
`;

export const TitleWrapper = styled.div`
  text-align: center;
`;

export const MarqueeWrapper = styled.div`
  margin-top: 3.125rem;
`;

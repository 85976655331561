import styled from "@emotion/styled";

export const Form = styled.form`
  margin-top: 1.5625rem;
`;

export const MultiWrapper = styled.div`
  display: inline-flex;
  width: 100%;
  gap: 0.9375rem;
  div {
    flex: 50%;
  }
  @media (max-width: 900px) {
    display: block;
    margin-bottom: 0.9375rem;
  }
`;

export const Field = styled.div`
  margin-bottom: 0.9375rem;
  input,
  textarea {
    width: 100%;
    margin-top: 0.3125rem;
  }
  &:last-of-type {
    margin-bottom: 0rem;
  }
`;

export const ButtonWrapper = styled.div``;

export const NotificationWrapper = styled.div`
  margin-top: 3.125rem;
`;

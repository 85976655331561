import { useState } from "react";
import Caption from "./caption";
import {
  DesktopTopWrapper,
  EmptyWrapper,
  SearchWrapper,
  TimelineContainer,
  TitleWrapper,
} from "./styled";
import Modal from "../../../../../library/modal";
import DeleteCaptionModalContent from "./delete-modal";
import AdjustTimestampModalContent from "./timestamp-modal";
import { useMediaQuery } from "react-responsive";
import { MOBILE_VIEW } from "../../../../../variables";
import { Title } from "../../../../../library/title";
import Input from "../../../../../library/input";
import EmptyView from "../../../../../components/EmptyView";

type TimelineProps = {
  currentTime: number;
  videoRef: any;
  onTextChange: any;
  captions?: any[];
  setValue: any;
  activeCaptionRef: any;
  scrollingContainerRef?: any;
  onSave: () => void;
};

export const Timeline = ({
  currentTime,
  videoRef,
  onTextChange,
  captions,
  setValue,
  scrollingContainerRef,
  activeCaptionRef,
  onSave,
}: TimelineProps) => {
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_VIEW}px)` });
  const [activeKey, setActiveKey] = useState<string | undefined>(undefined);

  const [showDeleteCaption, setShowDeleteCaption] = useState(false);
  const [showAdjustCaption, setShowAdjustCaption] = useState(false);

  const filteredCaptions = searchQuery
    ? captions?.filter((caption) =>
        caption?.text?.toLowerCase().includes(searchQuery?.toLowerCase())
      )
    : captions;

  const closeAdjustCaptionModal = () => {
    setShowAdjustCaption(false);
  };

  const openAdjustCaptionModal = () => {
    setShowAdjustCaption(true);
  };

  const closeDeleteCaptionModal = () => {
    setShowDeleteCaption(false);
  };

  const openDeleteCaptionModal = () => {
    setShowDeleteCaption(true);
  };

  const handleAdjust = (key: string, method: "change" | "delete") => {
    setActiveKey(key);

    if (method === "change") {
      openAdjustCaptionModal();
    } else {
      openDeleteCaptionModal();
    }
  };

  const clickSeek = (start: number) => {
    if (!videoRef.current) return;
    videoRef.current.currentTime = start;
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  return (
    <>
      {!isMobile && (
        <DesktopTopWrapper>
          <TitleWrapper>
            <Title size="xs">Undertekster</Title>
          </TitleWrapper>
          <SearchWrapper>
            <Input
              onChange={handleSearch}
              placeholder="Søk etter tekst.."
              variant="search"
              value={searchQuery}
            />
          </SearchWrapper>
        </DesktopTopWrapper>
      )}
      <TimelineContainer isMobile={isMobile} ref={scrollingContainerRef}>
        {filteredCaptions && filteredCaptions.length > 0 ? (
          filteredCaptions.map((caption, index) => (
            <Caption
              key={caption?.key}
              captions={captions}
              caption={caption}
              handleAdjust={handleAdjust}
              onTextChange={onTextChange}
              currentTime={currentTime}
              clickSeek={clickSeek}
              reference={activeCaptionRef}
              setValue={setValue}
              index={index}
            />
          ))
        ) : (
          <EmptyWrapper>
            <EmptyView
              size="small"
              title="Ingen undertekster funnet"
              description="Videoen du lastet opp har ingen undertekster som matcher søket ditt."
            />
          </EmptyWrapper>
        )}
        <Modal
          open={showAdjustCaption}
          onClose={closeAdjustCaptionModal}
          title="Endre påfølgende tidskoder?"
          size={495}
        >
          <AdjustTimestampModalContent
            onClose={closeAdjustCaptionModal}
            captionKey={activeKey}
            captions={captions}
            setValue={setValue}
          />
        </Modal>
        <Modal
          open={showDeleteCaption}
          onClose={closeDeleteCaptionModal}
          title="Slette undertekst?"
          size={475}
        >
          <DeleteCaptionModalContent
            onClose={closeDeleteCaptionModal}
            captionKey={activeKey}
            setValue={setValue}
            captions={captions}
          />
        </Modal>
      </TimelineContainer>
    </>
  );
};

export default Timeline;

import styled from "@emotion/styled";

export const StyledLabel = styled.label`
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  display: block;
  color: grey;
  padding-left: 1.0938rem;
`;

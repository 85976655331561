import { Title } from "../../../library/title";
import {
  Container,
  ContentWrapper,
  Section,
  FormWrapper,
  ContentRow,
  ContentCol,
} from "./styled";
import { Link } from "react-router-dom";
import { Text } from "../../../library/text";
import { useTranslation } from "react-i18next";
import FaqView from "./faq-view";
import FormView from "./form-view";

const ContactForm = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <Container>
        <ContentRow>
          <ContentCol md={8}>
            <ContentWrapper>
              <FormWrapper>
                <Title>{t("contact-us.title")}</Title>
                <Text color="dark">
                  {t("contact-us.text.pre-email")}{" "}
                  <Link to="mailto: andreas@randi.ai">andreas@randi.ai</Link>.{" "}
                  {t("contact-us.text.post-email")}
                </Text>
                <FormView />
              </FormWrapper>
            </ContentWrapper>
          </ContentCol>
          <ContentCol className="faq-col">
            <FaqView />
          </ContentCol>
        </ContentRow>
      </Container>
    </Section>
  );
};

export default ContactForm;

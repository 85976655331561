import styled from "@emotion/styled";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

export const NavWrapper = styled(Nav)`
  min-height: calc(100vh - 75px);
  padding-top: 50px;
`;

export const NavLink = styled(Link)`
  display: block;
  text-align: center;
  line-height: 45px;
  color: #3b3b3b;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  font-weight: 500;
  &.active {
    color: #2f8de4;
    &:hover {
      color: #2f8de4;
    }
  }

  &:hover {
    color: #3b3b3b;
  }
`;

export const Thumbnail = styled.img`
  height: 80px;
  width: 80px;
  object-fit: cover;
  border-radius: 1.5rem;
  display: block;
  margin: 0 auto;
`;

export const Name = styled.span`
  font-size: 18px;
  display: block;
  margin: 0 auto;
  text-align: center;
  margin-top: 10px;
  font-family: "Poppins";
  font-weight: 500;
`;

export const NavigationWrapper = styled.div`
  padding: 25px 0px;
`;

export const NavButton = styled.button`
  display: block;
  text-align: center;
  line-height: 45px;
  color: #3b3b3b;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  font-weight: 500;
  margin: 0 auto;
  border: none;
  background: none;
  padding: 0px;
`;

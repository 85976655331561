import styled from "@emotion/styled";
import { Col, Row, Carousel } from "react-bootstrap";

export const ProgressBarWrap = styled.div`
  position: relative;
  .full-progress-bar {
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto;
    width: 88%;
  }
`;

export const OnboardingContentWrapper = styled.div`
  margin-top: 40px;
`;

export const ProgressWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

export const StepWrapper = styled.div`
  flex: 33%;
  text-align: center;
  position: relative;
  padding-bottom: 0.625rem;
  z-index: 2;
  &.active {
    border-bottom: 0.125rem solid #2f8de4;
  }
`;

export const StepIcon = styled.span`
  display: block;
  font-size: 1.2rem;
  line-height: 2.4rem;
  color: grey;
  width: 35px;
  height: 35px;
  background-color: #f4f4f4;
  border-radius: 100%;
  margin: 0 auto;
  /*
  &.active {
    background-color: #2f8de4;
    color: #fff;
  }
  */

  &.active {
    animation: pulse 1s forwards 0.2s;
  }

  @keyframes pulse {
    0% {
      background-color: #f4f4f4;
      transform: scale(1);
      color: grey !important;
      opacity: 1;
    }
    50% {
      transform: scale(1.1);
      background-color: #2f8de4;
      opacity: 0.95;
      color: #fff;
    }
    100% {
      transform: scale(1);
      background-color: #2f8de4;
      opacity: 1;
      color: #fff;
    }
  }
`;

export const StepTitle = styled.span`
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  color: grey;
  font-weight: 500;
  &.active {
    color: #2f8de4;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;

export const OnboardingModalContainer = styled.div``;

export const ModalButtonsContainer = styled.div`
  padding-top: 40px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  button {
    margin-left: 5px;
    margin-right: 5px;
  }
  @media (max-width: 767px) {
    margin-top: 20px;
  }
`;

export const OnboardingRow = styled(Row)`
  &.introduction {
    margin-top: 30px;
    margin-bottom: 38px;
  }
  @media (max-width: 767px) {
    &.introduction {
      margin-top: 0px;
    }
  }
`;

export const OnboardingCol = styled(Col)`
  padding-right: 0px;
  &.details {
    max-width: 470px;
  }
  &.video {
    padding-right: 0px;
    padding-left: 0px;
  }
  &.animation {
    max-width: 450px;
    padding-right: 0px;
  }
  @media (max-width: 767px) {
    padding: 0px;
    &.animation {
      max-width: unset;
      padding-right: 0px;
      padding-left: 0px;
      margin-bottom: 20px;
    }
  }
`;

export const ProgressPointTitle = styled.span`
  font-family: Poppins, sans-serif;
  color: grey;
  margin-left: 12.5px;
  &.active {
    font-weight: 500;
    color: #2f8de4;
  }
`;

export const CarouselWrapper = styled(Carousel)``;

export const CarouselItem = styled(Carousel.Item)`
  padding: 0px 40px;
`;

export const VideoWrapper = styled.div`
  position: relative;
  text-align: center;
  padding: 0px 30px;
  @media (max-width: 767px) {
    padding: 0px;
    margin-bottom: 20px;
  }
`;

export const Video = styled.video`
  width: 100%;
  overflow: hidden;
  position: relative;
  object-fit: cover;
  display: block;
  background-color: #fff;
  overflow: hidden;
  border-radius: 1.25rem;
  display: block;
`;

export const VideoBorder = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 10px;
`;

export const Track = styled.track``;

export const TitleWrapper = styled.div`
  position: relative;
  text-align: center;
  padding: 20px 0px;
  .exit-button {
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 22px !important;
  }
`;

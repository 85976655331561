import { useCallback, useState } from "react";
import { firebaseApp } from "../firebase";
import { getAuth } from "firebase/auth";
import { sendErrorNotification } from "../library/notification";
import { setActiveTranscript } from "../redux/slices/transcript";
import { useAppDispatch, useAppSelector } from "../redux/hooks";

const useTranscript = () => {
  const dispatch = useAppDispatch();

  const auth = getAuth(firebaseApp);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const transcript = useAppSelector((state) => state.transcript.data);
  const [fetchError, setFetchError] = useState(false);
  const selectedWorkspace = useAppSelector(
    (state) => state.user.selectedWorkspace
  );

  const getTranscript = useCallback(
    async (id: string, onFirstLoad: () => void) => {
      try {
        setFetchLoading(true);
        const token = await auth.currentUser?.getIdToken(true);
        const request = await fetch(
          `${process.env.REACT_APP_BACKEND_ENDPOINT}/workspace/transcript?workspaceId=${selectedWorkspace.id}&token=${token}&id=${id}`,
          {
            method: "GET",
          }
        );
        const response = await request.json();
        if (response.error === null) {
          const subtitles = response.data?.subtitles;
          const blob = new Blob([subtitles], { type: "text/plain" });
          const url = URL.createObjectURL(blob);
          dispatch(setActiveTranscript({ ...response.data, subtitles: url }));
          setFetchLoading(false);
          onFirstLoad();
        } else {
          throw response.error;
        }
      } catch (e) {
        setFetchError(true);
        setFetchLoading(false);
      }
    },
    [auth.currentUser, dispatch, selectedWorkspace.id]
  );

  const saveTranscript = useCallback(
    async (captions: any, appearance: any, id: string) => {
      try {
        setSaveLoading(true);
        const token = await auth.currentUser?.getIdToken(true);
        const request = await fetch(
          `${process.env.REACT_APP_BACKEND_ENDPOINT}/workspace/transcript/update`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              token: token,
              workspaceId: selectedWorkspace?.id,
              transcriptId: id,
              data: { captions, appearance },
            }),
          }
        );
        const response = await request.json();
        if (response.error === null) {
          setSaveLoading(false);
          dispatch(
            setActiveTranscript({
              ...transcript,
              lastUpdated: response.data.timestamp,
              revisionRef: response.data.id,
              revisions: [response.data, ...transcript.revisions],
            })
          );
          return;
        } else {
          throw response.error;
        }
      } catch (e) {
        setSaveLoading(false);
        sendErrorNotification("Kunne ikke lagre transkript.");
      }
    },
    [auth.currentUser, selectedWorkspace?.id, transcript, dispatch]
  );
  return {
    getTranscript,
    fetchLoading,
    saveLoading,
    saveTranscript,
    transcript,
    fetchError,
  };
};

export default useTranscript;

import { Bold } from "../../styled";
import { ListItem, ListWrapper, TabsWrapper } from "./styled";

export const Index = () => {
  return (
    <TabsWrapper>
      <ListWrapper>
        <ListItem>
          Velg <Bold>Live lydopptak</Bold> fra <Bold>Velg type</Bold>-steget.
        </ListItem>
        <ListItem>
          Velg deretter ønsket språk du ønsker lydopptaket ditt tekstet til.
          Randi gjør oversettelse til over 70 språk.
        </ListItem>
        <ListItem>
          Trykk på den blå knappen med mikrofon for å starte opptak. Deretter
          trykker du på <Bold>Lag transkript</Bold>-knappen nederst.
        </ListItem>
      </ListWrapper>
    </TabsWrapper>
  );
};

export default Index;

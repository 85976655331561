import { Text } from "../../../../library/text";
import { Title } from "../../../../library/title";
import {
  OnboardingCol,
  OnboardingRow,
  Video,
  VideoWrapper,
} from "../../styled";
import { Bold } from "../styled";

const Index = () => {
  return (
    <OnboardingRow>
      <OnboardingCol md className="video">
        <VideoWrapper>
          <Video
            controls={false}
            muted={true}
            autoPlay={true}
            loop={true}
            playsInline={true}
            src="https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/public%2Fonboarding_step_four.mp4?alt=media&token=4264862a-8dca-4c5f-9e0d-39d11d840afc"
          />
        </VideoWrapper>
      </OnboardingCol>
      <OnboardingCol md className="details my-auto">
        <Title size="sm">Last ned transkript eller ferdigtekstet video</Title>
        <Text size="small" color="dark">
          Med Randi kan du velge om du vil laste ned video med teksten brent inn
          i videoen eller bare transkriptet i et av formatene som vises i listen
          over alternativer. Du kan laste ned selve transkriptet fra listen over
          alle transkripter, eller fra video-editoren.
        </Text>
        <br />
        <br />
        <Text size="small" color="dark">
          I dag kan du laste ned transkripter i følgende formater:
        </Text>
        <br />
        <Text size="small" color="dark">
          <Bold>SRT, VTT, TXT, PDF, HTML, CSV (Excel) og DOCX (Word).</Bold>
        </Text>
        <br />
        <br />
        <Text size="small" color="dark">
          Dersom du ønsker å laste ned video med tekst brent inn kan du gjøre
          ved å trykke på <Bold>Last ned video</Bold>-knappen inne i editoren.
        </Text>
      </OnboardingCol>
    </OnboardingRow>
  );
};

export default Index;

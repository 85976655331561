import { z } from "zod";

export const schema = z
  .object({
    subscription: z.string(),
    firstName: z.string().min(1, "Du må oppgi et fornavn."),
    lastName: z.string().min(1, "Du må oppgi et etternavn."),
    email: z.string().email("Du må oppgi en e-post."),
    phone: z.string().min(8, "Du må oppgi et telefonnummer."),
    password: z.string().min(8, "Passordet må være mer enn 8 tegn."),
    passwordRetype: z.string().min(8, "Passordet må være mer enn 8 tegn."),
    invitationId: z.string().optional().nullable(),
    acceptedTerms: z.boolean().refine((val) => val === true, {
      message: "Du må godta vilkårene.",
    }),
    referralId: z.string().optional().nullable(),
  })
  .superRefine((data, ctx) => {
    if (data.password !== data.passwordRetype) {
      ctx.addIssue({
        path: ["passwordRetype"],
        message: "Passordene må være like.",
        code: "custom",
      });
    }
  });

export type RegisterSchema = z.infer<typeof schema>;

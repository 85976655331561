import DashboardLayout from "../../../components/DashboardLayout";
import Content from "./content";

const Overview = () => {
  return (
    <DashboardLayout showNavigation={false}>
      <Content />
    </DashboardLayout>
  );
};

export default Overview;

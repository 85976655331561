import Button from "../../../../../../library/button";
import { Text } from "../../../../../../library/text";
import {
  ButtonContainer,
  ButtonsWrapper,
  DescriptionWrapper,
  ToggleContainer,
} from "./styled";

interface AdjustTimestampModalContentProps {
  onClose: () => void;
  captionKey: undefined | string;
  captions: any[] | undefined;
  setValue: any;
}

const AdjustTimestampModalContent = ({
  onClose,
  captionKey,
  captions,
  setValue,
}: AdjustTimestampModalContentProps) => {
  const clickAdjust = () => {
    if (!captionKey || !captions) return;

    const currentIndex = captions.findIndex(
      (caption) => caption.key === captionKey
    );
    if (currentIndex === -1) return;

    const updatedCaptions = [...captions];
    for (let i = currentIndex + 1; i < updatedCaptions.length; i++) {
      const prevCaption = updatedCaptions[i - 1];
      const currentCaption = updatedCaptions[i];

      const duration = currentCaption.end - currentCaption.start;
      currentCaption.start = prevCaption.end;
      currentCaption.end = currentCaption.start + duration;
    }

    setValue("captions", updatedCaptions);

    onClose();
  };

  return (
    <>
      <DescriptionWrapper>
        <Text color="dark">
          Vil du automatisk justere tidskoder på påfølgende undertekster for å
          tilpasse endringen?
        </Text>
      </DescriptionWrapper>
      <ToggleContainer></ToggleContainer>
      <ButtonContainer>
        <ButtonsWrapper>
          <Button onClick={onClose} variant="regular" color="grey">
            Nei, la være
          </Button>
          <Button onClick={clickAdjust} variant="regular" color="blue">
            Ja, endre dem
          </Button>
        </ButtonsWrapper>
      </ButtonContainer>
    </>
  );
};

export default AdjustTimestampModalContent;

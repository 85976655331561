import styled from "@emotion/styled";
import { Accordion } from "react-bootstrap";

export const FontPickerWrapper = styled.div`
  position: relative;
  margin-top: 5px;
`;

export const SelectedFontWrapper = styled.div`
  background-color: #f4f4f4;
  height: 49px;
  padding: 0.7813rem 1.25rem;
  border-radius: 0.875rem;
  border: 1px solid #f1f6ff;
  width: 100%;
`;

export const SelectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SelectedFont = styled.span`
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
`;

export const SelectArrow = styled.span`
  color: grey;
`;

export const DropdownWrapper = styled.div`
  position: absolute;
  padding: 0.7813rem 1.25rem;
  border-radius: 0.875rem;
  background-color: #f4f4f4;
  top: 50px;
  z-index: 99;
  display: block;
  height: auto !important;
`;

export const SearchWrapper = styled.div``;

export const SearchInput = styled.input`
  border: none;
  background: none;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  padding: 5px 25px;
  border-bottom: 0.0625rem solid #e1e1e1;
  &:focus {
    outline: none;
  }
`;

export const SearchIcon = styled.span`
  position: absolute;
  left: 20px;
  top: 20px;
  color: grey;
`;

export const ClearButton = styled.button`
  position: absolute;
  right: 20px;
  top: 20px;
  background: none;
  border: none;
  color: grey;
  &:hover {
    color: #2f8de4;
  }
`;

export const ResultsWrapper = styled.div`
  max-height: 350px;
  position: relative;
  overflow-y: scroll;
  padding-top: 10px;
`;

export const ResultWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  margin-top: 5px;
  &:hover {
    cursor: pointer;
  }
`;

export const ResultFont = styled.span<{ font: string; url: string }>`
  @font-face {
    font-family: ${({ font }) => font};
    src: url("${({ url }) => url}");
  }
  font-family: ${({ font }) => font};
`;

export const SelectedIcon = styled.span`
  color: #2f8de4;
`;

export const AccordionWrapper = styled.div``;

export const FontAccordion = styled(Accordion)``;

export const AccordionItem = styled(Accordion.Item)`
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  background: transparent;
  border: none;
  &:not(.collapsed) {
    background-color: transparent !important;
  }
`;

export const AccordionHeader = styled(Accordion.Header)`
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  background: transparent;
  button {
    padding: 10px 0px;
    background: transparent;
    box-shadow: none !important;
    &:hover {
      color: #2b84d7;
    }
    &:not(.collapsed) {
      color: grey;
      background-color: transparent !important;
    }
  }
`;

export const AccordionBody = styled(Accordion.Body)`
  padding: 0rem !important;
`;

export const NoResultTitle = styled.span`
  display: block;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
`;

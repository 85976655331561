import { ListItem, ListWrapper, TabsWrapper } from "../styled";

const Index = () => {
  return (
    <TabsWrapper>
      <ListWrapper>
        <ListItem>
          Du kan legge til en ny tekst ved å trykke på knappen med et pluss-tegn
          i bunnen av hver boks på venstre side.
        </ListItem>
        <ListItem>
          Randi vil gjøre automatisk justering av teksten før og etter den nye
          underteksten for å få den til å passe inn og du kan endre den nye
          tidskoden og teksten selv.
        </ListItem>
      </ListWrapper>
    </TabsWrapper>
  );
};

export default Index;

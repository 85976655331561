import { getAuth } from "firebase/auth";
import { firebaseApp } from "../firebase";
import {
  setActiveWorkspace,
  setLoadingWorkspaces,
  setUserWorkspaces,
} from "../redux/slices/user";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";

const useWorkspaces = () => {
  const selectedWorkspace = useAppSelector(
    (state) => state.user.selectedWorkspace
  );
  const loading = useAppSelector((state) => state.user.loadingWorkspaces);
  const auth = getAuth(firebaseApp);
  const dispatch = useAppDispatch();

  const signOut = async () => {
    await auth.signOut();
  };

  const fetchWorkspaces = useCallback(async () => {
    try {
      const token = await auth.currentUser?.getIdToken(true);
      const request = await fetch(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/user/workspaces?token=${token}`
      );
      const response = await request.json();
      if (response.error === null) {
        dispatch(setUserWorkspaces(response.payload));
        dispatch(setLoadingWorkspaces(false));
        if (!selectedWorkspace?.id) {
          dispatch(setActiveWorkspace(response.payload[0]));
        }
      } else {
        throw response.error;
      }
    } catch (e) {
      dispatch(setLoadingWorkspaces(false));
      throw e;
    }
  }, [dispatch, auth.currentUser, selectedWorkspace?.id]);

  return { signOut, fetchWorkspaces, loading };
};

export default useWorkspaces;

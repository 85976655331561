import { z } from "zod";

export const uploadTranscriptSchema = z.object({
  file: z
    .union([z.instanceof(File), z.undefined()])
    .refine((file) => file instanceof File, {
      message: "Du må laste opp en fil.",
    }),
  language: z.string(),
  public: z.boolean(),
  format: z.string(),
});

export type UploadTranscriptSchema = z.infer<typeof uploadTranscriptSchema>;

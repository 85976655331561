import { useEffect, useState } from "react";
import { getIcon } from "../../../../../../utils/get-icon";
import {
  ControllerWrapper,
  BottomControllerWrapper,
  LeftBottomWrapper,
  MainControls,
  SpeedButton,
  CenterBottomWrapper,
  AudioButton,
  VolumeInput,
  ExpandButton,
  RightBottomWrapper,
  SoundControls,
  DurationWrapper,
  Duration,
  PlayButton,
  BeginningButton,
  PlayerWrapper,
  VideoWrapper,
  Video,
  SubtitleWrapper,
  ActiveSubtitle,
} from "./styled";
import { formatVideoTime } from "../../helpers";

interface VideoPlayerDesktopProps {
  clickGoBackward: () => void;
  togglePlayPause: () => void;
  isPlaying: boolean;
  clickGoForward: () => void;
  clickStartBeginning: () => void;
  volume: number;
  handleVolumeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  mute: boolean;
  handleMute: () => void;
  clickFullscreen: () => void;
  currentTime: number;
  videoRef: any;
  videoUrl: string;
  activeSubtitle: () => string;
  onTimeUpdate: () => void;
  configuration: any;
  playerWidth: number;
}

const VideoPlayerDesktop: React.FC<VideoPlayerDesktopProps> = ({
  clickGoBackward,
  togglePlayPause,
  isPlaying,
  clickGoForward,
  clickStartBeginning,
  volume,
  handleVolumeChange,
  mute,
  handleMute,
  clickFullscreen,
  currentTime,
  videoRef,
  videoUrl,
  activeSubtitle,
  onTimeUpdate,
  configuration,
  playerWidth,
}) => {
  const [dynamicFontSize, setDynamicFontSize] = useState(
    configuration?.fontSize
  );

  useEffect(() => {
    const updateFontSize = () => {
      if (videoRef.current) {
        const videoHeight = videoRef.current.clientHeight;
        const userDefinedFontSize = configuration?.fontSize || 16;

        const scaleFactor = videoHeight / 500;
        const calculatedFontSize = userDefinedFontSize * scaleFactor;

        setDynamicFontSize(calculatedFontSize);
      }
    };

    const handleMetadataLoaded = () => {
      updateFontSize();
    };

    if (videoRef.current) {
      videoRef.current.addEventListener("loadedmetadata", handleMetadataLoaded);
    }

    window.addEventListener("resize", updateFontSize);

    updateFontSize();

    return () => {
      if (videoRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        videoRef.current.removeEventListener(
          "loadedmetadata",
          handleMetadataLoaded
        );
      }
      window.removeEventListener("resize", updateFontSize);
    };
  }, [videoRef, configuration?.fontSize]);

  const renderTotalTime = () => {
    return videoRef.current?.duration
      ? formatVideoTime(videoRef.current?.duration)
      : "00:00:00";
  };

  return (
    <PlayerWrapper>
      <VideoWrapper>
        <Video
          ref={videoRef}
          src={videoUrl}
          onTimeUpdate={onTimeUpdate}
          preload="metadata"
          playsInline
          controls={false}
        >
          Your browser does not support the video tag.
        </Video>
        <SubtitleWrapper
          className={`vertical-${configuration?.alignment?.vertical}`}
          style={{
            textAlign: configuration?.alignment?.horizontal || "center",
          }}
          playerWidth={playerWidth}
        >
          <ActiveSubtitle
            fontSize={dynamicFontSize}
            color={configuration?.color}
            backgroundColor={configuration?.backgroundColor}
            fontFamily={configuration?.fontFamily}
            decoration={configuration?.decoration}
            fontStyle={configuration?.fontStyle}
          >
            {activeSubtitle()}
          </ActiveSubtitle>
        </SubtitleWrapper>
      </VideoWrapper>
      <ControllerWrapper>
        <BottomControllerWrapper>
          <LeftBottomWrapper>
            <MainControls>
              <SpeedButton onClick={clickGoBackward}>
                {getIcon("backward")}
              </SpeedButton>
              <PlayButton onClick={togglePlayPause}>
                {isPlaying ? getIcon("pause") : getIcon("play")}
              </PlayButton>
              <SpeedButton onClick={clickGoForward}>
                {getIcon("forward")}
              </SpeedButton>
              <BeginningButton onClick={clickStartBeginning}>
                {getIcon("start-beginning")}
              </BeginningButton>
            </MainControls>
          </LeftBottomWrapper>
          <CenterBottomWrapper className="my-auto">
            <DurationWrapper>
              <Duration>
                {formatVideoTime(currentTime)}/{renderTotalTime()}
              </Duration>
            </DurationWrapper>
          </CenterBottomWrapper>
          <RightBottomWrapper>
            <SoundControls>
              <VolumeInput
                type="range"
                min={0}
                max={1}
                step={0.1}
                value={volume}
                onChange={handleVolumeChange}
              />
              <AudioButton onClick={handleMute}>
                {mute ? getIcon("volume-off") : getIcon("volume-on")}
              </AudioButton>
            </SoundControls>
            <ExpandButton onClick={clickFullscreen}>
              {getIcon("expand")}
            </ExpandButton>
          </RightBottomWrapper>
        </BottomControllerWrapper>
      </ControllerWrapper>
    </PlayerWrapper>
  );
};

export default VideoPlayerDesktop;

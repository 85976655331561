import styled from "@emotion/styled";
import { Modal } from "react-bootstrap";

export const PopupModal = styled(Modal)`
  .modal-dialog.modal-lg {
    max-width: 26.25rem;
  }
  .modal-content {
    padding: 1.25rem;
  }
`;

export const ModalTitle = styled(Modal.Title)`
  text-align: center;
  padding-top: 0.625rem;
`;

export const ExitIconButton = styled.button`
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
  border: none;
  color: grey;
  background-color: transparent;
  font-size: 1.25rem;
  opacity: 0.38;
  transition: 0.5s;
  &:hover {
    opacity: 1;
  }
`;

export const ModalBody = styled(Modal.Body)``;

export const ContentContainer = styled.div`
  padding-top: 0rem;
  margin-left: 0.1563rem;
  margin-right: 0.1563rem;
  .input-field {
    margin-bottom: 1.25rem;
  }
  .carousel-inner {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }
`;

export const ModalText = styled.span`
  display: block;
  color: grey;
  font-family: "Poppins", sans-serif;
  text-align: center;
`;

export const ModalButtonsContainer = styled.div`
  margin-top: 0.9375rem;
  text-align: center;
`;

import { zodResolver } from "@hookform/resolvers/zod";
import {
  ButtonsWrapper,
  Field,
  InfoBoxWrapper,
  SignupFormContainer,
} from "./styled";
import { WebinarSignupSchema, webinarSignupSchema } from "./schema";
import { useForm } from "react-hook-form";
import { Label } from "../../../../library/label";
import Input from "../../../../library/input";
import Button from "../../../../library/button";
import Checkbox from "../../../../library/checkbox";
import { useState } from "react";
import AttentionBox from "../../../../library/attention-box";
import { useNavigate } from "react-router-dom";

const SignupForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<{ title: string; text: string }>();
  const [status, setStatus] = useState<"success" | "error">();
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<WebinarSignupSchema>({
    resolver: zodResolver(webinarSignupSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      acceptedTerms: false,
    },
  });

  const acceptedTerms = watch("acceptedTerms");

  const clickSubmit = async (data: WebinarSignupSchema) => {
    try {
      setLoading(true);
      const request = await fetch(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/general/webinar/signup`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        }
      );
      const response = await request.json();
      const { status, error } = response;
      if (error === null) {
        reset();
        setLoading(false);
        navigate("/webinar/thank-you");
      } else {
        reset();
        setStatus(status);
        setError(error);
        setLoading(false);
      }
    } catch (e) {
      reset();
      setLoading(false);
      setError({
        title: "En uventet feil oppstod",
        text: "Vennligst prøv igjen senere.",
      });
    }
  };

  const renderInfoBox = () => {
    if (status === "success") {
      return (
        <AttentionBox
          type="information"
          icon="information"
          title="Påmeldingen var vellykket"
          text="Tusen takk for interessen! Du er nå påmeldt og vi vil holde deg oppdatert frem til datoen for webinaren."
        />
      );
    } else {
      if (status) {
        return (
          <AttentionBox
            type="fail"
            icon="information"
            title={(error?.title as string) || ""}
            text={(error?.text as string) || ""}
          />
        );
      }
      return null;
    }
  };

  return (
    <SignupFormContainer onSubmit={handleSubmit(clickSubmit)}>
      {renderInfoBox() && <InfoBoxWrapper>{renderInfoBox()}</InfoBoxWrapper>}
      <Field>
        <Label htmlFor="firstName">Fornavn</Label>
        <Input
          variant="regular"
          name="firstName"
          id="firstName"
          error={errors["firstName"]}
          register={register}
        />
      </Field>
      <Field>
        <Label htmlFor="lastName">Etternavn</Label>
        <Input
          variant="regular"
          name="lastName"
          id="lastName"
          error={errors["lastName"]}
          register={register}
        />
      </Field>
      <Field>
        <Label htmlFor="email">E-post</Label>
        <Input
          variant="regular"
          error={errors["email"]}
          name="email"
          id="email"
          register={register}
        />
      </Field>
      <Field>
        <Label htmlFor="mobile">Mobil</Label>
        <Input
          variant="regular"
          name="mobile"
          id="mobile"
          error={errors["mobile"]}
          register={register}
        />
      </Field>
      <Checkbox
        checked={acceptedTerms}
        onChange={(e: any) => setValue("acceptedTerms", e.target.checked)}
        name="product-select"
        error={errors["acceptedTerms"]}
        ariaLabel="Aksepter vilkår knapp"
        label="Ved å melde deg på denne webinaren godtar du at vi kan samle inn kontaktinformasjonen din for å kontakte deg i regi av webinaret."
      />
      <ButtonsWrapper>
        <Button color="blue" variant="regular" type="submit">
          {loading ? "Laster.." : "Meld meg på"}
        </Button>
      </ButtonsWrapper>
    </SignupFormContainer>
  );
};

export default SignupForm;

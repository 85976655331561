import styled from "@emotion/styled";

export const TopWrapper = styled.div`
  position: fixed;
  z-index: 2;
  top: calc(25px + 0px);
  width: 100%;
  padding: 10px;
  @media (max-width: 975px) {
    top: 0px;
  }
`;

export const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InnerLeftWrapper = styled.div``;

export const InnerRightWrapper = styled.div`
  &:last-of-type {
    button {
      margin-left: 10px;
    }
  }
`;

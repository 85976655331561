import { useTranslation } from "react-i18next";
import { Title } from "../../../../library/title";
import { FaqWrapper, AccordionWrapper, QuestionsAccordion } from "./styled";
import Question from "./question";

const FaqView = () => {
  const { t } = useTranslation();

  const QUESTIONS = [
    {
      key: "0",
      title: t("contact-us.faq-box.accordion.first-element.subtitle"),
      text: t("contact-us.faq-box.accordion.first-element.text"),
    },
    {
      key: "1",
      title: t("contact-us.faq-box.accordion.second-element.subtitle"),
      text: t("contact-us.faq-box.accordion.second-element.text"),
    },
    {
      key: "2",
      title: t("contact-us.faq-box.accordion.third-element.subtitle"),
      text: t("contact-us.faq-box.accordion.third-element.text"),
    },
    {
      key: "3",
      title: t("contact-us.faq-box.accordion.fourth-element.subtitle"),
      text: t("contact-us.faq-box.accordion.fourth-element.text"),
    },
    {
      key: "5",
      title: t("contact-us.faq-box.accordion.sixth-element.subtitle"),
      text: t("contact-us.faq-box.accordion.sixth-element.text"),
    },
  ];

  return (
    <FaqWrapper>
      <Title>{t("contact-us.faq-box.title")}</Title>
      <AccordionWrapper>
        <QuestionsAccordion defaultActiveKey="0">
          {QUESTIONS.map((question) => (
            <Question
              key={question.key}
              title={question.title}
              text={question.text}
            />
          ))}
        </QuestionsAccordion>
      </AccordionWrapper>
    </FaqWrapper>
  );
};

export default FaqView;

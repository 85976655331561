import Button from "../../../library/button";
import Input from "../../../library/input";
import { ContentContainer, SearchContainer, TableWrapper } from "./styled";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import AddModal from "./add-modal";
import useListTranscripts from "../../../hooks/useTranscriptsList";
import SubscriptionModalContent from "../../../components/SubscriptionModal";
import OnboardingModalContent from "../../../components/OnboardingModal";
import Table from "./list-view";
import TableLoader from "../../../library/table-loader";
import useDeleteTranscripts from "../../../hooks/useDeleteTranscripts";
import {
  sendErrorNotification,
  sendSuccessNotification,
} from "../../../library/notification";
import { useTranslation } from "react-i18next";
import AttentionBox from "../../../library/attention-box";

import useUser from "../../../hooks/useUser";
import Tabs from "../../../library/tabs";
import Modal from "../../../library/modal";
import { useMediaQuery } from "react-responsive";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  setSearchQuery,
  clearDeletionArray,
} from "../../../redux/slices/transcripts";
import DashboardTopBar from "../../../components/DashboardTopBar";
import DashboardPage from "../../../components/DashboardPage";
import Confirmation from "../../../library/modal/confirmation";
import { metadata } from "./meta";

const MOBILE_VIEW = 800;

const Content = () => {
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_VIEW}px)` });
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState<string>("private");
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [newTranscriptOpen, setNewTranscriptOpen] = useState<boolean>(false);
  const [onboardingOpen, setOnboardingOpen] = useState<boolean>(false);
  const [subscriptionOpen, setSubscriptionOpen] = useState<boolean>(false);

  const { deleteTranscripts } = useDeleteTranscripts();
  const { fetchTranscriptsList, loading } = useListTranscripts();
  const { loading: loadingUserDetails } = useUser();

  const selectedWorkspace = useAppSelector(
    (state) => state?.user?.selectedWorkspace
  );
  const user = useAppSelector((state) => state?.user?.details);
  const privateTranscripts = useAppSelector(
    (state) => state.transcripts?.records?.private
  );
  const publicTranscripts = useAppSelector(
    (state) => state?.transcripts?.records?.public
  );

  const deletions = useAppSelector(
    (state) => state?.transcripts?.deletionArray
  );
  const searchQuery = useAppSelector(
    (state) => state?.transcripts?.searchQuery
  );

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const transcripts = useCallback(
    (type: "private" | "public") => {
      if (searchQuery) {
        if (type === "private") {
          return privateTranscripts?.filter((x: any) =>
            x.filename?.includes(searchQuery)
          );
        }
        return publicTranscripts?.filter((x: any) =>
          x.filename?.includes(searchQuery)
        );
      }

      return type === "private" ? privateTranscripts : publicTranscripts;
    },
    [privateTranscripts, publicTranscripts, searchQuery]
  );

  const renderList = (type: "private" | "public") => {
    if (loading) {
      return (
        <TableLoader
          message={
            t("dashboard.pages.transcripts.loading-view.message") as string
          }
        />
      );
    }
    return <Table data={transcripts(type)} />;
  };

  const SELECTIONS = [
    {
      id: 0,
      key: "private",
      title: t(
        "dashboard.pages.transcripts.tab-selections.private-transcriptions.title"
      ),
      ariaLabel: t(
        "dashboard.pages.transcripts.tab-selections.private-transcriptions.ariaLabel"
      ) as string,
      display: (
        <TableWrapper className={loading ? "loading" : ""}>
          {renderList("private")}
        </TableWrapper>
      ),
    },
    {
      id: 1,
      key: "shared",
      title: t(
        "dashboard.pages.transcripts.tab-selections.shared-transcriptions.title"
      ),
      ariaLabel: t(
        "dashboard.pages.transcripts.tab-selections.shared-transcriptions.ariaLabel"
      ) as string,
      display: (
        <TableWrapper className={loading ? "loading" : ""}>
          {renderList("public")}
        </TableWrapper>
      ),
    },
  ];

  useEffect(() => {
    if (selectedWorkspace?.id && user?.uid) {
      fetchTranscriptsList();
    }
  }, [fetchTranscriptsList, selectedWorkspace?.id, user?.onboarded, user?.uid]);

  useEffect(() => {
    if (user?.uid && !user?.onboarded) {
      setOnboardingOpen(true);
    }
  }, [setOnboardingOpen, user?.onboarded, user?.uid]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchQuery(e.target.value));
  };

  const handleCloseOnboardingModal = () => {
    setOnboardingOpen(false);
  };

  const handleOpenNewTranscriptModal = () => {
    setNewTranscriptOpen(true);
  };

  const handleCloseNewTranscriptModal = () => {
    setNewTranscriptOpen(false);
    fetchTranscriptsList();
  };

  const handleOpenSubscriptionModal = () => {
    setSubscriptionOpen(true);
  };

  const handleCloseSubscriptionModal = () => {
    setSubscriptionOpen(false);
  };

  const renderAttention = () => {
    if (!loadingUserDetails && selectedWorkspace?.subscription === "small") {
      return (
        <AttentionBox
          title={t("dashboard.pages.transcripts.upgrade-box.title")}
          text={t(t("dashboard.pages.transcripts.upgrade-box.description"))}
          icon="information"
          type="information"
          buttonText={
            t("dashboard.pages.transcripts.upgrade-box.button.title") as string
          }
          onClick={handleOpenSubscriptionModal}
        />
      );
    }
    return null;
  };

  const performDeleteTranscripts = async () => {
    try {
      const deletion = await deleteTranscripts(deletions);
      if (deletion?.error === null) {
        dispatch(clearDeletionArray());
        closeDeleteModal();
        sendSuccessNotification(
          t(
            "dashboard.universal.notifications.transcripts.delete-multiple.success"
          )
        );
        fetchTranscriptsList();
      } else {
        sendErrorNotification(
          t(
            "dashboard.universal.notifications.transcripts.delete-multiple.failed"
          )
        );
      }
    } catch (e) {
      sendErrorNotification(
        t(
          "dashboard.universal.notifications.transcripts.delete-multiple.failed"
        )
      );
    }
  };

  return (
    <DashboardPage
      layout="default"
      meta={metadata}
      notification={renderAttention()}
    >
      <>
        <DashboardTopBar
          title={t("dashboard.pages.transcripts.title")}
          buttons={
            <>
              <Button
                onClick={openDeleteModal}
                variant={isMobile ? "icon" : "regular"}
                color="grey"
                icon="trash"
                size={isMobile ? "lg" : "md"}
                spacing="right"
                disabled={deletions?.length === 0}
                ariaLabel={
                  t(
                    "dashboard.pages.transcripts.buttons.remove-transcript.ariaLabel"
                  ) as string
                }
              >
                {t(
                  "dashboard.pages.transcripts.buttons.remove-transcript.title"
                )}
              </Button>
              <Button
                onClick={handleOpenNewTranscriptModal}
                variant={isMobile ? "icon" : "regular"}
                color="blue"
                icon="plus"
                size={isMobile ? "lg" : "md"}
                ariaLabel={
                  t(
                    "dashboard.pages.transcripts.buttons.add-transcript.ariaLabel"
                  ) as string
                }
              >
                {t("dashboard.pages.transcripts.buttons.add-transcript.title")}
              </Button>
            </>
          }
        />
        <SearchContainer>
          <Input
            variant="search"
            value={searchQuery}
            ariaLabel={
              t("dashboard.pages.transcripts.search-field.ariaLabel") as string
            }
            placeholder={`${t(
              "dashboard.pages.transcripts.search-field.placeholder"
            )}..`}
            onChange={handleSearch}
          />
        </SearchContainer>
        <ContentContainer>
          <Tabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            selections={SELECTIONS}
          />
        </ContentContainer>

        <Modal
          open={newTranscriptOpen}
          title={t("dashboard.pages.transcripts.add-modal.title")}
          onClose={handleCloseNewTranscriptModal}
        >
          <AddModal closeModal={handleCloseNewTranscriptModal} />
        </Modal>
        <Modal
          open={onboardingOpen}
          onClose={handleCloseOnboardingModal}
          title="Velkommen til Randi!"
          size={1100}
        >
          <OnboardingModalContent onClose={handleCloseOnboardingModal} />
        </Modal>
        <Modal
          open={subscriptionOpen}
          onClose={handleCloseSubscriptionModal}
          title={t("dashboard.pages.workspace.change-subscription-modal.title")}
        >
          <SubscriptionModalContent onClose={handleCloseSubscriptionModal} />
        </Modal>
        <Confirmation
          open={showDeleteModal}
          onClose={closeDeleteModal}
          title={t("dashboard.pages.transcripts.delete-multiple-modal.title")}
          description={
            t("dashboard.pages.transcripts.delete-multiple-modal.text")!
          }
          variant="delete"
          onSubmit={performDeleteTranscripts}
          submitText={
            t(
              "dashboard.pages.transcripts.delete-multiple-modal.buttons.delete.title"
            )!
          }
        />
      </>
    </DashboardPage>
  );
};

export default Content;

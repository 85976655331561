import styled from "@emotion/styled";
import { Col, Row } from "react-bootstrap";

export const AttentionBoxWrapper = styled.div``;

export const InnerBoxWrapper = styled.div`
  background-color: rgb(238, 246, 255);
  border-color: rgb(62, 151, 255);
  border-radius: 0.5rem;
  padding: 0.9375rem;
  border: 0.125rem dashed;
  &.type-information {
    background-color: rgb(238, 246, 255);
    border-color: rgb(62, 151, 255);
  }
  &.type-fail {
    background-color: #ffd7de;
    border: 0.0625rem dashed #ff4d6d;
  }
`;

export const AttentionRow = styled(Row)`
  @media (max-width: 767px) {
    display: block;
  }
`;

export const AttentionCol = styled(Col)`
  &.icon {
    max-width: 4.375rem;
    text-align: center;
  }
  &.text {
    padding-right: 0rem !important;
    &.no-button {
      padding-right: 1rem !important;
    }
  }
  &.button {
    padding-left: 0rem !important;
    text-align: right;
    max-width: 12.5rem;
  }
  @media (max-width: 767px) {
    &.button {
      margin-top: 0.9375rem !important;
      max-width: unset;
      text-align: left;
      padding-left: 0.625rem !important;
      button {
        width: 100%;
      }
    }
  }
`;

export const Icon = styled.span`
  font-size: 2.375rem;
  &.type-information {
    color: #2f8de4;
  }
  &.type-fail {
    color: #ff4d6d;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;

export const Title = styled.h3`
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  display: block;
  &.type-information {
    color: #2f8de4;
  }
  &.type-fail {
    color: #ff4d6d;
  }
  @media (max-width: 767px) {
    font-size: 1.125rem;
  }
`;

export const Text = styled.span`
  font-family: "Poppins", sans-serif;
  display: block;
  font-size: 0.875rem;
  margin-top: -0.3125rem;
  &.type-information {
    color: grey;
  }
  &.type-fail {
    color: #ff4d6d;
  }
  @media (max-width: 767px) {
    margin-top: 0.4688rem;
  }
`;

import Lottie from "lottie-react";
import { AnimationContainer, EmptyViewContainer, TextWrapper } from "./styled";
import emptyAnimation from "../../assets/animations/empty-animation.json";
import { Title } from "../../library/title";
import { Text } from "../../library/text";

interface IAnimationProps {
  title: string;
  description: string;
  size?: "regular" | "small";
}

const EmptyView: React.FC<IAnimationProps> = ({
  title,
  description,
  size = "regular",
}) => {
  const displaySize = size === "regular" ? "lg" : "sm";

  return (
    <EmptyViewContainer className={`size-${displaySize}`}>
      <AnimationContainer className={`size-${displaySize}`}>
        <Lottie animationData={emptyAnimation} />
      </AnimationContainer>
      <Title size={displaySize}>{title}</Title>
      <TextWrapper>
        <Text color="dark">{description}</Text>
      </TextWrapper>
    </EmptyViewContainer>
  );
};

export default EmptyView;

import { useForm } from "react-hook-form";
import Input from "../../../../../../library/input";
import { Label } from "../../../../../../library/label";
import { ButtonsWrapper, Field, Form, TabContainer } from "./styled";
import Button from "../../../../../../library/button";
import { ChangePasswordSchema, schema } from "./schema";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  sendErrorNotification,
  sendSuccessNotification,
} from "../../../../../../library/notification";
import useUpdatePassword from "../../../../../../hooks/useUpdatePassword";
import { useTranslation } from "react-i18next";

const Index = () => {
  const { t } = useTranslation();
  const { updatePassword } = useUpdatePassword();
  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<ChangePasswordSchema>({
    resolver: zodResolver(schema),
  });

  const changePassword = async (data: ChangePasswordSchema) => {
    try {
      const update = await updatePassword(data);
      if (update?.error === null) {
        reset();
        sendSuccessNotification("Passord ble oppdatert.");
      } else {
        throw update.error;
      }
    } catch (e) {
      sendErrorNotification("Kunne ikke oppdatere detaljer.");
    }
  };

  return (
    <TabContainer>
      <Form onSubmit={handleSubmit(changePassword)}>
        <Field>
          <Label htmlFor="passwordInput">
            {t(
              "dashboard.pages.account.settings-view.tabs.change-password.view.form.new-password-label"
            )}
          </Label>
          <Input
            register={register}
            error={errors["password"]}
            name="password"
            id="passwordInput"
            placeholder={
              t(
                "dashboard.pages.account.settings-view.tabs.change-password.view.form.new-password-input.placeholder"
              ) as string
            }
            ariaLabel={
              t(
                "dashboard.pages.account.settings-view.tabs.change-password.view.form.new-password-input.ariaLabel"
              ) as string
            }
            variant="regular"
            type="password"
          />
        </Field>
        <Field>
          <Label htmlFor="retypePasswordInput">
            {t(
              "dashboard.pages.account.settings-view.tabs.change-password.view.form.retype-password-label"
            )}
          </Label>
          <Input
            register={register}
            error={errors["passwordRetype"]}
            name="passwordRetype"
            id="retypePasswordInput"
            ariaLabel={
              t(
                "dashboard.pages.account.settings-view.tabs.change-password.view.form.retype-password-input.ariaLabel"
              ) as string
            }
            placeholder={
              t(
                "dashboard.pages.account.settings-view.tabs.change-password.view.form.retype-password-input.placeholder"
              ) as string
            }
            variant="regular"
            type="password"
          />
        </Field>
        <ButtonsWrapper>
          <Button
            ariaLabel={
              t(
                "dashboard.pages.account.settings-view.tabs.change-password.view.form.submit-button.ariaLabel"
              ) as string
            }
            type="submit"
            color="blue"
            variant="regular"
          >
            {t(
              "dashboard.pages.account.settings-view.tabs.change-password.view.form.submit-button.title"
            )}
          </Button>
        </ButtonsWrapper>
      </Form>
    </TabContainer>
  );
};

export default Index;

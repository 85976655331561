import { useCallback } from "react";
import { firebaseApp } from "../firebase";
import { getAuth } from "firebase/auth";
import { sendErrorNotification } from "../library/notification";
import { setSoundtrackCreationLoading } from "../redux/slices/soundtracks";
import { UploadSoundtrackSchema } from "../pages/dashboard/soundtracks/add-modal/schema";
import { useAppDispatch, useAppSelector } from "../redux/hooks";

const useCreateSoundtrack = (afterSave: any) => {
  const dispatch = useAppDispatch();
  const selectedWorkspace = useAppSelector(
    (state) => state.user.selectedWorkspace
  );
  const loading = useAppSelector((state) => state.soundtracks.loadingCreation);
  const auth = getAuth(firebaseApp);

  const createSoundtrack = useCallback(
    async (details: UploadSoundtrackSchema) => {
      try {
        dispatch(setSoundtrackCreationLoading(true));
        const token = await auth.currentUser?.getIdToken(true);
        if (token) {
          const request = await fetch(
            `${process.env.REACT_APP_BACKEND_ENDPOINT}/text-to-speech/start`,
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                token: token,
                workspaceId: selectedWorkspace.id,
                format: details.format,
                title: details.title,
                text: details.text,
                voice: details.voice,
                public: details.public,
                language: details.language,
              }),
            }
          );
          const response = await request.json();
          if (response.error === null) {
            dispatch(setSoundtrackCreationLoading(false));
            afterSave();
          } else {
            dispatch(setSoundtrackCreationLoading(false));
            switch (response.error) {
              case "upgrade-needed":
                sendErrorNotification(
                  "Du har nådd grensen på 2 gratis lydspor i måneden."
                );
                break;
              default:
                sendErrorNotification(
                  "Kunne ikke starte generering av lydspor."
                );
            }
          }
        }
      } catch (e) {
        dispatch(setSoundtrackCreationLoading(false));
        throw e;
      }
    },
    [afterSave, auth.currentUser, dispatch, selectedWorkspace.id]
  );

  return { createSoundtrack, loading };
};

export default useCreateSoundtrack;

export const languages = [
  { value: "arabic", title: "Arabisk" },
  { value: "danish", title: "Dansk" },
  { value: "english-au", title: "Engelsk (Australia)" },
  { value: "english-uk", title: "Engelsk (Storbritannia)" },
  { value: "english-us", title: "Engelsk (USA)" },
  { value: "filipino", title: "Filippinsk" },
  { value: "french", title: "Fransk" },
  { value: "german", title: "Tysk" },
  { value: "hebrew", title: "Hebraisk" },
  { value: "hindi", title: "Hindi" },
  { value: "italian", title: "Italiensk" },
  { value: "japanese", title: "Japansk" },
  { value: "korean", title: "Koreansk" },
  { value: "norwegian", title: "Norsk" },
  { value: "polish", title: "Polsk" },
  { value: "portuguese-br", title: "Portugisisk (Brasil)" },
  { value: "portuguese-pt", title: "Portugisisk (Portugal)" },
  { value: "russian", title: "Russisk" },
  { value: "spanish", title: "Spansk" },
  { value: "swedish", title: "Svensk" },
  { value: "vietnamese", title: "Vietnamesisk" },
];

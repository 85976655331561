import Switch from "react-switch";
import styled from "@emotion/styled";

export const SwitchWrapper = styled.div`
  align-items: center;
  display: flex;
  padding: 0.625rem 0rem;
`;

export const StyledSwitch = styled(Switch)`
  &.toggle-true {
    .react-switch-handle {
      height: 1.625rem !important;
      width: 1.625rem !important;
      top: 0.3125rem !important;
      right: 1.8438rem !important;
    }
  }
  &.toggle-false {
    .react-switch-handle {
      height: 1.625rem !important;
      width: 1.625rem !important;
      top: 0.3125rem !important;
      left: 0.3125rem !important;
    }
  }
`;

export const Label = styled.span`
  font-family: "Poppins", sans-serif;
  color: grey;
  font-size: 0.875rem;
  font-weight: 500;
  margin-left: 0.625rem;
`;

export const HelpingIcon = styled.span`
  margin-left: 0.3125rem;
  font-size: 1.125rem;
  color: grey;
`;

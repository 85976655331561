import styled from "@emotion/styled";

export const TopBarWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleWrapper = styled.div`
  display: flex;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 800px) {
    padding-left: 0rem !important;
    button {
      &:first-of-type {
        margin-right: 10px;
      }
    }
  }
`;

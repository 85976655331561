import { useCallback, useState } from "react";
import { getAuth } from "firebase/auth";
import { firebaseApp } from "../firebase";
import { ChangePasswordSchema } from "../pages/dashboard/account/content/settings-view/change-password/schema";

const useUpdatePassword = () => {
  const auth = getAuth(firebaseApp);
  const [loading, setLoading] = useState<boolean>(false);

  const updatePassword = useCallback(
    async (data: ChangePasswordSchema) => {
      try {
        setLoading(true);
        const token = await auth.currentUser?.getIdToken(true);
        const request = await fetch(
          `${process.env.REACT_APP_BACKEND_ENDPOINT}/user/update/password`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              token: token,
              password: data?.password,
            }),
          }
        );
        const response = await request.json();
        if (response.error === null) {
          return { error: null };
        } else {
          throw response.error;
        }
      } catch (e) {
        throw e;
      }
    },
    [auth]
  );

  return { loading, updatePassword };
};

export default useUpdatePassword;

import { useTranslation } from "react-i18next";
import {
  SelectionSubtitle,
  SelectionWrapper,
  BoxesWrapper,
  ToggleWrapper,
} from "./styled";
import { SUBSCRIPTION_TYPES } from "./config";
import Subscription from "./subscription";
import { ButtonsContainer } from "../../styled";
import Button from "../../../../library/button";
import { useNavigate } from "react-router-dom";
import ToggleButtons from "../../../../library/toggle-buttons";
import { Dispatch, SetStateAction, useState } from "react";
import { UseFormSetValue } from "react-hook-form";
import { RegisterSchema } from "../../schema";

interface ISelectSubscriptionProps {
  setValue: UseFormSetValue<RegisterSchema>;
  selectedSubscription: any;
  setStep: Dispatch<SetStateAction<1 | 2 | 3 | 4>>;
}

const SelectSubscription: React.FC<ISelectSubscriptionProps> = ({
  setValue,
  selectedSubscription,
  setStep,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedDuration, setSelectedDuration] = useState<string>("monthly");

  const selectSubscription = (type: string) => {
    setValue("subscription", type);
  };

  const clickGoNext = () => {
    setStep(2);
  };

  const clickGoBack = () => {
    navigate("/");
  };

  const handleTabSelection = (selection: string) => {
    setSelectedDuration(selection);
    if (selection === "monthly" && selectedSubscription === "go-annually") {
      setValue("subscription", "go-monthly");
    } else if (
      selection === "annually" &&
      selectedSubscription === "go-monthly"
    ) {
      setValue("subscription", "go-annually");
    } else {
      setValue("subscription", "small");
    }
  };

  const renderOptions = () => {
    if (selectedDuration === "monthly") {
      return SUBSCRIPTION_TYPES.filter((x) => x.id !== "go-annually");
    }
    return SUBSCRIPTION_TYPES.filter((x) => x.id !== "go-monthly");
  };

  return (
    <SelectionWrapper>
      <SelectionSubtitle>
        {t("register.views.select-subscription.title")}
      </SelectionSubtitle>
      <ToggleWrapper>
        <ToggleButtons
          selected={selectedDuration}
          options={[
            {
              title: t(
                "register.views.select-subscription.payment-duration.monthly-selection.title"
              ),
              value: "monthly",
            },
            {
              title: t(
                "register.views.select-subscription.payment-duration.annually-selection.title"
              ),
              value: "annually",
            },
          ]}
          onSelect={handleTabSelection}
        />
      </ToggleWrapper>
      <BoxesWrapper>
        {renderOptions().map((subscription) => (
          <Subscription
            id={subscription.id}
            onSelect={selectSubscription}
            title={t(subscription.title)}
            description={t(subscription.description)}
            price={t(subscription.price)}
            duration={t(subscription.duration)}
            selected={selectedSubscription}
            ariaLabel={t(subscription.ariaLabel)}
          />
        ))}
      </BoxesWrapper>
      <ButtonsContainer>
        <Button
          onClick={clickGoBack}
          variant="regular"
          color="grey"
          ariaLabel={
            t("register.bottom-row.go-back-button.ariaLabel") as string
          }
        >
          {t("register.bottom-row.go-back-button.title")}
        </Button>
        <Button
          onClick={clickGoNext}
          spacing="left"
          variant="regular"
          color="blue"
          ariaLabel={
            t("register.bottom-row.proceed-button.default.ariaLabel") as string
          }
        >
          {t("register.bottom-row.proceed-button.default.title")}
        </Button>
      </ButtonsContainer>
    </SelectionWrapper>
  );
};

export default SelectSubscription;

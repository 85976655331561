import Button from "../../library/button";
import { ButtonsWrapper, Form, StyledText, TextWrapper } from "./styled";
import TranscriptView from "./transcript-view";
import { useEffect, useRef, useState } from "react";
import { sendErrorNotification } from "../../library/notification";
import useDownloadTranscript from "../../hooks/useDownloadTranscript";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { DownloadTranscriptSchema, downloadTranscriptSchema } from "./schema";
import { getIcon } from "../../utils/get-icon";

interface ExportModalProps {
  onClose: () => void;
  transcriptId: string;
  captions?: any;
  configuration?: any;
  onGenerateVideo?: () => void;
  generateLoading?: boolean;
  progress?: number;
  variant?: "list" | "editor";
  videoDownloadUrl?: string;
  method?: any;
}

const Index = ({
  transcriptId,
  onClose,
  captions,
  configuration,
  variant = "list",
  onGenerateVideo,
  generateLoading,
  progress,
  videoDownloadUrl,
  method,
}: ExportModalProps) => {
  const { downloadTranscript } = useDownloadTranscript();
  const [selection, setSelection] = useState<
    "video-subtitles" | "text" | "html" | "csv" | "docx" | "srt" | "vtt"
  >();

  const descriptionRef = useRef<HTMLSpanElement>(null);

  const { handleSubmit, setValue } = useForm<DownloadTranscriptSchema>({
    resolver: zodResolver(downloadTranscriptSchema),
    defaultValues: {
      format: variant === "list" ? "text" : "video-subtitles",
    },
  });

  useEffect(() => {
    if (!selection && variant === "list") {
      setSelection("text");
    }
    if (!selection && variant === "editor") {
      setSelection("video-subtitles");
    }
  }, [selection, variant]);

  const handleSelection = (option: any) => {
    setSelection(option);
    setValue("format", option);
  };

  const clickSubmit = async (data: DownloadTranscriptSchema) => {
    try {
      if (!data.format) return;
      if (data.format === "video-subtitles" && onGenerateVideo) {
        onGenerateVideo();
      } else {
        const download = await downloadTranscript(transcriptId, data.format);
        if (download.error === null) {
          onClose();
        }
      }
    } catch (e) {
      sendErrorNotification("Kunne ikke laste ned transkript.");
    }
  };

  const renderMethod = () => {
    return method === "processing" ? "Forbereder video" : "Laster ned video";
  };

  const renderProgress = () => {
    if (progress) return progress;
    return 0;
  };

  return (
    <>
      <TextWrapper>
        <StyledText ref={descriptionRef}>
          Nedenfor kan du velge om du vil laste ned både video med teksting
          eller kun transkriptet i ønsket filformat.
        </StyledText>
      </TextWrapper>
      <Form onSubmit={handleSubmit(clickSubmit)}>
        <TranscriptView
          selection={selection}
          onSelect={handleSelection}
          variant={variant}
          generateLoading={generateLoading}
          videoDownloadUrl={videoDownloadUrl}
          descriptionHeight={descriptionRef.current?.clientHeight}
        />
        <ButtonsWrapper>
          <Button
            type="button"
            onClick={onClose}
            variant="regular"
            color="grey"
            ariaLabel=""
          >
            Avbryt
          </Button>
          <Button
            type="submit"
            variant="regular"
            color="blue"
            ariaLabel="Last ned knapp"
          >
            {generateLoading ? getIcon("spinner") : getIcon("download")}
            {generateLoading
              ? `${renderMethod()} (${Math.round(renderProgress())}%)`
              : "Last ned"}
          </Button>
        </ButtonsWrapper>
      </Form>
    </>
  );
};

export default Index;

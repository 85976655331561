import { useCallback, useEffect, useRef, useState } from "react";
import { PageContainer, LoaderWrapper } from "./styled";
import { useParams } from "react-router-dom";
import useTranscript from "../../../../hooks/useTranscript";
import { useSelector } from "react-redux";
import { IState } from "../../../../types";
import short from "short-uuid";
import Meta from "./meta";
import { sendErrorNotification } from "../../../../library/notification";
import SubscriptionModalContent from "../../../../components/SubscriptionModal";
import TableLoader from "../../../../library/table-loader";
import ErrorDisplay from "./error-display";
import useGenerateVideo from "../../../../hooks/useGenerateVideo";
import useMonitorVideoGeneration from "../../../../hooks/useMonitorVideoGeneration";
import { useTranslation } from "react-i18next";
import useFetchVideoEditorFonts from "../../../../hooks/useFetchVideoEditorFonts";
import { useForm } from "react-hook-form";
import { VideoAppearanceSchema, videoAppearanceSchema } from "./schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { defaultApperance } from "./utils";
import Modal from "../../../../library/modal";
import TopBar from "./top-bar";
import { useMediaQuery } from "react-responsive";
import { MOBILE_VIEW } from "../../../../variables";
import useDownloadVideoFile from "../../../../hooks/useDownloadVideoFile";
import EditorDisplay from "./editor-display";
import { isVideoFile } from "./helpers";
import _ from "lodash";

const Content = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { downloadVideo } = useGenerateVideo();
  const { monitorVideoGeneration } = useMonitorVideoGeneration();
  const { fetchVideoEditorFonts, fonts } = useFetchVideoEditorFonts();

  const [captionComparison, setCaptionComparison] = useState<any>(null);
  const [appearanceComparison, setAppearanceComparison] = useState<any>(null);
  const { fetchError, getTranscript, transcript, saveTranscript } =
    useTranscript();

  const [showSubscriptionModal, setShowSubscriptionModal] =
    useState<boolean>(false);

  const timeoutCaptionsRef = useRef<NodeJS.Timeout | null>(null);
  const timeoutAppearanceRef = useRef<NodeJS.Timeout | null>(null);

  const closeSubscriptionModal = () => {
    setShowSubscriptionModal(false);
  };

  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_VIEW}px)` });
  const [activeMethod, setActiveMethod] = useState<
    "downloading" | "processing"
  >("processing");

  const [firstLoadData, setFirstLoadData] = useState<boolean>(false);
  const [playerWidth, setPlayerWidth] = useState<number>(0);
  const [playerHeight, setPlayerHeight] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [generateLoading, setGenerateLoading] = useState<boolean>(false);
  const [videoDownloadUrl, setVideoDownloadUrl] = useState<string>();
  const [progress, setProgress] = useState<number>(0);
  const { downloadVideoFile } = useDownloadVideoFile();

  const selectedWorkspace = useSelector(
    (state: IState) => state.user.selectedWorkspace
  );
  const user = useSelector((state: IState) => state.user.details);
  const videoRef = useRef<HTMLVideoElement>(null);
  const scrollingContainerRef = useRef<HTMLDivElement>(null);
  const activeCaptionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updatePlayerHeight = () => {
      if (videoRef.current) {
        const videoElement = videoRef.current;

        setPlayerWidth(videoElement.clientWidth);
        setPlayerHeight(videoElement.clientHeight);
      }
    };

    const videoElement = videoRef.current;

    if (videoElement) {
      videoElement.addEventListener("loadedmetadata", updatePlayerHeight);
      window.addEventListener("resize", updatePlayerHeight);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener("loadedmetadata", updatePlayerHeight);
        window.removeEventListener("resize", updatePlayerHeight);
      }
    };
  }, [videoRef]);

  const { register, setValue, watch } = useForm<VideoAppearanceSchema>({
    resolver: zodResolver(videoAppearanceSchema),
    defaultValues: {
      captions: [],
      appearance: transcript?.appearance ?? defaultApperance,
    },
  });

  const captions: any[] = watch("captions");
  const appearance = watch("appearance");

  const updateFirstLoad = () => {
    setFirstLoadData(true);
  };

  useEffect(() => {
    if (selectedWorkspace?.id && user?.uid && id && !firstLoadData) {
      getTranscript(id, updateFirstLoad);
      document.documentElement.style.setProperty("--player-volume", `100%`);
      if (fonts?.length === 0) {
        fetchVideoEditorFonts();
      }
    }
  }, [
    fetchVideoEditorFonts,
    firstLoadData,
    fonts,
    getTranscript,
    id,
    selectedWorkspace?.id,
    transcript.id,
    user?.uid,
  ]);

  const clickSave = useCallback(async () => {
    try {
      if (transcript?.id && captions && captions?.length > 0) {
        saveTranscript(captions, appearance, transcript.id);
      } else {
        throw new Error("Error");
      }
    } catch (e) {
      sendErrorNotification("Kunne ikke lagre transkript.");
    }
  }, [appearance, captions, saveTranscript, transcript.id]);

  const updateGoogleFontsLink = useCallback(
    (selectedFont: any) => {
      const linkElement = document.getElementById(
        "editor-preview-fonts-link"
      ) as HTMLLinkElement;
      if (linkElement) {
        linkElement.href = `https://fonts.googleapis.com/css2?family=${selectedFont?.family?.replace(
          " ",
          "+"
        )}&display=swap`;
      } else {
        const newLink = document.createElement("link");
        newLink.id = "editor-preview-fonts-link";
        newLink.rel = "stylesheet";
        newLink.href = `https://fonts.googleapis.com/css2?family=${selectedFont?.family?.replace(
          " ",
          "+"
        )}&display=swap`;
        document.head.appendChild(newLink);
      }
      setValue("appearance.fontFamily", selectedFont.family);
    },
    [setValue]
  );

  useEffect(() => {
    if (transcript?.appearance?.fontFamily) {
      setValue("appearance", transcript?.appearance);
      updateGoogleFontsLink({ family: transcript.appearance.fontFamily });
    }
  }, [setValue, transcript?.appearance, updateGoogleFontsLink]);

  useEffect(() => {
    if (transcript?.content?.length > 0) {
      const fields: any[] = [];
      transcript?.content?.forEach((caption: any) => {
        const key = short.generate();
        fields.push({ ...caption, key: key });
      });
      setValue("captions", fields);
      setCaptionComparison(fields);
      if (!transcript?.appearance) {
        setAppearanceComparison(defaultApperance);
      } else {
        setAppearanceComparison(transcript.appearance);
      }
    }
  }, [setValue, transcript.appearance, transcript?.content]);

  const startPolling = () => {
    const pollInterval = 10000;

    const poll = async () => {
      try {
        const { downloadUrl, progress } = await monitorVideoGeneration(
          transcript?.id
        );

        setProgress(progress);

        if (downloadUrl) {
          setGenerateLoading(false);
          setVideoDownloadUrl(downloadUrl);
          setProgress(0);
          return;
        }

        setTimeout(poll, pollInterval);
      } catch (error) {
        setGenerateLoading(false);
      }
    };

    poll();
  };

  const onFinishMobileDownload = () => {
    setGenerateLoading(false);
    setProgress(0);
  };

  const handleTextChange = (key: string, value: string, save: boolean) => {
    setValue(
      "captions",
      captions.map((caption: any) => {
        if (caption.key === key) {
          return { ...caption, text: value };
        }
        return caption;
      })
    );
  };

  useEffect(() => {
    if (!appearance || !appearanceComparison) {
      return;
    }

    if (timeoutAppearanceRef.current) {
      clearTimeout(timeoutAppearanceRef.current);
    }

    timeoutAppearanceRef.current = setTimeout(() => {
      const appearanceChanged =
        JSON.stringify(appearance) !== JSON.stringify(appearanceComparison);

      if (appearanceChanged) {
        clickSave();
        setAppearanceComparison(appearance);
      }
    }, 300);
  }, [appearance, appearanceComparison, clickSave]);

  useEffect(() => {
    if (!captions || !captionComparison) {
      return;
    }

    if (timeoutCaptionsRef.current) {
      clearTimeout(timeoutCaptionsRef.current);
    }

    timeoutCaptionsRef.current = setTimeout(() => {
      const differences = _.differenceWith(
        captions,
        captionComparison,
        _.isEqual
      );

      if (differences.length > 0) {
        clickSave();
        setCaptionComparison(captions);
      }
    }, 300);
  }, [captions, clickSave, captionComparison]);

  const onGenerateVideo = async () => {
    try {
      if (transcript?.id && isVideoFile(transcript?.filename)) {
        const download = await downloadVideo(
          transcript.id,
          captions,
          appearance
        );

        if (download?.error === null) {
          const { status, downloadUrl } = download.data;
          if (status === "download") {
            try {
              if (isMobile) {
                setActiveMethod("downloading");
                setGenerateLoading(true);
                setProgress(0);
              }
              try {
                await downloadVideoFile(
                  downloadUrl,
                  transcript?.filename,
                  setProgress,
                  onFinishMobileDownload
                );
                if (isMobile) {
                  setGenerateLoading(false);
                  setProgress(0);
                }
              } catch (e) {
                sendErrorNotification("Kunne ikke laste ned video.");
              }
            } catch (e) {
              sendErrorNotification("Kunne ikke laste ned video.");
            }
          } else {
            setActiveMethod("processing");
            setGenerateLoading(true);
            startPolling();
          }
        }
        setVideoDownloadUrl(undefined);
      } else {
        sendErrorNotification(
          "Filen du har lastet opp er ikke i et video-format."
        );
      }
    } catch (e) {
      sendErrorNotification("Kunne ikke generere video.");
    }
  };

  useEffect(() => {
    if (activeCaptionRef.current && scrollingContainerRef?.current) {
      const activeElement = activeCaptionRef.current;
      const container = scrollingContainerRef.current;

      const activeElementRect = activeElement.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();

      let topPosition;

      if (isMobile) {
        if (playerHeight) {
          topPosition =
            activeElement.offsetTop - container.offsetTop + playerHeight - 15;
        } else {
          topPosition = activeElement.offsetTop - container.offsetTop;
        }
      } else {
        const offset = (containerRect.height - activeElementRect.height) / 2;
        topPosition = activeElement.offsetTop - container.offsetTop - offset;
      }

      container.scrollTo({
        top: topPosition,
        behavior: "smooth",
      });
    }
  }, [
    currentTime,
    scrollingContainerRef,
    activeCaptionRef,
    isMobile,
    playerHeight,
  ]);

  return (
    <>
      <Meta />
      <PageContainer>
        {!dataLoaded && (
          <LoaderWrapper>
            {showError || fetchError ? (
              <ErrorDisplay />
            ) : (
              <TableLoader message="Laster editor" />
            )}
          </LoaderWrapper>
        )}
        <TopBar
          generateLoading={generateLoading}
          onGenerateVideo={onGenerateVideo}
          progress={progress}
          captions={captions}
          appearance={appearance}
          videoDownloadUrl={videoDownloadUrl}
          setVideoDownloadUrl={setVideoDownloadUrl}
          method={activeMethod}
        />
        <EditorDisplay
          transcript={transcript}
          captions={captions}
          currentTime={currentTime}
          setDataLoaded={setDataLoaded}
          setCurrentTime={setCurrentTime}
          videoRef={videoRef}
          appearance={appearance}
          playerWidth={playerWidth}
          setValue={setValue}
          handleTextChange={handleTextChange}
          fonts={fonts}
          register={register}
          playerHeight={playerHeight}
          dataLoaded={dataLoaded}
          setShowError={setShowError}
          activeCaptionRef={activeCaptionRef}
          scrollingContainerRef={scrollingContainerRef}
          onSave={clickSave}
        />
        <Modal
          open={showSubscriptionModal}
          onClose={closeSubscriptionModal}
          title={t("dashboard.pages.workspace.change-subscription-modal.title")}
        >
          <SubscriptionModalContent onClose={closeSubscriptionModal} />
        </Modal>
      </PageContainer>
    </>
  );
};

export default Content;

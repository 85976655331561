import { Link, useParams } from "react-router-dom";
import Button from "../../library/button";
import { Title } from "../../library/title";
import { getIcon } from "../../utils/get-icon";
import {
  ButtonsContainer,
  Content,
  Description,
  Icon,
  InnerContainer,
  Section,
  ValidateContainer,
} from "./styled";
import TableLoader from "../../library/table-loader";
import { useEffect } from "react";
import useValidate from "../../hooks/useValidate";

const Validate = () => {
  const { id } = useParams();
  const { validatePayment, loading, status } = useValidate();

  useEffect(() => {
    if (id && id !== "error") {
      validatePayment(id);
    }
  }, [id, validatePayment]);

  const determineView = () => {
    if (id === "error") {
      return (
        <ValidateContainer>
          <Icon className="error">{getIcon("error")}</Icon>
          <Title>En feil oppstod</Title>
          <Description>
            Kunne ikke validere betaling, vennligst kontakt oss om du tror dette
            er en feil.
          </Description>
          <ButtonsContainer>
            <Link to="/register">
              <Button color="blue" variant="regular">
                Tilbake til registrering
              </Button>
            </Link>
          </ButtonsContainer>
        </ValidateContainer>
      );
    } else {
      if (status === "success" && !loading) {
        return (
          <ValidateContainer>
            <Icon className="success">{getIcon("success")}</Icon>
            <Title>Betaling fullført</Title>
            <Description>
              Betalingen var vellykket og alt er klargjort slik at du kan ta i
              bruk tjenesten.
            </Description>
            <ButtonsContainer>
              <Link to="/dashboard/transcripts">
                <Button color="blue" variant="regular">
                  Gå til dashboard
                </Button>
              </Link>
            </ButtonsContainer>
          </ValidateContainer>
        );
        //Her har antageligvis Stripe gitt riktig respons, men den thrower bare "Error".
      } else if (status === "error" && !loading) {
        return (
          <ValidateContainer>
            <Icon className="error">{getIcon("error")}</Icon>
            <Title>En feil oppstod</Title>
            <Description>
              Kunne ikke validere betaling, vennligst kontakt oss om du tror
              dette er en feil.
            </Description>
            <ButtonsContainer>
              <Link to="/register">
                <Button color="blue" variant="regular">
                  Tilbake til registrering
                </Button>
              </Link>
            </ButtonsContainer>
          </ValidateContainer>
        );
      }
      return (
        <ValidateContainer>
          <TableLoader className="checkout-loader" />
          <Title>Fullfører registrering</Title>
          <Description>
            Vennligst vent mens vi bekrefter betalingen og setter opp kontoen
            din.
          </Description>
        </ValidateContainer>
      );
    }
  };

  return (
    <Section>
      <InnerContainer>
        <Content>{determineView()}</Content>
      </InnerContainer>
    </Section>
  );
};

export default Validate;

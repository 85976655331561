import { toast, ToastPosition } from "react-toastify";

export const sendSuccessNotification = (title: string) => {
  toast.success(title, {
    position: "bottom-right" as ToastPosition,
    className: "success-popup",
    hideProgressBar: true,
  });
};

export const sendErrorNotification = (title: string) => {
  toast.error(title, {
    position: "bottom-right" as ToastPosition,
    className: "error-popup",
    hideProgressBar: true,
  });
};

import styled from "@emotion/styled";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

export const NavWrapper = styled(Nav)``;

export const NavLink = styled(Link)`
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-decoration: none;
  color: #3b3b3b !important;
  &.aligned-left {
    margin-right: 1.25rem;
  }
  &.aligned-right {
    margin-left: 1.25rem;
    &.flag {
      margin-left: 0.625rem;
    }
  }
  &:hover {
    color: #fff;
  }
`;

export const NavText = styled.span`
  line-height: 3.1rem;
`;

import { Interweave } from "interweave";
import {
  Container,
  Published,
  Section,
  Content as ArticleContent,
  Thumbnail,
  Wrapper,
} from "./styled";
import moment from "moment";
import TableLoader from "../../../library/table-loader";
import { Title } from "../../../library/title";

interface IContentProps {
  content: any;
  loading: boolean;
}

const Content: React.FC<IContentProps> = ({ content, loading }) => {
  const renderView = () => {
    if (loading) {
      return <TableLoader />;
    }
    return (
      <Wrapper>
        <Thumbnail alt={`Randi - ${content?.title}`} src={content?.thumbnail} />
        <Published>
          Publisert {moment.unix(content.timestamp).format("HH:mm DD/MM/YYYY")}
        </Published>
        <Title>{content?.title}</Title>
        <ArticleContent>
          <Interweave
            allowList={["iframe", "div", "p", "a", "h2", "strong", "script"]}
            allowAttributes={true}
            content={content?.content}
          />
        </ArticleContent>
      </Wrapper>
    );
  };

  return (
    <Section>
      <Container>{renderView()}</Container>
    </Section>
  );
};

export default Content;

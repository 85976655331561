import { useTranslation } from "react-i18next";
import {
  ButtonsWrapper,
  NavigationWrapper,
  NavLink,
  NavText,
  NavWrapper,
} from "./styled";
import Button from "../../../library/button";

const MobileView = () => {
  const { t } = useTranslation();
  return (
    <NavWrapper>
      <NavigationWrapper>
        <NavLink
          aria-label={
            t(
              "components.public-navigation.links.frontpage.ariaLabel"
            ) as string
          }
          className={`aligned-left`}
          to="/"
        >
          <NavText>
            {t("components.public-navigation.links.frontpage.title")}
          </NavText>
        </NavLink>
        <NavLink
          aria-label={
            t("components.public-navigation.links.blog.ariaLabel") as string
          }
          className={`aligned-left`}
          to="/blog"
        >
          <NavText>
            {t("components.public-navigation.links.blog.title")}
          </NavText>
        </NavLink>
        <NavLink
          aria-label={
            t("components.public-navigation.links.pricing.ariaLabel") as string
          }
          className={`aligned-left`}
          to="/pricing"
        >
          <NavText>
            {t("components.public-navigation.links.pricing.title")}
          </NavText>
        </NavLink>
        <NavLink
          aria-label={
            t(
              "components.public-navigation.links.contact-us.ariaLabel"
            ) as string
          }
          className={`aligned-left`}
          to="/contact"
        >
          <NavText>
            {t("components.public-navigation.links.contact-us.title")}
          </NavText>
        </NavLink>
      </NavigationWrapper>
      <ButtonsWrapper>
        <NavLink
          aria-label={
            t(
              "components.public-navigation.buttons.sign-in.ariaLabel"
            ) as string
          }
          className={`aligned-right flag`}
          to="/login"
        >
          <Button color="grey" variant="regular">
            {t("components.public-navigation.buttons.sign-in.title")}
          </Button>
        </NavLink>
        <NavLink
          aria-label={
            t(
              "components.public-navigation.buttons.register.ariaLabel"
            ) as string
          }
          className={`aligned-right flag`}
          to="/register"
        >
          <Button color="blue" variant="regular">
            {t("components.public-navigation.buttons.register.title")}
          </Button>
        </NavLink>
      </ButtonsWrapper>
    </NavWrapper>
  );
};

export default MobileView;

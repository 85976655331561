import Footer from "../../components/PublicFooter";
import Navigation from "../../components/PublicNavigation";
import SummaryView from "./summary-view";
import BottomView from "./bottom-view";
import Header from "./header";
import Video from "./video";
import LatestNews from "./latest-news";
import Testimonials from "./testimonials";
import Meta from "./meta";
import SelectionView from "./selection-view";

const Index = () => {
  return (
    <>
      <Meta />
      <Navigation />
      <Header />
      <SummaryView />
      <SelectionView />
      <Video />
      <Testimonials />
      <LatestNews />
      <BottomView />
      <Footer />
    </>
  );
};

export default Index;

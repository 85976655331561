import Footer from "../../components/PublicFooter";
import Navigation from "../../components/PublicNavigation";
import Header from "./header";
import Meta from "./meta";

const Index = () => {
  return (
    <>
      <Meta />
      <Navigation />
      <Header />
      <Footer />
    </>
  );
};

export default Index;

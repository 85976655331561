import { Helmet } from "react-helmet";

const Meta = () => {
  return (
    <Helmet>
      <title>
        Randi - Tale til tekst | transkribering av lyd og video med AI
      </title>
      <meta name="thumbnail" content="https://randi.ai/showcase.jpg" />
      <meta name="robots" content="index, follow" />
      <meta
        name="description"
        content="Randi er et tale til tekst verktøy som tekster lyd- og videofilene dine helt automatisk ved bruk av AI. Gjør presise oversettelser på opp til 50 forskjellige språk."
      />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:url" content="https://randi.ai/" />
      <meta
        name="twitter:title"
        content="Randi - Tale til tekst | transkribering av lyd og video med AI"
      />
      <meta
        name="twitter:description"
        content="Randi er et tale til tekst verktøy som tekster lyd- og videofilene dine helt automatisk ved bruk av AI. Gjør presise oversettelser på opp til 50 forskjellige språk."
      />
      <meta name="twitter:image" content="https://randi.ai/showcase.jpg" />

      <meta property="og:url" content="https://randi.ai/" />
      <meta property="og:type" content="article" />
      <meta
        property="og:title"
        content="Randi - Tale til tekst | transkribering av lyd og video med AI"
      />
      <meta
        property="og:description"
        content="Randi er et tale til tekst verktøy som tekster lyd- og videofilene dine helt automatisk ved bruk av AI. Gjør presise oversettelser på opp til 50 forskjellige språk."
      />
      <meta property="og:image" content="https://randi.ai/showcase.jpg" />
      <link rel="canonical" href="https://randi.ai/" />
    </Helmet>
  );
};

export default Meta;

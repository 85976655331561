import Input from "../../library/input";
import { Label } from "../../library/label";
import { t } from "i18next";
import { Field } from "./styled";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { LoginSchema } from "./schema";

export const FIELDS = [
  {
    renderField: (
      register: UseFormRegister<LoginSchema>,
      errors: FieldErrors
    ) => (
      <Field>
        <Label htmlFor="emailInput">{t("login.form.email.label")}</Label>
        <Input
          variant="regular"
          id="emailInput"
          placeholder={t("login.form.email.placeholder") as string}
          register={register}
          name="email"
          error={errors["email"]}
          ariaLabel={t("login.form.email.ariaLabel") as string}
        />
      </Field>
    ),
  },
  {
    renderField: (
      register: UseFormRegister<LoginSchema>,
      errors: FieldErrors
    ) => (
      <Field>
        <Label htmlFor="passwordInput">{t("login.form.password.label")}</Label>
        <Input
          variant="regular"
          type="password"
          id="passwordInput"
          placeholder={t("login.form.password.placeholder") as string}
          register={register}
          name="password"
          error={errors["password"]}
          ariaLabel={t("login.form.password.ariaLabel") as string}
        />
      </Field>
    ),
  },
];

import Button from "../../../../../../library/button";
import Input from "../../../../../../library/input";
import { Label } from "../../../../../../library/label";
import {
  Field,
  Form,
  ModalButtonsContainer,
  ModalContentContainer,
} from "./styled";
import useInvite from "../../../../../../hooks/useInvite";
import {
  sendErrorNotification,
  sendSuccessNotification,
} from "../../../../../../library/notification";
import AttentionBox from "../../../../../../library/attention-box";
import useMembersList from "../../../../../../hooks/useMembersList";
import useWorkspaceDetails from "../../../../../../hooks/useWorkspaceDetails";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { InviteSchema, schema } from "./schema";

interface IInviteModalProps {
  onClose: any;
}

const InviteModal: React.FC<IInviteModalProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const { sendInvitation } = useInvite();
  const { fetchMembersList } = useMembersList();
  const { fetchWorkspaceDetails } = useWorkspaceDetails();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<InviteSchema>({
    resolver: zodResolver(schema),
    defaultValues: { email: "" },
  });

  const sendInvite = async (data: InviteSchema) => {
    try {
      const invitation = await sendInvitation(data.email);
      if (invitation.error === null) {
        reset();
        onClose();
        fetchMembersList();
        fetchWorkspaceDetails();
        sendSuccessNotification(
          t(
            "dashboard.universal.notifications.workspace.send-invitation.success"
          )
        );
      }
    } catch (e) {
      sendErrorNotification(
        t("dashboard.universal.notifications.workspace.send-invitation.failed")
      );
    }
  };

  return (
    <>
      <AttentionBox
        icon="information"
        type="information"
        title={t("dashboard.pages.workspace.invite-modal.attention-box.title")}
        text={t("dashboard.pages.workspace.invite-modal.attention-box.text")}
        onClick={() => ""}
      />
      <Form onSubmit={handleSubmit(sendInvite)}>
        <ModalContentContainer>
          <Field>
            <Label>
              {t(
                "dashboard.pages.workspace.invite-modal.form-input.title-label"
              )}
            </Label>
            <Input
              variant="regular"
              register={register}
              name="email"
              error={errors["email"]}
              ariaLabel={
                t(
                  "dashboard.pages.workspace.invite-modal.form-input.title-input.ariaLabel"
                )!
              }
              placeholder={
                t(
                  "dashboard.pages.workspace.invite-modal.form-input.title-input.placeholder"
                )!
              }
            />
          </Field>
        </ModalContentContainer>

        <ModalButtonsContainer>
          <Button
            onClick={onClose}
            color="grey"
            spacing="right"
            variant="regular"
            type="button"
            ariaLabel={
              t(
                "dashboard.pages.workspace.invite-modal.buttons.cancel.ariaLabel"
              )!
            }
          >
            {t("dashboard.pages.workspace.invite-modal.buttons.cancel.title")}
          </Button>
          <Button
            type="submit"
            color="blue"
            variant="regular"
            ariaLabel={
              t(
                "dashboard.pages.workspace.invite-modal.buttons.send.ariaLabel"
              )!
            }
          >
            {t("dashboard.pages.workspace.invite-modal.buttons.send.title")}
          </Button>
        </ModalButtonsContainer>
      </Form>
    </>
  );
};

export default InviteModal;

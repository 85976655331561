import { getIcon } from "../../utils/get-icon";
import Button from "../button";
import {
  AttentionBoxWrapper,
  AttentionCol,
  AttentionRow,
  Icon,
  InnerBoxWrapper,
  Text,
  Title,
} from "./styled";

interface IAttentionBoxProps {
  icon: string;
  title: string;
  text: string;
  type: "information" | "fail";
  buttonText?: string;
  onClick?: () => void;
}

const AttentionBox: React.FC<IAttentionBoxProps> = ({
  icon,
  title,
  text,
  buttonText,
  type = "information",
  onClick,
}) => {
  const renderColor = () => {
    if (type === "information") {
      return "blue";
    }
    return "red";
  };

  return (
    <AttentionBoxWrapper>
      <InnerBoxWrapper className={`type-${type}`}>
        <AttentionRow>
          <AttentionCol className="icon my-auto text-center">
            <Icon className={`type-${type}`}>{getIcon(icon)}</Icon>
          </AttentionCol>
          <AttentionCol
            className={`text my-auto ${buttonText ? "" : "no-button"}`}
          >
            <Title className={`type-${type}`}>{title}</Title>
            <Text className={`type-${type}`}>{text}</Text>
          </AttentionCol>
          {buttonText && (
            <AttentionCol className="button my-auto">
              <Button
                onClick={onClick}
                size="md"
                color={renderColor()}
                variant="regular"
              >
                {buttonText}
              </Button>
            </AttentionCol>
          )}
        </AttentionRow>
      </InnerBoxWrapper>
    </AttentionBoxWrapper>
  );
};

export default AttentionBox;

import { getAuth } from "firebase/auth";
import { firebaseApp } from "../firebase";
import { useState } from "react";
import { Font } from "../types";

const useFetchVideoEditorFonts = () => {
  const [fonts, setFonts] = useState<Font[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const auth = getAuth(firebaseApp);

  const fetchVideoEditorFonts = async () => {
    try {
      setLoading(true);
      const token = await auth.currentUser?.getIdToken(true);
      if (token) {
        const request = await fetch(
          `${process.env.REACT_APP_BACKEND_ENDPOINT}/video/appearance/fonts?token=${token}`,
          { method: "GET", headers: { "Content-Type": "application/json" } }
        );
        const response = await request.json();
        if (response.error === null) {
          setLoading(false);
          setFonts(response?.result);
        } else {
          throw response.error;
        }
      }
    } catch (e) {
      setLoading(false);
      throw e;
    }
  };

  return { fetchVideoEditorFonts, loading, fonts };
};

export default useFetchVideoEditorFonts;

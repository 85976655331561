import { Link } from "react-router-dom";
import {
  Content,
  Field,
  ForgotContainer,
  ForgotForm,
  GoBackText,
  InnerContainer,
  Logo,
  LogoContainer,
  Section,
} from "./styled";
import logo from "../../assets/img/logo-2.svg";
import { Title } from "../../library/title";
import { Label } from "../../library/label";
import Input from "../../library/input";
import Button from "../../library/button";
import useResetPassword from "../../hooks/useResetPassword";
import { sendErrorNotification } from "../../library/notification";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ForgotPasswordSchema, schema } from "./schema";
import Meta from "./meta";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const { resetPassword } = useResetPassword();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<ForgotPasswordSchema>({
    resolver: zodResolver(schema),
    defaultValues: { email: "" },
  });

  const performReset = async (data: ForgotPasswordSchema) => {
    try {
      await resetPassword(data.email);
      reset();
    } catch (e) {
      sendErrorNotification("Kunne ikke tilbakestille passord.");
    }
  };

  return (
    <>
      <Meta />
      <Section>
        <InnerContainer>
          <Content>
            <LogoContainer>
              <Link to="/">
                <Logo src={logo} />
              </Link>
            </LogoContainer>
            <ForgotContainer>
              <Title>{t("reset-password.title")}</Title>
              <ForgotForm onSubmit={handleSubmit(performReset)}>
                <Field>
                  <Label htmlFor="emailInput">
                    {t("reset-password.form.email.label")}
                  </Label>
                  <Input
                    variant="regular"
                    placeholder={
                      t("reset-password.form.email.placeholder") as string
                    }
                    register={register}
                    name="email"
                    id="emailInput"
                    error={errors["email"]}
                    ariaLabel={
                      t("reset-password.form.email.ariaLabel") as string
                    }
                  />
                </Field>
                <Button
                  className="login-button"
                  type="submit"
                  variant="regular"
                  color="blue"
                  ariaLabel={
                    t("reset-password.form.reset-button.ariaLabel") as string
                  }
                >
                  {t("reset-password.form.reset-button.title")}
                </Button>
                <GoBackText>
                  {t("reset-password.form.go-back.text")}{" "}
                  <Link
                    to="/login"
                    aria-label={
                      t("reset-password.form.go-back.ariaLabel") as string
                    }
                  >
                    {t("reset-password.form.go-back.link")}
                  </Link>
                  .
                </GoBackText>
              </ForgotForm>
            </ForgotContainer>
          </Content>
        </InnerContainer>
      </Section>
    </>
  );
};

export default ForgotPassword;

import { useCallback, useState } from "react";
import { getAuth } from "firebase/auth";
import { firebaseApp } from "../firebase";
import { getStorage, uploadBytes, ref, getDownloadURL } from "firebase/storage";

const useUploadThumbnail = () => {
  const auth = getAuth(firebaseApp);
  const storage = getStorage(firebaseApp);
  const [loading, setLoading] = useState<boolean>(false);

  const uploadThumbnail = useCallback(
    async (file: any) => {
      try {
        setLoading(true);
        const storageRef = ref(
          storage,
          `users/${auth.currentUser!.uid}/thumbnail`
        );

        const uploadFile = await uploadBytes(storageRef, file);
        if (!uploadFile?.ref) {
          throw new Error("error");
        }
        const thumbnailRef = await getDownloadURL(uploadFile.ref);
        const token = await auth.currentUser?.getIdToken(true);
        const request = await fetch(
          `${process.env.REACT_APP_BACKEND_ENDPOINT}/user/update/thumbnail`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              token: token,
              thumbnailRef: thumbnailRef,
            }),
          }
        );
        const response = await request.json();
        if (response.error === null) {
          return { error: null };
        } else {
          throw response.error;
        }
      } catch (e) {
        throw e;
      }
    },
    [auth, storage]
  );

  return { loading, uploadThumbnail };
};

export default useUploadThumbnail;

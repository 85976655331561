import { useSelector } from "react-redux";
import Button from "../../../../../library/button";
import InputFields from "./input-fields";
import RecordView from "./record-view";
import { ButtonsWrapper } from "./styled";
import UploadView from "./upload-view";
import { IState } from "../../../../../types";
import { useTranslation } from "react-i18next";
import { FieldErrors, UseFormSetValue } from "react-hook-form";
import { UploadTranscriptSchema } from "../schema";
import { Dispatch, SetStateAction } from "react";

interface SourceViewProps {
  selectionType: "file" | "recording" | null;
  setValue: UseFormSetValue<UploadTranscriptSchema>;
  selectedFile: File | undefined;
  errors: FieldErrors;
  isFilePublic: boolean;
  progress: number;
  setStep: Dispatch<SetStateAction<1 | 2 | 3 | 4>>;
}

const SourceView = ({
  selectionType,
  setValue,
  selectedFile,
  errors,
  isFilePublic,
  progress,
  setStep,
}: SourceViewProps) => {
  const { t } = useTranslation();
  const uploading = useSelector(
    (state: IState) => state.transcripts.loadingUpload
  );

  const clickBack = () => {
    setStep(3);
  };

  return (
    <div>
      {selectionType === "recording" ? (
        <RecordView
          setValue={setValue}
          selectedFile={selectedFile}
          progress={progress}
        />
      ) : (
        <UploadView
          setValue={setValue}
          selectedFile={selectedFile as any}
          errors={errors}
          progress={progress}
        />
      )}
      {!uploading && (
        <>
          <InputFields setValue={setValue} isFilePublic={isFilePublic} />
          <ButtonsWrapper>
            <Button
              onClick={clickBack}
              variant="regular"
              color="grey"
              ariaLabel={
                t(
                  "dashboard.pages.transcripts.add-modal.content.source-step.buttons.back.ariaLabel"
                ) as string
              }
            >
              {t(
                "dashboard.pages.transcripts.add-modal.content.source-step.buttons.back.title"
              )}
            </Button>
            <Button
              type="submit"
              variant="regular"
              color="blue"
              disabled={uploading}
              ariaLabel={
                t(
                  "dashboard.pages.transcripts.add-modal.content.source-step.buttons.create.ariaLabel"
                ) as string
              }
            >
              {t(
                "dashboard.pages.transcripts.add-modal.content.source-step.buttons.create.title"
              )}
            </Button>
          </ButtonsWrapper>
        </>
      )}
    </div>
  );
};

export default SourceView;

import { useTranslation } from "react-i18next";
import { getIcon } from "../../utils/get-icon";
import { HelpingIcon, Label, StyledSwitch, SwitchWrapper } from "./styled";
import { Tooltip } from "react-tooltip";

interface ISwitchprops {
  checked: boolean;
  onChange: (value: boolean) => void;
  label?: string;
  helpingText?: string;
  className?: string;
  ariaLabel?: string;
}

const Switch: React.FC<ISwitchprops> = ({
  checked,
  onChange,
  label,
  helpingText,
  className,
  ariaLabel,
}) => {
  const { t } = useTranslation();
  return (
    <SwitchWrapper>
      <StyledSwitch
        checked={checked}
        onChange={onChange}
        onColor="#2f8de4"
        offColor="#f4f4f4"
        height={36}
        width={60}
        uncheckedIcon={false}
        checkedIcon={false}
        aria-label={ariaLabel}
        className={`${className ? className : ""} toggle-${checked}`}
      />
      {label && <Label>{label}</Label>}
      {helpingText && (
        <HelpingIcon
          data-tooltip-id="switchBtnTooltip"
          data-tooltip-content={helpingText}
          aria-label={t("dashboard.universal.switch.help-button.ariaLabel")!}
        >
          {getIcon("information")}
        </HelpingIcon>
      )}
      <Tooltip id="switchBtnTooltip" />
    </SwitchWrapper>
  );
};

export default Switch;

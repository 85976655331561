import styled from "@emotion/styled";

export const TimelineContainer = styled.div<{ isMobile: boolean }>`
  max-height: calc(100vh - 50px - 60px - 54px - 64px);
  width: 100%;
  overflow-y: scroll;
  padding-top: ${({ isMobile }) => (isMobile ? "10px" : "0px")};
  padding-bottom: 47.5px;

  @media (max-width: 975px) {
    overflow-y: scroll;
    overflow-y: unset;
    padding-bottom: 15px;
    max-width: unset;
  }
`;

export const TitleWrapper = styled.div`
  padding: 1rem;
  h2 {
    padding: 0px !important;
    margin: 0px !important;
  }
`;

export const DesktopTopWrapper = styled.div`
  input {
    width: 100%;
  }
`;

export const SearchWrapper = styled.div`
  padding: 0rem 1rem;
  padding-bottom: 1rem;
`;

export const EmptyWrapper = styled.div`
  margin: 0 auto;
  display: block;
  padding: 0px 25px;
`;

import { Caption as CaptionType } from "../../../../../../../types";
import { getIcon } from "../../../../../../../utils/get-icon";
import {
  AddButton,
  BindingIcon,
  CaptionWrapper,
  MenuButton,
  TimestampInput,
  UpperCol,
  UpperRow,
} from "./styled";
import sanitizeHtml from "sanitize-html";
import ContentEditable from "react-contenteditable";
import Dropdown from "../../../../../../../library/dropdown-menu";
import {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { formatTime } from "../../../helpers";
import {
  clickNewCaption,
  convertToFloat,
  formatTimestamp,
  INPUT_TIME_PATTERN,
  INPUT_TIME_PLACEHOLDER,
  isValidTimestamp,
  renderActive,
  selectCaption,
} from "../utils";

type MobileCaptionProps = {
  captions: any;
  caption: CaptionType;
  onTextChange: (key: string, value: string) => void;
  selections: any[];
  handleAdjust: (key: string, method: "delete" | "change") => void;
  currentTime: number;
  clickSeek: (time: number) => void;
  setValue: any;
  captionRef: any;
};

const MobileCaption: ForwardRefRenderFunction<
  HTMLDivElement,
  MobileCaptionProps
> = ({
  captions,
  caption,
  selections,
  onTextChange,
  handleAdjust,
  currentTime,
  clickSeek,
  setValue,
  captionRef,
}) => {
  const [currentText, setCurrentText] = useState(caption?.text || "");

  const start = formatTime(caption?.start);
  const end = formatTime(caption?.end);

  const startInputRef = useRef<HTMLInputElement | null>(null);
  const endInputRef = useRef<HTMLInputElement | null>(null);

  const [duration, setDuration] = useState<{ start: string; end: string }>({
    start: "",
    end: "",
  });

  const handleTimestampChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: "start" | "end"
  ) => {
    const input = e.target.value;
    setDuration({ ...duration, [type]: input });
  };

  const handleTimestampBlur = (key: string, type: "start" | "end") => {
    let similar = false;
    const value = duration[type];
    const formattedValue = formatTimestamp(value);

    if (isValidTimestamp(formattedValue)) {
      const floatValue = convertToFloat(formattedValue);
      setValue(
        "captions",
        captions.map((caption: any) => {
          if (caption.key === key) {
            return { ...caption, [type]: floatValue };
          }
          return caption;
        })
      );

      const comparison = captions.find((c: any) => c.key === key);
      if (comparison[type] === floatValue) {
        similar = true;
      }
    }

    setDuration({ ...duration, [type]: formattedValue });

    if (!similar && type === "end") {
      handleAdjust(key, "change");
    }
  };

  const blurInput = (type: "start" | "end") => {
    if (type === "start") {
      startInputRef.current?.blur();
    } else {
      endInputRef.current?.blur();
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    key: string,
    type: "start" | "end"
  ) => {
    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
      ":",
      ".",
    ];

    if (e.key >= "0" && e.key <= "9") {
      return;
    }

    if (allowedKeys.includes(e.key)) {
      return;
    }

    if (e.key === "Enter") {
      e.preventDefault();
      blurInput(type);
      handleTimestampBlur(key, type);
      return;
    }

    e.preventDefault();
  };

  useEffect(() => {
    if (start || end) {
      setDuration({ start: start, end: end });
    }
  }, [caption, caption.start, caption.end, start, end]);

  const onContentChange = useCallback((evt: any) => {
    setCurrentText(evt.target.value);
  }, []);

  const onContentBlur = (evt: any) => {
    const rawHTML = evt.currentTarget.innerHTML;
    const sanitizedHTML = sanitizeHtml(rawHTML, {
      allowedTags: [],
      allowedAttributes: {},
    });

    const cleanedHTML = sanitizedHTML.replace(/&nbsp;/g, " ");

    const div = document.createElement("div");
    div.innerHTML = cleanedHTML;

    const cleanText = div.textContent || div.innerText || "";

    onTextChange(caption?.key, cleanText);
  };

  const onContentKeyDown = useCallback(
    (evt: React.KeyboardEvent<HTMLDivElement>) => {
      if (evt.key === "Enter") {
        evt.preventDefault();
        (evt.target as HTMLElement).blur();
      }
    },
    []
  );

  return (
    <CaptionWrapper
      ref={renderActive(currentTime, start, end) ? captionRef : null}
      onClick={() => selectCaption(currentTime, start, end, clickSeek)}
      className={renderActive(currentTime, start, end) ? "active" : ""}
    >
      <UpperRow>
        <UpperCol className="left-aligned my-auto">
          <TimestampInput
            type="text"
            placeholder={INPUT_TIME_PLACEHOLDER}
            id="captionStart"
            maxLength={12}
            value={duration.start}
            onChange={(e) => handleTimestampChange(e, "start")}
            onKeyDown={(e) => handleKeyDown(e, caption.key, "start")}
            onBlur={() => handleTimestampBlur(caption.key, "start")}
            pattern={INPUT_TIME_PATTERN}
            ref={startInputRef}
          />

          <BindingIcon>{getIcon("arrow-right")}</BindingIcon>
          <TimestampInput
            type="text"
            placeholder={INPUT_TIME_PLACEHOLDER}
            id="captionEnd"
            value={duration.end}
            maxLength={12}
            onChange={(e) => handleTimestampChange(e, "end")}
            onKeyDown={(e) => handleKeyDown(e, caption.key, "end")}
            onBlur={() => handleTimestampBlur(caption.key, "end")}
            pattern={INPUT_TIME_PATTERN}
            ref={endInputRef}
          />
        </UpperCol>
        <UpperCol className="delete right-aligned">
          <Dropdown
            selections={selections}
            title={"Velg handling"}
            toggle={<MenuButton>{getIcon("caption-menu")}</MenuButton>}
            ariaLabel="Velg handling"
            variant="button"
          />
        </UpperCol>
      </UpperRow>
      <ContentEditable
        onChange={onContentChange}
        contentEditable
        html={currentText}
        onBlur={onContentBlur}
        onKeyDown={onContentKeyDown}
        className={`caption-text ${caption?.text ? "" : "placeholder"}`}
      />
      <AddButton
        onClick={() => clickNewCaption(captions, caption, setValue)}
        className={renderActive(currentTime, start, end) ? "active" : ""}
      >
        {getIcon("plus")}
      </AddButton>
    </CaptionWrapper>
  );
};

export default forwardRef(MobileCaption);

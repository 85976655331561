import { useState } from "react";
import {
  ColorIndicator,
  ColorText,
  ColorWrapper,
  FinishButton,
  FullWrapper,
  InnerColorWrapper,
  MobileSelectorWrapper,
  NotVisibleIndicator,
  SelectorWrapper,
} from "./styled";
import { useDetectClickOutside } from "react-detect-click-outside";
import { ChromePicker, SketchPicker } from "react-color";
import Button from "../button";
import { useMediaQuery } from "react-responsive";
import { MOBILE_VIEW } from "../../variables";
import { getIcon } from "../../utils/get-icon";

interface IColorSelectProps {
  onChange: any;
  color: string;
  onReset: any;
}

const ColorSelect: React.FC<IColorSelectProps> = ({
  onChange,
  color,
  onReset,
}) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_VIEW}px)` });
  const [open, setOpen] = useState<boolean>(false);

  const handleClickOutside = () => {
    setOpen(false);
  };

  const ref = useDetectClickOutside({ onTriggered: handleClickOutside });

  const clickSelect = () => {
    setOpen(!open);
  };

  const clickReset = () => {
    setOpen(false);
    onReset();
  };

  const renderColor = () => {
    if (color) {
      return color?.toUpperCase();
    }
    return "#ffffff";
  };

  const onClickFinish = () => {
    setOpen(false);
  };

  const renderPicker = () => {
    return isMobile ? (
      <MobileSelectorWrapper>
        <ChromePicker
          color={renderColor()}
          onChange={onChange}
          onChangeComplete={onChange}
        />
        <FinishButton onClick={onClickFinish}>{getIcon("done")}</FinishButton>
      </MobileSelectorWrapper>
    ) : (
      <SelectorWrapper>
        <SketchPicker
          color={renderColor()}
          onChange={onChange}
          onChangeComplete={onChange}
        />
      </SelectorWrapper>
    );
  };

  return (
    <FullWrapper ref={ref}>
      <ColorWrapper>
        <InnerColorWrapper onClick={clickSelect}>
          {color ? (
            <ColorIndicator
              style={{ backgroundColor: color ?? "transparent" }}
            />
          ) : (
            <NotVisibleIndicator>{getIcon("not-visible")}</NotVisibleIndicator>
          )}

          <ColorText onClick={clickSelect}>{color ?? "Ingen"}</ColorText>
          <Button
            onClick={clickReset}
            color="transparent"
            variant="icon"
            icon="exit"
          />
        </InnerColorWrapper>
      </ColorWrapper>
      {open && renderPicker()}
    </FullWrapper>
  );
};

export default ColorSelect;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITranscript } from "../../types/transcript";

const initialState: ITranscript = {
  saveLoading: false,
  comparison: null,
  compareLoading: false,
  data: {},
};

const transcriptSlice = createSlice({
  name: "transcript",
  initialState,
  reducers: {
    setActiveTranscript: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
    clearActiveTranscript: (state) => {
      state.data = {};
    },
    setTranscriptUpdate: (state, action: PayloadAction<string>) => {
      state.data = {
        ...state.data,
        lastUpdated: action.payload,
      };
    },
    setSaveLoadingTranscript: (state, action: PayloadAction<boolean>) => {
      state.saveLoading = action.payload;
    },
    setActiveComparison: (state, action: PayloadAction<any>) => {
      state.comparison = action.payload;
    },
    clearActiveComparison: (state) => {
      state.comparison = null;
    },
    setLoadingComparison: (state, action: PayloadAction<boolean>) => {
      state.compareLoading = action.payload;
    },
  },
});

export const {
  setActiveTranscript,
  clearActiveTranscript,
  setTranscriptUpdate,
  setSaveLoadingTranscript,
  setActiveComparison,
  clearActiveComparison,
  setLoadingComparison,
} = transcriptSlice.actions;

export default transcriptSlice.reducer;

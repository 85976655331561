import { useSelector } from "react-redux";
import { firebaseApp } from "../firebase";
import { getAuth } from "firebase/auth";
import { IState } from "../types";

const useDeleteInvite = () => {
  const auth = getAuth(firebaseApp);
  const selectedWorkspace = useSelector(
    (state: IState) => state.user.selectedWorkspace
  );

  const deleteInvite = async (id: string) => {
    try {
      const token = await auth.currentUser?.getIdToken(true);
      const request = await fetch(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/workspace/invitation/delete/${selectedWorkspace.id}/${id}/${token}`,
        { method: "DELETE" }
      );
      const response = await request.json();
      if (response.error === null) {
        return { error: null };
      } else {
        throw response.error;
      }
    } catch (e) {
      throw e;
    }
  };
  return { deleteInvite };
};

export default useDeleteInvite;

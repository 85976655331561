import styled from "@emotion/styled";
import { Col, Row, Accordion } from "react-bootstrap";

export const Section = styled.section`
  background-color: #f7fafc;
`;

export const Container = styled.div`
  max-width: 87.5rem;
  margin: 0 auto;
  padding: 4.6875rem 1.25rem;
  @media (max-width: 767px) {
    padding: 1rem 1.25rem;
  }
`;

export const Wrapper = styled.div`
  margin-top: 1.7625rem;
  margin-bottom: 1.5625rem;
  @media (max-width: 767px) {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
  }
`;

export const SelectionRow = styled(Row)``;

export const SelectionCol = styled(Col)``;

export const ContentWrapper = styled.div`
  @media (min-width: 991px) {
    max-width: 37.5rem;
  }
`;

export const AccordionWrapper = styled(Accordion)`
  margin-top: 20px;
`;

export const AccordionItem = styled(Accordion.Item)`
  border: none;
  padding-left: 0rem !important;
  margin: 0rem !important;
  background: transparent;
  border-bottom: 0.0625rem solid #3b3b3b;
  &:last-of-type {
    border-bottom: none;
  }
  &:not(.collapsed) {
    border-color: #dee2e6 !important;
  }
`;

export const AccordionHeader = styled(Accordion.Header)`
  background: transparent;
  outline: none !important;
  button {
    padding: 1.25rem 0rem;
    background: transparent !important;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    outline: none !important;
    color: grey;
    &:not(.collapsed) {
      color: #2f8de4 !important;
    }
  }
`;

export const AccordionBody = styled(Accordion.Body)`
  padding-left: 0rem !important;
`;

export const IllustrationWrapper = styled.div`
  background-color: #fff;
  width: 100%;
  box-shadow: 0rem 0rem 0.9375rem #e3e3e3;
  border-radius: 0.875rem;
  padding: 15px;
  @media (max-width: 991px) {
    margin-bottom: 25px;
  }
`;

export const VideoWrapper = styled.div`
  position: relative;
`;

export const Video = styled.video`
  width: 100%;
`;

export const VideoBorder = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 10px;
  background-color: #fff;
  z-index: 3;
`;

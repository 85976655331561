import styled from "@emotion/styled";

export const PlayButton = styled.button`
  height: 35px;
  width: 35px;
  border: none;
  background: none;
  font-size: 24px;
  color: #2f8de4 !important;
  z-index: 3;
  position: relative;
`;

export const AudioWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const WaveformWrapper = styled.div`
  width: 100%;
`;

import { useMediaQuery } from "react-responsive";
import { MOBILE_VIEW } from "../../../../../variables";
import {
  ContentWrapper,
  PanelGroupWrapper,
  PanelWrapper,
  PanelResizeHandleWrapper,
  PanelGrip,
} from "./styled";
import { getIcon } from "../../../../../utils/get-icon";
import VideoPlayer from "../video-player";
import Appearance from "../appearance";
import Selections from "../selections";
import Slider from "../slider";
import { Font } from "../../../../../types";
import { updateGoogleFontsLink } from "../helpers";
import Timeline from "../timeline";

interface EditorDisplayProps {
  transcript: any;
  captions: any[];
  currentTime: any;
  videoRef: any;
  appearance: any;
  playerWidth: any;
  setCurrentTime: any;
  setValue: any;
  fonts: Font[];
  register: any;
  playerHeight: number;
  activeCaptionRef: any;
  scrollingContainerRef: any;
  handleTextChange: any;
  setDataLoaded: any;
  dataLoaded: any;
  setShowError: any;
  onSave: () => void;
}

const EditorDisplay = ({
  transcript,
  captions,
  currentTime,
  videoRef,
  appearance,
  playerWidth,
  setCurrentTime,
  setValue,
  fonts,
  register,
  playerHeight,
  activeCaptionRef,
  scrollingContainerRef,
  setDataLoaded,
  dataLoaded,
  setShowError,
  handleTextChange,
  onSave,
}: EditorDisplayProps) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_VIEW}px)` });

  return (
    <div>
      {isMobile ? (
        <>
          <VideoPlayer
            videoUrl={transcript?.videoUrl}
            captions={captions}
            currentTime={currentTime}
            videoRef={videoRef}
            configuration={appearance}
            playerWidth={playerWidth}
            setCurrentTime={setCurrentTime}
          />
          <Selections
            currentTime={currentTime}
            setValue={setValue}
            fonts={fonts}
            register={register}
            appearance={appearance}
            videoRef={videoRef}
            captions={captions}
            playerHeight={playerHeight}
            activeCaptionRef={activeCaptionRef}
            scrollingContainerRef={scrollingContainerRef}
            onSave={onSave}
          />
        </>
      ) : (
        <ContentWrapper>
          <PanelGroupWrapper direction="horizontal" id="group">
            <PanelWrapper
              minSize={20.5}
              maxSize={35}
              defaultSize={30}
              id="left-panel"
            >
              <Timeline
                currentTime={currentTime}
                videoRef={videoRef}
                onTextChange={handleTextChange}
                captions={captions}
                setValue={setValue}
                scrollingContainerRef={scrollingContainerRef}
                activeCaptionRef={activeCaptionRef}
                onSave={onSave}
              />
            </PanelWrapper>
            <PanelResizeHandleWrapper id="resize-handle-left">
              <PanelGrip>{getIcon("grip")}</PanelGrip>
            </PanelResizeHandleWrapper>
            <PanelWrapper id="main-panel" maxSize={50}>
              <VideoPlayer
                videoUrl={transcript?.videoUrl}
                captions={captions}
                currentTime={currentTime}
                videoRef={videoRef}
                configuration={appearance}
                playerWidth={playerWidth}
                setCurrentTime={setCurrentTime}
              />
            </PanelWrapper>
            <PanelResizeHandleWrapper id="resize-handle-right">
              <PanelGrip>{getIcon("grip")}</PanelGrip>
            </PanelResizeHandleWrapper>
            <PanelWrapper
              minSize={20}
              maxSize={25}
              defaultSize={20}
              id="right-panel"
            >
              <Appearance
                fonts={fonts}
                register={register}
                setValue={setValue}
                appearance={appearance}
                updateGoogleFontsLink={updateGoogleFontsLink}
              />
            </PanelWrapper>
          </PanelGroupWrapper>
        </ContentWrapper>
      )}
      <Slider
        audioUrl={transcript?.audioUrl}
        audioUrlMobile={transcript?.audioUrlMobile}
        videoRef={videoRef}
        currentTime={currentTime}
        duration={videoRef?.current?.duration}
        setDataLoaded={setDataLoaded}
        dataLoaded={dataLoaded}
        setShowError={setShowError}
      />
    </div>
  );
};

export default EditorDisplay;

import {
  ContentWrapper,
  HeaderWrapper,
  InnerContainer,
  TextWrapper,
  TitleWrapper,
} from "./styled";
import { Text } from "../../../library/text";
import { Title } from "../../../library/title";

const Header = () => {
  return (
    <HeaderWrapper>
      <InnerContainer>
        <ContentWrapper>
          <TitleWrapper>
            <Title size="xl">Takk for at du meldte deg på!</Title>
          </TitleWrapper>
          <TextWrapper>
            <Text color="dark">
              Du vil nå motta en epost som bekrefter at du er påmeldt, og vil få
              tilsendt alt du trenger av informasjon etter hvert som webinaret
              nærmer seg!
            </Text>
          </TextWrapper>
        </ContentWrapper>
      </InnerContainer>
    </HeaderWrapper>
  );
};

export default Header;

import styled from "@emotion/styled";

export const Container = styled.div`
  max-width: 87.5rem;
  margin: 0 auto;
  padding: 6.25rem 1.25rem;
  @media (max-width: 767px) {
    padding-top: 3.125rem;
  }
`;

export const PageRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: no-wrap;
  gap: 40px;
  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
`;

export const PageCol = styled.div`
  &:first-of-type {
    width: 55%;
  }
  &:last-of-type {
    width: 45%;
  }
  @media (max-width: 900px) {
    &:last-of-type {
      width: 100%;
    }
    &:first-of-type {
      order: 1;
      width: 100%;
    }
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
`;

export const RegisterWrapper = styled.div``;

export const DetailsRow = styled.div`
  display: flex;
  margin: 25px 0px;
  padding: 10px 0px;
  border-bottom: 0.125rem dashed #e1e1e1;
  border-top: 0.125rem dashed #e1e1e1;
  justify-content: space-between;
`;

export const DetailsCol = styled.div`
  padding-left: 0px;
`;

export const DetailsTitle = styled.span`
  display: block;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #2f8de4;
`;

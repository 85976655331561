export const languages = [
  { value: "af", title: "Afrikaans" },
  { value: "ar", title: "Arabisk" },
  { value: "hy", title: "Armensk" },
  { value: "az", title: "Aserbajdsjansk" },
  { value: "be", title: "Hviterussisk" },
  { value: "bs", title: "Bosnisk" },
  { value: "bg", title: "Bulgarsk" },
  { value: "ca", title: "Katalansk" },
  { value: "zh", title: "Kinesisk" },
  { value: "hr", title: "Kroatisk" },
  { value: "cs", title: "Tsjekkisk" },
  { value: "da", title: "Dansk" },
  { value: "nl", title: "Nederlandsk" },
  { value: "en", title: "Engelsk" },
  { value: "et", title: "Estisk" },
  { value: "fi", title: "Finsk" },
  { value: "fr", title: "Fransk" },
  { value: "gl", title: "Galisisk" },
  { value: "de", title: "Tysk" },
  { value: "el", title: "Gresk" },
  { value: "he", title: "Hebraisk" },
  { value: "hi", title: "Hindi" },
  { value: "hu", title: "Ungarsk" },
  { value: "is", title: "Islandsk" },
  { value: "id", title: "Indonesisk" },
  { value: "it", title: "Italiensk" },
  { value: "ja", title: "Japansk" },
  { value: "kn", title: "Kannada" },
  { value: "kk", title: "Kasakhisk" },
  { value: "ko", title: "Koreansk" },
  { value: "lv", title: "Latvisk" },
  { value: "lt", title: "Litauisk" },
  { value: "mk", title: "Makedonsk" },
  { value: "ms", title: "Malayisk" },
  { value: "mr", title: "Marathi" },
  { value: "mi", title: "Maorisk" },
  { value: "ne", title: "Nepalsk" },
  { value: "no", title: "Norsk bokmål" },
  { value: "fa", title: "Persisk" },
  { value: "pl", title: "Polsk" },
  { value: "pt", title: "Portugisisk" },
  { value: "ro", title: "Rumensk" },
  { value: "ru", title: "Russisk" },
  { value: "sr", title: "Serbisk" },
  { value: "sk", title: "Slovak" },
  { value: "sl", title: "Slovensk" },
  { value: "es", title: "Spansk" },
  { value: "sw", title: "Swahili" },
  { value: "sv", title: "Svensk" },
  { value: "tl", title: "Tagalog" },
  { value: "ta", title: "Tamil" },
  { value: "th", title: "Thai" },
  { value: "tr", title: "Tyrkisk" },
  { value: "uk", title: "Ukrainsk" },
  { value: "ur", title: "Urdu" },
  { value: "vi", title: "Vietnamesisk" },
  { value: "cy", title: "Walisisk" },
];

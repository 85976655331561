import Lottie from "lottie-react";
import notFoundAnimation from "../../assets/animations/not-found.json";
import { Title } from "../../library/title";
import Button from "../../library/button";
import {
  AnimationWrapper,
  ButtonsWrapper,
  Container,
  MessageContainer,
  Section,
  TextWrapper,
} from "./styled";
import Navigation from "../../components/PublicNavigation";
import Footer from "../../components/PublicFooter";
import { Link } from "react-router-dom";
import { Text } from "../../library/text";
import { useTranslation } from "react-i18next";
import Meta from "./meta";

const Index = () => {
  const { t } = useTranslation();

  return (
    <>
      <Meta />
      <Section>
        <Navigation background={true} />
        <Container>
          <MessageContainer>
            <AnimationWrapper>
              <Lottie
                aria-label={t("not-found.animation.ariaLabel") as string}
                animationData={notFoundAnimation}
              />
            </AnimationWrapper>
            <Title>{t("not-found.title")}</Title>
            <TextWrapper>
              <Text color="dark">
                {t("not-found.text")}{" "}
                <Link
                  aria-label={t("not-found.link.ariaLabel") as string}
                  to="/contact"
                >
                  {t("not-found.link.title")}.
                </Link>
              </Text>
            </TextWrapper>
            <ButtonsWrapper>
              <Link to="/">
                <Button
                  variant="regular"
                  color="blue"
                  ariaLabel={t("not-found.back-button.title") as string}
                >
                  {t("not-found.back-button.title")}
                </Button>
              </Link>
            </ButtonsWrapper>
          </MessageContainer>
        </Container>
        <Footer />
      </Section>
    </>
  );
};

export default Index;

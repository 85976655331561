import styled from "@emotion/styled";

export const Section = styled.section`
  background-color: #fbfbff;
  height: 100vh;
`;

export const InnerContainer = styled.div``;

export const Content = styled.div`
  display: block;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.625rem;
`;

export const ValidateContainer = styled.div`
  background-color: #fff;
  padding: 1.875rem 2.5rem;
  border-radius: 1.5625rem;
  overflow: hidden;
  display: block;
  position: relative;
  box-shadow: 0rem 0rem 0.625rem #e2e2e2;
  width: 25rem;
  text-align: center;

  .checkout-loader {
    padding-bottom: 0rem;
    padding-top: 0rem;
  }

  input {
    width: 100%;
  }
`;

export const Icon = styled.span`
  font-size: 2.625rem;
  display: block;
  &.success {
    color: #5cd394;
  }
  &.error {
    color: #e84969;
  }
`;

export const Description = styled.span`
  color: grey;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
`;

export const ButtonsContainer = styled.div`
  margin-top: 1.25rem;
`;

import styled from "@emotion/styled";
import { Col, Row } from "react-bootstrap";

export const SelectionWrapper = styled.div`
  margin-top: 1.5625rem;
  margin-bottom: 1.5625rem;
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  @media (max-width: 767px) {
    margin-bottom: 0.625rem;
  }
`;

export const SelectionSubtitle = styled.h2`
  text-align: center;
  font-size: 1.25rem;
  display: block;
  color: #3b3b3b;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
`;

export const BoxesWrapper = styled.div`
  margin-top: 1.25rem;
`;

export const PriceDisplayContainer = styled.div`
  border-radius: 0.5rem;
  border: 0.125rem dashed #e1e1e1;
  padding: 1.875rem 0.625rem;
  text-align: center;
  position: relative;
  margin-bottom: 0.625rem;
  &:last-of-type {
    margin-bottom: 0rem;
  }
  &.active {
    border: 0.125rem dashed #2f8de4;
    background-color: #e5f0f9;
  }
  &:hover {
    cursor: pointer;
  }

  @media (max-width: 767px) {
    padding: 1.5625rem 0.625rem;
  }
`;

export const PriceDisplayRow = styled(Row)``;

export const PriceDetailsCol = styled(Col)`
  &.button-col {
    max-width: 4.375rem;
  }
  &.details-col {
    text-align: left;
  }
  &.price-col {
    text-align: left;
    max-width: 7.5rem;
  }
  @media (max-width: 767px) {
    &.button-col {
      max-width: 4.0625rem;
      padding-right: 0rem;
    }
    &.details-col {
      padding-left: 0rem;
      padding-right: 0rem;
    }
    &.price-col {
      padding-left: 0.625rem;
    }
  }
`;

export const SelectButton = styled.button`
  border-radius: 100%;
  min-height: 1.5625rem;
  min-width: 1.5625rem;
  font-size: 1.375rem;
  padding: 0rem;
  margin: 0rem;
  justify-content: center;
  color: grey;
  border: none;
  background: transparent;
  &.active {
    color: #2f8de4;
  }
`;

export const PlanTitle = styled.span`
  font-family: "Poppins", sans-serif;
  display: block;
  color: #3b3b3b;
  font-size: 1rem;
  font-weight: 600;
`;

export const PlanDescription = styled.span`
  font-family: "Poppins", sans-serif;
  display: block;
  color: grey;
  font-size: 0.875rem;
`;

export const PriceTitle = styled.span`
  font-family: "Poppins", sans-serif;
  color: #3b3b3b;
  font-weight: 600;
  display: block;
`;

export const BillingTitle = styled.span`
  font-family: "Poppins", sans-serif;
  display: block;
  color: grey;
  font-size: 0.875rem;
`;

export const ToggleWrapper = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
`;

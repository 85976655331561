import { z } from "zod";

export const downloadTranscriptSchema = z.object({
  format: z.union([
    z.literal("srt"),
    z.literal("vtt"),
    z.literal("text"),
    z.literal("pdf"),
    z.literal("html"),
    z.literal("video-subtitles"),
    z.literal("docx"),
    z.literal("csv"),
  ]),
});

export type DownloadTranscriptSchema = z.infer<typeof downloadTranscriptSchema>;

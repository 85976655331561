import { useState } from "react";
import Button from "../../../../../../library/button";
import Switch from "../../../../../../library/switch";
import { Text } from "../../../../../../library/text";
import {
  ButtonContainer,
  ButtonsWrapper,
  DescriptionWrapper,
  ToggleContainer,
} from "./styled";

interface DeleteCaptionModalContentProps {
  onClose: () => void;
  captionKey: undefined | string;
  captions: any[] | undefined;
  setValue: any;
}

const DeleteCaptionModalContent = ({
  onClose,
  captionKey,
  captions,
  setValue,
}: DeleteCaptionModalContentProps) => {
  const [isAutoSync, setIsAutoSync] = useState<boolean>(false);

  const clickDelete = () => {
    if (!captionKey || !captions) return;

    const indexToDelete = captions.findIndex(
      (caption) => caption.key === captionKey
    );
    if (indexToDelete === -1) return;

    const deletedCaption = captions[indexToDelete];

    const updatedCaptions = captions
      .filter((_, index) => index !== indexToDelete)
      .map((caption, index) => {
        if (index >= indexToDelete && isAutoSync) {
          const duration = deletedCaption.end - deletedCaption.start;
          return {
            ...caption,
            start: caption.start - duration,
            end: caption.end - duration,
          };
        }
        return caption;
      });

    setValue("captions", updatedCaptions);
    onClose();
  };

  return (
    <>
      <DescriptionWrapper>
        <Text color="dark">
          Er du sikker på at du vil slette denne underteksten? Du kan også velge
          å justere tidskodene for alle påfølgende undertekster automatisk.
        </Text>
      </DescriptionWrapper>
      <ToggleContainer>
        <Switch
          checked={isAutoSync}
          onChange={(value: boolean) => setIsAutoSync(value)}
          ariaLabel="wtf"
          label="Automatisk synkronisering"
          helpingText="Automatisk synkronisering fletter sammen tidskodene for alle påfølgende undertekster slik at neste undertekst starter der denne egentlig skulle starte."
        />
      </ToggleContainer>
      <ButtonContainer>
        <ButtonsWrapper>
          <Button onClick={onClose} variant="regular" color="grey">
            Avbryt
          </Button>
          <Button onClick={clickDelete} variant="regular" color="red">
            Ja, slett den
          </Button>
        </ButtonsWrapper>
      </ButtonContainer>
    </>
  );
};

export default DeleteCaptionModalContent;

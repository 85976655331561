import {
  CardWrapper,
  DetailsWrapper,
  Field,
  FileUploadButton,
  Thumbnail,
  ThumbnailWrapper,
} from "./styled";
import thumbnail from "../../../../../assets/img/thumbnail.svg";
import { Label } from "../../../../../library/label";
import { Title } from "../../../../../library/title";
import Button from "../../../../../library/button";
import { useRef } from "react";
import useUploadThumbnail from "../../../../../hooks/useUploadThumbnail";
import {
  sendErrorNotification,
  sendSuccessNotification,
} from "../../../../../library/notification";
import useUser from "../../../../../hooks/useUser";
import { getAuth } from "firebase/auth";
import { firebaseApp } from "../../../../../firebase";
import { useSelector } from "react-redux";
import { IState } from "../../../../../types";
import { useTranslation } from "react-i18next";

interface IUserCardProps {
  profile: any;
}

const Index: React.FC<IUserCardProps> = ({ profile }) => {
  const { t } = useTranslation();
  const auth = getAuth(firebaseApp);
  const { uploadThumbnail } = useUploadThumbnail();
  const { fetchUserDetails } = useUser();
  const fileInput = useRef<HTMLInputElement>(null);
  const user = useSelector((state: IState) => state.user?.details);

  const renderThumbnail = () => {
    if (user?.thumbnail) {
      return user.thumbnail;
    }
    return thumbnail;
  };

  const handleSelect = async (e: any) => {
    try {
      const file = e.target.files[0];
      const upload = await uploadThumbnail(file);
      if (upload?.error === null) {
        fetchUserDetails(auth.currentUser!.uid);
        sendSuccessNotification("Profilbildet ditt ble oppdatert.");
      } else {
        throw upload?.error;
      }
    } catch (e) {
      sendErrorNotification("Kunne ikke endre profilbilde.");
    }
  };

  const clickUpload = () => {
    fileInput?.current!.click();
  };

  return (
    <CardWrapper>
      <ThumbnailWrapper>
        <Thumbnail
          alt={
            t("dashboard.pages.account.profile-preview.thumbnail.alt") as string
          }
          src={renderThumbnail()}
        />
        <Button
          onClick={clickUpload}
          size="md"
          icon="upload"
          color="grey"
          variant="regular"
          ariaLabel={
            t(
              "dashboard.pages.account.profile-preview.change-picture-button.ariaLabel"
            ) as string
          }
        >
          {t(
            "dashboard.pages.account.profile-preview.change-picture-button.title"
          )}
        </Button>
        <FileUploadButton type="file" onChange={handleSelect} ref={fileInput} />
      </ThumbnailWrapper>
      <DetailsWrapper>
        <Field>
          <Label>
            {t(
              "dashboard.pages.account.profile-preview.details-list.first-name.title"
            )}
          </Label>
          <Title size="xs">{profile?.firstName ?? "Ikke tilgjengelig"}</Title>
        </Field>
        <Field>
          <Label>
            {t(
              "dashboard.pages.account.profile-preview.details-list.last-name.title"
            )}
          </Label>
          <Title size="xs">{profile?.lastName ?? "Ikke tilgjengelig"}</Title>
        </Field>
        <Field>
          <Label>
            {t(
              "dashboard.pages.account.profile-preview.details-list.phone.title"
            )}
          </Label>
          <Title size="xs">{profile?.phone ?? "Ikke tilgjengelig"}</Title>
        </Field>
      </DetailsWrapper>
    </CardWrapper>
  );
};

export default Index;

import { useTranslation } from "react-i18next";
import { getIcon } from "../../../utils/get-icon";
import {
  SelectButton,
  SelectIcon,
  SelectionCol,
  SelectionDescription,
  SelectionPrice,
  SelectionPriceDescription,
  SelectionRow,
  SelectionTitle,
  SelectionsWrapper,
} from "../styled";

type RegularSelectionProps = {
  option: any;
  type: "default" | "pricing";
};

const RegularSelection = ({ option, type }: RegularSelectionProps) => {
  const { t } = useTranslation();

  return (
    <SelectionsWrapper>
      <SelectionRow>
        <SelectionCol className="button-col my-auto">
          {option?.icon ? (
            <SelectIcon>{getIcon(option?.icon)}</SelectIcon>
          ) : (
            <SelectButton>{getIcon("unselected-box")}</SelectButton>
          )}
        </SelectionCol>
        <SelectionCol
          className={`details-col my-auto ${
            option?.description ? "" : "small"
          }`}
        >
          <SelectionTitle>{t(option?.title)}</SelectionTitle>
          {option?.description && (
            <SelectionDescription>
              {t(option?.description)}
            </SelectionDescription>
          )}
        </SelectionCol>
        {type === "pricing" && (
          <SelectionCol className="price-col">
            <SelectionPrice>{option?.price}</SelectionPrice>
            <SelectionPriceDescription>
              {option?.priceDescription}
            </SelectionPriceDescription>
          </SelectionCol>
        )}
      </SelectionRow>
    </SelectionsWrapper>
  );
};

export default RegularSelection;

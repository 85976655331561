import { Title } from "../../../library/title";
import {
  Container,
  MarqueeWrapper,
  Section,
  TitleWrapper,
  Wrapper,
} from "./styled";
import Marquee from "react-fast-marquee";
import TestimonialBox from "./testimonial-box";
import { useTranslation } from "react-i18next";

const TESTIMONIALS = [
  {
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/testimonials%2Froger_kvaloy.svg?alt=media&token=9f6b498b-2400-4457-9d9c-4e82085930a6",
    name: "Roger Kvaløy",
    description: "Kajabi Ekspert",
    content:
      "Randi.ai er en imponerende tale-til-tekst løsning. Jeg er imponert over nøyaktigheten i den genererte teksten og hvor raskt det genereres. Hovedsakelig bruker jeg Randi.ai til å legge på undertekster på mine YouTube-videoer. Det er veldig enkelt å laste opp den genererte STR-formatet fra Randi.ai direkte i YouTube. ",
    link: "https://www.kajabihjelp.no",
    linkTitle: "kajabihjelp.no",
  },
  {
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/testimonials%2Fforeldremestring.svg?alt=media&token=03e9c8e7-d9ce-4813-8a11-9559b94752d4",
    name: "Espen & Nina S. Bryhn",
    description: "Foreldremestring AS",
    content:
      "Randi er et nyttig verktøy som er enkelt å bruke og som sparer oss for masse tid. Vi bruker tjenesten til å transkribere innspillinger, webinarer og spørretimer. I tillegg er det et fint verktøy til å tekste videoer, enten det er til bruk i kurs, mailer eller sosiale medier. Vi anbefaler denne tjenesten da den forenkler hverdagen til de som jobber med digitalt innhold.",
    link: "https://www.foreldremestring.no",
    linkTitle: "foreldremestring.no",
  },
  {
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/testimonials%2Fthumbnail_Bianca%20og%20Espen%201.svg?alt=media&token=ca8b3ad7-fb7d-4870-aade-df3829938589",
    name: "Bianca & Espen Simonsen",
    description: "Gjør en forskjell AS",
    content:
      "Randi er et presist verktøy som minimerer etterarbeid. I tillegg vil vi også løfte opp det vi opplever som super service, med gode tips og ideer til hvordan vi kan få enda mer ut av alt det materialet vi har brukt en årrekke på å produsere. Med Randi føler vi at vi får mer enn det vi betaler for, vi gleder oss til fortsettelsen og kan på det sterkeste anbefale.",
    link: "https://www.gjorenforskjell.no",
    linkTitle: "gjorenforskjell.no",
  },
  {
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/testimonials%2F1700669903525.jpeg?alt=media&token=2c782581-9584-46c9-9a73-785bc63c24ea",
    name: "Christer Aase",
    description: "Aase Media AS",
    content:
      "Randi.ai er eneste løsningen vi har kommet over som automatisk tekster norsk bra - og ikke minst dialekter. Vi sparer mangfoldige timer med manuell teksting hver måned takket være denne tjenesten. Anbefales til de grader!",
    link: "https://www.aasemedia.no",
    linkTitle: "aasemedia.no",
  },
  {
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/testimonials%2Flidenskap-eierskap-thumbnail.svg?alt=media&token=0a1ff100-fec6-4409-bced-7833e884626e",
    name: "Alexander Johannessen",
    description: "AJ Media",
    content:
      "Et fantastisk verktøy som gjør arbeidsdagen til de som driver med podcast og klipping av videoer mye enklere. Vi bruker Randi til å transkribere podcaster og andre lydfiler som vi publiserer på nettsidene våres. Vi har merket en markant økning i trafikk og forbedret SEO samtidig som vi sparer enormt mye tid som vi ellers ville brukt på manuell teksting.",
    link: "https://www.ajmediaco.no",
    linkTitle: "ajmediaco.no",
  },
];

const Index = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <Container>
        <Wrapper>
          <TitleWrapper>
            <Title>{t("frontpage.sections.testimonials-view.title")}</Title>
          </TitleWrapper>
          <MarqueeWrapper>
            <Marquee pauseOnHover pauseOnClick speed={15}>
              {TESTIMONIALS?.map((testimonial, i) => (
                <TestimonialBox
                  key={i}
                  thumbnail={testimonial?.thumbnail}
                  name={testimonial?.name}
                  description={testimonial?.description}
                  content={testimonial?.content}
                  link={testimonial?.link}
                  linkTitle={testimonial?.linkTitle}
                />
              ))}
            </Marquee>
          </MarqueeWrapper>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default Index;

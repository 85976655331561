import styled from "@emotion/styled";
import { Accordion } from "react-bootstrap";

export const FaqWrapper = styled.div`
  background-color: #f4f4f4;
  padding: 2.1875rem;
  border-radius: 1.5rem;
  label {
    font-size: 1.375rem !important;
  }
`;

export const AccordionWrapper = styled.div`
  margin-top: 1.5625rem;
`;

export const QuestionsAccordion = styled(Accordion)``;

import styled from "@emotion/styled";

export const Section = styled.section`
  background-color: #fbfbff;
  height: 100vh;
  @media (max-width: 767px) {
    background-color: #fff;
  }
`;

export const InnerContainer = styled.div`
  padding: 1.5625rem;
`;

export const LogoContainer = styled.div``;

export const Logo = styled.img`
  max-width: 10.9375rem;
  margin-bottom: 2.5rem;
`;

export const Content = styled.div`
  text-align: center;
  display: block;
  margin: 0 auto;
  width: 100%;
  padding-top: 6.25rem;
  max-width: 28.125rem;
  @media (max-width: 767px) {
    padding-top: 0.625rem;
  }
`;

export const ForgotContainer = styled.div`
  background-color: #fff;
  padding: 1.875rem 2.5rem;
  border-radius: 1.5625rem;
  overflow: hidden;
  display: block;
  position: relative;
  box-shadow: 0rem 0rem 0.625rem #e2e2e2;
  @media (max-width: 767px) {
    box-shadow: none;
    padding: 0.625rem 0.625rem;
  }
`;

export const ForgotForm = styled.form`
  margin-top: 1.5625rem;
  text-align: left;
  input {
    width: 100%;
  }
  button {
    display: block;
    width: 100%;
    margin-bottom: 0.7813rem;
    &.login-button {
      margin-top: 1.5625rem;
    }
  }
`;

export const Field = styled.div`
  margin-bottom: 0.9375rem;
  input {
    margin-top: 0.3125rem;
  }
`;

export const GoBackText = styled.span`
  font-family: "Poppins";
  font-size: 0.875rem;
  text-align: center;
  margin: 0 auto;
  display: block;
  margin-top: 1.25rem;
  color: grey;
  a {
    color: #2f8de4 !important;
  }
`;

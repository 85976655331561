import { Caption as CaptionType } from "../../../../../../../types";
import { getIcon } from "../../../../../../../utils/get-icon";
import Dropdown from "../../../../../../../library/dropdown-menu";
import {
  AddButton,
  BindingIcon,
  CaptionWrapper,
  InnerCaptionWrapper,
  MenuButton,
  TimestampInput,
  UpperCol,
  UpperRow,
} from "./styled";
import ContentEditable from "react-contenteditable";
import sanitizeHtml from "sanitize-html";
import {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { formatTime } from "../../../helpers";
import {
  clickNewCaption,
  convertToFloat,
  formatTimestamp,
  INPUT_TIME_PATTERN,
  INPUT_TIME_PLACEHOLDER,
  isValidTimestamp,
  renderActive,
  selectCaption,
} from "../utils";

type DesktopCaptionProps = {
  captions: any;
  caption: CaptionType;
  captionRef: any;
  selections: any[];
  handleAdjust: (key: string, method: "delete" | "change") => void;
  onTextChange: (key: string, value: string) => void;
  currentTime: number;
  clickSeek: (time: number) => void;
  setValue: any;
};

const DesktopCaption: ForwardRefRenderFunction<
  HTMLDivElement,
  DesktopCaptionProps
> = ({
  captions,
  caption,
  captionRef,
  selections,
  onTextChange,
  handleAdjust,
  currentTime,
  clickSeek,
  setValue,
}) => {
  const [currentText, setCurrentText] = useState(caption?.text || "");
  const startInputRef = useRef<HTMLInputElement | null>(null);
  const endInputRef = useRef<HTMLInputElement | null>(null);

  const start = formatTime(caption?.start);
  const end = formatTime(caption?.end);

  const [duration, setDuration] = useState<{ start: string; end: string }>({
    start: "",
    end: "",
  });

  const handleTimestampChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: "start" | "end"
  ) => {
    const input = e.target.value;
    setDuration({ ...duration, [type]: input });
  };

  const handleTimestampBlur = (key: string, type: "start" | "end") => {
    let similar = false;
    const value = duration[type];
    const formattedValue = formatTimestamp(value);

    if (isValidTimestamp(formattedValue)) {
      const floatValue = convertToFloat(formattedValue);
      setValue(
        "captions",
        captions.map((caption: any) => {
          if (caption.key === key) {
            return { ...caption, [type]: floatValue };
          }
          return caption;
        })
      );

      const comparison = captions.find((c: any) => c.key === key);
      if (comparison[type] === floatValue) {
        similar = true;
      }
    }

    setDuration({ ...duration, [type]: formattedValue });

    if (!similar && type === "end") {
      handleAdjust(key, "change");
    }
  };

  useEffect(() => {
    if (start || end) {
      setDuration({ start: start, end: end });
    }
  }, [caption, caption.start, caption.end, start, end]);

  useEffect(() => {
    setCurrentText(caption?.text || "");
  }, [caption?.text]);

  const blurInput = (type: "start" | "end") => {
    if (type === "start") {
      startInputRef.current?.blur();
    } else {
      endInputRef.current?.blur();
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    key: string,
    type: "start" | "end"
  ) => {
    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
      ":",
      ".",
    ];

    if (e.key >= "0" && e.key <= "9") {
      return;
    }

    if (allowedKeys.includes(e.key)) {
      return;
    }

    if (e.key === "Enter") {
      e.preventDefault();
      blurInput(type);
      handleTimestampBlur(key, type);
      return;
    }

    e.preventDefault();
  };

  const onContentChange = useCallback((evt: any) => {
    setCurrentText(evt.target.value);
  }, []);

  const onContentKeyDown = useCallback(
    (evt: React.KeyboardEvent<HTMLDivElement>) => {
      if (evt.key === "Enter") {
        evt.preventDefault();
        (evt.target as HTMLElement).blur();
      }
    },
    []
  );

  const onContentBlur = (evt: any) => {
    const rawHTML = evt.currentTarget.innerHTML;
    const sanitizedHTML = sanitizeHtml(rawHTML, {
      allowedTags: [],
      allowedAttributes: {},
    });

    const cleanedHTML = sanitizedHTML.replace(/&nbsp;/g, " ");

    const div = document.createElement("div");
    div.innerHTML = cleanedHTML;

    const cleanText = div.textContent || div.innerText || "";

    onTextChange(caption?.key, cleanText);
  };

  return (
    <CaptionWrapper
      ref={renderActive(currentTime, start, end) ? captionRef : null}
      onClick={() => selectCaption(currentTime, start, end, clickSeek)}
    >
      <InnerCaptionWrapper
        className={renderActive(currentTime, start, end) ? "active" : ""}
      >
        <UpperRow>
          <UpperCol className="left-aligned my-auto">
            <TimestampInput
              type="text"
              placeholder={INPUT_TIME_PLACEHOLDER}
              id="captionStart"
              maxLength={12}
              value={duration.start}
              onChange={(e) => handleTimestampChange(e, "start")}
              onKeyDown={(e) => handleKeyDown(e, caption.key, "start")}
              onBlur={() => handleTimestampBlur(caption.key, "start")}
              pattern={INPUT_TIME_PATTERN}
              ref={startInputRef}
            />

            <BindingIcon className="bind-icon">
              {getIcon("arrow-right")}
            </BindingIcon>
            <TimestampInput
              type="text"
              placeholder={INPUT_TIME_PLACEHOLDER}
              id="captionEnd"
              value={duration.end}
              maxLength={12}
              onChange={(e) => handleTimestampChange(e, "end")}
              onKeyDown={(e) => handleKeyDown(e, caption.key, "end")}
              onBlur={() => handleTimestampBlur(caption.key, "end")}
              pattern={INPUT_TIME_PATTERN}
              ref={endInputRef}
            />
          </UpperCol>
          <UpperCol className="delete right-aligned">
            <Dropdown
              selections={selections}
              title={"Velg handling"}
              toggle={<MenuButton>{getIcon("caption-menu")}</MenuButton>}
              ariaLabel="Velg handling"
              variant="button"
            />
          </UpperCol>
        </UpperRow>
        <ContentEditable
          onChange={onContentChange}
          contentEditable
          html={currentText}
          onBlur={onContentBlur}
          onKeyDown={onContentKeyDown}
          className={`caption-text ${currentText ? "" : "placeholder"}`}
        />
        <AddButton
          className="add-button"
          onClick={() => clickNewCaption(captions, caption, setValue)}
        >
          {getIcon("plus")}
        </AddButton>
      </InnerCaptionWrapper>
    </CaptionWrapper>
  );
};

export default forwardRef(DesktopCaption);

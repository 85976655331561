import { UseFormSetValue } from "react-hook-form";
import ToggleButtons from "../../../../../../library/toggle-buttons";
import { getIcon } from "../../../../../../utils/get-icon";
import {
  AccordionTitle,
  Field,
  InnerAccordionHeader,
  InputCol,
  InputRow,
} from "../styled";
import { AccordionBody, AccordionHeader, AccordionItem } from "../styled";
import { VideoAppearanceSchema } from "../../schema";
import { VideoAppearance } from "../../../../../../types";

interface IPositionSettingsProps {
  isMobile: boolean;
  appearance: VideoAppearance;
  setValue: UseFormSetValue<VideoAppearanceSchema>;
  keyRef: string;
}

const PositionSettings: React.FC<IPositionSettingsProps> = ({
  isMobile,
  appearance,
  setValue,
  keyRef,
}) => {
  const handleHorizontalSelection = (
    alignment: "left" | "center" | "right"
  ) => {
    const updatedAppearance = {
      ...appearance,
      alignment: {
        ...appearance.alignment,
        horizontal: alignment,
      },
    };
    setValue("appearance", updatedAppearance); // Update the entire appearance object
  };

  const handleVerticalSelection = (alignment: "top" | "center" | "bottom") => {
    const updatedAppearance = {
      ...appearance,
      alignment: {
        ...appearance.alignment,
        vertical: alignment,
      },
    };
    setValue("appearance", updatedAppearance);
  };

  return (
    <AccordionItem key={keyRef} eventKey={keyRef}>
      <AccordionHeader>
        <InnerAccordionHeader>
          <AccordionTitle>{getIcon("")} Posisjonering</AccordionTitle>
        </InnerAccordionHeader>
      </AccordionHeader>
      <AccordionBody>
        <Field isMobile={isMobile}>
          <InputRow>
            <InputCol md={12}>
              <ToggleButtons
                selected={appearance?.alignment?.horizontal}
                className="toggle-buttons"
                options={[
                  {
                    title: getIcon("align-horizontal-left"),
                    value: "left",
                  },
                  {
                    title: getIcon("align-horizontal-center"),
                    value: "center",
                  },
                  {
                    title: getIcon("align-horizontal-right"),
                    value: "right",
                  },
                ]}
                onSelect={handleHorizontalSelection}
              />
            </InputCol>
            <InputCol md={12}>
              <ToggleButtons
                selected={appearance?.alignment?.vertical}
                className="toggle-buttons"
                options={[
                  {
                    title: getIcon("align-vertical-top"),
                    value: "top",
                  },
                  {
                    title: getIcon("align-vertical-center"),
                    value: "center",
                  },
                  {
                    title: getIcon("align-vertical-bottom"),
                    value: "bottom",
                  },
                ]}
                onSelect={handleVerticalSelection}
              />
            </InputCol>
          </InputRow>
        </Field>
      </AccordionBody>
    </AccordionItem>
  );
};

export default PositionSettings;

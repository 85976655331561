import { z } from "zod";

export const videoAppearanceSchema = z.object({
  captions: z.any().array(),
  appearance: z.object({
    alignment: z.object({
      horizontal: z.string(),
      vertical: z.string(),
    }),
    fontFamily: z.string(),
    fontStyle: z.string(),
    fontSize: z.string(),
    color: z.string(),
    backgroundColor: z.string().nullable(),
    decoration: z.string(),
  }),
});

export type VideoAppearanceSchema = z.infer<typeof videoAppearanceSchema>;

import { useCallback } from "react";
import { saveAs } from "file-saver";
import { sendErrorNotification } from "../library/notification";

const useDownloadVideoFile = () => {
  const fetchWithProgress = (
    url: string,
    onProgress: (progress: number) => void
  ): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";

      xhr.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          onProgress(percentComplete);
        }
      };

      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(xhr.response);
        } else {
          reject(new Error(`Failed to fetch: ${xhr.statusText}`));
        }
      };

      xhr.onerror = () => {
        reject(new Error("Network error"));
      };

      xhr.send();
    });
  };

  const downloadVideoFile = useCallback(
    async (
      url: string | undefined,
      fileName: string,
      onMobileProgress?: (progress: number) => void,
      onMobileFinished?: () => void,
      onClose?: () => void
    ) => {
      if (!url) return;

      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

      try {
        if (isMobile && navigator.share) {
          // Immediately trigger download progress feedback for mobile devices
          const response = await fetchWithProgress(url, (progress) => {
            if (onMobileProgress) onMobileProgress(progress);
            if (progress === 100) {
              onMobileFinished && onMobileFinished();
            }
          });

          const blob = response;
          const file = new File([blob], `${fileName}.mp4`, {
            type: "video/mp4",
          });

          try {
            await navigator.share({
              files: [file],
              title: "Last ned video",
            });
          } catch (e) {
            sendErrorNotification("Kunne ikke laste ned video.");
          }
        } else {
          // For desktop, just download the file using saveAs
          saveAs(url, `${fileName}.mp4`);
        }

        if (onClose) onClose();
      } catch (error) {
        console.error("Error sharing or downloading the file", error);
        throw error;
      }
    },
    []
  );

  return { downloadVideoFile };
};

export default useDownloadVideoFile;

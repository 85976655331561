import { Title } from "../../../library/title";
import { Text } from "../../../library/text";
import {
  ButtonWrapper,
  Container,
  DetailsCol,
  InformationRow,
  Section,
  SubTextWrapper,
  TextWrapper,
  Thumbnail,
  ThumbnailCol,
  Wrapper,
} from "./styled";
import thumbnailOne from "../../../assets/img/summary-thumbnail-one.jpg";
import thumbnailTwo from "../../../assets/img/summary-thumbnail-two.jpg";
import thumbnailThree from "../../../assets/img/summary-thumbnail-three.jpg";
import thumbnailFour from "../../../assets/img/summary-thumbnail-four.jpg";
import { useTranslation } from "react-i18next";
import Button from "../../../library/button";
import { Link } from "react-router-dom";

const Index = () => {
  const { t } = useTranslation();
  const SECTIONS = [
    {
      id: 0,
      title: t("frontpage.sections.summary-view.first-summary.title"),
      text: t("frontpage.sections.summary-view.first-summary.text"),
      thumbnail: thumbnailOne,
      thumbnailAlt: t(
        "frontpage.sections.summary-view.first-summary.thumbnail.alt"
      ),
      button: t("frontpage.sections.summary-view.first-summary.button"),
      linkAriaLabel: t(
        "frontpage.sections.summary-view.first-summary.link.ariaLabel"
      ),
    },
    {
      id: 1,
      title: t("frontpage.sections.summary-view.second-summary.title"),
      text: t("frontpage.sections.summary-view.second-summary.text"),
      thumbnail: thumbnailTwo,
      thumbnailAlt: t(
        "frontpage.sections.summary-view.second-summary.thumbnail.alt"
      ),
      button: t("frontpage.sections.summary-view.second-summary.button"),
      linkAriaLabel: t(
        "frontpage.sections.summary-view.second-summary.link.ariaLabel"
      ),
    },
    {
      id: 2,
      title: t("frontpage.sections.summary-view.third-summary.title"),
      text: t("frontpage.sections.summary-view.third-summary.text"),
      thumbnail: thumbnailThree,
      thumbnailAlt: t(
        "frontpage.sections.summary-view.third-summary.thumbnail.alt"
      ),
      button: t("frontpage.sections.summary-view.third-summary.button"),
      linkAriaLabel: t(
        "frontpage.sections.summary-view.third-summary.link.ariaLabel"
      ),
    },
    {
      id: 3,
      title: t("frontpage.sections.summary-view.fourth-summary.title"),
      text: t("frontpage.sections.summary-view.fourth-summary.text"),
      thumbnail: thumbnailFour,
      thumbnailAlt: t(
        "frontpage.sections.summary-view.fourth-summary.thumbnail.alt"
      ),
      button: t("frontpage.sections.summary-view.fourth-summary.button"),
      linkAriaLabel: t(
        "frontpage.sections.summary-view.fourth-summary.link.ariaLabel"
      ),
    },
  ];

  return (
    <Section>
      <Container>
        {SECTIONS.map((section, index) => (
          <Wrapper>
            {index % 2 === 1 ? (
              <InformationRow>
                <DetailsCol
                  xs={{ span: 12, order: 2 }}
                  sm={{ span: 12, order: 2 }}
                  lg={{ span: 6, order: 1 }}
                  className="my-auto"
                >
                  <TextWrapper>
                    <Title>{section.title}</Title>
                    <SubTextWrapper>
                      <Text color="dark">{section.text}</Text>
                    </SubTextWrapper>
                    <ButtonWrapper>
                      <Link aria-label={section.linkAriaLabel} to="/register">
                        <Button variant="regular" color="blue">
                          {section.button}
                        </Button>
                      </Link>
                    </ButtonWrapper>
                  </TextWrapper>
                </DetailsCol>
                <ThumbnailCol
                  xs={{ span: 12, order: 1 }}
                  sm={{ span: 12, order: 1 }}
                  md={{ span: 6, order: 1 }}
                  lg={{ span: 6, order: 2 }}
                >
                  <Thumbnail
                    alt={section.thumbnailAlt}
                    src={section.thumbnail}
                  />
                </ThumbnailCol>
              </InformationRow>
            ) : (
              <InformationRow>
                <ThumbnailCol md={{ span: 6, order: 1 }}>
                  <Thumbnail
                    alt={section.thumbnailAlt}
                    src={section.thumbnail}
                  />
                </ThumbnailCol>
                <DetailsCol md={{ span: 6, order: 2 }} className="my-auto">
                  <TextWrapper>
                    <Title>{section.title}</Title>
                    <SubTextWrapper>
                      <Text color="dark">{section.text}</Text>
                    </SubTextWrapper>
                    <ButtonWrapper>
                      <Link aria-label={section.linkAriaLabel} to="/register">
                        <Button variant="regular" color="blue">
                          {section.button}
                        </Button>
                      </Link>
                    </ButtonWrapper>
                  </TextWrapper>
                </DetailsCol>
              </InformationRow>
            )}
          </Wrapper>
        ))}
      </Container>
    </Section>
  );
};

export default Index;

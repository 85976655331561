import { useEffect, useState } from "react";
import { SelectionRow, SelectionsWrapper, SingleSelection } from "./styled";
import RegularSelection from "./regular-selection";
import AudioSelection from "./audio-selection";
import FileSelection from "./file-selection";
import VideoSelection from "./video-selection";
import FormatSelection from "./format-selection";
import { useSelector } from "react-redux";
import { IState } from "../../types";

type Option = {
  id: string;
  title: string;
  description?: string;
  icon?: string | null;
  value: string;
  price?: string;
  voice?: string;
  priceDescription?: string;
};

interface ISelectionProps {
  options: Option[];
  onSelect: (value: any) => void;
  type: "video" | "file" | "default" | "pricing" | "audio" | "format";
  defaultSelected?: string | null;
}

const Selections: React.FC<ISelectionProps> = ({
  options,
  onSelect,
  type,
  defaultSelected,
}) => {
  const [selected, setSelected] = useState<string>();
  const subscription = useSelector(
    (state: IState) => state.user.selectedWorkspace?.subscription
  );

  const isPayingCustomer = () => {
    switch (subscription) {
      case "go-monthly":
        return true;
      case "go-annually":
        return true;
      default:
        return false;
    }
  };

  const handleSelect = (option: any) => {
    if (option?.paid && !isPayingCustomer()) return;
    onSelect(option);
    setSelected(option.id);
  };

  const renderType = (type: any, option: any, i: number) => {
    switch (type) {
      case "file": {
        return <FileSelection option={option} />;
      }
      case "audio": {
        return <AudioSelection option={option} index={i} />;
      }
      case "video": {
        return <VideoSelection option={option} />;
      }
      case "format": {
        return <FormatSelection option={option} />;
      }
      default: {
        return <RegularSelection option={option} type={type} />;
      }
    }
  };

  useEffect(() => {
    if (defaultSelected && !selected) {
      setSelected(defaultSelected);
    }
  }, [defaultSelected, selected, setSelected]);

  return (
    <SelectionsWrapper className={`variant-${type}`}>
      {options.map((option, i) => {
        return (
          <SingleSelection
            className={`${selected === option.id ? "active" : ""}`}
            onClick={() => handleSelect(option)}
          >
            <SelectionRow>{renderType(type, option, i)}</SelectionRow>
          </SingleSelection>
        );
      })}
    </SelectionsWrapper>
  );
};

export default Selections;

import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { IState } from "../types";
import { getAuth } from "firebase/auth";
import { firebaseApp } from "../firebase";
import { sendErrorNotification } from "../library/notification";

const useMonitorVideoGeneration = () => {
  const auth = getAuth(firebaseApp);
  const [isPolling, setIsPolling] = useState<boolean>(false);
  const selectedWorkspace = useSelector(
    (state: IState) => state.user.selectedWorkspace
  );

  const getDownloadUrl = useCallback(
    async (transcriptId: string) => {
      try {
        const token = await auth.currentUser?.getIdToken(true);
        if (token) {
          const request = await fetch(
            `${process.env.REACT_APP_BACKEND_ENDPOINT}/video/get-url?transcriptId=${transcriptId}&workspaceId=${selectedWorkspace?.id}&token=${token}`,
            {
              method: "GET",
              headers: { "Content-Type": "application/json" },
            }
          );
          const response = await request.json();
          if (response.error === null) {
            return { error: null, downloadUrl: response.downloadUrl };
          } else {
            throw response.error;
          }
        }
      } catch (e) {
        sendErrorNotification("Kunne ikke hente nedlastingslenke.");
      }
    },
    [auth.currentUser, selectedWorkspace?.id]
  );

  const monitorVideoGeneration = useCallback(
    async (transcriptId: string) => {
      try {
        const token = await auth.currentUser?.getIdToken(true);
        const request = await fetch(
          `${process.env.REACT_APP_BACKEND_ENDPOINT}/video-generation/status?workspaceId=${selectedWorkspace.id}&transcriptId=${transcriptId}&token=${token}`,
          { method: "GET" }
        );
        const response = await request.json();

        if (response.error === null) {
          if (response?.downloadRef) {
            const video = await getDownloadUrl(transcriptId);
            if (video?.error === null) {
              return {
                downloadUrl: video?.downloadUrl,
                progress: response?.progress,
              };
            } else {
              return { downloadUrl: null, progress: response?.progress };
            }
          }
        } else {
          throw response.error;
        }

        return { downloadUrl: null, progress: response?.progress };
      } catch (e) {
        setIsPolling(false);
        throw e;
      }
    },
    [auth.currentUser, selectedWorkspace?.id, getDownloadUrl]
  );

  return {
    monitorVideoGeneration,
    isPolling,
    setIsPolling,
  };
};

export default useMonitorVideoGeneration;

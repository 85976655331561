import styled from "@emotion/styled";

export const NotSelectedWrapper = styled.div`
  border: 0.125rem dashed rgb(47, 141, 228);
  background-color: rgb(229, 240, 249);
  border-radius: 10px;
  padding: 15px;
  height: 100%;
  display: flex;
  align-items: center;
  max-height: calc(100vh - 89.94px - 95px - 80px - 20px);
`;

export const Icon = styled.span`
  display: block;
  font-size: 34px;
  margin-bottom: 15px;
  color: #2f8de4 !important;
  &.loading {
    font-size: 30px;
  }
`;

export const ContentWrapper = styled.div`
  display: block;
  text-align: center;
  max-width: 250px;
  margin: 0 auto;
`;

export const InnerContentWrapper = styled.div`
  display: block;
`;

import styled from "@emotion/styled";

export const SearchContainer = styled.div`
  margin-top: 1.875rem;
  input {
    width: 100%;
    height: 3.125rem;
  }
`;

export const ListWrapper = styled.div<{
  descriptionHeight?: number;
  attentionBoxHeight?: number;
}>`
  display: block;
  position: relative;
  overflow-y: scroll;
  max-height: 45svh;
  padding-bottom: 70px;
  margin-top: 25px;
  @media (max-width: 975px) {
    max-height: calc(
      100svh - ${({ descriptionHeight }) => descriptionHeight}px -
        ${({ attentionBoxHeight }) => attentionBoxHeight}px - 270px
    );
    padding-bottom: 0px;
  }
`;

export const AttentionBoxWrapper = styled.div`
  margin-bottom: 25px;
`;

import {
  Name,
  NavWrapper,
  Thumbnail,
  NavigationWrapper,
  NavLink,
  NavButton,
} from "./styled";
import thumbnail from "../../../assets/img/thumbnail.svg";
import { useTranslation } from "react-i18next";

interface MobileViewProps {
  checkActive: (path: string) => boolean;
  selectedWorkspace: any;
  onSignout: () => void;
  renderLanguage: () => JSX.Element;
  languageItems: any[];
  loading: boolean;
  details: any;
}

const MobileView = ({
  checkActive,
  selectedWorkspace,
  onSignout,
  loading,
  details,
}: MobileViewProps) => {
  const { t } = useTranslation();

  const renderThumbnail = () => {
    if (details?.thumbnail) {
      return details.thumbnail;
    }
    return thumbnail;
  };

  return (
    <NavWrapper>
      <Thumbnail src={renderThumbnail()} />
      {loading ? null : (
        <Name>{`${details?.firstName} ${details?.lastName}`}</Name>
      )}
      <NavigationWrapper>
        <NavLink
          aria-label={
            t("dashboard.top-navigation.links.transcripts.ariaLabel") as string
          }
          className={`aligned-left ${
            checkActive("/dashboard/transcripts") ? "active" : ""
          }`}
          to="/dashboard/transcripts"
        >
          {t("dashboard.top-navigation.links.transcripts.title")}
        </NavLink>
        <NavLink
          aria-label={
            t("dashboard.top-navigation.links.soundtracks.ariaLabel") as string
          }
          className={`aligned-left ${
            checkActive("/dashboard/soundtracks") ? "active" : ""
          }`}
          to="/dashboard/soundtracks"
        >
          {t("dashboard.top-navigation.links.soundtracks.title")}
        </NavLink>
        {selectedWorkspace?.role === "administrator" && (
          <NavLink
            aria-label={
              t("dashboard.top-navigation.links.workspace.ariaLabel") as string
            }
            className={`aligned-left ${
              checkActive("/dashboard/workspace") ? "active" : ""
            }`}
            to="/dashboard/workspace"
          >
            {t("dashboard.top-navigation.links.workspace.title")}
          </NavLink>
        )}
        {details?.role === "administrator" && (
          <NavLink
            aria-label={`Link blogginnlegg`}
            className={`aligned-left ${
              checkActive("/dashboard/admin/blogposts") ? "active" : ""
            }`}
            to="/dashboard/admin/blogposts"
          >
            Blogginnlegg
          </NavLink>
        )}
        <NavLink
          aria-label={
            t(
              "dashboard.top-navigation.user-dropdown.links.account-settings.ariaLabel"
            ) as string
          }
          to="/dashboard/account"
        >
          {t(
            "dashboard.top-navigation.user-dropdown.links.account-settings.title"
          )}
        </NavLink>
        <NavButton onClick={onSignout}>
          {t("dashboard.top-navigation.user-dropdown.links.sign-out.title")}
        </NavButton>
      </NavigationWrapper>
    </NavWrapper>
  );
};

export default MobileView;

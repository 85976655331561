import styled from "@emotion/styled";

export const PlayerWrapper = styled.div<{
  currentHeight?: number;
  collapsed: boolean;
}>`
  position: relative;
  width: 100%;
  z-index: 1;
  overflow: hidden;
  transition: height 0.5s ease, opacity 0.5s ease;
  height: ${({ collapsed, currentHeight }) =>
    collapsed ? `${0}px` : `${currentHeight}px`};
`;

export const BadgeWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 3;
`;

export const VideoWrapper = styled.div`
  position: relative;
  display: block;
  background-color: black;
  border-radius: 1rem;
  text-align: center;
  width: 100%;
  display: block;
  overflow: hidden;
`;

export const Video = styled.video`
  display: block;
  object-fit: contain;
  overflow: hidden;
  background-color: #000;
  margin: 0 auto;
  width: 100%;
`;

export const SubtitleWrapper = styled.div<{ playerWidth: number }>`
  position: absolute;
  text-align: center;
  padding: 0px 20px;
  width: 100%;
  max-width: ${({ playerWidth }) => playerWidth}px;
  left: 50%;
  transform: translateX(-50%);
  overflow-x: hidden;
  &.vertical-top {
    top: 5%;
  }
  &.vertical-center {
    top: 50%;
    transform: translateY(-50%);
    transform: translateX(-50%);
  }
  &.vertical-bottom {
    bottom: 5%;
  }
`;

export const ActiveSubtitle = styled.span<{
  fontFamily: string;
  fontSize: number;
  color: string;
  backgroundColor: string;
  decoration: string;
  fontStyle: string;
}>`
  position: relative;
  color: white;
  display: inline;
  line-height: 1.1;
  font-size: ${({ fontSize }) => fontSize}px;
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  font-family: ${({ fontFamily }) => fontFamily};
  text-decoration: ${({ decoration }) => decoration};
  font-style: ${({ fontStyle }) =>
    fontStyle === "italic" ? "italic" : "normal"};
  font-weight: ${({ fontStyle }) => (fontStyle === "bold" ? "bold" : "none")};
`;

import styled from "@emotion/styled";
import { Col, Row } from "react-bootstrap";

export const PageContainer = styled.div`
  margin-top: 3.125rem;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  width: 100%;
`;

export const InnerPageContainer = styled.div`
  margin: 1.875rem;
`;

export const SplitView = styled(Row)``;

export const SplitCol = styled(Col)`
  &.profile-view {
    max-width: 24.375rem;
  }
`;

export const InnerCol = styled.div`
  padding: 1.875rem;
  background-color: white;
  border-radius: 0.5rem;
  border-color: #eff2f5;
  border-radius: 0.625rem;
  box-shadow: 0rem 0rem 0.625rem #e2e2e2;
  overflow: hidden;
`;

export const TopWrapper = styled.div`
  margin: 1.875rem;
  @media (max-width: 767px) {
    margin-left: 0rem;
    margin-right: 0rem;
  }
`;

export const Field = styled.div`
  margin-bottom: 0.9375rem;
  input {
    margin-top: 0.3125rem;
  }
`;

import { useState } from "react";
import { getIcon } from "../../utils/get-icon";
import { useDetectClickOutside } from "react-detect-click-outside";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  AccordionWrapper,
  ClearButton,
  DropdownWrapper,
  FontAccordion,
  FontPickerWrapper,
  NoResultTitle,
  ResultFont,
  ResultsWrapper,
  ResultWrapper,
  SearchIcon,
  SearchInput,
  SearchWrapper,
  SelectArrow,
  SelectedFont,
  SelectedFontWrapper,
  SelectedIcon,
  SelectionWrapper,
} from "./styled";
import { popular_fonts } from "./popular";

interface IFontPickerProps {
  fonts: any[];
  selectedFont: string;
  onSelect: any;
}

const FontPicker: React.FC<IFontPickerProps> = ({
  fonts,
  selectedFont,
  onSelect,
}) => {
  const [search, setSearch] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);

  const clickOpen = () => {
    setOpen(!open);
  };

  const handleClickOutside = () => {
    setOpen(false);
  };

  const ref = useDetectClickOutside({
    onTriggered: handleClickOutside,
  });

  const renderFonts = () => {
    if (fonts && fonts.length > 0) {
      let allFonts: any = [];
      fonts.forEach((font: any) => {
        allFonts.push({
          value: font.family,
          title: font.family,
          url: font.files?.regular,
          variants: font.variants,
        });
      });

      return allFonts.filter((font: any) =>
        font.value.toLowerCase().includes(search.toLowerCase())
      );
    }
    return [];
  };

  const selectFont = (font: any) => {
    onSelect(font);
  };

  const onSearch = (e: any) => {
    setSearch(e.target.value);
  };

  const onClear = () => {
    setSearch("");
  };

  const renderResults = () => {
    const filteredFonts = renderFonts();

    if (search && filteredFonts.length === 0) {
      return <NoResultTitle>Kunne ikke finne font.</NoResultTitle>;
    }

    if (search) {
      return renderFonts().map((font: any) => (
        <ResultWrapper onClick={() => selectFont(font)}>
          <ResultFont font={font?.value} url={font?.url}>
            {font.title}
          </ResultFont>
          {selectedFont === font?.value && (
            <SelectedIcon>{getIcon("done")}</SelectedIcon>
          )}
        </ResultWrapper>
      ));
    } else {
      return (
        <AccordionWrapper>
          <FontAccordion alwaysOpen defaultActiveKey="0">
            <AccordionItem eventKey="0">
              <AccordionHeader>Populære</AccordionHeader>
              <AccordionBody>
                {popular_fonts.map((font: any) => (
                  <ResultWrapper onClick={() => selectFont(font)}>
                    <ResultFont font={font?.value} url={font?.url}>
                      {font.title}
                    </ResultFont>
                    {selectedFont === font?.value && (
                      <SelectedIcon>{getIcon("done")}</SelectedIcon>
                    )}
                  </ResultWrapper>
                ))}
              </AccordionBody>
            </AccordionItem>
            <AccordionItem eventKey="1">
              <AccordionHeader>Alle</AccordionHeader>
              <AccordionBody>
                {renderFonts().map((font: any) => (
                  <ResultWrapper onClick={() => selectFont(font)}>
                    <ResultFont font={font?.value} url={font?.url}>
                      {font.title}
                    </ResultFont>
                    {selectedFont === font?.value && (
                      <SelectedIcon>{getIcon("done")}</SelectedIcon>
                    )}
                  </ResultWrapper>
                ))}
              </AccordionBody>
            </AccordionItem>
          </FontAccordion>
        </AccordionWrapper>
      );
    }
  };

  return (
    <FontPickerWrapper ref={ref}>
      <SelectedFontWrapper onClick={clickOpen}>
        <SelectionWrapper>
          <SelectedFont>{selectedFont}</SelectedFont>
          <SelectArrow>{getIcon("arrow-down")}</SelectArrow>
        </SelectionWrapper>
      </SelectedFontWrapper>
      {open && (
        <DropdownWrapper>
          <SearchWrapper>
            <SearchIcon>{getIcon("search")}</SearchIcon>
            <SearchInput
              value={search}
              onChange={onSearch}
              placeholder="Søk etter font"
            />
            {search && (
              <ClearButton onClick={onClear}>{getIcon("exit")}</ClearButton>
            )}
          </SearchWrapper>
          <ResultsWrapper>{renderResults()}</ResultsWrapper>
        </DropdownWrapper>
      )}
    </FontPickerWrapper>
  );
};

export default FontPicker;

import moment from "moment";
import {
  SelectionCol,
  SelectionDetails,
  SelectionRow,
  SelectionTitle,
  SelectionsWrapper,
  Thumbnail,
} from "../styled";

type VideoSelectionProps = {
  option: any;
};

const VideoSelection = ({ option }: VideoSelectionProps) => {
  const renderDate = (date: string) => {
    return moment(date).format("DD.MMMM YYYY HH:MM");
  };

  return (
    <SelectionsWrapper>
      <SelectionRow>
        <SelectionCol className="thumbnail-col my-auto">
          <Thumbnail src={option?.thumbnail} />
        </SelectionCol>
        <SelectionCol className="details-col video my-auto">
          <SelectionTitle>{option?.title}</SelectionTitle>
          <SelectionDetails className="video-title">
            {renderDate(option?.publishDate)}
          </SelectionDetails>
        </SelectionCol>
      </SelectionRow>
    </SelectionsWrapper>
  );
};

export default VideoSelection;

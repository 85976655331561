import "react-quill/dist/quill.snow.css";
import {
  ErrorWrapper,
  StyledTextEditor,
  StyledTextEditorContainer,
} from "./styled";
import { Text } from "../text";

interface ITextEditorProps {
  value: any;
  onChange: any;
  error?: any;
}

const TextEditor: React.FC<ITextEditorProps> = ({ value, onChange, error }) => {
  return (
    <StyledTextEditorContainer>
      <StyledTextEditor theme="snow" value={value} onChange={onChange} />
      {error?.message && (
        <ErrorWrapper>
          <Text color="fail" size="extrasmall">
            {error?.message}
          </Text>
        </ErrorWrapper>
      )}
    </StyledTextEditorContainer>
  );
};

export default TextEditor;

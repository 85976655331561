import { Text } from "../text";
import { ErrorWrapper, StyledTextarea, TextareaWrapper } from "./styled";

interface ITextareaProps {
  register: any;
  error: any;
  name?: string;
  placeholder?: string;
  rows?: number;
  ariaLabel?: string;
  id?: string;
  maxLength?: number;
}

const Textarea: React.FC<ITextareaProps> = ({
  register,
  name,
  error,
  placeholder,
  rows = 4,
  ariaLabel,
  id,
  maxLength = 524288,
}) => {
  return (
    <TextareaWrapper>
      <StyledTextarea
        {...register(name)}
        name={name}
        placeholder={placeholder}
        aria-label={ariaLabel}
        id={id}
        rows={rows}
        maxLength={maxLength}
      />
      {error?.message && (
        <ErrorWrapper>
          <Text color="fail" size="extrasmall">
            {error?.message}
          </Text>
        </ErrorWrapper>
      )}
    </TextareaWrapper>
  );
};

export default Textarea;

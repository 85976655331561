import { Text } from "../../../../../library/text";
import { AccordionBody, AccordionHeader, AccordionItem } from "./styled";

interface IQuestionProps {
  key: string;
  title: string;
  text: string;
}

const Question: React.FC<IQuestionProps> = ({ key, title, text }) => {
  return (
    <AccordionItem eventKey={key}>
      <AccordionHeader>{title}</AccordionHeader>
      <AccordionBody>
        <Text color="dark">{text}</Text>
      </AccordionBody>
    </AccordionItem>
  );
};

export default Question;

import {
  BoxContent,
  BoxContentWrapper,
  BoxDescription,
  BoxName,
  BoxNameCol,
  BoxTestimonial,
  BoxThumbnailCol,
  BoxWrapper,
  LinkIcon,
  LinkUrl,
  LinkWrapper,
  Thumbnail,
  TopBoxRow,
} from "./styled";
import { getIcon } from "../../../../utils/get-icon";

interface ITestimonialBoxProps {
  thumbnail: string;
  name: string;
  description: string;
  content: string;
  link: string;
  linkTitle: string;
}

const TestimonialBox: React.FC<ITestimonialBoxProps> = ({
  thumbnail,
  name,
  description,
  content,
  link,
  linkTitle,
}) => {
  return (
    <BoxWrapper>
      <BoxContent>
        <TopBoxRow>
          <BoxThumbnailCol>
            <Thumbnail alt={name} src={thumbnail} />
          </BoxThumbnailCol>
          <BoxNameCol>
            <BoxName>{name}</BoxName>
            <BoxDescription>{description}</BoxDescription>
          </BoxNameCol>
        </TopBoxRow>
        <BoxContentWrapper>
          <BoxTestimonial>{content}</BoxTestimonial>
          <LinkWrapper>
            <LinkIcon>{getIcon("link")}</LinkIcon>
            <LinkUrl target="_blank" to={link}>
              {linkTitle}
            </LinkUrl>
          </LinkWrapper>
        </BoxContentWrapper>
      </BoxContent>
    </BoxWrapper>
  );
};

export default TestimonialBox;

import styled from "@emotion/styled";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

export const NavigationWrapper = styled.div`
  padding: 0.3125rem 1.25rem;
  position: fixed;
  width: 100%;
  transition: 0.5s;
  z-index: 20;
  &.white {
    background-color: #fff;
  }
  &.scroll {
    background-color: #fff;
    box-shadow: 0rem 0rem 1.25rem grey;
  }
`;

export const InnerNavigationWrapper = styled.div`
  max-width: 87.5rem;
  margin: 0 auto;
`;

export const NavbarWrapper = styled(Navbar)`
  background-color: transparent !important;
`;

export const NavbarBrand = styled(Navbar.Brand)``;

export const NavbarLogo = styled.img`
  max-width: 8.5rem;
  margin-top: 0rem;
`;

export const NavbarToggle = styled(Navbar.Toggle)`
  border: none;
  padding: 0rem;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
`;

export const Toggle = styled.span`
  height: 1.5625rem;
  display: block;
  color: #3b3b3b;
  width: 1.25rem;
  > i {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.75rem;
  }
`;

export const NavbarCollapse = styled(Navbar.Collapse)``;

export const NavDropdownWrapper = styled(NavDropdown)``;

export const NavDropdownItem = styled(NavDropdown.Item)``;

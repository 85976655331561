import styled from "@emotion/styled";

export const HeaderWrapper = styled.header`
  width: 100%;
  max-height: 100%;
  background-color: #f7fafc;
  background-size: 100% 100%;
  z-index: 2;
  position: relative;
`;

export const InnerContainer = styled.div`
  padding: 10.0625rem 1.5625rem 6.25rem 1.5625rem;
  text-align: center;
  @media (max-width: 767px) {
    padding: 9.375rem 1.5625rem 3.125rem 1.5625rem;
  }
`;

export const TitleWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  display: block;
`;

export const TextWrapper = styled.div`
  max-width: 44rem;
  text-align: center;
  margin: 0 auto;
  margin-top: 0.9375rem;
  margin-bottom: 2.1875rem;
`;

export const HeaderBottom = styled.img`
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  z-index: 3;
  max-height: 50px;
`;

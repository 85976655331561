import styled from "@emotion/styled";

export const Badge = styled.span`
  background-color: yellow;
  font-size: 0.75rem;
  padding: 0.4688rem 0.625rem;
  border-radius: 0.25rem;
  display: inline;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  background-color: rgb(241, 250, 255);
  color: rgb(0, 158, 247);
`;

import styled from "@emotion/styled";
import { Col, Row } from "react-bootstrap";

export const Section = styled.section``;

export const Container = styled.div`
  max-width: 87.5rem;
  margin: 0 auto;
  padding: 4.6875rem 1.25rem;
  @media (max-width: 767px) {
    padding: 0rem 1.25rem;
  }
`;

export const Wrapper = styled.div`
  margin-top: 7.8125rem;
  margin-bottom: 1.5625rem;
  @media (max-width: 767px) {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
  }
`;

export const InformationRow = styled(Row)``;

export const ThumbnailCol = styled(Col)`
  max-width: 37.5rem;
`;

export const DetailsCol = styled(Col)``;

export const TextWrapper = styled.div`
  padding: 2.5rem;
  @media (max-width: 767px) {
    padding: 2.5rem 0rem;
  }
`;

export const Title = styled.h2``;

export const Thumbnail = styled.img`
  width: 100%;
  box-shadow: 0rem 0rem 0.9375rem #e3e3e3;
  border-radius: 0.875rem;
  max-width: 34.375rem;
`;

export const SubTextWrapper = styled.div`
  margin-top: 0.625rem;
`;

export const ButtonWrapper = styled.div`
  margin-top: 1.25rem;
`;

import { useCallback, useEffect, useRef } from "react";
import { SliderWrapper, WaveformWrapper } from "./styled";
import WaveSurfer from "wavesurfer.js";
import { useMediaQuery } from "react-responsive";
import { MOBILE_VIEW } from "../../../../../variables";
import { supportsCompressedAudio } from "../helpers";

type SliderProps = {
  audioUrl: any;
  audioUrlMobile: any;
  currentTime: any;
  duration: any;
  setDataLoaded: any;
  dataLoaded: any;
  setShowError: any;
  videoRef: any;
};

const Slider = ({
  audioUrl,
  audioUrlMobile,
  currentTime,
  duration,
  setDataLoaded,
  dataLoaded,
  setShowError,
  videoRef,
}: SliderProps) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_VIEW}px)` });
  const waveformRef = useRef<HTMLDivElement>(null);
  const wavesurferRef = useRef<WaveSurfer | null>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const performSeek = (time: number) => {
    if (!videoRef.current) return;
    videoRef.current.currentTime = time;
  };

  wavesurferRef?.current?.on("ready", () => {
    setDataLoaded(true);
  });

  wavesurferRef.current?.on("error", (e) => {
    setShowError(true);
  });

  const handleSeek = useCallback(
    (event: any) => {
      if (!wavesurferRef.current || !waveformRef.current) return;

      const mouseX =
        event.clientX - waveformRef.current.getBoundingClientRect().left;
      const duration = wavesurferRef.current.getDuration();
      const seekTime = (mouseX / waveformRef.current.offsetWidth) * duration;

      performSeek(seekTime);
    },
    [performSeek]
  );

  useEffect(() => {
    if (!waveformRef.current) return;

    const currentWaveformRef = waveformRef.current;

    currentWaveformRef.addEventListener("click", handleSeek);

    return () => {
      if (currentWaveformRef) {
        currentWaveformRef.removeEventListener("click", handleSeek);
      }
    };
  }, [handleSeek]);

  useEffect(() => {
    if (!waveformRef.current) return;

    const audio = supportsCompressedAudio() ? audioUrlMobile : audioUrl;

    if (!audio) return;

    wavesurferRef.current = WaveSurfer.create({
      container: waveformRef.current,
      waveColor: "#74bcff",
      progressColor: "#2f8de4",
      cursorWidth: 1,
      barWidth: 3,
      height: 20,
    });

    wavesurferRef.current.load(audio);

    return () => {
      if (wavesurferRef.current) {
        wavesurferRef.current.destroy();
      }
    };
  }, [audioUrl, audioUrlMobile, isMobile]);

  useEffect(() => {
    if (currentTime && duration && wavesurferRef.current) {
      wavesurferRef.current?.seekTo(currentTime / duration);
    }
  }, [currentTime, duration]);

  return (
    <SliderWrapper className={dataLoaded ? "show" : "hide"}>
      <WaveformWrapper ref={waveformRef} />
    </SliderWrapper>
  );
};

export default Slider;

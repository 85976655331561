import { Title } from "../../../../../../../../library/title";
import { Text } from "../../../../../../../../library/text";
import { getIcon } from "../../../../../../../../utils/get-icon";
import { ContentWrapper, Icon, NotSelectedWrapper } from "./styled";

interface NotSelectedViewProps {
  loading: boolean;
}

const NotSelectedView = ({ loading = true }: NotSelectedViewProps) => {
  return (
    <NotSelectedWrapper>
      {loading ? (
        <ContentWrapper>
          <Icon className="loading">{getIcon("spinner")}</Icon>
          <Title size="sm">Laster inn</Title>
          <Text size="small" color="dark">
            Henter data fra valgt revisjon..
          </Text>
        </ContentWrapper>
      ) : (
        <ContentWrapper>
          <Icon>{getIcon("revision")}</Icon>
          <Title size="sm">Velg en revisjon</Title>
          <Text size="small" color="dark">
            Trykk på en revisjon fra listen til venstre for å sammenligne.
          </Text>
        </ContentWrapper>
      )}
    </NotSelectedWrapper>
  );
};

export default NotSelectedView;

import styled from "@emotion/styled";

export const MobileWrapper = styled.div<{ playerHeight: number }>`
  position: fixed;
  width: 100%;
  background-color: #fff;
  padding-bottom: 5px;
  z-index: 4;
  top: ${({ playerHeight }) => `calc(${playerHeight}px + 10px)`};
`;

export const MenuWrapper = styled.div<{ playerHeight: number }>`
  display: flex;
  border-bottom: 0.125rem solid rgb(244, 244, 244);
  position: fixed;
  background-color: #fff;
  width: 100%;
  z-index: 11;
  top: ${({ playerHeight }) => playerHeight}px;
`;

export const MenuButton = styled.button`
  border: none;
  background: none;
  padding: 7.5px 10px;
  font-family: "Poppins"; sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  color: grey;
  &.active{
    color: #2f8de4 !important;
  }
  &:hover{
    color: #2f8de4 !important;
  }
`;

export const ContentWrapper = styled.div<{
  playerHeight: number;
}>`
  position: fixed;
  padding: 0px 1px;
  overflow: hidden;
  padding-bottom: 15px;
  overflow-y: scroll;
  bottom: 55px;
  width: 100%;
  top: ${({ playerHeight }) => `calc(${playerHeight}px + 10px)`};
`;

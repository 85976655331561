import { getIcon } from "../../../../utils/get-icon";
import {
  BillingTitle,
  PlanDescription,
  PlanTitle,
  PriceDetailsCol,
  PriceDisplayContainer,
  PriceDisplayRow,
  PriceTitle,
  SelectButton,
} from "./styled";

interface ISubscriptionProps {
  id: string;
  selected: string;
  onSelect: any;
  title: string;
  description: string;
  price: string;
  duration: string;
  ariaLabel: string;
}

const Subscription: React.FC<ISubscriptionProps> = ({
  id,
  selected,
  onSelect,
  title,
  description,
  price,
  duration,
  ariaLabel,
}) => {
  return (
    <PriceDisplayContainer
      className={selected === id ? "active" : ""}
      onClick={() => onSelect(id)}
      aria-label={ariaLabel}
    >
      <PriceDisplayRow>
        <PriceDetailsCol className="button-col my-auto">
          <SelectButton className={selected === id ? "active" : ""}>
            {selected === id
              ? getIcon("selected-box")
              : getIcon("unselected-box")}
          </SelectButton>
        </PriceDetailsCol>
        <PriceDetailsCol className="details-col">
          <PlanTitle>{title}</PlanTitle>
          <PlanDescription>{description}</PlanDescription>
        </PriceDetailsCol>
        <PriceDetailsCol className="price-col">
          <PriceTitle>{price}</PriceTitle>
          <BillingTitle>{duration}</BillingTitle>
        </PriceDetailsCol>
      </PriceDisplayRow>
    </PriceDisplayContainer>
  );
};

export default Subscription;

import styled from "@emotion/styled";

export const SignupForm = styled.form`
  margin-top: 0.9375rem;
`;

export const FormWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 0.5rem;
  div {
    flex-grow: 1 !important;
    input {
      width: 100%;
    }
  }
`;

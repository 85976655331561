import styled from "@emotion/styled";

export const StyledSelect = styled.select`
  padding: 0.7813rem 1.25rem;
  border-radius: 0.875rem;
  border: 1px solid #f1f6ff;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  background-color: #f4f4f4;
  width: 100%;
  margin-top: 0.3125rem;
`;

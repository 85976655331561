import styled from "@emotion/styled";

export const Wrapper = styled.div`
  postion: relative;
`;

export const RemoveButton = styled.button`
  position: absolute;
  right: 0.625rem;
  top: 0.625rem;
  border: none;
  background: none;
  color: #f83d76;
  font-size: 1.125rem;
`;

export const FileIcon = styled.h2`
  color: #2f8de4;
`;

export const FileName = styled.h4`
  font-family: "Poppins", sans-serif;
  color: #3b3b3b;
  font-size: 1rem;
  font-weight: 600;
`;

export const FileSize = styled.span`
  font-size: 0.875rem;
  display: block;
  font-family: "Poppins", sans-serif;
  color: grey;
  margin-top: -0.3125rem;
`;

import { Text } from "../text";
import {
  CheckboxContainer,
  CheckboxToggle,
  CheckboxWrapper,
  ErrorWrapper,
  FullCheckboxWrapper,
  TextWrapper,
  ToggleIcon,
} from "./styled";

interface ICheckboxProps {
  checked?: boolean;
  onChange: (val: boolean) => void;
  ariaLabel?: string;
  label?: string | React.ReactNode;
  name?: string;
  error?: any;
}

const Index: React.FC<ICheckboxProps> = ({
  onChange,
  checked,
  name,
  ariaLabel = "",
  label,
  error,
  ...props
}) => {
  return (
    <>
      <FullCheckboxWrapper>
        <CheckboxWrapper>
          <CheckboxContainer
            onChange={onChange as any}
            className={checked === true ? "checked" : ""}
          >
            <CheckboxToggle {...props} aria-label={ariaLabel} type="checkbox" />
            {checked ? <ToggleIcon className="fa-duotone fa-check" /> : null}
          </CheckboxContainer>
        </CheckboxWrapper>
        <TextWrapper>
          {" "}
          <Text color="dark" size="small">
            {label}
          </Text>
        </TextWrapper>
      </FullCheckboxWrapper>
      {error?.message && (
        <ErrorWrapper>
          <Text color="fail" size="extrasmall">
            {error?.message}
          </Text>
        </ErrorWrapper>
      )}
    </>
  );
};

export default Index;

// redux/slices/blogpostSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface BlogpostData {
  status: string;
  timestamp: number;
  title: string;
  content: string;
  excerpt: string;
  prefix: string;
  thumbnail: string;
  id: string;
}

interface BlogpostState {
  saveLoading: boolean;
  data: BlogpostData;
}

const initialState: BlogpostState = {
  saveLoading: false,
  data: {
    status: "",
    timestamp: 0,
    title: "",
    content: "",
    excerpt: "",
    prefix: "",
    thumbnail: "",
    id: "",
  },
};

const blogpostSlice = createSlice({
  name: "blogpost",
  initialState,
  reducers: {
    setActiveBlogpost(state, action: PayloadAction<BlogpostData>) {
      state.data = action.payload;
    },
    clearActiveBlogpost(state) {
      state.data = initialState.data;
    },
    setSaveLoadingBlogpost(state, action: PayloadAction<boolean>) {
      state.saveLoading = action.payload;
    },
  },
});

export const {
  setActiveBlogpost,
  clearActiveBlogpost,
  setSaveLoadingBlogpost,
} = blogpostSlice.actions;
export default blogpostSlice.reducer;

import { useTranslation } from "react-i18next";
import { getIcon } from "../../utils/get-icon";
import { ProgressWrapper, StepIcon, StepTitle, StepWrapper } from "./styled";

interface IProgressBarProps {
  step: number;
}

const ProgressBar: React.FC<IProgressBarProps> = ({ step }) => {
  const { t } = useTranslation();
  return (
    <ProgressWrapper>
      <StepWrapper className={`${step === 1 && "active"}`}>
        <StepIcon
          aria-label={t("register.top-steps.first-step.ariaLabel") as string}
          className={`${step === 1 && "active"}`}
        >
          {getIcon("select")}
        </StepIcon>
        <StepTitle className={`${step === 1 && "active"}`}>
          {t("register.top-steps.first-step.title")}
        </StepTitle>
      </StepWrapper>
      <StepWrapper className={`${step === 2 && "active"}`}>
        <StepIcon
          aria-label={t("register.top-steps.second-step.ariaLabel") as string}
          className={`${step === 2 && "active"}`}
        >
          {getIcon("details")}
        </StepIcon>
        <StepTitle className={`${step === 2 && "active"}`}>
          {t("register.top-steps.second-step.title")}
        </StepTitle>
      </StepWrapper>
      <StepWrapper>
        <StepIcon
          aria-label={t("register.top-steps.third-step.ariaLabel") as string}
        >
          {getIcon("rocket")}
        </StepIcon>
        <StepTitle>{t("register.top-steps.third-step.title")}</StepTitle>
      </StepWrapper>
    </ProgressWrapper>
  );
};

export default ProgressBar;

import styled from "@emotion/styled";

export const ContentContainer = styled.div`
  margin-top: 3.125rem;
`;

export const Field = styled.div`
  margin-bottom: 0.9375rem;
  &.bottom-space {
    margin-bottom: 1.875rem;
  }
  input {
    margin-top: 0.3125rem;
    width: 100%;
  }
`;

export const PrefixDisplay = styled.span`
  font-size: 0.875rem;
  padding-left: 1.25rem;
  display: block;
  margin-top: 0.3125rem;
  color: grey;
  font-family: "Poppins", sans-serif;
`;

export const PrefixLabel = styled.small`
  font-size: 0.875rem;
  font-family: "Poppins", sans-serif;
`;

export const ChangePrefixButton = styled.button`
  border: none;
  padding: 0px;
  background: none;
  margin-left: 0.3125rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #2f8de4 !important;
`;

export const PrefixInput = styled.input`
  background-color: #fff;
  font-size: 0.875rem;
  display: inline-flex;
  width: unset !important;
  min-width: 14.0625rem;
  height: 1.0625rem;
  border: none;
  margin-left: 0.0313rem;
  color: grey;
`;

export const ThumbnailWrapper = styled.div`
  position: relative;
  border-radius: 1rem;

  overflow: hidden;
  margin-bottom: 1.875rem;
  min-height: 38.25rem;
  button {
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }
`;

export const BlogThumbnail = styled.img`
  width: 100%;
`;

export const ThumbnailOverlay = styled.div`
  top: 0px;
  bottom: 0px;
  position: absolute;
  width: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
`;

export const FileUploadButton = styled.input`
  display: none;
`;

export const Form = styled.form``;

import {
  InnerNavigationWrapper,
  NavbarBrand,
  NavbarCollapse,
  NavbarLogo,
  NavbarToggle,
  NavbarWrapper,
  NavigationWrapper,
  Toggle,
} from "./styled";

import flagNorway from "../../assets/img/flag-norway.svg";
import flagEnglish from "../../assets/img/flag-english.svg";
import flagDenmark from "../../assets/img/flag-denmark.svg";
import flagSweden from "../../assets/img/flag-sweden.svg";
import logoDark from "../../assets/img/logo-2.svg";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { getIcon } from "../../utils/get-icon";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import DesktopView from "./desktop-view";
import MobileView from "./mobile-view";
import { MOBILE_VIEW } from "../../variables";

interface INavigationProps {
  background?: boolean;
}

const Navigation: React.FC<INavigationProps> = ({ background }) => {
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_VIEW}px)` });
  const [scrolled, setScrolled] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  const LANGUAGE_SELECTIONS = [
    {
      display: (
        <span
          aria-label={
            t(
              "components.public-navigation.language-dropdown.selections.danish"
            ) as string
          }
          className="single-selection"
        >
          <img
            alt={
              t("components.public-navigation.language-dropdown.flags.danish")!
            }
            src={flagDenmark}
          />{" "}
          {t(
            "components.public-navigation.language-dropdown.language-options.danish"
          )}
        </span>
      ),
      onClick: () => changeLanguage("dk"),
    },
    {
      display: (
        <span
          aria-label={
            t(
              "components.public-navigation.language-dropdown.selections.english"
            ) as string
          }
          className="single-selection"
        >
          <img
            alt={
              t("components.public-navigation.language-dropdown.flags.english")!
            }
            src={flagEnglish}
          />{" "}
          {t(
            "components.public-navigation.language-dropdown.language-options.english"
          )}
        </span>
      ),
      onClick: () => changeLanguage("en"),
    },
    {
      display: (
        <span
          aria-label={
            t(
              "components.public-navigation.language-dropdown.selections.norwegian"
            ) as string
          }
          className="single-selection"
        >
          <img
            alt={
              t(
                "components.public-navigation.language-dropdown.flags.norwegian"
              )!
            }
            src={flagNorway}
          />{" "}
          {t(
            "components.public-navigation.language-dropdown.language-options.norwegian"
          )}
        </span>
      ),
      onClick: () => changeLanguage("no"),
    },
    {
      display: (
        <span
          aria-label={
            t(
              "components.public-navigation.language-dropdown.selections.swedish"
            ) as string
          }
          className="single-selection"
        >
          <img
            alt={
              t("components.public-navigation.language-dropdown.flags.swedish")!
            }
            src={flagSweden}
          />{" "}
          {t(
            "components.public-navigation.language-dropdown.language-options.swedish"
          )}
        </span>
      ),
      onClick: () => changeLanguage("sv"),
    },
  ];

  const renderLanguage = () => {
    const language = i18next.language;
    switch (language) {
      case "no":
        return (
          <span className="language-wrapper">
            <img
              src={flagNorway}
              alt={
                t(
                  "components.public-navigation.language-dropdown.selected-flag.norwegian"
                ) as string
              }
            />
          </span>
        );
      case "sv":
        return (
          <span className="language-wrapper">
            <img
              src={flagSweden}
              alt={
                t(
                  "components.public-navigation.language-dropdown.selected-flag.sweden"
                ) as string
              }
            />
          </span>
        );
      case "dk":
        return (
          <span className="language-wrapper">
            <img
              src={flagDenmark}
              alt={
                t(
                  "components.public-navigation.language-dropdown.selected-flag.denmark"
                ) as string
              }
            />
          </span>
        );
      default:
        return (
          <span className="language-wrapper">
            <img
              src={flagEnglish}
              alt={
                t(
                  "components.public-navigation.language-dropdown.selected-flag.english"
                ) as string
              }
            />
          </span>
        );
    }
  };

  useEffect(() => {
    window.onscroll = () => {
      if (window.pageYOffset > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
  });

  const renderBackground = () => {
    if (isMobile) {
      if (collapsed) {
        return "scroll";
      }
      if (scrolled) {
        return "scroll";
      }
      if (background) {
        return "white";
      }
      return "";
    } else {
      if (scrolled) {
        return "scroll";
      }
      if (background) {
        return "white";
      }
      return "";
    }
  };

  return (
    <NavigationWrapper className={renderBackground()}>
      <InnerNavigationWrapper>
        <NavbarWrapper
          aria-label={t("components.public-navigation.ariaLabel") as string}
          collapseOnSelect
          expand="lg"
        >
          <NavbarBrand href="/">
            <NavbarLogo alt="Randi logo" src={logoDark} />
          </NavbarBrand>
          <NavbarToggle aria-controls="responsive-navbar-nav">
            <Toggle onClick={() => setCollapsed(!collapsed)}>
              {collapsed ? getIcon("exit") : getIcon("menu")}
            </Toggle>
          </NavbarToggle>
          <NavbarCollapse id="responsive-navbar-nav">
            {isMobile ? (
              <MobileView />
            ) : (
              <DesktopView
                renderLanguage={renderLanguage}
                languageItems={LANGUAGE_SELECTIONS}
              />
            )}
          </NavbarCollapse>
        </NavbarWrapper>
      </InnerNavigationWrapper>
    </NavigationWrapper>
  );
};

export default Navigation;

import { InfinitySpin } from "react-loader-spinner";
import { InnerLoaderContainer, LoaderContainer, LoadingText } from "./styled";

interface ITableLoaderProps {
  message?: string;
  className?: string;
}

const TableLoader: React.FC<ITableLoaderProps> = ({ message, className }) => {
  return (
    <LoaderContainer className={className}>
      <InnerLoaderContainer>
        <InfinitySpin width="200" color="#2f8de4" />
        {message && <LoadingText>{message}</LoadingText>}
      </InnerLoaderContainer>
    </LoaderContainer>
  );
};

export default TableLoader;

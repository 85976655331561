import moment from "moment";
import {
  SelectIcon,
  SelectionCol,
  SelectionDetails,
  SelectionRow,
  SelectionTitle,
  SelectionsWrapper,
} from "../styled";
import { getIcon } from "../../../utils/get-icon";
import { filesize } from "filesize";

type FileSelectionProps = {
  option: any;
};

const FileSelection = ({ option }: FileSelectionProps) => {
  const renderDate = (date: string) => {
    return moment(date).format("DD.MMMM YYYY HH:MM");
  };

  function renderFileSize(bytes: any) {
    return <>{filesize(bytes, { base: 2, standard: "jedec" })}</>;
  }

  return (
    <SelectionsWrapper>
      <SelectionRow>
        <SelectionCol className="icon-col my-auto">
          <SelectIcon>{getIcon("media")}</SelectIcon>
        </SelectionCol>
        <SelectionCol className="details-col video my-auto">
          <SelectionTitle className="video-title">
            {option?.title}
          </SelectionTitle>
          <SelectionDetails>
            {renderDate(option?.created)} ● {renderFileSize(option?.size)}
          </SelectionDetails>
        </SelectionCol>
      </SelectionRow>
    </SelectionsWrapper>
  );
};

export default FileSelection;

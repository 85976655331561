import styled from "@emotion/styled";

export const Field = styled.div`
  margin-bottom: 0.9375rem;
  input {
    margin-top: 0.3125rem;
  }
  &:last-of-type {
    margin-bottom: 0rem;
  }
`;

export const ModalContentContainer = styled.div`
  margin-top: 1.25rem;
  input {
    width: 100%;
  }
`;

export const ModalButtonsContainer = styled.div`
  margin-top: 1.5625rem;
  text-align: center;
`;

export const Form = styled.form``;

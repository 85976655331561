import { ListItem, ListWrapper, TabsWrapper } from "../styled";

const Index = () => {
  return (
    <TabsWrapper>
      <ListWrapper>
        <ListItem>
          For å slette en undertekst kan du trykke på den grå x-knappen øverst i
          høyre hjørne i hver boks på venstre side.
        </ListItem>
        <ListItem>
          Fra nedtrekksmenyen som dukker opp må du bekrefte at du ønsker å
          slette teksten. Dersom du bekrefter vil teksten forsvinne helt fra
          transkriptet.
        </ListItem>
      </ListWrapper>
    </TabsWrapper>
  );
};

export default Index;

import { useTranslation } from "react-i18next";
import { NavLink, NavText, NavWrapper } from "./styled";
import Dropdown from "../../../library/dropdown-menu";
import Button from "../../../library/button";

interface DesktopViewProps {
  renderLanguage: () => JSX.Element;
  languageItems: any[];
}

const DesktopView = ({ renderLanguage, languageItems }: DesktopViewProps) => {
  const { t } = useTranslation();

  return (
    <>
      <NavWrapper className="me-auto">
        <NavLink
          aria-label={
            t(
              "components.public-navigation.links.frontpage.ariaLabel"
            ) as string
          }
          className={`aligned-left`}
          to="/"
        >
          <NavText>
            {t("components.public-navigation.links.frontpage.title")}
          </NavText>
        </NavLink>
        <NavLink
          aria-label={
            t("components.public-navigation.links.blog.ariaLabel") as string
          }
          className={`aligned-left`}
          to="/blog"
        >
          <NavText>
            {t("components.public-navigation.links.blog.title")}
          </NavText>
        </NavLink>
        <NavLink
          aria-label={
            t("components.public-navigation.links.pricing.ariaLabel") as string
          }
          className={`aligned-left`}
          to="/pricing"
        >
          <NavText>
            {t("components.public-navigation.links.pricing.title")}
          </NavText>
        </NavLink>
        <NavLink
          aria-label={
            t(
              "components.public-navigation.links.contact-us.ariaLabel"
            ) as string
          }
          className={`aligned-left`}
          to="/contact"
        >
          <NavText>
            {t("components.public-navigation.links.contact-us.title")}
          </NavText>
        </NavLink>
      </NavWrapper>
      <Dropdown
        selections={languageItems}
        title={t("components.public-navigation.language-dropdown.title")!}
        toggle={renderLanguage()}
        ariaLabel={
          t(
            "components.public-navigation.language-dropdown.toggle.ariaLabel"
          ) as string
        }
      />
      <NavWrapper>
        <NavLink
          aria-label={
            t(
              "components.public-navigation.buttons.sign-in.ariaLabel"
            ) as string
          }
          className={`aligned-right flag`}
          to="/login"
        >
          <NavText>
            {t("components.public-navigation.buttons.sign-in.title")}
          </NavText>
        </NavLink>
        <NavLink
          aria-label={
            t(
              "components.public-navigation.buttons.register.ariaLabel"
            ) as string
          }
          className="aligned-right"
          to="/register"
        >
          <Button color="blue" variant="regular">
            {t("components.public-navigation.buttons.register.title")}
          </Button>
        </NavLink>
      </NavWrapper>
    </>
  );
};

export default DesktopView;

import moment from "moment";
import Button from "../../../../../../../library/button";
import {
  Badge,
  ButtonsWrapper,
  ButtonWrapper,
  CaptionsWrapper,
  CollapseButton,
  CollapseWrapper,
  ComparisonColumn,
  ComparisonRow,
  ComparisonWrapper,
  DetailsDescription,
  DetailsTitle,
  DetailsWrapper,
  LeftBorder,
  LeftCircle,
  ListSeparator,
  ListWrapper,
  RevisionModalContentWrapper,
  SingleRevisionWrapper,
} from "./styled";
import Video from "./video";
import { useAppSelector } from "../../../../../../../redux/hooks";
import useRevision from "../../../../../../../hooks/useRevision";
import { sendErrorNotification } from "../../../../../../../library/notification";
import { getIcon } from "../../../../../../../utils/get-icon";
import Caption from "./caption";
import NotSelectedView from "./not-selected";
import { useEffect, useRef, useState } from "react";
import { compareCaptionsIgnoringKey } from "../../../helpers";
import { defaultApperance } from "../../../utils";
import { useDispatch } from "react-redux";
import { setActiveTranscript } from "../../../../../../../redux/slices/transcript";

interface ComparisonProps {
  videoUrl: string;
  original: any;
  onClose: () => void;
}

const RevisionModal = ({ videoUrl, original, onClose }: ComparisonProps) => {
  const dispatch = useDispatch();
  const { getRevision } = useRevision();
  const transcript = useAppSelector((state) => state.transcript.data);
  const [playerHeight, setPlayerHeight] = useState({
    original: 0,
    comparison: 0,
  });
  const [videoCollapsed, setVideoCollapsed] = useState({
    original: false,
    comparison: false,
  });
  const originalVideoRef = useRef<HTMLVideoElement>(null);
  const comparisonVideoRef = useRef<HTMLVideoElement>(null);

  const originalCaptionsRef = useRef<HTMLDivElement>(null);
  const comparisonCaptionsRef = useRef<HTMLDivElement>(null);

  const revisionListRef = useRef<HTMLDivElement>(null);

  const isSyncing = useRef(false);

  const [originalDisplayText, setOriginalDisplayText] = useState("");
  const [comparisonDisplayText, setComparisonDisplayText] = useState("");

  const compareLoading = useAppSelector(
    (state) => state.transcript.compareLoading
  );

  const latestRevision = useAppSelector(
    (state) => state.transcript?.data?.revisionRef
  );
  const comparison = useAppSelector((state) => state.transcript?.comparison);
  const revisions: any[] =
    useAppSelector((state) => state.transcript.data?.revisions) ?? [];

  const clickSelectRevision = async (revisionId: string | null) => {
    try {
      await getRevision(revisionId);
    } catch (e) {
      sendErrorNotification("Kunne ikke hente revisjon.");
    }
  };

  const clickCollapse = (variant: "original" | "comparison") => {
    setVideoCollapsed({
      ...videoCollapsed,
      [variant]: !videoCollapsed[variant],
    });
  };

  useEffect(() => {
    const updatePlayerHeight = () => {
      if (originalVideoRef.current && comparisonVideoRef.current) {
        const originalVideoElement = originalVideoRef.current;
        const comparisonVideoElement = comparisonVideoRef.current;

        setPlayerHeight({
          original: originalVideoElement.clientHeight,
          comparison: comparisonVideoElement.clientHeight,
        });
      }
    };

    const originalVideoElement = originalVideoRef.current;
    const comparisonVideoElement = comparisonVideoRef.current;

    if (originalVideoElement && comparisonVideoElement) {
      originalVideoElement.addEventListener(
        "loadedmetadata",
        updatePlayerHeight
      );
      comparisonVideoElement.addEventListener(
        "loadedmetadata",
        updatePlayerHeight
      );
      window.addEventListener("resize", updatePlayerHeight);
    }

    return () => {
      if (originalVideoElement && comparisonVideoElement) {
        originalVideoElement.removeEventListener(
          "loadedmetadata",
          updatePlayerHeight
        );
        comparisonVideoElement.removeEventListener(
          "loadedmetadata",
          updatePlayerHeight
        );
        window.removeEventListener("resize", updatePlayerHeight);
      }
    };
  }, [originalVideoRef, comparisonVideoRef]);

  useEffect(() => {
    const originalStart = original?.captions[0]?.end;
    const comparisonStart = comparison?.content[0]?.end;

    if (originalVideoRef.current) {
      if (comparisonVideoRef.current?.currentTime !== originalStart) {
        originalVideoRef.current.currentTime = originalStart;
        setOriginalDisplayText(original?.captions[0]?.text);
      }
    }

    if (comparisonVideoRef.current) {
      if (comparisonVideoRef.current?.currentTime !== comparisonStart) {
        comparisonVideoRef.current.currentTime = comparisonStart;
        setComparisonDisplayText(comparison?.content[0]?.text);
      }
    }
  }, [comparison?.content, original?.captions]);

  const handleScroll = (sourceRef: any, targetRef: any) => {
    if (!isSyncing.current && sourceRef.current && targetRef.current) {
      isSyncing.current = true;
      targetRef.current.scrollTop = sourceRef.current.scrollTop;
    }
    isSyncing.current = false;
  };

  useEffect(() => {
    const originalCaptions = originalCaptionsRef.current;
    const comparisonCaptions = comparisonCaptionsRef.current;

    const onOriginalScroll = () =>
      handleScroll(originalCaptionsRef, comparisonCaptionsRef);
    const onComparisonScroll = () =>
      handleScroll(comparisonCaptionsRef, originalCaptionsRef);

    // Short delay to ensure all elements are fully loaded before attaching events
    const timeout = setTimeout(() => {
      if (originalCaptions && comparisonCaptions) {
        originalCaptions.addEventListener("scroll", onOriginalScroll);
        comparisonCaptions.addEventListener("scroll", onComparisonScroll);
      }
    }, 100);

    // Cleanup
    return () => {
      clearTimeout(timeout);
      if (originalCaptions && comparisonCaptions) {
        originalCaptions.removeEventListener("scroll", onOriginalScroll);
        comparisonCaptions.removeEventListener("scroll", onComparisonScroll);
      }
    };
  }, [playerHeight, videoCollapsed, original, comparison]);

  const differences = compareCaptionsIgnoringKey(
    original.captions,
    comparison?.content
  );

  const clickRevise = () => {
    dispatch(
      setActiveTranscript({
        ...transcript,
        content: comparison.content,
      })
    );
    onClose();
  };

  return (
    <>
      <RevisionModalContentWrapper>
        <ListWrapper ref={revisionListRef}>
          {revisions
            ?.filter((r) => r.id !== latestRevision)
            .map((revision, index, arr) => (
              <SingleRevisionWrapper isActive={comparison?.id === revision.id}>
                <LeftBorder
                  isFirst={index === 0}
                  isLast={index === arr.length - 1}
                />
                <LeftCircle isActive={comparison?.id === revision?.id} />
                <DetailsWrapper>
                  <DetailsTitle
                    className="revision-title"
                    active={comparison?.id === revision?.id}
                  >
                    {revision?.id
                      ? "Revisjon ble lagret"
                      : "Transkript opprettet"}
                  </DetailsTitle>
                  <DetailsDescription>
                    Den {moment.unix(revision?.timestamp).format("DD/MM/YYYY")}{" "}
                    kl. {moment.unix(revision?.timestamp).format("HH:mm")}
                  </DetailsDescription>
                </DetailsWrapper>
                <ButtonWrapper>
                  {comparison?.id === revision?.id && <Badge>Aktiv</Badge>}
                  <Button
                    size="sm"
                    variant="regular"
                    color="grey"
                    disabled={comparison?.id === revision?.id}
                    onClick={() => clickSelectRevision(revision?.id)}
                  >
                    Velg
                  </Button>
                </ButtonWrapper>
              </SingleRevisionWrapper>
            ))}
        </ListWrapper>
        <ListSeparator />
        <ComparisonWrapper>
          <ComparisonRow>
            <ComparisonColumn>
              <Video
                displayText={originalDisplayText}
                videoUrl={videoUrl}
                videoRef={originalVideoRef}
                configuration={original?.appearance}
                variant="original"
                collapsed={videoCollapsed.original}
              />
              <CollapseWrapper>
                <CollapseButton
                  collapsed={videoCollapsed.original}
                  onClick={() => clickCollapse("original")}
                >
                  {getIcon("arrow-up")}
                </CollapseButton>
              </CollapseWrapper>
              <CaptionsWrapper
                playerHeight={playerHeight.original}
                isVideoCollapsed={videoCollapsed.original}
                ref={originalCaptionsRef}
                revisionListHeight={revisionListRef.current?.clientHeight}
              >
                {original?.captions?.map((caption: any) => {
                  let difference = differences.find(
                    (diff: any) => diff.key === caption.key
                  );
                  return (
                    <Caption
                      caption={caption}
                      difference={difference}
                      isComparison={false} // Show original view (with deletions)
                    />
                  );
                })}
              </CaptionsWrapper>
            </ComparisonColumn>
            <ComparisonColumn>
              {comparison && !compareLoading ? (
                <>
                  <Video
                    displayText={comparisonDisplayText}
                    videoUrl={videoUrl}
                    videoRef={comparisonVideoRef}
                    configuration={comparison?.appearance ?? defaultApperance}
                    variant="comparison"
                    collapsed={videoCollapsed.comparison}
                  />
                  <CollapseWrapper>
                    <CollapseButton
                      collapsed={videoCollapsed.comparison}
                      onClick={() => clickCollapse("comparison")}
                    >
                      {getIcon("arrow-up")}
                    </CollapseButton>
                  </CollapseWrapper>
                  <CaptionsWrapper
                    playerHeight={playerHeight.comparison}
                    isVideoCollapsed={videoCollapsed.comparison}
                    ref={comparisonCaptionsRef}
                    revisionListHeight={revisionListRef.current?.clientHeight}
                  >
                    {comparison?.content.map((caption: any) => {
                      let difference = differences.find(
                        (diff: any) => diff.key === caption.key
                      );
                      return (
                        <Caption
                          caption={caption}
                          difference={difference}
                          isComparison={true} // Show comparison view (with insertions)
                        />
                      );
                    })}
                  </CaptionsWrapper>
                </>
              ) : (
                <NotSelectedView loading={compareLoading} />
              )}
            </ComparisonColumn>
          </ComparisonRow>
        </ComparisonWrapper>
      </RevisionModalContentWrapper>
      <ButtonsWrapper>
        <Button variant="regular" color="grey">
          Avbryt
        </Button>
        <Button
          icon="refresh"
          variant="regular"
          color="blue"
          onClick={clickRevise}
        >
          Hent tilbake
        </Button>
      </ButtonsWrapper>
    </>
  );
};

export default RevisionModal;

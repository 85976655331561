import styled from "@emotion/styled";

export const TableContainer = styled.div``;

export const Table = styled.table`
  width: 100%;
  border-spacing: 0.625rem 0;
`;

export const TableRow = styled.tr``;

export const TableHeader = styled.th`
  border-bottom: 0.0625rem dashed #f2f2f2;
  padding-bottom: 0.625rem;
  color: grey;
`;

export const Title = styled.span`
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  color: #3b3b3b;
  font-weight: 600;
`;

export const TableData = styled.td``;

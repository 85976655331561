import Tabs from "../../../../library/tabs";
import { Text } from "../../../../library/text";
import { Title } from "../../../../library/title";
import AudioSelection from "./tabs/audio-selection";
import FileSelection from "./tabs/file-selection";
import {
  OnboardingCol,
  OnboardingRow,
  Video,
  VideoWrapper,
} from "../../styled";
import { TabsWrapper } from "./styled";
import { Bold } from "../styled";
import { useState } from "react";

const Index = () => {
  const [activeTab, setActiveTab] = useState<string>("private");
  const SELECTIONS = [
    {
      id: 0,
      key: "private",
      title: "Filopplasting",
      display: <FileSelection />,
    },
    {
      id: 1,
      key: "shared",
      title: "Live taleopptak",
      display: <AudioSelection />,
    },
  ];

  return (
    <OnboardingRow>
      <OnboardingCol md className="video">
        <VideoWrapper>
          <Video
            controls={false}
            muted={true}
            autoPlay={true}
            loop={true}
            playsInline={true}
            src={
              "https://firebasestorage.googleapis.com/v0/b/project-x-bc8e6.appspot.com/o/public%2Fonboarding_step_two.mp4?alt=media&token=77c55310-3709-4463-9308-df3b3bd8bbbb"
            }
          />
        </VideoWrapper>
      </OnboardingCol>
      <OnboardingCol md className="details my-auto">
        <Title size="sm">Gjør din første transkribering</Title>
        <Text size="small" color="dark">
          Trykk på <Bold>Transkripter</Bold> i navigasjonen øverst og deretter
          på <Bold>Nytt transkript</Bold>-knappen øverst i høyre hjørne. Fra
          dialogboksen som popper opp gjør du følgende:
        </Text>
        <TabsWrapper>
          <Tabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            selections={SELECTIONS}
          />
        </TabsWrapper>
      </OnboardingCol>
    </OnboardingRow>
  );
};

export default Index;

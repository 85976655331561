import { Text } from "../../../library/text";
import { Title } from "../../../library/title";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  AccordionWrapper,
  Container,
  ContentWrapper,
  IllustrationWrapper,
  Section,
  SelectionCol,
  SelectionRow,
  Video,
  VideoBorder,
  VideoWrapper,
  Wrapper,
} from "./styled";
import editorVideo from "../../../assets/videos/video-editor.mp4";
import { useTranslation } from "react-i18next";

const SelectionView = () => {
  const { t } = useTranslation();

  const SELECTION_ITEMS = [
    {
      key: "1",
      title: t(
        "frontpage.sections.video-editor-selection.selections.first-selection.title"
      ),
      content: t(
        "frontpage.sections.video-editor-selection.selections.first-selection.text"
      ),
    },
    {
      key: "2",
      title: t(
        "frontpage.sections.video-editor-selection.selections.second-selection.title"
      ),
      content: t(
        "frontpage.sections.video-editor-selection.selections.second-selection.text"
      ),
    },
    {
      key: "3",
      title: t(
        "frontpage.sections.video-editor-selection.selections.third-selection.title"
      ),
      content: t(
        "frontpage.sections.video-editor-selection.selections.third-selection.text"
      ),
    },
    {
      key: "4",
      title: t(
        "frontpage.sections.video-editor-selection.selections.fourth-selection.title"
      ),
      content: t(
        "frontpage.sections.video-editor-selection.selections.fourth-selection.text"
      ),
    },
  ];

  return (
    <Section>
      <Container>
        <Wrapper>
          <SelectionRow className="my-auto">
            <SelectionCol
              xs={{ span: 12, order: 2 }}
              sm={{ span: 12, order: 2 }}
              lg={{ span: 6, order: 1 }}
            >
              <ContentWrapper>
                <Title>
                  {t("frontpage.sections.video-editor-selection.title")}
                </Title>

                <AccordionWrapper defaultActiveKey={"1"}>
                  {SELECTION_ITEMS.map((item, i) => (
                    <AccordionItem key={i} eventKey={item.key}>
                      <AccordionHeader>{item.title}</AccordionHeader>
                      <AccordionBody>
                        <Text color="dark">{item.content}</Text>
                      </AccordionBody>
                    </AccordionItem>
                  ))}
                </AccordionWrapper>
              </ContentWrapper>
            </SelectionCol>
            <SelectionCol
              xs={{ span: 12, order: 1 }}
              sm={{ span: 12, order: 1 }}
              md={{ span: 12, order: 1 }}
              lg={{ span: 6, order: 2 }}
              className="my-auto"
            >
              <IllustrationWrapper>
                <VideoWrapper>
                  <Video
                    src={editorVideo}
                    autoPlay
                    loop={true}
                    controls={false}
                    aria-label={
                      t(
                        "frontpage.sections.video-editor-selection.illustration.video-illustration.ariaLabel"
                      ) as string
                    }
                  />
                  <VideoBorder />
                </VideoWrapper>
              </IllustrationWrapper>
            </SelectionCol>
          </SelectionRow>
        </Wrapper>
      </Container>
    </Section>
  );
};
export default SelectionView;

import styled from "@emotion/styled";

export const Section = styled.section``;

export const Container = styled.div`
  max-width: 87.5rem;
  margin: 0 auto;
  padding: 4.6875rem 1.25rem;
  @media (max-width: 767px) {
    padding: 4rem 1.25rem;
  }
`;

export const Wrapper = styled.div`
  margin-bottom: 4.6875rem;
  @media (max-width: 767px) {
    margin-bottom: 3.125rem;
  }
`;

export const TextContentWrapper = styled.div`
  text-align: center;
`;

export const VideoWrapper = styled.div`
  margin-top: 3.125rem;
  overflow: hidden;
  border-radius: 1.25rem;
`;

export const DescriptionWrapper = styled.div`
  max-width: 46.875rem;
  margin: 0 auto;
  margin-top: 0.625rem;
`;

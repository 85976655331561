import { useCallback, useState } from "react";

const useFetchBlogposts = () => {
  const [blogposts, setBlogposts] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchBlogposts = useCallback(async (limit: number) => {
    try {
      setLoading(true);
      const request = await fetch(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/general/blogposts?limit=${limit}`,
        {
          method: "GET",
        }
      );
      const response = await request.json();
      if (response.error === null) {
        setLoading(false);
        setBlogposts(response.result);
      } else {
        throw response.error;
      }
    } catch (e) {
      setLoading(false);
      throw e;
    }
  }, []);

  return { loading, blogposts, fetchBlogposts };
};

export default useFetchBlogposts;

import React, { ReactNode, useEffect } from "react";
import { BrowserRouter, Routes as AllRoutes, Route } from "react-router-dom";
import Index from "./pages/index";
import Login from "./pages/login";
import DashboardHistory from "./pages/dashboard/transcripts";
import DashboardTranscript from "./pages/dashboard/transcript";
import { getAuth } from "firebase/auth";
import { firebaseApp } from "./firebase";
import Account from "./pages/dashboard/account";
import Register from "./pages/register";
import Validate from "./pages/validate";
import PrivacyPolicy from "./pages/privacy-policy";
import Cookies from "./pages/cookies";
import Contact from "./pages/contact";
import TermsOfUse from "./pages/terms-of-use";
import Blog from "./pages/blog";
import Article from "./pages/article";
import Blogposts from "./pages/dashboard/blogposts";
import Blogpost from "./pages/dashboard/edit-blogpost";
import NewBlogpost from "./pages/dashboard/new-blogpost";
import Workspace from "./pages/dashboard/workspace";
import useWorkspaces from "./hooks/useWorkspaces";
import useUser from "./hooks/useUser";
import NotFound from "./pages/not-found";
import ForgotPassword from "./pages/forgot-password";
import Pricing from "./pages/pricing";
import Webinar from "./pages/webinar";
import ThankYou from "./pages/thank-you";
import HelpCenter from "./pages/dashboard/help-center";
import DashboardSoundtracks from "./pages/dashboard/soundtracks";
import { IState } from "./types";
import { useAppDispatch, useAppSelector } from "./redux/hooks";

const Routes = () => {
  const dispatch = useAppDispatch();
  const auth = getAuth(firebaseApp);
  const activeUser = useAppSelector((state: IState) => state?.user?.details);
  const { fetchWorkspaces } = useWorkspaces();
  const { fetchUserDetails } = useUser();

  //Clickjacking prevention
  useEffect(() => {
    if (!window?.top?.location) return;
    if (window.top !== window.self) {
      window.top.location = window.self.location;
    }
  }, []);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        fetchWorkspaces();
        fetchUserDetails(user.uid);
      }
    });
  }, [dispatch, auth, fetchUserDetails, fetchWorkspaces]);

  const renderPage = (element: ReactNode) => {
    if (activeUser?.uid !== null) {
      return element;
    } else {
      window.location.href = "/login";
    }
  };

  return (
    <BrowserRouter>
      <AllRoutes>
        <Route path="/" element={<Index />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/validate/:id" element={<Validate />} />
        <Route
          path="/dashboard/transcripts"
          element={renderPage(<DashboardHistory />)}
        />
        <Route
          path="/dashboard/soundtracks"
          element={renderPage(<DashboardSoundtracks />)}
        />
        <Route
          path="/dashboard/transcripts/:id"
          element={renderPage(<DashboardTranscript />)}
        />
        <Route path="/dashboard/account" element={renderPage(<Account />)} />
        <Route
          path="/dashboard/workspace"
          element={renderPage(<Workspace />)}
        />
        <Route
          path="/dashboard/help-center"
          element={renderPage(<HelpCenter />)}
        />
        <Route path="/dashboard/admin/blogposts" element={<Blogposts />} />
        <Route path="/dashboard/admin/blogposts/:id" element={<Blogpost />} />
        <Route path="/dashboard/admin/blogpost/new" element={<NewBlogpost />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/terms" element={<TermsOfUse />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/webinar" element={<Webinar />} />
        <Route path="/webinar/thank-you" element={<ThankYou />} />
        <Route path="/article/:id" element={<Article />} />
        <Route path="*" element={<NotFound />} />
      </AllRoutes>
    </BrowserRouter>
  );
};

export default Routes;
